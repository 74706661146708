export enum FontColors {
    BACKGROUND = '--background',
    DARK = '--dark',
    DARK_GRAY = '--dark-gray',
    BRAND_GRAY= '--brand-gray',
    LIGHT_GRAY = '--light-gray',
    PRIMARY = '--primary',
    SECONDARY = '--secondary',
    LOW_PRIORITY = '--low-priority',
    MEDIUM_PRIORITY = '--medium-priority',
    HIGH_PRIORITY = '--high-priority',
    WHITE = '--white',
}

export enum FontSizes {
    SUPER_EXTRA_LARGE = '--super-extra-large',
    EXTRA_LARGE = '--extra-large',
    EXTRA_SMALL = '--extra-small',
    LARGE = '--large',
    REGULAR = '--regular',
    SMALL = '--small',
}
