import { SelectOption } from 'types/inputProps';

export const deepEqual = (object1: Record<string, unknown>, object2: Record<string, unknown>): boolean => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i += 1) {
    const key = keys1[i];
    const val1: unknown = object1[key];
    const val2: unknown = object2[key];

    if (
      val1 !== null
        && val2 !== null
        && typeof val1 === 'object'
        && typeof val2 === 'object'
    ) {
      if (!deepEqual(val1 as Record<string, unknown>, val2 as Record<string, unknown>)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
};

export type AccountData = {
    accountPrimaryEmail: string;
    accountFirstName: string;
    accountLastName: string;
    accountRelationship: SelectOption;
    accountAddress: string;
    accountCity: string;
    accountState: SelectOption;
    accountZip: string;
    accountPhone: string;
    accountNotificationEmail: string;
    accountReceiveEmails: boolean;
    accountReceiveTexts: boolean;
    accountUserRoleID: number;
    accountID: number;
    accountStatusID: number;
    accountPatientID: number;
    lastLoginDate: string;
    emailSentDate: string;
}

export type PatientData = {
    emergencyContactFirstName: string;
    emergencyContactLastName: string;
    emergencyContactRelationship: SelectOption;
    emergencyContactPhone: string;
    emergencyContactEmail: string;
    facility: SelectOption | { label: string, value: number };
    providerName: string;
    providerPhone: string;
    providerWebAddress: string;
    patientID: number;
    patientAccountID: number;
}
