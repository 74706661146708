import React, { useState, useEffect, useContext } from 'react';

import './styles.scss';

import PageLayout from 'global_elements/Layouts/PageLayout';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import FlexContainer from 'global_elements/Layouts/FlexContainer';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { FontColors } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { ActiveFacilityContext } from 'context/activeFacility';
import { PrintableAssessments } from './printableAssessment';

const PrintableAssessmentsPage = (): JSX.Element => {
  const { facility } = useContext(ActiveFacilityContext);
  const [facilityID, setFacilityID] = useState<number>();

  useEffect(() => {
    const tmpfacilityID = parseInt(facility.value, 10);
    if (!Number.isNaN(tmpfacilityID)) {
      setFacilityID(tmpfacilityID);
    }
  }, [facility]);

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Printable Assessments Page"
      loadingTitle="Printable Assessments"
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="printable-assessments-page-content"
      >
        <PrimaryHeader text="Printable Assessments" fontColor={FontColors.PRIMARY} />
        <PrintableAssessments
          facilityID={facilityID}
          showCardTitle={false}
        />
      </FlexContainer>
    </PageLayout>
  );
};

export default PrintableAssessmentsPage;
