import ApiProxyV2 from 'api/lib/ApiService/v2';
import ApiResponseV2 from 'api/lib/models/ApiResponse/v2';
import { URLS } from 'constants/appUrls';
import { AttachmentFolderContentsModel } from '../../interfaces/attachments/AttachmentFolderContentsModel';

class AttachmentsProxy {
  static CoreMeasuresHelpFolderId = '5c2acc81-11cc-481d-93c2-4b5d72cda2f2';

  static getCoreMeasuresHelpFolderContents(): Promise<ApiResponseV2<AttachmentFolderContentsModel>> {
    return this.getFolderContents(this.CoreMeasuresHelpFolderId);
  }

  static getFacilityContents(facilityId: number): Promise<ApiResponseV2<AttachmentFolderContentsModel>> {
    const url = `${URLS.MHO_API}/GetFacilityContents?facilityId=${facilityId}`;
    return ApiProxyV2.get<AttachmentFolderContentsModel>(url);
  }

  static getFolderContents(folderId: string): Promise<ApiResponseV2<AttachmentFolderContentsModel>> {
    const url = `${URLS.MHO_API}/GetAttachmentFolderContents?folderId=${folderId}`;
    return ApiProxyV2.get<AttachmentFolderContentsModel>(url);
  }

  static download(attachmentId: string): Promise<ApiResponseV2<Blob>> {
    const url = `${URLS.MHO_API}/DownloadAttachment?attachmentId=${attachmentId}`;
    return ApiProxyV2.get<Blob>(url, undefined, true);
  }

  static upload(folderId: string, files: FileList): Promise<ApiResponseV2<number>> {
    const url = `${URLS.MHO_API}/UploadAttachment`;
    const data = new FormData();
    data.append('attachmentFolderId', folderId);
    for (let i = 0; i < files.length; i += 1) {
      data.append('files', files[i]);
    }
    return ApiProxyV2.postFormData(url, data);
  }

  static saveFolder(folderId: string | undefined, parentFolderId: string, name: string): Promise<ApiResponseV2<undefined>> {
    const url = `${URLS.MHO_API}/SaveFolder`;
    return ApiProxyV2.post(url, { attachmentFolderId: folderId, parentAttachmentFolderId: parentFolderId, name });
  }

  static deleteFolder(folderId: string): Promise<ApiResponseV2<undefined>> {
    const url = `${URLS.MHO_API}/DeleteFolder/${folderId}`;
    return ApiProxyV2.post(url, undefined);
  }

  static saveAttachment(attachmentId: string, name: string): Promise<ApiResponseV2<undefined>> {
    const url = `${URLS.MHO_API}/SaveAttachment`;
    return ApiProxyV2.post(url, { attachmentId, name });
  }

  static deleteAttachment(attachmentId: string): Promise<ApiResponseV2<undefined>> {
    const url = `${URLS.MHO_API}/DeleteAttachment/${attachmentId}`;
    return ApiProxyV2.post(url, undefined);
  }
}

export default AttachmentsProxy;
