import { useHistory } from 'react-router-dom'
import './styles.scss'
import { useContext, useEffect, useState } from 'react'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PrimaryHeader from 'global_elements/Text/PrimaryHeader'
import Button from 'global_elements/Button'
import { ButtonVariant } from 'global_elements/Button/variants'
import InlineText from 'global_elements/Text/InlineText'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import SecondaryHeader from 'global_elements/Text/SecondaryHeader'
import { AllRoutes } from 'constants/routes'
import GroupTherapyProxy from 'api/groupTherapy/GroupTherapyProxy'
import { GroupTherapyGetSessionResponse, GroupTherapySessionWorkListRow } from 'api/groupTherapy/types'
import GroupTherapySessionWorkList from 'global_elements/Layouts/DataTables/GroupTherapySessionWorkList'
import { UserContext } from 'context/user'
import MHODateTime from 'domain/dateTime/MHODateTime'
import DataListsProxy from 'api/dataLists/dataListsProxy'
import { ListProgram } from 'interfaces/dataLists/listFacilityProgram'
import { ActiveFacilityContext } from 'context/activeFacility'
import Utilities from 'api/lib/Utilities'

const GroupTherapyDashboardPage = (): JSX.Element => {
  const [loadingPrograms, setLoadingPrograms] = useState<boolean>(true)
  const [loadingTable, setLoadingTable] = useState<boolean>(true)
  const history = useHistory()
  const [sessionRows, setSessionRows] = useState<GroupTherapySessionWorkListRow[]>([])
  const [programs, setPrograms] = useState<ListProgram[]>([])
  const { SetActiveGroupTherapySessionId } = useContext(UserContext)
  const { facility } = useContext(ActiveFacilityContext)

  function convertToGroupTherapySessionWorkRow(session: GroupTherapyGetSessionResponse): GroupTherapySessionWorkListRow {
    return {
      groupTherapyID: session.groupTherapyID,
      groupTherapyTitle: session.groupTherapyTitle,
      groupTherapyPIN: session.groupTherapyPIN,
      startDate: new MHODateTime(session.startDate).getJSDate().toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit' }),
      programLabel: programs.filter((program) => (program.programID === session.programID))[0].programName,
    }
  }

  useEffect(() => {
    if (!loadingTable) {
      Utilities.setCellTitles();
    }
  }, [loadingTable])

  useEffect(() => {
    if (facility.value) {
      DataListsProxy.getPrograms(parseInt(facility.value, 10)).then((programList: ListProgram[]) => {
        setPrograms(programList)
        setLoadingPrograms(false)
      })
    }
  }, [facility])

  useEffect(() => {
    if (!loadingPrograms) {
      GroupTherapyProxy.getGroupTherapySession('incomplete').then((result) => {
        const rows = result.map((session) => convertToGroupTherapySessionWorkRow(session))
        setSessionRows(rows)
        setLoadingTable(false)
      })
    }
  }, [loadingPrograms])

  const resumeSession = (groupId: number): void => {
    SetActiveGroupTherapySessionId(`${groupId}`)
    history.push(`${AllRoutes.GROUP_THERAPY_BASE}/${groupId}/participants`)
  }

  const startNewSession = async (): Promise<void> => {
    history.push(`${AllRoutes.GROUP_THERAPY_START_SESSION}`)
  }

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Group Therapy Dashboard Page"
      loadingText={loadingTable ? 'Loading...' : undefined}
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
      >
        <PrimaryHeader text="Group Therapy Sessions" fontColor={FontColors.PRIMARY} marginBottomPx={16} />

        <SecondaryHeader text="Start New Session" fontColor={FontColors.SECONDARY} marginBottomPx={16} />

        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="group-therapy-new-session"
        >
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={startNewSession}
            testid="start-new-session-button"
          >
            <InlineText
              text="Start New Session"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
        </FlexContainer>

        <SecondaryHeader text="Resume Session" fontColor={FontColors.SECONDARY} marginBottomPx={16} />

        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.CENTER}
          extraClasses="group-therapy-resume-sessions"
        >
          <GroupTherapySessionWorkList
            data={sessionRows}
            isLoading={loadingTable}
            onRowClicked={(selected) => resumeSession(selected.groupTherapyID)}
            onChangePage={Utilities.setCellTitles}
          />
        </FlexContainer>
      </FlexContainer>
    </PageLayout>
  )
}

export default GroupTherapyDashboardPage
