import * as React from 'react';
import PageLayout from 'global_elements/Layouts/PageLayout';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import TableauReportDashboard from 'pages/shared/tableau/dashboard';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'context/user';
import { AccessType } from 'types/facilityUserAccount';

const SuperCorporationDashboardPage = (): JSX.Element => {
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const { user } = React.useContext(UserContext);

  React.useEffect(() => {
    if (user?.accessType === AccessType.CORE_MEASURES) {
      history.push('core-measures-worklist');
    }
  });

  if (user?.accessType === AccessType.CORE_MEASURES) {
    return (
      <PageLayout layout={PageLayoutVariant.PADDED} testText="Super Corporation Dashboard Page" title="Super Corporation Dashboard">
        Redirecting...
      </PageLayout>
    );
  }

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Super Corporation Dashboard Page" title="Super Corporation Dashboard" loadingText={loading ? 'Loading Tableau...' : undefined}>
      <TableauReportDashboard setLoading={setLoading} />
    </PageLayout>
  );
};

export default SuperCorporationDashboardPage;
