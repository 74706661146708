import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.scss';

import DataListsProxy, { DataListType, ProgramListMode } from 'api/dataLists/dataListsProxy';
import WorklistProxy from 'api/CoreMeasures/WorklistProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import Paragraph from 'global_elements/Text/Paragraph';
import Button from 'global_elements/Button';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { AllRoutes } from 'constants/routes';
import { CoreMeasuresWorklistDataRow, CMPatientCompletionDataRow, IncompletePatientsDataRow } from 'types/tableProps';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { SelectOption, FacilitySelectOption } from 'types/inputProps';
import { UserContext } from 'context/user';
import { ActiveFacilityContext } from 'context/activeFacility';
import { CoreMeasuresPatientSearchCriteria } from 'interfaces/coreMeasures/coreMeasuresPatientSearchResult';
import Utilities from 'api/lib/Utilities';
import { CoreMeasurePatientSearchState } from 'types/CoreMeasurePatient';
import { LabeledDatePickerInput } from 'global_elements/Inputs/LabeledDatePickerInput';
import CoreMeasuresWorklistTable from 'global_elements/Layouts/DataTables/CoreMeasuresWorklistTable';
import CMPatientCompletionTable from 'global_elements/Layouts/DataTables/CMPatientCompletionTable';
import CollapsibleSection from 'global_elements/Layouts/CollapsibleSection';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import PopupWindow from 'global_elements/Layouts/PopupWindow';
import { ReactComponent as X } from 'icons/mho-x-icon.svg';
import IncompletePatientsTable from 'global_elements/Layouts/DataTables/IncompletePatientsTable';

const currDate = new Date();
const priorYear = currDate.getFullYear() - 1;

const CoreMeasuresWorklistPage = (): JSX.Element => {
  const { user, coreMeasuresPatientSearchData, SetCoreMeasuresPatientSearchData } = useContext(UserContext);
  const [searchState, setSearchState] = useState<CoreMeasurePatientSearchState>(
    coreMeasuresPatientSearchData?.query || {
      fromDate: new Date(`1/1/${priorYear}`), // Initial date range: January 1st of prior year
      toDate: null,
      dateRangeType: { value: '2', label: 'Discharge Date' }, // Set to discharge date initially
      sample: null,
      serviceCode: null,
      age: null,
      formCompletion: null,
      sdohCompletionType: null,
      accountNumber: '',
      medicalRecordNumber: '',
      executed: false,
      facility: undefined,
      currentPage: 1,
      program: null,
    },
  );
  const [patientData, setPatientData] = useState<CoreMeasuresWorklistDataRow[]>(coreMeasuresPatientSearchData?.data ?? []);
  const [completionGridData, setCompletionGridData] = useState<CMPatientCompletionDataRow[]>([]);
  const [incompletePatientsData, setIncompletePatientsData] = useState<IncompletePatientsDataRow[]>([]);
  const [selectedIncompleteMonth, setSelectedIncompleteMonth] = useState<string>('');
  const [availableFacilities, setAvailableFacilities] = useState<SelectOption[]>(coreMeasuresPatientSearchData?.availableFacilities ?? []);
  const { facility } = useContext(ActiveFacilityContext);
  const [sampleOptions, setSampleOptions] = useState<SelectOption[]>([]);
  const [ageRangeOptions, setAgeRangeOptions] = useState<SelectOption[]>([]);
  const [formCompletionOptions, setFormCompletionOptions] = useState<SelectOption[]>([]);
  const [sdohOptions, setSdohOptions] = useState<SelectOption[]>([]);
  const [dateRangeOptions, setDateRangeOptions] = useState<SelectOption[]>([]);
  const [serviceCodeOptions, setServiceCodeOptions] = useState<SelectOption[]>([]);
  const [programOptions, setProgramOptions] = useState<SelectOption[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingInitialWorklist, setIsLoadingInitialWorklist] = useState(true);
  const [showIncompletePatientsWindow, setShowIncompletePatientsWindow] = useState<boolean>(false);

  const contextFacilityFiltered = facility.value !== '' ? facility : undefined;
  const isAdmin = user?.role === 'admin' || user?.role === 'super-admin';

  const history = useHistory();
  const inputRef = React.useRef<any>();

  /**
   * Shared function to load the various drop downs for the System Alert Editor.
   *
   * @param optionType The 4 types of options: Platform, Severity, ClientType, and AlertType
   * @returns {Promise} the retrieved options list.
   */
  const loadDropdownOptions = (optionType: DataListType): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    DataListsProxy.getOptionList(
      optionType,
      (response) => {
        const dropdownOptions: SelectOption[] = [];

        if (response.data) {
          response.data.forEach((option) => {
            dropdownOptions.push({
              label: option.lookupDesc,
              value: option.lookupID.toString(),
            });
          });
        }
        resolve(dropdownOptions);
      },
      (error) => {
        console.log(error);
        reject(error);
      },
    );
  });

  const loadProgramOptions = (fid: number, isReload?: boolean): void => {
    setProgramOptions([]);
    // Need to clear out old program value when reloading programs
    if (isReload) {
      setSearchState((prevState) => ({
        ...prevState,
        program: null,
      }));
    }
    DataListsProxy.getPrograms(fid, ProgramListMode.CoreMeasures).then((results) => {
      const options = results.map((x) => ({
        label: x.programName,
        value: x.programID.toString(),
      }));

      setProgramOptions(options);
    });
  };

  const loadServiceCodeOptions = (fid: number, isReload?: boolean): void => {
    setServiceCodeOptions([]);
    // Need to clear out old service code value when reloading service codes
    if (isReload) {
      setSearchState((prevState) => ({
        ...prevState,
        serviceCode: null,
      }));
    }
    WorklistProxy.getServiceCodes(fid, ProgramListMode.CoreMeasures).then((results) => {
      if (results.data) {
        const options = results.data.map((x) => ({
          label: x.unitCode,
          value: x.unitCode,
        }));
        setServiceCodeOptions(options);
      }
    });
  };

  const loadAllDropdownOptions = (): void => {
    loadDropdownOptions(DataListType.SampleType).then((options) => setSampleOptions(options));
    loadDropdownOptions(DataListType.DateRangeType).then((options) => setDateRangeOptions(options));
    loadDropdownOptions(DataListType.AgeRangeType).then((options) => setAgeRangeOptions(options));
    loadDropdownOptions(DataListType.AssessmentCompletionType).then((options) => setFormCompletionOptions(options));
    loadDropdownOptions(DataListType.SdohCompletion).then((options) => setSdohOptions(options));
    if (searchState.facility?.value) {
      loadProgramOptions(parseInt(searchState.facility.value, 10));
      loadServiceCodeOptions(parseInt(searchState.facility.value, 10));
    }
  };

  const clearAllDropdowns = (): void => {
    setSampleOptions([]);
    setDateRangeOptions([]);
    setAgeRangeOptions([]);
    setFormCompletionOptions([]);
    setServiceCodeOptions([]);
    setProgramOptions([]);
    setSdohOptions([]);
  };

  const loadCompletionGridData = (fid: number): void => {
    setCompletionGridData([
      {
        month: 'Loading...',
        complete: '',
        total: '',
        completionPercent: '',
        leaveDays: '',
        monthID: -1,
        yearID: -1,
      },
    ]);
    WorklistProxy.GetCompletionGrid(fid).then((response) => {
      if (response.data) {
        const completionData: CMPatientCompletionDataRow[] = [];

        for (let i = 0; i < response.data.length; i += 1) {
          const completionDataRow = response.data[i];

          if (completionDataRow) {
            completionData.push({
              month: completionDataRow.completenessMonth,
              complete: completionDataRow.completeCnt.toString(),
              total: completionDataRow.totalCnt.toString(),
              completionPercent: completionDataRow.completePercent.toString(),
              leaveDays: completionDataRow.leaveDaysPresent,
              monthID: completionDataRow.monthID,
              yearID: completionDataRow.yearID,
            } as CMPatientCompletionDataRow);
          }
        }
        setCompletionGridData(completionData);
      }
    });
  };

  const search = (): void => {
    setSearchState((prevState) => ({
      ...prevState,
      executed: true,
    }));
    // ***added dischargeDate 2/2024
    setPatientData([
      {
        patientName: 'Searching...',
        patientID: -1,
        accountNumber: '',
        medicalRecordNumber: '',
        dateAdmitted: '',
        dateDischarged: '',
        age: '',
        serviceCode: '',
        complete: '',
        sample: '',
      } as CoreMeasuresWorklistDataRow,
    ]);

    let adjustedServiceCode: string | null;

    if (searchState.serviceCode?.value && searchState.serviceCode.value !== 'All Service Codes') {
      adjustedServiceCode = searchState.serviceCode.value;
    } else {
      // Need to set service code to null if you want all service codes
      adjustedServiceCode = null;
    }

    const searchCriteria: CoreMeasuresPatientSearchCriteria = {
      FacilityID: searchState.facility ? parseInt(searchState.facility.value, 10) : parseInt(facility.value, 10),
      DateRangeType: searchState.dateRangeType ? parseInt(searchState.dateRangeType.value, 10) : 0,
      DateRangeFrom: searchState.fromDate,
      DateRangeTo: searchState.toDate,
      SampleType: searchState.sample ? parseInt(searchState.sample.value, 10) : 0,
      serviceCode: adjustedServiceCode,
      sdohCompletionType: searchState.sdohCompletionType ? parseInt(searchState.sdohCompletionType.value, 10) : 0,
      AgeRangeType: searchState.age ? parseInt(searchState.age.value, 10) : 0,
      AssessmentCompletionType: searchState.formCompletion ? parseInt(searchState.formCompletion.value, 10) : 0,
      PatientNumber: searchState.accountNumber ? searchState.accountNumber.trim() : null,
      MedicalRecordNumber: searchState.medicalRecordNumber ? searchState.medicalRecordNumber.trim() : null,
      ProgramID: searchState.program ? parseInt(searchState.program.value, 10) : null,
    };

    WorklistProxy.Search(searchCriteria)
      .then((response) => {
        if (response.data) {
          const workList: CoreMeasuresWorklistDataRow[] = [];

          for (let i = 0; i < response.data.length; i += 1) {
            const worklistData = response.data[i];

            if (worklistData) {
              workList.push({
                patientID: worklistData.patientID,
                patientName: worklistData.patientName,
                accountNumber: worklistData.patientNumber,
                medicalRecordNumber: worklistData.medicalRecordNumber,
                dateAdmitted: worklistData.dateAdmitted,
                dateDischarged: worklistData.dateDischarged,
                age: worklistData.ageAtAdmission.toString(),
                serviceCode: worklistData.servicdeCode,
                complete: worklistData.complete,
                sample: worklistData.sample,
                sdoh: worklistData.sdohComplete ? worklistData.sdohComplete : '',
              } as CoreMeasuresWorklistDataRow);
            }
          }
          setPatientData(workList);
          setIsLoadingInitialWorklist(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setPatientData([]);
      });
  };

  useEffect(() => {
    if (!isAdmin && contextFacilityFiltered !== searchState.facility) {
      setPatientData([]);
      inputRef.current?.focus();
      setSearchState((prevState) => ({
        ...prevState,
        executed: false,
        facility: contextFacilityFiltered,
      }));
    }
  }, [contextFacilityFiltered]);

  useEffect(() => {
    if (searchState.facility?.value) {
      if (!searchState.facility.isCoreMeasures) {
        setErrorMessage('Core Measures features are not available for this facility.');
      } else {
        setErrorMessage('');
        loadProgramOptions(parseInt(searchState.facility.value, 10), true);
        loadServiceCodeOptions(parseInt(searchState.facility.value, 10), true);

        // Update patient completion grid for new facility
        if (searchState.facility?.isCoreMeasures) {
          loadCompletionGridData(parseInt(searchState.facility.value, 10));
        }
      }
    }
  }, [searchState.facility]);

  useEffect(() => {
    if (searchState.executed) {
      Utilities.setCellTitles();
    }
  }, [searchState]);

  useEffect(() => {
    if (searchState.executed) {
      SetCoreMeasuresPatientSearchData({
        query: searchState,
        data: patientData,
        availableFacilities,
      });
    } else {
      SetCoreMeasuresPatientSearchData(null);
    }
  }, [patientData, searchState]);

  useEffect(() => {
    if (patientData.length > 0) {
      Utilities.setCellTitles();
    }
  }, [patientData]);

  useEffect(() => {
    // Load dropdown options
    loadAllDropdownOptions();

    DataListsProxy.getAllUserFacilities(
      (response) => {
        if (response?.data) {
          const facilityOptions: FacilitySelectOption[] = [];

          response.data
            .sort((a, b) => {
              if (a.facilityName.toLowerCase() < b.facilityName.toLowerCase()) {
                return -1;
              }

              if (a.facilityName.toLowerCase() > b.facilityName.toLowerCase()) {
                return 1;
              }

              return 0;
            })
            .forEach((element) => {
              if (element.facilityName && element.facilityID) {
                facilityOptions.push({
                  label: element.facilityName,
                  value: element.facilityID.toString(),
                  isCoreMeasures: !!element.coreMeasures, // Convert numbers to boolean
                  hasServiceCodes: !!element.svcCode,
                });
              }
            });

          setAvailableFacilities(facilityOptions);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );

    // Populate patient grid on page load
    if (contextFacilityFiltered && contextFacilityFiltered.value !== '0' && contextFacilityFiltered.isCoreMeasures) {
      search();
    }
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      executed: false,
    }));
  };

  const handleDateRangeSelectChange = (option: SelectOption): void => {
    setSearchState((prevState) => ({
      ...prevState,
      dateRangeType: option,
      executed: false,
    }));
  };

  const handleFacilitySelectChange = (option: FacilitySelectOption): void => {
    setPatientData([]);
    setSearchState((prevState) => ({
      ...prevState,
      facility: option,
      executed: false,
    }));
  };

  const handleSampleSelectChange = (option: SelectOption): void => {
    setPatientData([]);
    setSearchState((prevState) => ({
      ...prevState,
      sample: option,
      executed: false,
    }));
  };

  const handleServiceCodeSelectChange = (option: SelectOption): void => {
    setPatientData([]);
    setSearchState((prevState) => ({
      ...prevState,
      serviceCode: option,
      executed: false,
    }));
  };

  const handleProgramSelectChange = (option: SelectOption): void => {
    setPatientData([]);
    setSearchState((prevState) => ({
      ...prevState,
      program: option,
      executed: false,
    }));
  };

  const handleAgeSelectChange = (option: SelectOption): void => {
    setPatientData([]);
    setSearchState((prevState) => ({
      ...prevState,
      age: option,
      executed: false,
    }));
  };

  const handleFormCompletionSelectChange = (option: SelectOption): void => {
    setPatientData([]);
    setSearchState((prevState) => ({
      ...prevState,
      formCompletion: option,
      executed: false,
    }));
  };

  const handleSdohSelectChange = (option: SelectOption): void => {
    setPatientData([]);
    setSearchState((prevState) => ({
      ...prevState,
      sdohCompletionType: option,
      executed: false,
    }));
  };

  const handleDatePickerChange = (selectedDate: Date, dateType: string): void => {
    // Don't clear the patient list if it's loading for the first time
    if (!isLoadingInitialWorklist) {
      setPatientData([]);
    }
    setSearchState((prevState) => ({
      ...prevState,
      [dateType]: selectedDate,
    }));
  };

  const goToPatientDetails = (row: CoreMeasuresWorklistDataRow, e: React.MouseEvent<Element, MouseEvent>): void => {
    e.preventDefault();
    // This will take the user to the patient info page
    history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${row.patientID}`);
  };

  // ****clear data
  const clearSearchCriteria = (): void => {
    setPatientData([]);
    // Clear all dropdown option lists (forces a reload so they can reset)
    clearAllDropdowns();
    setSearchState((prevState) => ({
      ...prevState,
      fromDate: null,
      toDate: null,
      dateRangeType: null,
      sample: null,
      age: null,
      formCompletion: null,
      serviceCode: null,
      program: null,
      accountNumber: '',
      medicalRecordNumber: '',
      executed: false,
    }));

    // Load dropdown options again
    loadAllDropdownOptions();
  };

  const invalidDate = (field: 'fromDate' | 'toDate'): string | undefined => {
    const admissionMinDate: Date = new Date(Date.now());
    const admissionMaxDate: Date = new Date(Date.now());
    const dischargeMinDate: Date = new Date(Date.now());
    const dischargeMaxDate: Date = new Date(Date.now());
    const myDate = searchState[field];

    admissionMaxDate.setDate(admissionMinDate.getDate() + 3);
    admissionMinDate.setFullYear(admissionMaxDate.getFullYear() - 5);
    dischargeMaxDate.setDate(admissionMinDate.getDate() + 3);
    dischargeMinDate.setFullYear(admissionMaxDate.getFullYear() - 5);

    if (searchState.dateRangeType?.value === '1') {
      if ((myDate && myDate > admissionMaxDate) || (myDate && myDate < admissionMinDate)) {
        return 'Invalid Admission Date';
      }
    }

    if (searchState.dateRangeType?.value === '2') {
      if ((myDate && myDate > dischargeMaxDate) || (myDate && myDate < dischargeMinDate)) {
        return 'Invalid Discharge Date';
      }
    }

    if (field === 'toDate' && myDate && searchState.fromDate && myDate < searchState.fromDate) {
      return 'To Must Be Later Than From';
    }

    return undefined;
  };

  const disableClearButton = (): boolean => {
    if (
      searchState.sample === null
      && searchState.age === null
      && searchState.formCompletion === null
      && searchState.serviceCode === null
      && searchState.dateRangeType === null
      && searchState.program === null
      && searchState.accountNumber === ''
      && searchState.medicalRecordNumber === ''
      && (!isAdmin || !searchState.facility)
    ) {
      return true;
    }

    return false;
  };

  const disableSearchButton = (): boolean => {
    if (errorMessage || ((facility.value === '' || facility.value === '0') && (!searchState.facility || searchState.facility.value === '0'))) {
      return true;
    }

    return false;
  };

  const onChangePage = (page: number): void => {
    Utilities.setCellTitles();

    setSearchState((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  const addPatient = (): void => {
    if (searchState.facility) {
      history.push(`${AllRoutes.PATIENT_REGISTRATION}/${searchState.facility.value}`);
    } else {
      history.push(`${AllRoutes.PATIENT_REGISTRATION}/${facility.value}`);
    }
  };

  const closeIncompletePatientsWindow = (): void => {
    setShowIncompletePatientsWindow(false);
  };

  const openIncompletePatientsWindow = (month: string, monthID: number, yearID: number): void => {
    setSelectedIncompleteMonth(month);
    setShowIncompletePatientsWindow(true);
    setIncompletePatientsData([{ name: 'Loading...', age: '', admissionDate: '', dischargeDate: '', patientNumber: '', medicalRecordNumber: '' }]);

    if (!searchState.facility) {
      setIncompletePatientsData([{ name: 'Error: no facility found', age: '', admissionDate: '', dischargeDate: '', patientNumber: '', medicalRecordNumber: '' }]);
      return;
    }
    const facilityID = parseInt(searchState.facility?.value, 10);

    WorklistProxy.GetIncompletePatients(facilityID, yearID, monthID).then((response) => {
      if (response.data) {
        const incompletePatients = response.data.map((result) => ({
          name: result.patientName,
          age: result.patientAge.toString(),
          admissionDate: result.dateAdmitted,
          dischargeDate: result.dateDischarged,
          patientNumber: result.patientNumber,
          medicalRecordNumber: result.medicalRecordNbr,
        }));

        setIncompletePatientsData(incompletePatients);
      }
    });
  };

  // ***Patient Accounts / Patient Search Page
  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Patient Search Page">
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-worklist-content">
        <form onSubmit={search}>
          <PrimaryHeader text="Core Measures Work List" fontColor={FontColors.PRIMARY} marginTopPx={16} marginBottomPx={16} />
          {isAdmin && availableFacilities.length === 0 && (
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Facility"
              options={[{ label: 'Loading...', value: '' }]}
              defaultValue={{ label: 'Loading...', value: '' }}
              value={{ label: 'Loading...', value: '' }}
              onSelection={handleFacilitySelectChange}
            />
          )}
          {isAdmin && availableFacilities.length > 0 && (
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Facility"
              options={availableFacilities}
              defaultValue={searchState.facility ?? { label: 'Select a Facility', value: '' }}
              value={searchState.facility}
              onSelection={handleFacilitySelectChange}
            />
          )}
          {!isAdmin && (facility.value === '' || facility.value === '0') && (
            <Paragraph text="Please select a facility in the header to search" fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.EXTRA_LARGE} />
          )}
          {errorMessage ? (
            <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.CENTER}>
              <InlineText text={errorMessage} fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.REGULAR} />
            </FlexContainer>
          ) : (
            <div>
              <CollapsibleSection expandedOnDefault banner={<TertiaryHeader text="Completion Grid" fontColor={FontColors.PRIMARY} />}>
                <CMPatientCompletionTable data={completionGridData} openIncompletePatients={(row) => openIncompletePatientsWindow(row.month, row.monthID, row.yearID)} />
              </CollapsibleSection>
              <CollapsibleSection banner={<TertiaryHeader text="Search Filters" fontColor={FontColors.PRIMARY} />}>
                <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.CENTER} extraClasses="core-measures-worklist-content_date-range-inputs">
                  {dateRangeOptions.length === 0 && (
                    <LabledSingleSelect
                      label="Date Type"
                      styles={SingleStandardDropdownStyles}
                      options={[]}
                      defaultValue={{ label: 'Loading...', value: '0' }}
                      value={searchState.dateRangeType ?? undefined}
                      onSelection={handleDateRangeSelectChange}
                    />
                  )}
                  {dateRangeOptions.length > 0 && (
                    <LabledSingleSelect
                      label="Date Type"
                      styles={SingleStandardDropdownStyles}
                      options={dateRangeOptions}
                      defaultValue={searchState.dateRangeType}
                      value={searchState.dateRangeType ?? undefined}
                      onSelection={handleDateRangeSelectChange}
                    />
                  )}
                  {searchState.dateRangeType?.value && searchState.dateRangeType.value !== '0' && (
                    <LabeledDatePickerInput
                      label="From"
                      name="fromDate"
                      onChange={(selectedDate) => handleDatePickerChange(selectedDate, 'fromDate')}
                      error={invalidDate('fromDate')}
                      testid="from-date-input"
                      initialValue={searchState.fromDate}
                    />
                  )}
                  {searchState.dateRangeType?.value && searchState.dateRangeType?.value !== '0' && (
                    <LabeledDatePickerInput label="To" name="toDate" onChange={(selectedDate) => handleDatePickerChange(selectedDate, 'toDate')} error={invalidDate('toDate')} testid="to-date-input" />
                  )}
                </FlexContainer>
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-worklist-content__list-inputs">
                  {sampleOptions.length === 0 && (
                    <LabledSingleSelect
                      label="Sample"
                      styles={SingleStandardDropdownStyles}
                      options={[]}
                      defaultValue={{ label: 'Loading...', value: '0' }}
                      value={searchState.sample ?? undefined}
                      onSelection={handleSampleSelectChange}
                    />
                  )}
                  {sampleOptions.length > 0 && (
                    <LabledSingleSelect
                      label="Sample"
                      styles={SingleStandardDropdownStyles}
                      options={sampleOptions}
                      defaultValue={sampleOptions[0]}
                      value={searchState.sample ?? undefined}
                      onSelection={handleSampleSelectChange}
                    />
                  )}
                  {ageRangeOptions.length === 0 && (
                    <LabledSingleSelect
                      label="Age"
                      styles={SingleStandardDropdownStyles}
                      options={[]}
                      defaultValue={{ label: 'Loading...', value: '0' }}
                      value={searchState.age ?? undefined}
                      onSelection={handleAgeSelectChange}
                    />
                  )}
                  {ageRangeOptions.length > 0 && (
                    <LabledSingleSelect
                      label="Age"
                      styles={SingleStandardDropdownStyles}
                      options={ageRangeOptions}
                      defaultValue={ageRangeOptions[0]}
                      value={searchState.age ?? undefined}
                      onSelection={handleAgeSelectChange}
                    />
                  )}
                  {formCompletionOptions.length === 0 && (
                    <LabledSingleSelect
                      label="Form Completion"
                      styles={SingleStandardDropdownStyles}
                      options={[]}
                      defaultValue={{ label: 'Loading...', value: '0' }}
                      value={searchState.formCompletion ?? undefined}
                      onSelection={handleFormCompletionSelectChange}
                    />
                  )}
                  {formCompletionOptions.length > 0 && (
                    <LabledSingleSelect
                      label="Form Completion"
                      styles={SingleStandardDropdownStyles}
                      options={formCompletionOptions}
                      defaultValue={formCompletionOptions[0]}
                      value={searchState.formCompletion ?? undefined}
                      onSelection={handleFormCompletionSelectChange}
                    />
                  )}
                  {sdohOptions.length === 0 && (
                    <LabledSingleSelect
                      label="SDOH Completion"
                      styles={SingleStandardDropdownStyles}
                      options={[]}
                      defaultValue={{ label: 'Loading...', value: '0' }}
                      value={searchState.sdohCompletionType ?? undefined}
                      onSelection={handleSdohSelectChange}
                    />
                  )}
                  {sdohOptions.length > 0 && (
                    <LabledSingleSelect
                      label="SDOH Completion"
                      styles={SingleStandardDropdownStyles}
                      options={sdohOptions}
                      defaultValue={sdohOptions[0]}
                      value={searchState.sdohCompletionType ?? undefined}
                      onSelection={handleSdohSelectChange}
                    />
                  )}
                </FlexContainer>
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-worklist-content__text-inputs">
                  {searchState.facility?.hasServiceCodes && serviceCodeOptions.length === 0 ? (
                    <LabledSingleSelect
                      label="Service Code"
                      styles={SingleStandardDropdownStyles}
                      // options={searchState.roleOptions}
                      options={[]}
                      // defaultValue={searchState.roleOptions[0]}
                      defaultValue={{ label: 'Loading...', value: '0' }}
                      value={searchState.serviceCode ?? undefined}
                      onSelection={handleServiceCodeSelectChange}
                    />
                  ) : (
                    ''
                  )}
                  {searchState.facility?.hasServiceCodes && serviceCodeOptions.length > 0 ? (
                    <LabledSingleSelect
                      label="Service Code"
                      styles={SingleStandardDropdownStyles}
                      // options={searchState.roleOptions}
                      options={serviceCodeOptions}
                      // defaultValue={searchState.roleOptions[0]}
                      defaultValue={serviceCodeOptions[0]}
                      value={searchState.serviceCode ?? undefined}
                      onSelection={handleServiceCodeSelectChange}
                    />
                  ) : (
                    ''
                  )}
                  {!searchState.facility?.hasServiceCodes && programOptions.length === 0 ? (
                    <LabledSingleSelect
                      label="Program"
                      styles={SingleStandardDropdownStyles}
                      // options={searchState.roleOptions}
                      options={[]}
                      defaultValue={{ label: 'Loading...', value: '0' }}
                      value={searchState.program ?? undefined}
                      onSelection={handleProgramSelectChange}
                    />
                  ) : (
                    ''
                  )}
                  {!searchState.facility?.hasServiceCodes && programOptions.length > 0 ? (
                    <LabledSingleSelect
                      label="Program"
                      styles={SingleStandardDropdownStyles}
                      // options={searchState.roleOptions}
                      options={programOptions}
                      defaultValue={programOptions[0]}
                      value={searchState.program ?? undefined}
                      onSelection={handleProgramSelectChange}
                    />
                  ) : (
                    ''
                  )}
                  <LabledTextInput
                    label="Account Number"
                    placeholder="Search By Account Number..."
                    name="accountNumber"
                    value={searchState.accountNumber}
                    onChange={handleInputChange}
                    type="text"
                    variant={TextInputVariant.PRIMARY}
                  />
                  <LabledTextInput
                    label="Medical Record"
                    placeholder="Search By Medical Record..."
                    name="medicalRecordNumber"
                    value={searchState.medicalRecordNumber}
                    onChange={handleInputChange}
                    type="text"
                    variant={TextInputVariant.PRIMARY}
                  />
                </FlexContainer>
              </CollapsibleSection>

              <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="button-container__search">
                <Button variant={ButtonVariant.SECONDARY} onClick={clearSearchCriteria} disabled={disableClearButton()}>
                  <InlineText text="Clear" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                </Button>
                <Button variant={ButtonVariant.PRIMARY} onClick={search} disabled={disableSearchButton()} submit>
                  <InlineText text="Search" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                </Button>
              </FlexContainer>
            </div>
          )}
        </form>
        {(patientData.length > 0 || searchState.executed) && (
          <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="card-primary">
            <CoreMeasuresWorklistTable data={patientData} onRowClicked={goToPatientDetails} onChangePage={onChangePage} defaultPage={searchState.currentPage} />
          </FlexContainer>
        )}
        {Boolean(searchState.facility?.isCoreMeasures) && (
          <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="button-container__add-account">
            <Button variant={ButtonVariant.PRIMARY} onClick={addPatient} disabled={!searchState.executed}>
              <InlineText text="Add Patient" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
            </Button>
          </FlexContainer>
        )}

        {showIncompletePatientsWindow && (
          <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="popup-window-wrapper">
            <PopupWindow>
              <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="popup-window-content">
                <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.CENTER} extraClasses="popup-window-header">
                  <TertiaryHeader text={`Incomplete Patients for ${selectedIncompleteMonth}`} fontColor={FontColors.PRIMARY} />
                  <Button
                    variant={ButtonVariant.INVISIBLE}
                    onClick={(e) => {
                      e?.preventDefault();
                      e?.stopPropagation();
                      closeIncompletePatientsWindow();
                    }}
                  >
                    <X />
                  </Button>
                </FlexContainer>
                <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.START}>
                  <IncompletePatientsTable data={incompletePatientsData} />
                </FlexContainer>
              </FlexContainer>
            </PopupWindow>
          </FlexContainer>
        )}
      </FlexContainer>
    </PageLayout>
  );
};

export default CoreMeasuresWorklistPage;
