import { useEffect, useState } from 'react';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import moment, { Moment } from 'moment';
import Datetime from 'react-datetime';
import Paragraph from 'global_elements/Text/Paragraph';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'global_elements/Button';
import { ButtonVariant } from 'global_elements/Button/variants';
import './styles.scss';
import { TextInputVariant } from '../TextInput/variants';
import { maskTextInputAsDate } from '../TextInput/lib/date';

export interface LabeledDatePickerInputProps {
  label: string;
  name: string;
  error?: string;
  onChange: (value: Date) => void;
  isValidDate?: (current: Moment) => boolean;
  maskInput?: boolean;
  testid?: string;
  isDisabled?: boolean;
  initialValue?: Date | null;
}

const isValidDateString = (dateString: string): boolean => {
  const parts = dateString.split('/');

  if (parts.length !== 3) {
    return false;
  }

  const month = parseInt(parts[0], 10);
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  if (Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(year)) {
    return false;
  }

  if (month < 1 || month > 12) {
    return false;
  }

  if (day < 1 || day > 31) {
    return false;
  }

  if (year < 1000) {
    return false;
  }

  return true;
};

export const LabeledDatePickerInput = (props: LabeledDatePickerInputProps): JSX.Element => {
  const [dateUserInput, setDateUserInput] = useState<string | undefined>(props.initialValue ? props.initialValue.toLocaleDateString('en-US') : undefined);
  const [date, setDate] = useState<Date | null>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const { error, isDisabled } = props;
  const [datePickerClassName, setDatePickerClassName] = useState('');
  const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setDatePickerClassName(`input-text ${TextInputVariant.ERROR}`);
    } else if (isDisabled) {
      setDatePickerClassName(`input-text ${TextInputVariant.DISABLED}`);
    } else {
      setDatePickerClassName(`input-text ${TextInputVariant.PRIMARY}`);
    }
  }, [error, isDisabled]);

  useEffect(() => {
    if (dateUserInput && isValidDateString(dateUserInput)) {
      setDate(new Date(dateUserInput));
    } else if (date !== null) {
      setDate(null);
    }
  }, [dateUserInput]);

  useEffect(() => {
    if (date) {
      props.onChange(date);
    }
  }, [date]);

  const handleOnChange = (value: string | Moment): void => {
    let valueMoment = null;
    if (typeof value === 'string') {
      const newValue = maskTextInputAsDate(value) ?? value;
      setDateUserInput(newValue);
      if (newValue) {
        valueMoment = moment(newValue, 'M/d/yyyy', true);
      }
    } else {
      setDate(value.toDate());
      setDateUserInput(value.format('MM/DD/yyyy'));
      setIsDatePickerOpen(false);
      valueMoment = value;
    }
    setIsInvalidDate(valueMoment != null && !valueMoment.isValid());
  };

  return (
    <label>
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} testid={props.testid}>
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="labeled-date-picker-input__header">
          <Paragraph text={props.label} fontColor={(isInvalidDate || error) ? FontColors.HIGH_PRIORITY : FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} bold />
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START}>
          <Datetime
            value={date ?? dateUserInput ?? ''}
            onChange={handleOnChange}
            inputProps={{
              className: datePickerClassName,
              name: props.name,
              autoComplete: props.maskInput ? 'new-password' : 'on',
              value: dateUserInput ?? '',
              maxLength: 10,
              type: props.maskInput ? 'password' : 'text',
              inputMode: 'numeric',
              disabled: isDisabled,
            }}
            isValidDate={props.isValidDate}
            open={isDatePickerOpen}
            timeFormat={false}
          />
          <Button variant={ButtonVariant.PRIMARY} onClick={() => setIsDatePickerOpen(!isDatePickerOpen)} extraClasses="datepicker-button" testid="datepicker-button" disabled={isDisabled}>
            <FontAwesomeIcon icon={faCalendar} size="xl" />
          </Button>
        </FlexContainer>
        {(isInvalidDate || error) && <Paragraph text={(isInvalidDate ? 'Invalid Date Entered' : (error ?? ''))} fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.REGULAR} bold />}
      </FlexContainer>
    </label>
  );
};
