import * as React from 'react';
import MHODateTime from 'domain/dateTime/MHODateTime';

import './styles.scss';

import Button from 'global_elements/Button';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import Paragraph from 'global_elements/Text/Paragraph';
import { ButtonVariant } from 'global_elements/Button/variants';
import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { PatientRoutes } from 'constants/routes';
import { PatientAssessmentCardProps } from 'types/cardProps';
import { useHistory } from 'react-router-dom';

const PatientAssessmentCard = (props: PatientAssessmentCardProps): JSX.Element => {
  const history = useHistory();
  const searchParams = new URLSearchParams();

  let isValidDate = false;
  if (props?.lastCompleted) {
    searchParams.append('date', props.lastCompleted);
    searchParams.append('awid', props.appliedWhenID.toString());
    const tmpDate = new MHODateTime(props.lastCompleted);
    isValidDate = !tmpDate.isUninitializedMHODate();
  }
  const isResult: boolean = props.cta === 'view';
  let clickUrl: string;

  if (isResult) {
    const queryParam = isValidDate ? `?${searchParams.toString()}` : '';
    clickUrl = `${PatientRoutes.ASSESSMENT_RESULTS}/${props.careID}/${props.typeID}/${props.patientID}${queryParam}`;
  } else if (props?.isTodo) {
    const queryParam = new URLSearchParams();
    queryParam.append('startAssessment', props.assessmentNumber.toString());
    clickUrl = `${PatientRoutes.ASSESSMENTS}?${queryParam.toString()}`;
  } else {
    clickUrl = `${PatientRoutes.ASSESSMENT}/${props.id}/${props.typeID}/${props.appliedWhenID}`;
  }

  return (
    <FlexContainer
      display={DisplayVariant.FLEX_ROW}
      justify={JustifyVariant.SPACE_BETWEEN}
      align={AlignVariant.CENTER}
      extraClasses="patient-assessment-card-wrapper"
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        justify={JustifyVariant.SPACE_BETWEEN}
        align={AlignVariant.START}
        extraClasses="patient-assessment-card-left"
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          justify={JustifyVariant.START}
          align={AlignVariant.START}
          extraClasses="patient-assessment-card-left-top"
        >
          <Paragraph
            text={props.title}
            fontColor={FontColors.SECONDARY}
            fontSize={FontSizes.REGULAR}
            bold
          />
          <Paragraph
            text={props.type}
            fontColor={FontColors.DARK}
            fontSize={FontSizes.SMALL}
          />
        </FlexContainer>
        {props.lastCompleted && isValidDate
        && (
          <Paragraph
            text={`Last completed: ${new MHODateTime(props.lastCompleted).getFormattedCalendarDate()}`}
            fontColor={FontColors.DARK}
            fontSize={FontSizes.SMALL}
            bold
          />
        )}
      </FlexContainer>
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        justify={JustifyVariant.CENTER}
        align={AlignVariant.START}
        extraClasses="patient-assessment-card-right"
      >
        <Button
          extraClasses="btn-fill"
          variant={ButtonVariant.INVISIBLE}
          onClick={() => history.push(clickUrl)}
        >
          <InlineText
            text={props.cta[0].toUpperCase() + props.cta.substring(1)}
            fontColor={FontColors.BACKGROUND}
            fontSize={FontSizes.REGULAR}
            bold
          />
        </Button>
      </FlexContainer>
    </FlexContainer>
  );
};

export default React.memo(PatientAssessmentCard);
