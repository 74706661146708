import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export const DualListIcons = {
  moveLeft: <FontAwesomeIcon icon={faChevronLeft} />,
  moveAllLeft: [
    <FontAwesomeIcon key={0} icon={faChevronLeft} />,
    <FontAwesomeIcon key={1} icon={faChevronLeft} />,
  ],
  moveRight: <FontAwesomeIcon icon={faChevronRight} />,
  moveAllRight: [
    <FontAwesomeIcon key={0} icon={faChevronRight} />,
    <FontAwesomeIcon key={1} icon={faChevronRight} />,
  ],
  moveDown: <FontAwesomeIcon icon={faChevronUp} />,
  moveUp: <FontAwesomeIcon icon={faChevronDown} />,
};
