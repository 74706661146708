import * as React from 'react';

import './styles.scss';

import PageLayout from 'global_elements/Layouts/PageLayout';
import MentalHealthResourceCard from 'global_elements/Layouts/Cards/MentalHealthResourceCard';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { MentalHealthResourceCardProps } from 'types/cardProps';
import { FontColors } from 'global_elements/Text/variants';

const HOTLINE_CARDS: MentalHealthResourceCardProps[][] = [
  [
    {
      type: 'phone',
      title: 'National Suicide Prevention Lifeline',
      description: 'Free, confidential, 24/7 support and prevention and crisis resources for you or your loved ones.',
      resource: '800-273-8255',
    },
    {
      type: 'phone',
      title: 'National Helpline',
      description: 'Free, confidential, 24/7 mental health and/or treatment referrals and information.',
      resource: '800-662-4357',
    },
    {
      type: 'decorative',
      title: 'National Alliance on Mental Illness (NAMI) Helpline',
      description: 'Connect with a crisis counselor 24/7 via text message.',
      resource: 'Text HOME to 741741',
    },
    {
      type: 'phone',
      title: 'National Domestic Violence Hotline',
      description: 'Free, confidential, 24/7 support for those affected by domestic violence.',
      resource: '800-799-7233',
    },
    {
      type: 'phone',
      title: 'Disaster Distress Helpline',
      description: 'Free, confidential, 24/7 crisis counseling, referrals, and support for people who are experiencing emotional distress related to any natural or human-caused disaster.',
      resource: '800-985-5990',
    },
  ],
];

const URL_CARDS: MentalHealthResourceCardProps[][] = [
  [
    {
      type: 'url',
      title: 'National Suicide Prevention Lifeline',
      description: 'Free and confidential emotional support for people in suicidal crisis or emotional distress 24 hours a day, 7 days a week.',
      resource: 'https://suicidepreventionlifeline.org/',
    },
    {
      type: 'url',
      title: 'National Alliance on Mental Illness (NAMI)',
      description: 'Connect with the NAMI HelpLine Monday through Friday, 10 a.m.–8 p.m., ET. 1-800-950-NAMI (6264) or info@nami.org for support and answers to questions.',
      resource: 'https://nami.org/',
    },
    {
      type: 'url',
      title: 'Substance Abuse and Mental Health Services Administration (SAMHSA)',
      description: 'Education and resources for those impacted by mental health and substance abuse disorders.',
      resource: 'https://www.samhsa.gov/',
    },
    {
      type: 'url',
      title: 'SAMHSA Treatment Locator',
      description: 'Information on thousands of state-licensed providers who specialize in treating substance use disorders, addiction, and mental illness.',
      resource: 'https://findtreatment.gov/',
    },
    {
      type: 'url',
      title: 'Mental Health America (MHA)',
      description: 'Connect with education, prevention, intervention, and support services for mental health.',
      resource: 'https://www.mhanational.org/',
    },
    {
      type: 'url',
      title: 'Anxiety and Depression Association of America (ADAA)',
      description: 'Education, practice, and research focused on improving quality of life for those with anxiety, depression, OCD, PTSD, and co-occurring disorders.',
      resource: 'https://adaa.org/',
    },
    {
      type: 'url',
      title: 'Depression and Bipolar Support Alliance',
      description: 'DBSA provides hope, help, support and education to improve the lives of people living with mood disorders (depression and bipolar disorder).',
      resource: 'https://www.dbsalliance.org/',
    },
    {
      type: 'url',
      title: 'Military OneSource',
      description: 'A free service provided by the Department of Defense to service members and their families to help with a broad range of mental health and other concerns.',
      resource: 'https://www.militaryonesource.mil/',
    },
    {
      type: 'url',
      title: 'VA Mental Health',
      description: 'Connects veterans to mental health services provided by the VA for veterans and their families.',
      resource: 'https://www.mentalhealth.va.gov/index.asp',
    },
  ],
];

const PatientResourcesPage = (): JSX.Element => {
  const [hotlineCards] = React.useState<MentalHealthResourceCardProps[][]>(HOTLINE_CARDS);
  const [URLCards] = React.useState<MentalHealthResourceCardProps[][]>(URL_CARDS);

  const urlCardGroup: JSX.Element = (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.START}
      justify={JustifyVariant.START}
      extraClasses="mhr-card-group"
    >
      <SecondaryHeader text="Resources" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.START}
        justify={JustifyVariant.SPACE_BETWEEN}
        extraClasses="mhr-card-group__resources"
        wrap
      >
        {URLCards[0].map((card) => (
          <MentalHealthResourceCard
            key={card.title}
            title={card.title}
            description={card.description}
            resource={card.resource}
            type={card.type}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );

  const hotlineCardGroup: JSX.Element = (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.START}
      justify={JustifyVariant.START}
      extraClasses="mhr-card-group"
    >
      <SecondaryHeader text="Hotlines" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.START}
        justify={JustifyVariant.SPACE_BETWEEN}
        extraClasses="mhr-card-group__resources"
        wrap
      >
        {hotlineCards[0].map((card) => (
          <MentalHealthResourceCard
            title={card.title}
            description={card.description}
            resource={card.resource}
            type={card.type}
          />
        ))}
      </FlexContainer>
    </FlexContainer>
  );

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Patient Resources Page"
      bannerCard={hotlineCardGroup}
    >
      {urlCardGroup}
    </PageLayout>
  );
};
export default PatientResourcesPage;
