export enum SurveyJSQuestionTypes {
  TEXT = 'text',
  CHECKBOX = 'checkbox',
  RADIO_GROUP = 'radiogroup',
  DROPDOWN = 'dropdown',
  MATRIX_SINGLE = 'matrix',
  HTML = 'html',
  PANEL = 'panel',
  EXPRESSION = 'expression',
  RATING = 'rating',
  COMMENT = 'comment',
  DYNAMIC_PANEL = 'paneldynamic',
}
