import { HiddenQuestionNames } from 'constants/MHO/hiddenQuestionNames';
import { Roles, UserRole } from 'constants/roles';
import { AllRoutes } from 'constants/routes';
import * as Survey from 'survey-react';

/**
 * TEMPORARY until we have the API updated.
 */
export function getResponseRoleID(role: UserRole): number {
  switch (role) {
    case Roles.PATIENT:
      return 1;
    case Roles.PROVIDER:
      return 2;
    case Roles.FACILITY_DIRECTOR:
      return 3;
    case Roles.FACILITY_EXECUTIVE:
      return 4;
    case Roles.CORPORATION:
      return 5;
    case Roles.SUPER_CORPORATION:
      return 6;
    case Roles.ADMIN:
      return 7;
    case Roles.SUPER_ADMIN:
      return 8;
    default:
      return 0; // unknown role
  }
}

export interface AssessmentResultsDescriptorConfig {
  assessmentNumber: string
  careId: number
  instrumentTypeId: number
  assessmentPatientId: number
  daisyChain?: boolean
}

export interface LocationDescriptor {
  pathname: string
  search: string
}

export function getAssessmentResultsDescriptor(survey: Survey.Model, {
  assessmentNumber,
  assessmentPatientId,
  careId,
  daisyChain,
  instrumentTypeId,
}: AssessmentResultsDescriptorConfig): LocationDescriptor | null {
  // When the survey is complete, redirect to the results page.
  const searchParams = new URLSearchParams();
  const question = survey.getQuestionByName(HiddenQuestionNames.COMPLETED_DATE);
  if (question && question.value) {
    searchParams.append('date', question.value);
  }
  if (daisyChain) {
    searchParams.append('daisyChain', '1')
  }
  const resultsUrl = `${AllRoutes.ASSESSMENT_RESULTS}/${careId}/${instrumentTypeId}/${assessmentPatientId}`;

  // Only route to results if not a FUQ, noted by the following assessment number url parameters, or instrument type id.
  const fuqAssessmentNumbers = ['100005', '100007', '100008', '100009', '100010'];
  const fuqInstrumentTypeId = 146;
  if (fuqAssessmentNumbers.includes(assessmentNumber) || instrumentTypeId === fuqInstrumentTypeId) return null;

  return {
    pathname: resultsUrl,
    search: searchParams.toString(),
  };
}
