import * as React from 'react';

import { ContextProviderProps } from 'types/contextProviderProps';
import { FacilitySelectOption } from 'types/inputProps';

import { ActiveFacilityContext } from 'context/activeFacility';

const ActiveFacilityProvider = (props: ContextProviderProps): JSX.Element => {
  const [currentFacility, setCurrentFacility] = React.useState<FacilitySelectOption>({ label: '', value: '', isCoreMeasures: false, hasServiceCodes: false });

  return (
    <ActiveFacilityContext.Provider
      value={{
        facility: currentFacility,
        SwitchFacility: (option: FacilitySelectOption): void => {
          setCurrentFacility(option);
        },
      }}
    >
      {props.children}
    </ActiveFacilityContext.Provider>
  );
};

export default ActiveFacilityProvider;
