import './styles.scss';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import Paragraph from 'global_elements/Text/Paragraph';
import CollapsibleSection from '../CollapsibleSection';

const AccordionList = (props: { title: string, listItems: Map<number, any[]> }): JSX.Element => {
  const keys = Array.from(props.listItems.keys());
  const items = Array.from(props.listItems.values());
  const getFaqItems = (): JSX.Element[] => (
    keys.map((key: number, idx: number) => (
      <CollapsibleSection
        key={key}
        arrowAtLeft
        bottomDivider
        banner={
          <TertiaryHeader text={items[idx][0].helpFAQName} fontColor={FontColors.PRIMARY} />
        }
      >
        {items[idx].map((item: any): JSX.Element => (
          <>
            <Paragraph
              text={item.helpFAQLabel}
              fontColor={FontColors.DARK}
              fontSize={FontSizes.REGULAR}
              extraClasses="bold"
            />
            <InlineText
              text={item.faqText}
              fontColor={FontColors.DARK}
              fontSize={FontSizes.REGULAR}
            />
          </>
        ))}
      </CollapsibleSection>
    ))
  );

  return (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.START}
      justify={JustifyVariant.START}
      extraClasses="faq-container"
    >
      <SecondaryHeader text={props.title} fontColor={FontColors.PRIMARY} marginBottomPx={16} />
      {getFaqItems()}
    </FlexContainer>
  );
};

export default AccordionList;
