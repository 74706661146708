import React, { useEffect, useState, useRef } from 'react';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import InlineText from 'global_elements/Text/InlineText';

import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import Paragraph from 'global_elements/Text/Paragraph';
import { AssessmentScoreDefinition } from 'domain/Forms/MHO/AssessmentScoreDefinition';
import AssessmentProxy from 'api/assessments/assessmentProxy';

import './styles.scss';
import { awSortOrders } from 'pages/shared/AssessmentResult/appliedWhenSortOrder';

export type PatientScoreCardProp = {
  isPatient: boolean
  patientID: number
  careID: number
  instrumentTypeID: number
  useTitle?: boolean
  showScores?: boolean
  isOverallView?: boolean
  sequence?: number
  dateProcessed?: string
  maxAppliedWhenId?: number
  onLoaded?: () => void
}

/**
 * Manages the Patient Score Card.  Represents the scores "Patient", "Program", and "Comparison" for
 * non-patients, and "Patient scores" (worded as Assessment Scores) for patients.
 * Temporarily manges the title for the card and subscale/component.
 *
 * @param props props for the Patient Score component.
 * @returns JSX Element for the patient score component.
 */
export const PatientScoreCard = (props: PatientScoreCardProp): JSX.Element => {
  const [assessmentScores, setAssessmentScores] = useState<AssessmentScoreDefinition[]>([]);
  const [selectedScore, setSelectedScore] = useState<AssessmentScoreDefinition|null>(null);
  const [assessmentTitle, setAssessmentTitle] = useState<string>(props.isPatient ? 'Score' : 'Scores');
  const scoreNodeRef = useRef<(HTMLDivElement | null)>(null);

  const getSequenceNumber = ((): number | undefined => (props.sequence));
  const isSubsection = ((): boolean => (!!getSequenceNumber()));
  const hasScores = ((): boolean => (assessmentScores.length > 0));

  /**
   * Get and set the assessment scores.
   */
  useEffect(() => {
    let scorePromise: Promise<AssessmentScoreDefinition[]>;

    if (props.isPatient) {
      scorePromise = AssessmentProxy.getUserAssessmentScore(
        props.careID, props.instrumentTypeID, props.isOverallView,
      );
    } else {
      scorePromise = AssessmentProxy.getPatientAssessmentScore(
        props.patientID, props.instrumentTypeID, props.isOverallView,
      );
    }

    const maxAppliedWhenSort = awSortOrders.find((c) => c.awid === props.maxAppliedWhenId);

    scorePromise.then((scores: AssessmentScoreDefinition[]) => {
      const filteredScores: AssessmentScoreDefinition[] = scores
        .map((v) => {
          const awidSortOrder = awSortOrders.find((c) => c.awid === v.appliedWhenID);
          return { ...v, awidSort: awidSortOrder ? awidSortOrder.sortOrder : 8 };
        })
        .filter((score) => score.showScores > 0)
        .filter((score) => score.awidSort <= (maxAppliedWhenSort ? maxAppliedWhenSort.sortOrder : 999))
        .filter((score) => score.subscaleSequence === getSequenceNumber());

      const count = filteredScores.length;
      if (count > 0) {
        let score: AssessmentScoreDefinition = filteredScores[count - 1]; // last score
        if (props.dateProcessed) {
          const index = filteredScores.findIndex((s) => s.dateProcessed.toString() === props.dateProcessed);
          if (index > -1) {
            score = filteredScores[index];
          }
        }

        if (props.useTitle) {
          let title = 'Overall';
          if (isSubsection() && !props.isOverallView && score.subscale) {
            title = `${score.subscale}`;
          }
          setAssessmentTitle(title);
        }

        if (props.showScores) {
          setSelectedScore(score);
        }
      }

      setAssessmentScores(scores);
      if (props.onLoaded) props.onLoaded();
    }).catch((jsonDefinitionError: any): void => {
      console.log(jsonDefinitionError);
      if (props.onLoaded) props.onLoaded();
    });
  }, [props.careID, props.instrumentTypeID, props.patientID, props.isOverallView, props.dateProcessed]);

  useEffect(() => {
    const filteredScores: AssessmentScoreDefinition[] = assessmentScores
      .filter((score) => score.showScores > 0)
      .filter((score) => score.subscaleSequence === getSequenceNumber());

    const scoreElement = scoreNodeRef.current;
    const count = filteredScores.length;
    if (count > 0) {
      scoreElement?.classList.remove('hidden');
      const chartContainer = scoreElement?.closest('.score-container');
      chartContainer?.classList.remove('hidden');
    } else if (props.useTitle) {
      // If there are no scores, and this is set to use custom titles, just unhide the title only.
      const chartContainer = scoreElement?.closest('.score-container:not(.card-container--standalone)');
      chartContainer?.classList.remove('hidden');
    }
  }, [assessmentScores]);

  /**
   * Show a single score "<label> Score: <score>".
   *
   * @param label prefix to the "Score:" name. If it's not empty, "<label> Score:".
   * @param score score value for the
   * @returns JSX.Element: Either a empty element <></> if it's not to show score, or the score element.
   */
  const showScoreSection = ((label: string, score: string, maxScore?: string): JSX.Element => {
    if (!props.showScores) {
      return <></>;
    }

    let scoreText = 'Score:';
    if (label && label.trim()) {
      scoreText = `${label.trim()} Score:`;
    }

    return (
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.CENTER}
        justify={JustifyVariant.START}
      >
        <Paragraph
          text={scoreText}
          fontColor={FontColors.DARK_GRAY}
          fontSize={FontSizes.SMALL}
          bold
          extraClasses="score-name"
        />
        <InlineText text={maxScore ? `${score} out of ${maxScore}` : score} fontColor={FontColors.SECONDARY} fontSize={FontSizes.LARGE} />
      </FlexContainer>
    );
  });

  const patientScoreLabel = props.isPatient ? '' : 'Patient';

  if (!hasScores()) {
    return <></>;
  }
  return (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.START}
      justify={JustifyVariant.SPACE_AROUND}
      extraClasses="card-primary"
    >
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses=""
      >
        {props.useTitle && (
          <TertiaryHeader
            text={assessmentTitle}
            fontColor={FontColors.PRIMARY}
            marginBottomPx={16}
            testId="assessment-title-tertiary-header"
          />
        )}
        {!props.useTitle && (
          <SecondaryHeader
            text={assessmentTitle}
            fontColor={FontColors.PRIMARY}
            marginBottomPx={16}
          />
        )}
      </FlexContainer>
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.START}
        justify={JustifyVariant.SPACE_BETWEEN}
        ref={scoreNodeRef}
        extraClasses="card-content hidden"
      >
        {showScoreSection(patientScoreLabel, `${selectedScore?.patientScore}`, `${selectedScore?.chartMax}`)}
        {!props.isPatient && showScoreSection('Program', `${selectedScore?.programScore}`)}
        {!props.isPatient && showScoreSection('Comparison', `${selectedScore?.comparisonScore}`)}
      </FlexContainer>
    </FlexContainer>
  );
};

PatientScoreCard.defaultProps = {
  isOverallView: true,
  sequence: 0,
  useTitle: false,
  showScores: true,
  dateProcessed: '',
  maxAppliedWhenId: undefined,
  onLoaded: () => ({}),
};
