import * as React from 'react';

import TableauReportView from 'global_elements/TableauReportView/index.js';
import ReportsProxy from 'api/reports/reportsProxy';
import { ActiveFacilityContext } from 'context/activeFacility';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import { FontColors } from 'global_elements/Text/variants';

/**
 * Shared dashboard view that contains Tableau.
 *
 * @param props Dashboard props.
 * @returns JSX Element with the Tableau view.
 */
const TableauReportDashboard = (props: any): JSX.Element => {
  const [report, setReport] = React.useState<string|null>(null);
  const [isValidFacility, setIsValidFacility] = React.useState<boolean>(false);
  const { facility } = React.useContext(ActiveFacilityContext);

  React.useEffect(() => {
    const facilityID = parseInt(facility.value, 10);
    const isValid = !Number.isNaN(facilityID);
    setReport(null);

    props.setLoading(isValid);
    setIsValidFacility(isValid);

    if (!isValid) {
      return;
    }

    ReportsProxy.getReport(facilityID).then((reportData) => {
      setReport(reportData);
      props.setLoading(false);
    }).catch((error) => {
      props.setLoading(false);
      console.log(error);
    });
  }, [facility]);

  return (
    <div>
      {!isValidFacility
      && (
        <TertiaryHeader text="Please select a facility to see report." fontColor={FontColors.HIGH_PRIORITY} />
      )}
      {isValidFacility && report
      && (
        <>
          <TableauReportView url={report} />
        </>
      )}
    </div>
  );
};

export default TableauReportDashboard;
