export enum MHOQuestionTypes {
    NONE = 'None',
    TRUE_FALSE = 'True/False',
    YES_NO = 'Yes/No',
    TEXT = 'Text',
    SINGLE_SELECT = 'Single Select',
    SINGLE_SELECT_W_OTHER = 'Single Select  w/Other',
    MULTI_SELECT = 'Multi Select',
    MULTI_SELECT_W_OTHER = 'Multi Select w/Other',
    NUMBER = 'Number',
    DATE = 'Date',
    RADIO_BUTTON = 'Radio Button',
    MATRIX = 'Matrix',
    RATING = 'Rating',
    EMOTICON_RATING = 'Emoticon Rating',
}

export enum VariableNames {
    DF_INSTRUCTIONS = 'DF_instruction'
}
