import * as React from 'react';

import './styles.scss';

import FlexContainer from 'global_elements/Layouts/FlexContainer';

import { PopupWindowProps } from 'types/popupWindowProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';

const PopupWindow = (props: PopupWindowProps): JSX.Element => (
  <FlexContainer
    display={DisplayVariant.FLEX_COL}
    align={AlignVariant.START}
    justify={JustifyVariant.START}
    extraClasses="popup-window"
  >
    {props.children}
  </FlexContainer>
);

export default PopupWindow;
