import * as React from 'react';
import UserAccount from 'interfaces/users/userAccount';
import { SavedPatientSearchData } from 'types/patientAccount';
import { FacilityUserSearchState } from 'types/facilityUserAccount';
import { SavedCoreMeasurePatientSearchData } from 'types/CoreMeasurePatient';
import { UserInterface, UserContextInterface } from '../interfaces/userInterface';

export const UserContext = React.createContext<UserContextInterface>({
  user: null,
  userAccount: null,
  isPopupActive: false,
  isSystemAlertsActive: true,
  activeGroupTherapySessionId: null,
  activeGroupTherapyPatientId: null,
  patientReflectionsConsentAccepted: false,
  patientHipaaConsentAccepted: false,
  patientSearchData: null,
  coreMeasuresPatientSearchData: null,
  facilityUserSearchQuery: null,
  SetPatientSearchData: (query: SavedPatientSearchData | null): void => (console.log(query)),
  SetCoreMeasuresPatientSearchData: (query: SavedCoreMeasurePatientSearchData | null): void => (console.log(query)),
  SetFacilityUserSearchQuery: (query: FacilityUserSearchState | null): void => (console.log(query)),
  SetActiveGroupTherapySessionId: (sessionId: string | null): void => (console.log(sessionId)),
  SetActiveGroupTherapyPatientId: (patientId: string | null): void => (console.log(patientId)),
  SetPatientReflectionsConsentAccepted: (value: boolean): void => (console.log(value)),
  SetPatientHipaaConsentAccepted: (value: boolean): void => (console.log(value)),
  DismissSystemAlerts: (): void => (console.log('dismiss alerts')),
  Logout: (): void => (console.log('logout')),
  Login: (newUser: UserInterface): void => (console.log(newUser)),
  SetAccount: (newAccount: UserAccount): void => (console.log(newAccount)),
} as UserContextInterface);
