import ApiProxy from 'api/lib/ApiService'
import { URLS } from 'constants/appUrls'
import { AssessmentAppliedWhen } from 'constants/assessment_types'
import { AssessmentData } from 'interfaces/assessments/assessmentData'
import {
  CreateGroupTherapySessionArgs,
  CreateGroupTherapySessionResponse,
  GroupTherapyAddPatientsArgs,
  GroupTherapyAddPatientsResponse,
  GroupTherapyAddAssessmentsArgs,
  GroupTherapyAddAssessmentsResponse,
  GroupTherapyIncompleteAssessment,
  GroupTherapyGetSessionResponse,
} from './types'

export default class GroupTherapyProxy {
  static createGroupTherapySession(args: CreateGroupTherapySessionArgs): Promise<CreateGroupTherapySessionResponse> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/GTherapy`

      ApiProxy.post<number>(url, [args], (response) => {
        if (!response.data) {
          reject(response)
          return
        }
        resolve({ id: response.data })
      }, (response: any) => {
        reject(response)
      })
    })
  }

  static addGroupTherapyPatients(args: GroupTherapyAddPatientsArgs[]): Promise<GroupTherapyAddPatientsResponse> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/GPatients`

      ApiProxy.post<number>(url, args, (response) => {
        if (!response.data) {
          reject(response)
          return
        }
        resolve({ count: response.data })
      }, (response: any) => {
        reject(response)
      })
    })
  }

  static addGroupTherapyAssessments(args: GroupTherapyAddAssessmentsArgs[]): Promise<GroupTherapyAddAssessmentsResponse> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/GForms`
      ApiProxy.post<number>(url, args, (response) => {
        if (!response.data) {
          reject(response)
          return
        }
        resolve({ id: response.data })
      }, (response: any) => {
        reject(response)
      })
    })
  }

  static async getGroupTherapyAvailableAssessments(groupTherapyId: string, appliedWhenId: string): Promise<AssessmentData[]> {
    const url = `${URLS.MHO_API}/GProgNum/${groupTherapyId}/${appliedWhenId}`
    const response = await ApiProxy.alternativeGet<AssessmentData[]>(url, {})
    return response.data || []
  }

  static async getAppliedWhens(): Promise<AssessmentAppliedWhen[]> {
    const url = `${URLS.MHO_API}/aw`
    const response = await ApiProxy.alternativeGet<AssessmentAppliedWhen[]>(url, {}).then()
    return response.data || []
  }

  static async getIncompleteAssessments(groupId: string): Promise<GroupTherapyIncompleteAssessment[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/GPatients/${groupId}`

      ApiProxy.get<GroupTherapyIncompleteAssessment[]>(url, (response) => {
        if (response.data === null) {
          reject(response)
          return
        }
        resolve(response.data)
      }, (response: any) => {
        reject(response)
      })
    })
  }

  static async getGroupTherapySession(status: 'all' | 'complete' | 'incomplete', groupId?: string): Promise<GroupTherapyGetSessionResponse[]> {
    return new Promise((resolve, reject) => {
      let url = `${URLS.MHO_API}/GSession/${status}`
      if (groupId) {
        url += `?gpid=${groupId}`
      }

      ApiProxy.get<GroupTherapyGetSessionResponse[]>(url, (response) => {
        if (!response.data) {
          reject(response)
          return
        }
        resolve(response.data)
      }, (response: any) => {
        reject(response)
      })
    })
  }
}
