import * as React from 'react';

import '../styles.scss';
import './styles.scss';

const PrintSignature = (): JSX.Element => (
  <div className="print-signature-container">
    <div className="print-signature">
      <span className="text">Staff Signature</span>
      <span className="text date">Date</span>
    </div>
  </div>
);

export default React.memo(PrintSignature);
