import React, { LegacyRef, forwardRef } from 'react';

import './styles.scss';

import { FlexContainerProps } from 'types/flexContainerProps';

const FlexContainer = forwardRef((props: FlexContainerProps, ref: LegacyRef<HTMLDivElement>): JSX.Element => {
  const style =
    `${
      props.display} ${
      props.justify} ${
      props.align}${
      props.position ? ` ${props.position}` : ''}${
      props.wrap ? ' --wrap' : ''}${
      props.extraClasses ? ` ${props.extraClasses}` : ''
    }`;

  return (
    <div id={props.id} ref={ref} className={style} data-testid={props.testid}>
      {props.children}
    </div>
  );
});

export default FlexContainer;
