import React, { Component, useState, useEffect } from 'react';
import tableau from 'tableau-api';

class TableauReportView extends Component {
    componentDidMount() {
      this.initViz();
    }

    initViz() {
      if (this.viz) {
        this.viz.dispose();
      }
      
      const isMobile = window.innerWidth <= 1024;
      const options = {
          hideTabs: true,
          device: isMobile ? 'mobile' : 'desktop',
      };
      this.viz = new window.tableau.Viz(this.vizContainer, this.props.url, options)
    }

    render() {
      return (
          <div ref={(div) => { this.vizContainer = div }}>
          </div>
      )
    }
}

export default TableauReportView;
