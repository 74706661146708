import React, { useState } from 'react';
import { SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

import { AsyncSingleSelectDropdownProps, SelectOption } from 'types/inputProps';

const AsyncSingleSelect = (props: AsyncSingleSelectDropdownProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(props.value || props.defaultValue);

  const handleChange = (option: SingleValue<SelectOption>): void => {
    setSelectedOption(option);
    props.onSelection(option as SelectOption);
  };

  return (
    <div className="single-select-dropdown-wrapper">
      <AsyncSelect<SelectOption>
        key={props.propsKey}
        cacheOptions
        defaultOptions
        styles={props.styles}
        value={selectedOption}
        onChange={handleChange}
        loadOptions={props.loadOptions}
        getOptionLabel={(option: SelectOption) => option.label}
        getOptionValue={(option: SelectOption) => option.value}
        isSearchable
        onBlur={props.onblur}
        blurInputOnSelect
        tabIndex={0}
        menuPlacement={props.menuPlacement}
      />
    </div>
  );
};

export default React.memo(
  AsyncSingleSelect,
  (prevProps: Readonly<AsyncSingleSelectDropdownProps>, nextProps: Readonly<AsyncSingleSelectDropdownProps>) => (
    typeof prevProps.value?.value === typeof nextProps.value?.value
  ),
);
