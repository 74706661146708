/* eslint-disable no-nested-ternary */
import { StylesConfig } from 'react-select';
import { SelectOption } from 'types/inputProps';

export const SingleHeaderDropdownStyles: StylesConfig<SelectOption> = {
  container: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    width: 300,
    borderRadius: 0,
    outline: state.isFocused ? 'lightgreen solid 5px' : '',
  }),
  control: (provided: Record<string, unknown>) => ({
    ...provided,
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided: Record<string, unknown>) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#337EAD',
    height: '100%',
  }),
  option: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#023860'
      : state.isFocused
        ? '#337EAD'
        : 'transparent',
    color: state.isSelected || state.isFocused ? 'white' : 'black',
    fontFamily: 'noto-sans',
    fontSize: 14,
  }),
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid black',
  }),
  singleValue: (provided: Record<string, unknown>) => ({
    ...provided,
    fontFamily: 'noto-sans',
    fontSize: 14,
  }),
};

export const SingleStandardDropdownStyles: StylesConfig<SelectOption> = {
  container: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid #8a8a8a',
    outline: state.isFocused ? '#023860 solid 5px' : '',
  }),
  control: (provided: Record<string, unknown>) => ({
    ...provided,
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided: Record<string, unknown>) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#337EAD',
    height: '100%',
  }),
  option: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    fontFamily: 'noto-sans',
    fontSize: 14,
    backgroundColor: state.isSelected
      ? '#023860'
      : state.isFocused
        ? '#337EAD'
        : 'transparent',
    color: state.isSelected || state.isFocused ? 'white' : 'black',
  }),
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid black',
  }),
  singleValue: (provided: Record<string, unknown>) => ({
    ...provided,
    fontFamily: 'noto-sans',
    fontSize: 14,
  }),
  placeholder: (provided: Record<string, unknown>) => ({
    ...provided,
    fontFamily: 'noto-sans',
    fontSize: 14,
  }),
  noOptionsMessage: (provided: Record<string, unknown>) => ({
    ...provided,
    fontFamily: 'noto-sans',
    fontSize: 14,
  }),
};

export const SingleErrorDropdownStyles: StylesConfig<SelectOption> = {
  container: (provided: Record<string, unknown>) => ({
    ...provided,
    borderRadius: 0,
    border: '2px solid #cc0000',
  }),
  control: (provided: Record<string, unknown>) => ({
    ...provided,
    borderRadius: 0,
    border: 'none',
    boxShadow: 'none',
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided: Record<string, unknown>) => ({
    ...provided,
    color: 'white',
    backgroundColor: '#337EAD',
    height: '100%',
  }),
  option: (provided: Record<string, unknown>, state: any) => ({
    ...provided,
    fontFamily: 'noto-sans',
    fontSize: 14,
    backgroundColor: state.isSelected
      ? '#023860'
      : state.isFocused
        ? '#337EAD'
        : 'transparent',
    color: state.isSelected || state.isFocused ? 'white' : 'black',
  }),
  menu: (provided: Record<string, unknown>) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid black',
  }),
  singleValue: (provided: Record<string, unknown>) => ({
    ...provided,
    fontFamily: 'noto-sans',
    fontSize: 14,
  }),
  placeholder: (provided: Record<string, unknown>) => ({
    ...provided,
    fontFamily: 'noto-sans',
    fontSize: 14,
  }),
  noOptionsMessage: (provided: Record<string, unknown>) => ({
    ...provided,
    fontFamily: 'noto-sans',
    fontSize: 14,
  }),
};
