import { SurveyJSPage } from './SurveyJSPage';

export class SurveyJSFormDefinition {
  constructor() {
    this.pages = [];
  }

  title?: string;
  assessmentNumber?: number;
  description?: string;
  showProgressBar?: string;
  progressBarType?: string;
  pages: Array<SurveyJSPage>;
}
