import * as React from 'react';

import '../styles.scss';

import { HeadingTextProps } from 'types/textProps';

const TertiaryHeader = (props: HeadingTextProps): JSX.Element => {
  const classes =
    `text ${
      props.fontColor}${
      props.italic ? ' --italic' : ''}${
      props.underlined ? ' --underlined' : ''}${
      props.alignLeft ? ' --align-left' : ''}
        }
    }`;

  const style = {
    marginTop: props.marginTopPx,
    marginRight: props.marginRightPx,
    marginBottom: props.marginBottomPx,
    marginLeft: props.marginLeftPx,
  };

  return (
    <h3
      className={classes}
      style={style}
      data-testid={props.testId}
    >
      {props.text}
    </h3>
  );
};

export default React.memo(
  TertiaryHeader,
  (prevProps: Readonly<HeadingTextProps>, nextProps: Readonly<HeadingTextProps>) => (
    prevProps.text === nextProps.text
  ),
);
