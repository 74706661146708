import { Configuration } from '@azure/msal-browser';
import { Environments } from 'constants/environments';

import { MsalConfig } from './abstractMsalSettings';
import { DevMsalPolicies } from './devMsalSettings';
import { UatMsalPolicies } from './uatMsalSettings';
import { ProdMsalPolicies } from './prodMsalSettings';

/**
 * Retrieves the MSAL configuration matching the environment.
 *
 * @returns correct MSAL configuration.
 */
export const getMsalConfig = (): Configuration => (MsalConfig);

/**
 * Retrieves the MSAL policies matching the environment.
 *
 * @returns correct MSAL policies.
 */
export const getMsalPolicies = (): any => {
  switch (process.env.REACT_APP_ENV) {
    case Environments.LOCAL:
    case Environments.TEST:
    case Environments.DEVELOPMENT:
      return DevMsalPolicies;
    case Environments.UAT:
      return UatMsalPolicies;
    case Environments.PRODUCTION:
      return ProdMsalPolicies;
    default:
      console.error('Unknown environment');
      return ProdMsalPolicies;
  }
};

/**
 * Check to see if the current environment is the test environment.
 *
 * @return true if it's currently a test environment.
 */
export const isTestEnvironment = (): boolean => (process.env.REACT_APP_ENV === Environments.TEST);

export const isProduction = (): boolean => (process.env.REACT_APP_ENV === Environments.PRODUCTION);
