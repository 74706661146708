/**
 * Various helper/utilities function consolidation class.
 */
class Utilities {
  /**
   * Check to see if the object in question is defined and has any data in it.
   *
   * @param object typescript object to check whether it's null, undefinied, or is empty.
   * @returns true if the object in question is null, undefined, or is empty.
   */
  static isEmpty(object: any): boolean {
    return !object || Object.keys(object).length === 0;
  }

  /**
   * Compare two strings to see if they're identical.
   *
   * @param text first string for compare against.
   * @param compStr second string to compare to.
   * @param isCaseInsensitive flag to denote whether to check the string using case sensitive or insensitive check.
   * @returns true if the text are identical.
   */
  static stringAreEqual(text: string | null | undefined, compStr: string | null | undefined, isCaseInsensitive = true): boolean {
    if (text && compStr) {
      if (isCaseInsensitive) {
        return text.localeCompare(compStr, undefined, { sensitivity: 'accent' }) === 0;
      }
      return text.localeCompare(compStr) === 0;
    }

    return text === compStr;
  }

  /**
   * Converts hex code such as #FEC910 or #FFF to their equivalent RGBA version.
   *
   * @param hex css hex color code (#FFEEAA or #FEA)
   * @param alpha optional transparency
   * @returns converted RGBA string
   */
  static hexToRGBA(hex: string, alpha = 1.0): string {
    // Catch short hands such as #FFF and convert it to #FFFFFF
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const updatedHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    // Convert long version #FEFEFE to RGBA, with optional transparency
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(updatedHex);
    if (result) {
      const rgbList: number[] = [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
      const rgbString = `${rgbList.join(', ')}`;
      return `rgba(${rgbString}, ${alpha})`;
    }

    throw new Error('Bad Hex');
  }

  /**
   * Calculate the number and rounds to the nearest 2nd decimal point.
   *
   * @param num original number to adjust and round to the 2nd decimal points.
   * @returns number rounded to the nearest 2nd decimal points.
   */
  static roundToTwoDecimal(num: number): number {
    const m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  }

  /**
   * Set an element's input (for example, input element), and trigger the update.
   *
   * @param element element, such as an input to set the value and trigger the update.
   * @param text text string to update the value as.
   */
  static htmlElementValueSetter(element: Element, text: string): void {
    element.setAttribute('value', text);
    const ev2 = new Event('change', { bubbles: true });
    element.dispatchEvent(ev2);
  }

  /**
   * Set a timer to sleep for x milliseconds.
   *
   * @param ms time delay in milliseconds
   * @returns {Promise} timer set at specified milliseconds.
   */
  static sleep(ms: number): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  /**
   * Set title attribute of worklist table cells to show full cell text on hover.
   */
  static setCellTitles = (): void => {
    const cells = document.getElementsByClassName('rdt_TableCell');
    Array.from(cells).forEach((cell) => {
      const title = cell.textContent;
      if (title) {
        cell.setAttribute('title', title);
      }
    });
  };

  // eslint-disable-next-line arrow-body-style
  static caseInsensitiveSort = (rows: any, selector: any, direction: any): any => {
    return rows.sort((a: any, b: any) => {
      const aField = selector(a)?.toLowerCase();
      const bField = selector(b)?.toLowerCase();

      let comparison = 0;
      if (aField == null && bField == null) {
        comparison = 0;
      } else if (bField == null || aField > bField) {
        comparison = 1;
      } else if (aField == null || aField < bField) {
        comparison = -1;
      }

      return direction === 'desc' ? comparison * -1 : comparison;
    });
  };
}

export default Utilities;
