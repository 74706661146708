import { MHOQuestionTypes } from 'constants/MHO/question_types';

export class MHOFormDataRow {
  constructor() {
    this.sequenceNumber = 0;
    this.hiddenFromUser = 0;
  }

  assessmentNumber?: number;
  assessmentTitle?: string;
  assessmentInstructions?: string;
  appliedWhenID?: number;
  questionNumber?: number;
  variableName?: string;
  variableID?: number;
  lookupID?: string;
  lookupDesc?: string;
  responseID?: string;
  questionText?: string;
  questionTypeDesc?: MHOQuestionTypes;
  sequenceNumber: number;
  dataType?: string;
  dataValue?: string;
  hiddenFromUser: number;
  minVal?: number;
  maxVal?: number;
  lang?: string;
}
