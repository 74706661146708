import { useContext, useState } from 'react'
import { faWarning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'global_elements/Button'
import { ButtonVariant } from 'global_elements/Button/variants'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PopupWindow from 'global_elements/Layouts/PopupWindow'
import InlineText from 'global_elements/Text/InlineText'
import SecondaryHeader from 'global_elements/Text/SecondaryHeader'
import TertiaryHeader from 'global_elements/Text/TertiaryHeader'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import { UserContext } from 'context/user'
import UserProxy from 'api/user/userProxy'

import './DeleteConfirmationPopup.scss'

export interface DeleteConfirmationPopupProps {
  onCancel: () => void
  accountID: number
}

const DeleteConfirmationPopup = ({
  onCancel,
  accountID,
}: DeleteConfirmationPopupProps): JSX.Element => {
  const { Logout } = useContext(UserContext)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const onConfirm = async (): Promise<void> => {
    try {
      await UserProxy.deletePatientAccount(accountID)
      setErrorMessage(null)
    } catch (error: any) {
      console.error(error)
      setErrorMessage('An unexpected error occurred. Please try again.')
    }
    Logout()
  }

  return (
    <PopupWindow>
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        justify={JustifyVariant.START}
        align={AlignVariant.START}
        extraClasses="popup-window-content"
      >
        <SecondaryHeader text="Delete Account" fontColor={FontColors.PRIMARY} />
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          justify={JustifyVariant.SPACE_BETWEEN}
          align={AlignVariant.CENTER}
          extraClasses="popup-window-content"
        >
          <FontAwesomeIcon icon={faWarning} className="icon-control warning-icon" />
          <TertiaryHeader text="This action is irreversible." fontColor={FontColors.PRIMARY} />
          {errorMessage && (
            <TertiaryHeader text={errorMessage} fontColor={FontColors.HIGH_PRIORITY} extraClasses="error-message" />
          )}
        </FlexContainer>
        <form className="send-assessment-link" onSubmit={onCancel}>
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            justify={JustifyVariant.END}
            align={AlignVariant.END}
            extraClasses="popup-window-buttons"
          >
            <Button
              variant={ButtonVariant.SECONDARY}
              submit
              onClick={onCancel}
              testid="delete-account-cancel-button"
            >
              <InlineText
                text="Cancel"
                fontColor={FontColors.BACKGROUND}
                fontSize={FontSizes.REGULAR}
                bold
              />
            </Button>
            <Button
              variant={ButtonVariant.HIGH_PRIORITY}
              submit
              onClick={onConfirm}
              testid="delete-account-confirm-button"
            >
              <InlineText
                text="Delete"
                fontColor={FontColors.BACKGROUND}
                fontSize={FontSizes.REGULAR}
                bold
              />
            </Button>
          </FlexContainer>
        </form>
      </FlexContainer>
    </PopupWindow>
  )
}

export default DeleteConfirmationPopup
