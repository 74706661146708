import * as React from 'react';

import AdminDashboard from 'pages/shared/Admin/dashboard';

const AdminDashboardPage = (): JSX.Element => (
  <AdminDashboard
    testText="Admin Dashboard Page"
    title="Site Administration"
  />
);

export default AdminDashboardPage;
