import ApiProxy from 'api/lib/ApiService';
import { URLS } from 'constants/appUrls';
import { TableauReportType } from '../../interfaces/reports/tableauReport';

class ReportsProxy {
  static getTypes(facilityId: number): Promise<TableauReportType[]> {
    const url = `${URLS.MHO_API}/TabMenu?fid=${facilityId}`;

    return new Promise((resolve, reject) => {
      ApiProxy.get<any>(
        url,
        (response: any) => {
          resolve(response?.data || '');
        },
        (response: any) => {
          reject(response);
        },
      );
    });
  }

  static async getReport(facilityID: number, reportTypeId: number | null = null): Promise<string> {
    const url = `${URLS.MHO_API}/PdTabLink`;
    const params = {
      facility: facilityID,
      type: reportTypeId,
    };

    const response = await ApiProxy.alternativeGet<any>(url, { params });

    return response?.data || '';
  }
}

export default ReportsProxy;
