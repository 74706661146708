import { useContext, useEffect, useState } from 'react'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PrimaryHeader from 'global_elements/Text/PrimaryHeader'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import { useHistory, useParams } from 'react-router-dom'

import './styles.scss'
import { ActiveFacilityContext } from 'context/activeFacility'
import { ProviderWorkListRow } from 'types/tableProps'
import FacilityUsersProxy from 'api/facilityUsers/facilityUsersProxy'
import { WorkListPatient } from 'interfaces/patients/patientData'
import ProviderWorkList, { WorkListColumnNames } from 'global_elements/Layouts/DataTables/ProviderWorkList'
import { GroupTherapyAddPatientsArgs, GroupTherapyPatientStatus, UriParams } from 'api/groupTherapy/types'
import { AllRoutes } from 'constants/routes'
import Button from 'global_elements/Button'
import { ButtonVariant } from 'global_elements/Button/variants'
import InlineText from 'global_elements/Text/InlineText'
import GroupTherapyProxy from 'api/groupTherapy/GroupTherapyProxy'
import { TableColumn } from 'react-data-table-component'
import Utilities from 'api/lib/Utilities'

const rowSelected = (row: ProviderWorkListRow): boolean => !!row.selected

const GroupTherapyAddPatientsPage = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true)
  const { groupId } = useParams<UriParams>()
  const history = useHistory()
  const { facility } = useContext(ActiveFacilityContext)
  const [patients, setPatients] = useState<ProviderWorkListRow[]>([])
  const [selectedPatients, setSelectedPatients] = useState<ProviderWorkListRow[]>([])

  function convertToProviderWorkListRow(patient: WorkListPatient): ProviderWorkListRow {
    return {
      patientName: patient.patientName,
      accountNumber: patient.patientNumber,
      patientID: patient.patientID,
      medicalRecord: patient.medicalRecordNumber,
      admissionDate: patient.dateAdmitted,
      program: patient.programName,
      unit: patient.unitCode || '',
      provider: patient.providerName || '',
      patientStatus: patient.lookupDesc,
    }
  }

  useEffect(() => {
    setLoading(true)
    FacilityUsersProxy.getProviderWorkList(facility.value, groupId).then((result) => {
      const rows = result.map((patient) => convertToProviderWorkListRow(patient))
      setPatients(rows)
      setLoading(false)
    })
  }, [facility.value, groupId])

  useEffect(() => {
    if (!loading) {
      Utilities.setCellTitles();
    }
  }, [loading])

  const submit = (): void => {
    const patientPayloads: GroupTherapyAddPatientsArgs[] = selectedPatients.map((patient) => ({
      PatientID: patient.patientID,
      GroupTherapyID: parseInt(groupId, 10),
      StatusID: GroupTherapyPatientStatus.Active,
    }))
    GroupTherapyProxy.addGroupTherapyPatients(patientPayloads).then(() => {
      history.push(`${AllRoutes.GROUP_THERAPY_BASE}/${groupId}/add-assessments`)
    })
  }

  const inputValid = (): boolean => selectedPatients.length > 0
  const getColumns = (columns: TableColumn<ProviderWorkListRow>[]): TableColumn<ProviderWorkListRow>[] => columns.filter((column) => (
    column.name !== WorkListColumnNames.PATIENT_STATUS
  ))

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Add Group Therapy Patients Page"
      loadingText={loading ? 'Loading...' : undefined}
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
      >
        <PrimaryHeader text="Add Group Therapy Patients" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="group-therapy-work-list-container"
        >
          <ProviderWorkList
            data={patients}
            isLoading={loading}
            onRowClicked={(row) => setPatients(patients.map((patient) => {
              if (patient.patientID === row.patientID) {
                return {
                  ...patient,
                  selected: !patient.selected,
                }
              }
              return patient
            }))}
            selectableRows
            selectableRowSelected={rowSelected}
            onSelectedRowsChange={(selected) => setSelectedPatients(selected.selectedRows)}
            getColumns={getColumns}
            onChangePage={Utilities.setCellTitles}
          />
        </FlexContainer>
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={submit}
          disabled={!inputValid()}
          testid="submit-button"
        >
          <InlineText
            text="Save Patients"
            fontColor={FontColors.BACKGROUND}
            fontSize={FontSizes.REGULAR}
            bold
          />
        </Button>
      </FlexContainer>
    </PageLayout>
  )
}

export default GroupTherapyAddPatientsPage
