export type PatientID = number

export type RegistrationPIN = {
  patientRegPINID: number | null
  patientID: PatientID
  registrationPIN: string | null
  statusID: number
  createdBy: string
}

export type PatientData = {
  patientID: number | null
  patientFirstName: string
  patientLastName: string
  dateOfBirth: string
  dateAdmitted: string
  dateDischarged: string
  programID: number
  programName: string
  programCode: number
  patientNumber: string
  medicalRecordNumber: string
  accountID: number | null
  careID: number
  statusID: number
  registrationPIN: number | null
  populationTypeID: number
  corporationID: number
  treatmentTypeID: number
  race: number | null
  gender: number | null
}

export interface PatientConsentData {
  accountEmail: string
  accountID: number
  careID: number
  consentAcceptedDate: string | null
  consentAgreementText: null | null
  consentAgreementType: number | null
  consentAgreementTypeID: number | null
  consentAuthorityOtherText: string | null
  consentAuthorityType: string | null
  consentAuthorityTypeID: number | null
  consentAuthorizationText: string | null
  consentFooterText: string | null
  consentNotObtainedReason: string | null
  consentObtained: number
  consentType: string
  consentTypeID: number // 1 for reflections, 2 for HIPAA
  consentVersionID: number
  consentVersionText: string
  dateDischarged: string | null
  patientConsentID: number
  patientID: number
}

export interface PatientConsentPostData {
  patientConsentID: number | null
  accountID: number | null
  careID: number | null
  consentTypeID: number | null
  consentVersionID: number | null
  consentAgreementTypeID: number
  consentAuthorityTypeID: number | null
  consentAuthorityOtherText: string | null
  consentAcceptedDate: string | null
  consentIPAddress: string | null
  consentDeviceID: string | null
  statusID: number | null
  createdBy: string | null
  updatedBy: string | null
}

export interface WorkListPatient {
    facilityID: number
    patientID: number
    patientName: string
    patientNumber: string
    medicalRecordNumber: string
    dateAdmitted: string
    dateDischarged: string
    programID: number
    programName: string
    unitCode: string | null
    providerName: string | null
    lookupID: number
    lookupDesc: string
}

export function getNewPatientData(): PatientData {
  return {
    patientID: 0,
    patientFirstName: '',
    patientLastName: '',
    dateOfBirth: '',
    dateAdmitted: '',
    dateDischarged: '',
    programID: -1,
    programName: '',
    programCode: -1,
    patientNumber: '',
    medicalRecordNumber: '',
    accountID: -1,
    careID: -1,
    registrationPIN: null,
    statusID: 1,
    populationTypeID: -1,
    corporationID: -1,
    treatmentTypeID: -1,
    race: null,
    gender: null,
  }
}

type AccountData = {
  AccountEmail: string
  AccountID: null | number
  UserRoleID: number
  FirstName: string
  LastName: string
  Phone: string | null
  CreatedBy: string
  UpdatedBy: string
  StatusID: number
  TextNotify: boolean
  EmailNotify: boolean
}

export type PatientAccountData = {
  Account: AccountData,
  AccountPatient: {
    AccountPatientID: null | number
    AccountID: null | number
    CareID: number
    PatientID: PatientID
    RelationshipID: number
    StatusID: number
    CreatedBy: string
    UpdatedBy: string
  }[],
  mode: 'Patient',
}

export type FacilityAccountData = {
  Account: AccountData,
  AccountEntitlement: {
    AccountEntitlementID: number | null,
    AccountID: number | null,
    StatusID: number,
    CorporationID: number,
    FacilityID: number,
    ProgramID: number,
    CreatedBy: string
    UpdatedBy: string
  }[],
  mode: 'Facility',
}
