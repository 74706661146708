import * as React from 'react'

import FlexContainer from 'global_elements/Layouts/FlexContainer'
import Paragraph from 'global_elements/Text/Paragraph'
import TextCounter from 'global_elements/Text/TextCounter'

import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import { LabledTextAreaProps } from 'types/inputProps'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import TextArea from '..'
import { TextAreaVariant } from '../variants'

const LabledTextArea = (props: LabledTextAreaProps): JSX.Element => (
  <label id={props.labelId}>
    <FlexContainer
      display={DisplayVariant.FLEX_ROW}
      align={AlignVariant.CENTER}
      justify={props.maxTextLength ? JustifyVariant.SPACE_BETWEEN : JustifyVariant.START}
      extraClasses="labeled-text-input__header"
    >
      <Paragraph
        text={`${props.label}${props.error ? ` -- ${props.error}` : ''}`}
        fontColor={props.error !== undefined ? FontColors.HIGH_PRIORITY : FontColors.DARK_GRAY}
        fontSize={FontSizes.REGULAR}
        bold
      />
      {props.maxTextLength
        && (
          <TextCounter
            current={props.value ? props.value.length : 0}
            max={props.maxTextLength}
          />
        )}
    </FlexContainer>
    <TextArea
      variant={props.error !== undefined ? TextAreaVariant.ERROR : props.variant}
      placeholder={props.placeholder}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onCut={props.onCut}
      onCopy={props.onCopy}
      onPaste={props.onPaste}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      testid={props.testid}
    />
  </label>
)

export default React.memo(
  LabledTextArea,
  (prevProps: Readonly<LabledTextAreaProps>, nextProps: Readonly<LabledTextAreaProps>) => (
    prevProps.value === nextProps.value
    && prevProps.error === nextProps.error
  ),
)
