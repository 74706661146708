import { SharedPolicies } from './abstractMsalSettings';

export const DevMsalPolicies = {
  ...SharedPolicies,
  ...{
    names: {
      forgotPassword: 'B2C_1A_PASSWORDRESET',
    },
    authorities: {
      forgotPassword: {
        authority: 'https://mhob2c.b2clogin.com/mhob2c.onmicrosoft.com/B2C_1A_PASSWORDRESET',
      },
    },
  },
};
