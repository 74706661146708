import * as React from 'react';
import '../styles.scss';
import { ParagraphTextProps } from 'types/textProps';

const InlineText = (props: ParagraphTextProps): JSX.Element => {
  const style =
    `text ${
      props.fontColor} ${
      props.fontSize}${
      props.bold ? ' --bold' : ''}${
      props.italic ? ' --italic' : ''}${
      props.underlined ? ' --underlined' : ''
    }`;

  return (
    <span className={style}>
      {props.text}
    </span>
  );
};

export default React.memo(InlineText);
