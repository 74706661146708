import * as React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { ReactComponent as SortIcon } from 'icons/mho-sort-icon-white.svg';

import { IncompletePatientsDataRow, IncompletePatientsTableProps } from 'types/tableProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';

import MHODateTime from 'domain/dateTime/MHODateTime';
import { AlternatingBasicTableStyle } from '../styles';

const NoDataComponent: JSX.Element = <InlineText text="No Data Found." fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} />;

const columns: TableColumn<IncompletePatientsDataRow>[] = [
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: 'Age',
    selector: (row) => row.age,
    sortable: true,
  },
  {
    name: 'Admit',
    selector: (row) => row.admissionDate,
    sortable: true,
    format: (row) => (new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/).test(row.admissionDate) ? new MHODateTime(row.admissionDate).getFormattedCalendarDate() : row.admissionDate),
  },
  {
    name: 'Discharged',
    selector: (row) => row.dischargeDate,
    sortable: true,
    format: (row) => (new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/).test(row.dischargeDate) ? new MHODateTime(row.dischargeDate).getFormattedCalendarDate() : row.dischargeDate),
  },
  {
    name: 'Patient #',
    selector: (row) => row.patientNumber,
    sortable: true,
  },
  {
    name: 'Medical Record',
    selector: (row) => row.medicalRecordNumber,
    sortable: true,
  },
];

const IncompletePatientsTable = (props: IncompletePatientsTableProps): JSX.Element => (
  <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.END} extraClasses="incomplete-patients-table">
    <DataTable dense persistTableHead sortIcon={<SortIcon />} customStyles={AlternatingBasicTableStyle} columns={columns} data={props.data} noDataComponent={NoDataComponent} />
  </FlexContainer>
);

export default IncompletePatientsTable;
