import * as React from 'react';

import '../styles.scss';

import { EthnicityLabelProps } from 'types/textProps';

const Paragraph = (props: EthnicityLabelProps): JSX.Element => {
  const style =
    `text ${
      props.fontColor} ${
      props.fontSize}${
      props.bold ? ' --bold' : ''}${
      props.italic ? ' --italic' : ''}${
      props.underlined ? ' --underlined' : ''}${
      props.extraClasses ? ` ${props.extraClasses}` : ''
    }`;

  return (
    <label className={style} data-testid={props.testId}>
      {props.children}
      <span className={style}>{props.text}</span>
    </label>
  );
};

export default React.memo(Paragraph);
