import { SurveyJSQuestion } from './SurveyJSQuestion';

export class SurveyJSPage {
  constructor() {
    this.elements = [];
  }

  name: string|undefined;
  elements: SurveyJSQuestion[];
}
