import GroupTherapyProxy from 'api/groupTherapy/GroupTherapyProxy'
import { AllRoutes } from 'constants/routes'
import { UserContext } from 'context/user'
import Button from 'global_elements/Button'
import { ButtonVariant } from 'global_elements/Button/variants'
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect'
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import InlineText from 'global_elements/Text/InlineText'
import PrimaryHeader from 'global_elements/Text/PrimaryHeader'
import SecondaryHeader from 'global_elements/Text/SecondaryHeader'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { SelectOption } from 'types/inputProps'
import './styles.scss'
import moment, { Moment } from 'moment'
import { isProduction } from 'config/msal/configMgmt'
import { LabeledDatePickerInput } from 'global_elements/Inputs/LabeledDatePickerInput'

interface UriParams {
  groupId: string
}

interface Patient {
  id: number
  name: string
  dateOfBirth: Date
}

export const PATIENT_INSTRUCTIONS_TEXT = 'Select your name from the list to continue.'
export const PROVIDER_INSTRUCTIONS_TEXT = 'All assessments have been completed. Please return to the group leader.'

const GroupTherapyParticipantsPage = (): JSX.Element => {
  const { groupId } = useParams<UriParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const history = useHistory()
  const { SetActiveGroupTherapySessionId, SetActiveGroupTherapyPatientId } = useContext(UserContext)
  const [patients, setPatients] = useState<Patient[]>([])
  const [selectedPatient, setSelectedPatient] = useState<SelectOption | undefined>()
  const [selectedDob, setSelectedDob] = useState<Date | undefined>()
  const [dobError, setDobError] = useState<string | undefined>()
  const continueButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    SetActiveGroupTherapySessionId(groupId)
    SetActiveGroupTherapyPatientId(null)

    GroupTherapyProxy.getIncompleteAssessments(groupId).then((assessments) => {
      // Get the unique patients from the assessments
      const uniquePatients = assessments.reduce((acc: Patient[], assessment) => {
        if (!acc.find((patient) => patient.id === assessment.patientID)) {
          acc.push({
            id: assessment.patientID,
            name: `${assessment.patientFirstName} ${assessment.patientLastName}`,
            dateOfBirth: new Date(assessment.dateOfBirth),
          })
        }
        return acc
      }, [])

      setPatients(uniquePatients)

      if (uniquePatients.length > 0) {
        setSelectedPatient({
          value: uniquePatients[0].id.toString(),
          label: uniquePatients[0].name,
        })

        if (!isProduction()) {
          console.log(uniquePatients[0].dateOfBirth)
        }
      }

      setLoading(false)
    })
  }, [])

  const exit = (): void => {
    history.push(AllRoutes.GROUP_THERAPY_EXIT_SESSION.replace(':groupId', groupId))
  }

  let instructions = PATIENT_INSTRUCTIONS_TEXT
  if (!loading) {
    instructions = patients.length > 0 ? PATIENT_INSTRUCTIONS_TEXT : PROVIDER_INSTRUCTIONS_TEXT
  }

  const handleContinue = (): void => {
    const patient = patients.find((p) => p.id === parseInt(selectedPatient?.value ?? '', 10))
    if (!patient) {
      console.error('patient not found: ', selectedPatient?.value)
      return
    }

    if (!selectedDob) {
      console.error('dob input not found')
      return
    }

    const dob = moment(patient.dateOfBirth)
    if (selectedDob.getFullYear() !== dob.year() || selectedDob.getMonth() !== dob.month() || selectedDob.getDate() !== dob.date()) {
      setDobError('Date of birth is incorrect')
      return
    }

    SetActiveGroupTherapyPatientId(patient.id.toString())
    history.push(AllRoutes.GROUP_THERAPY_ASSESSMENTS.replace(':groupId', groupId).replace(':patientId', patient.id.toString()))
  }

  const inputValid = (): boolean => {
    if (!selectedPatient || !selectedDob) {
      return false
    }

    return true
  }

  const handleDobChange = (date: Date | null): void => {
    setSelectedDob(date ?? undefined)
    setDobError(undefined)
    continueButtonRef.current?.focus()
  }

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Group Therapy Participants Page"
      loadingText={loading ? 'Loading...' : undefined}
      hideHeader
      extraClasses="group-therapy-participants-page-layout"
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="group-therapy-participants-page"
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
        >
          <PrimaryHeader text="Group Therapy" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
          <SecondaryHeader text={instructions} fontColor={FontColors.SECONDARY} marginBottomPx={16} />
          {patients.length === 0 && loading && (
            <LabledSingleSelect
              options={[{
                value: '',
                label: 'Loading...',
              }]}
              onSelection={(value: SelectOption) => {
                setSelectedPatient(value ?? undefined)
              }}
              value={selectedPatient}
              defaultValue={{
                value: '',
                label: 'Select Patient',
              }}
              styles={SingleStandardDropdownStyles}
              label="Patient"
              testid="patient-dropdown"
            />
          )}
          {patients.length > 0 && (
            <>
              <LabledSingleSelect
                options={patients.map((patient) => ({
                  value: patient.id.toString(),
                  label: patient.name,
                }))}
                onSelection={(value: SelectOption) => {
                  setSelectedPatient(value ?? undefined)

                  if (!isProduction()) {
                    const patient = patients.find((p) => p.id === parseInt(value.value, 10))
                    console.log(patient?.dateOfBirth)
                  }
                }}
                value={selectedPatient}
                defaultValue={{
                  value: patients[0].id.toString(),
                  label: patients[0].name,
                }}
                styles={SingleStandardDropdownStyles}
                label="Patient"
                testid="patient-dropdown"
              />
              <LabeledDatePickerInput
                label="Date of Birth (mm/dd/yyyy)"
                name="dob"
                onChange={handleDobChange}
                error={dobError}
                isValidDate={(current: Moment): boolean => (
                  current.isBefore(moment())
                )}
                maskInput
              />
            </>
          )}
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.CENTER}
            justify={JustifyVariant.END}
            extraClasses="group-therapy-participants-page__button-container"
          >
            {patients.length > 0 && (
              <Button
                ref={continueButtonRef}
                variant={ButtonVariant.PRIMARY}
                onClick={handleContinue}
                testid="continue-button"
                disabled={!inputValid()}
                submit
              >
                <InlineText
                  text="Continue"
                  fontColor={FontColors.BACKGROUND}
                  fontSize={FontSizes.REGULAR}
                  bold
                />
              </Button>
            )}
            <Button
              variant={ButtonVariant.SECONDARY}
              onClick={exit}
              testid="exit-button"
            >
              <InlineText
                text="Exit Session"
                fontColor={FontColors.BACKGROUND}
                fontSize={FontSizes.REGULAR}
                bold
              />
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </PageLayout>
  )
}

export default GroupTherapyParticipantsPage
