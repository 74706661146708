import ApiProxy from 'api/lib/ApiService';
import ApiResponse from 'api/lib/models/ApiResponse';
import { URLS } from 'constants/appUrls';
import { ExtremeResponse, AssessmentExtremeResponse } from 'domain/Forms/MHO/AssessmentExtremeResponse';

/**
 * API accessor proxy all Assessment Extreme Responses.
 */
export class ExtremeResponseProxy {
  /**
   * Retrieve the list of pre-defined assessment's extreme responses.
   *
   * @param assessmentNumber assessment number identifier.
   * @returns {Promise} a list of extreme responses pertaining to the assessment number.
   */
  static getExtremeResponse(assessmentNumber: number): Promise<ExtremeResponse[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/ExResp/${assessmentNumber}`;

      ApiProxy.get<ExtremeResponse[]>(url, (response: ApiResponse<any[]>) => {
        resolve(response?.data || []);
      }, (response: any) => {
        reject(response);
      });
    });
  }

  /**
   * Retrieve the list of pre-defined assessment's extreme responses.
   *
   * @param patientID patient in query's ID.
   * @param assessmentNumber assessment number to retrieve.
   * @param appliedWhenID 'Applied When' ID number to retrieve the correct treatment data.
   * @returns {Promise}
   */
  static getAssessmentExtremeResponse(
    patientID: number, assessmentNumber: number, appliedWhenID: number,
  ): Promise<AssessmentExtremeResponse[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/AssessmentExResp/${patientID}/${assessmentNumber}/${appliedWhenID}`;

      ApiProxy.get<AssessmentExtremeResponse[]>(url, (response: ApiResponse<AssessmentExtremeResponse[]>) => {
        resolve(response?.data || []);
      }, (response: any) => {
        reject(response);
      });
    });
  }

  /**
   * Untested
   */
  static saveExtremeResponse(data: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/ExResp`;

      ApiProxy.post<void>(
        url,
        JSON.stringify(data),
        (response: any) => {
          console.log(response);
          resolve(true);
        },
        (response: any) => {
          console.log(response);
          reject(response);
        },
      );
    });
  }
}
