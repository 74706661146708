import './styles.scss'
// import FlexContainer from 'global_elements/Layouts/FlexContainer'
// import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants'
// import { FontColors, FontSizes } from 'global_elements/Text/variants'
// import Paragraph from 'global_elements/Text/Paragraph'

interface Props {
  email: string | undefined,
  date: string,
}

export default function Signatures({ email, date }: Props): JSX.Element {
  return (
    <div className="signature-container">
      <div>
        <span className="bold">Email:</span>
        {email}
      </div>
      <div>
        <span className="bold">Today&apos;s date:</span>
        {date}
      </div>
    </div>
  )
}
