import * as React from 'react';

import './styles.scss';

import DataListsProxy from 'api/dataLists/dataListsProxy';
import UserProxy from 'api/user/userProxy';
import UserAccount from 'interfaces/users/userAccount';
import { ListFacilityProgram } from 'interfaces/dataLists/listFacilityProgram';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import Paragraph from 'global_elements/Text/Paragraph';
import CheckboxTree from 'global_elements/Inputs/CheckboxTree';
import Button from 'global_elements/Button';
import { ButtonVariant } from 'global_elements/Button/variants';
import InlineText from 'global_elements/Text/InlineText';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';

import { FacilityProgramList } from 'types/facilityUserAccount';
import { UserContext } from 'context/user';
import { FacilityUserTools } from 'lib/Facility/tools';
import { getMsalPolicies } from 'config/msal/configMgmt';
import { useMsal } from '@azure/msal-react';
import { isUHSEmail } from 'global_elements/Inputs/TextInput/lib/email';

const msalPolicies = getMsalPolicies();

const FacilityUserSettingsPage = (): JSX.Element => {
  const [firstName, setFirstName] = React.useState<string>('');
  const [lastName, setLastName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [programLists, setProgramLists] = React.useState<FacilityProgramList[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { user } = React.useContext(UserContext);
  const { instance } = useMsal();

  React.useEffect(() => {
    UserProxy.getUserAccountInfo(
      user ? parseInt(user.accountId, 10) : -1,
      (accountResponse) => {
        if (accountResponse?.data) {
          const accountInfo: UserAccount | null = accountResponse.data[0] ?? null;

          if (accountInfo !== null) {
            setFirstName(accountInfo.firstName ? accountInfo.firstName : 'ERROR');
            setLastName(accountInfo.lastName ? accountInfo.lastName : 'ERROR');
            setEmail(accountInfo.accountEmail ? accountInfo.accountEmail : 'ERROR');
          }
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );

    DataListsProxy.getFacilityPrograms().then((facilityPrograms: ListFacilityProgram[]) => {
      const newProgramLists: FacilityProgramList[] = FacilityUserTools.getFacilityProgramList(facilityPrograms);

      setLoading(false);
      setProgramLists(newProgramLists);
    }).catch((errorResponse) => {
      console.log(errorResponse);
      setLoading(false);
    });
  }, [user]);

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Facility User Account Settings Page"
      loadingTitle={loading ? 'Account Settings' : ''}
      loadingText={loading ? 'Loading User Permissions...' : ''}
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="facility-user-account-settings-content"
      >
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.SPACE_BETWEEN}
          extraClasses="facility-user-account-settings--btn-container"
        >
          <PrimaryHeader
            text="Account Settings"
            fontColor={FontColors.PRIMARY}
          />
          {!isUHSEmail(email)
            && (
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={() => { instance.loginRedirect({ authority: msalPolicies.authorities.forgotPassword.authority, scopes: [] }); }}
              >
                <InlineText text="Change Password" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
              </Button>
            )}
        </FlexContainer>
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="facility-user-account-settings-content__first-row"
        >
          <FlexContainer
            display={DisplayVariant.FLEX_COL}
            align={AlignVariant.START}
            justify={JustifyVariant.START}
          >
            <TertiaryHeader text="First Name" fontColor={FontColors.DARK_GRAY} />
            <Paragraph text={firstName} fontColor={FontColors.DARK} fontSize={FontSizes.REGULAR} />
          </FlexContainer>
          <FlexContainer
            display={DisplayVariant.FLEX_COL}
            align={AlignVariant.START}
            justify={JustifyVariant.START}
          >
            <TertiaryHeader text="Last Name" fontColor={FontColors.DARK_GRAY} />
            <Paragraph text={lastName} fontColor={FontColors.DARK} fontSize={FontSizes.REGULAR} />
          </FlexContainer>
          <FlexContainer
            display={DisplayVariant.FLEX_COL}
            align={AlignVariant.START}
            justify={JustifyVariant.START}
          >
            <TertiaryHeader text="Email" fontColor={FontColors.DARK_GRAY} />
            <Paragraph text={email} fontColor={FontColors.DARK} fontSize={FontSizes.REGULAR} />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="facility-user-account-settings-content__permission-tree"
        >
          <TertiaryHeader text="Assigned Facilities/Programs" fontColor={FontColors.DARK_GRAY} />
          <CheckboxTree
            items={programLists}
            editable={false}
          />
        </FlexContainer>
      </FlexContainer>
    </PageLayout>
  );
};

export default FacilityUserSettingsPage;
