// Addtional Propertie type for Survey JS.
type AdditionalSurveyProperty = {
  propertyName: string,
  propertyInfo: any,
}

// Custom Category
type PropertyCategory = {
  name: string,
  index: number,
}

/**
 * Shared additional attributes for SurveyJS and SurveyJS Creator for Assessments.
 */
export class AdditionalSurveyAttrHandler {
  private static instance: AdditionalSurveyAttrHandler;

  internalDataCategory: PropertyCategory = {
    name: 'Internal Data',
    index: 900,
  };

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): AdditionalSurveyAttrHandler {
    if (!AdditionalSurveyAttrHandler.instance) {
      AdditionalSurveyAttrHandler.instance = new AdditionalSurveyAttrHandler();
    }

    return AdditionalSurveyAttrHandler.instance;
  }

  /**
   * Retrieve a list of additional SurveyJS properties used by both the SurveyJS and the Creator.
   *
   * @returns a list of additional survey properties shared by both the creator and the SurveyJS.
   */
  getAdditionalProperties(): AdditionalSurveyProperty[] {
    return [
      {
        propertyName: 'survey',
        propertyInfo: {
          name: 'assessmentNumber:number',
          displayName: 'Assessment Number',
          category: 'general',
          visibleIndex: 1,
          readOnly: false,
        },
      },
      {
        propertyName: 'question',
        propertyInfo: {
          name: 'questionNumber:number',
          displayName: 'Question Number',
          category: this.internalDataCategory.name,
          categoryIndex: this.internalDataCategory.index,
          readOnly: true,
        },
      },
      {
        propertyName: 'question',
        propertyInfo: {
          name: 'variableID:number',
          displayName: 'Variable ID',
          category: this.internalDataCategory.name,
          categoryIndex: this.internalDataCategory.index,
          readOnly: true,
        },
      },
      {
        propertyName: 'rating',
        propertyInfo: {
          name: 'useEmoticon:boolean',
          displayName: 'Use Emoticon Rating',
          category: 'general',
          categoryIndex: 4,
        },
      },
    ];
  }
}
