import ApiProxy from 'api/lib/ApiService/v2';
import ApiResponseV2 from 'api/lib/models/ApiResponse/v2';
import { URLS } from 'constants/appUrls';
import { CoreMeasuresMonthlyPopulationSummaryModel, CoreMeasuresMonthlyPopulationModel, CoreMeasuresMonthlyPopulationPostModel, CoreMeasuresDiagnosticCategoryCountsModel, CoreMeasuresDiagnosticCategoryCountsPostModel } from '../../interfaces/coreMeasures/coreMeasuresMonthlyPopulationSummaryModel';

class FacilityInfoProxy {
  static getMonthlyPopulationSummary(facilityId: number): Promise<ApiResponseV2<CoreMeasuresMonthlyPopulationSummaryModel[]>> {
    const url = `${URLS.MHO_API}/MonPopSum/${facilityId}`;

    return ApiProxy.get<CoreMeasuresMonthlyPopulationSummaryModel[]>(url, undefined);
  }

  static getMonthlyPopulation(summaryId: number): Promise<ApiResponseV2<CoreMeasuresMonthlyPopulationModel[]>> {
    const url = `${URLS.MHO_API}/MonPop/${summaryId}`;

    return ApiProxy.get<CoreMeasuresMonthlyPopulationModel[]>(url, undefined);
  }

  static getDiagnosticCategoryCounts(facilityId: number, year: number, month: number): Promise<ApiResponseV2<CoreMeasuresDiagnosticCategoryCountsModel[]>> {
    const url = `${URLS.MHO_API}/CmDiagCnt/${facilityId}/${year}?mm=${month}`;

    return ApiProxy.get<CoreMeasuresDiagnosticCategoryCountsModel[]>(url, undefined);
  }

  static postMonthlyPopulation(data : CoreMeasuresMonthlyPopulationPostModel[]): Promise<ApiResponseV2<number>> {
    const url = `${URLS.MHO_API}/MonPop`;

    return ApiProxy.postWithResponse<CoreMeasuresMonthlyPopulationPostModel[], number>(url, data);
  }

  static postDiagnosticCategoryCounts(data: CoreMeasuresDiagnosticCategoryCountsPostModel[]): Promise<ApiResponseV2<number>> {
    const url = `${URLS.MHO_API}/CmDiagCnt`;

    return ApiProxy.postWithResponse<CoreMeasuresDiagnosticCategoryCountsPostModel[], number>(url, data);
  }
}

export default FacilityInfoProxy;
