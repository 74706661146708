import { MHOFormDataRow } from 'domain/Forms/MHO/MHOFormDataRow';
import { SurveyJSFormDefinition } from 'domain/Forms/SurveyJS/SurveyJSFormDefinition';
import { SurveyJSPage } from 'domain/Forms/SurveyJS/SurveyJSPage';
import { SurveyJSQuestion } from 'domain/Forms/SurveyJS/SurveyJSQuestion';
import { MHOQuestionTypes, VariableNames } from 'constants/MHO/question_types';
import { SurveyJSQuestionTypes } from 'constants/SurveyJS/question_types';
import { SurveyJSInputTypes } from 'constants/SurveyJS/input_types';
import { HiddenQuestions } from 'constants/MHO/hidden_questions';
import { HiddenQuestion } from 'constants/MHO/hidden_question';
import { HiddenQuestionNames } from 'constants/MHO/hiddenQuestionNames';

class MHOToSurveyJSConverter {
  public static questionTypeMap = new Map([
    [MHOQuestionTypes.TEXT, SurveyJSQuestionTypes.TEXT],
    [MHOQuestionTypes.YES_NO, SurveyJSQuestionTypes.RADIO_GROUP],
    [MHOQuestionTypes.TRUE_FALSE, SurveyJSQuestionTypes.RADIO_GROUP],
    [MHOQuestionTypes.RADIO_BUTTON, SurveyJSQuestionTypes.RADIO_GROUP],
    [MHOQuestionTypes.SINGLE_SELECT, SurveyJSQuestionTypes.RADIO_GROUP],
    [MHOQuestionTypes.SINGLE_SELECT_W_OTHER, SurveyJSQuestionTypes.RADIO_GROUP],
    [MHOQuestionTypes.MULTI_SELECT, SurveyJSQuestionTypes.CHECKBOX],
    [MHOQuestionTypes.MULTI_SELECT_W_OTHER, SurveyJSQuestionTypes.CHECKBOX],
    [MHOQuestionTypes.NUMBER, SurveyJSQuestionTypes.TEXT],
    [MHOQuestionTypes.DATE, SurveyJSQuestionTypes.TEXT],
    [MHOQuestionTypes.MATRIX, SurveyJSQuestionTypes.MATRIX_SINGLE],
    [MHOQuestionTypes.RATING, SurveyJSQuestionTypes.RATING],
    [MHOQuestionTypes.EMOTICON_RATING, SurveyJSQuestionTypes.RATING],
  ]);

  public static inputTypeMap = new Map([
    [MHOQuestionTypes.DATE, SurveyJSInputTypes.DATE],
    [MHOQuestionTypes.NUMBER, SurveyJSInputTypes.NUMBER],
  ]);

  public static questionTypesThatSupportChoices = [
    MHOQuestionTypes.YES_NO,
    MHOQuestionTypes.TRUE_FALSE,
    MHOQuestionTypes.RADIO_BUTTON,
    MHOQuestionTypes.SINGLE_SELECT,
    MHOQuestionTypes.SINGLE_SELECT_W_OTHER,
    MHOQuestionTypes.MULTI_SELECT,
    MHOQuestionTypes.MULTI_SELECT_W_OTHER,
  ];

  public static questionWithRatings = [
    MHOQuestionTypes.RATING,
    MHOQuestionTypes.EMOTICON_RATING,
  ]

  /**
   * Update the passed in param question with the necessary specific fields.
   *
   * @param question system/hidden question to update the custom fields for.
   */
  private static setCustomSystemAttribute(question: SurveyJSQuestion): void {
    if (question.name === HiddenQuestionNames.COMPLETED_DATE) {
      question.visibleIf = 'isNotPatient()'; // eslint-disable-line no-param-reassign
      question.readOnly = false; // eslint-disable-line no-param-reassign
    }
  }

  static ConvertToSurveyJSFormat(dataRows: MHOFormDataRow[]): Promise<SurveyJSFormDefinition> {
    return new Promise((resolve, reject) => {
      const mhoQuestionGroups = MHOToSurveyJSConverter.getSortedQuestions(dataRows);
      const newSurveyJSForm = new SurveyJSFormDefinition();
      newSurveyJSForm.showProgressBar = 'top';
      newSurveyJSForm.progressBarType = 'questions';
      const newSurveyDefaultPage = new SurveyJSPage();

      MHOToSurveyJSConverter.getHiddenQuestions().then((hiddenQuestions: SurveyJSQuestion[]) => {
        hiddenQuestions.forEach((hiddenQuestion) => {
          newSurveyDefaultPage.elements.push(hiddenQuestion);
        });

        mhoQuestionGroups.forEach((mhoQuestionGroup) => {
          MHOToSurveyJSConverter.sortQuestionGroupBysequenceNumber(mhoQuestionGroup);
          const convertedQuestion = new SurveyJSQuestion();

          mhoQuestionGroup.forEach((dataRow) => {
            const mhoQuestionType = dataRow.questionTypeDesc ? dataRow.questionTypeDesc : MHOQuestionTypes.NONE;
            if (dataRow.sequenceNumber === 1) {
              newSurveyJSForm.title = dataRow.assessmentTitle?.trim();
              newSurveyJSForm.assessmentNumber = dataRow.assessmentNumber;
              newSurveyJSForm.description = dataRow.assessmentInstructions?.trim();
              convertedQuestion.name = dataRow.variableName?.trim();
              convertedQuestion.title = dataRow.questionText?.trim();
              convertedQuestion.variableID = dataRow.variableID;
              convertedQuestion.questionNumber = dataRow.questionNumber;

              const isSystemQuestion = dataRow.hiddenFromUser !== 0;
              convertedQuestion.visible = !isSystemQuestion;
              convertedQuestion.readOnly = isSystemQuestion;
              convertedQuestion.isRequired = isSystemQuestion;
              if (isSystemQuestion) {
                this.setCustomSystemAttribute(convertedQuestion);
              }

              if (mhoQuestionType === MHOQuestionTypes.EMOTICON_RATING) {
                convertedQuestion.useEmoticon = true;
              }

              if (MHOToSurveyJSConverter.questionTypeMap.has(mhoQuestionType)) {
                convertedQuestion.type = MHOToSurveyJSConverter.questionTypeMap.get(mhoQuestionType);

                if (MHOToSurveyJSConverter.inputTypeMap.has(mhoQuestionType)) {
                  convertedQuestion.inputType = MHOToSurveyJSConverter.inputTypeMap.get(mhoQuestionType);
                }
              } else if (dataRow.variableName === VariableNames.DF_INSTRUCTIONS) {
                convertedQuestion.type = SurveyJSQuestionTypes.HTML;
                convertedQuestion.html = dataRow.questionText;
              }
            }

            if (MHOToSurveyJSConverter.questionTypesThatSupportChoices.includes(mhoQuestionType)) {
              convertedQuestion.choices.push({ value: dataRow.lookupID, text: dataRow.lookupDesc });
            } else if (MHOToSurveyJSConverter.questionWithRatings.includes(mhoQuestionType)) {
              convertedQuestion.rateValues.push({ value: dataRow.lookupID, text: dataRow.lookupDesc });
            }
          });

          newSurveyDefaultPage.elements.push(convertedQuestion);
        });
        newSurveyJSForm.pages.push(newSurveyDefaultPage);
        resolve(newSurveyJSForm);
      }).catch((requestError) => {
        console.log(requestError);
        reject(requestError);
      });
    });
  }

  public static getSortedQuestions(dataRows: MHOFormDataRow[]): Map<number, MHOFormDataRow[]> {
    const map = new Map<number, MHOFormDataRow[]>();

    dataRows.forEach((dataRow) => {
      const { questionNumber } = dataRow;
      if (!questionNumber) {
        return;
      }

      if (!map.has(questionNumber)) {
        map.set(questionNumber, []);
      }

      const dataSet = map.get(questionNumber);
      dataSet?.push(dataRow);
    });

    return map;
  }

  public static sortQuestionGroupBysequenceNumber(data: MHOFormDataRow[]): void {
    data.sort((a: MHOFormDataRow, b: MHOFormDataRow) => ((a.sequenceNumber > b.sequenceNumber) ? 1 : -1));
  }

  private static getHiddenQuestions(): Promise<SurveyJSQuestion[]> {
    return new Promise((resolve, reject) => {
      HiddenQuestions.getInstance().getQuestionsList().then((questionsList: HiddenQuestion[]) => {
        const hiddenQuestions: SurveyJSQuestion[] = [];
        for (let i = 0; i < questionsList.length; i += 1) {
          const surveyQuestion = new SurveyJSQuestion();
          surveyQuestion.visible = false;
          surveyQuestion.isRequired = true;
          surveyQuestion.readOnly = true;
          surveyQuestion.type = SurveyJSQuestionTypes.RADIO_GROUP;
          surveyQuestion.title = questionsList[i].getQuestionTitle().trim();
          surveyQuestion.name = questionsList[i].getQuestionName().trim();
          surveyQuestion.description = questionsList[i].getQuestionDesc().trim();

          const choices = questionsList[i].getChoiceList();
          for (let j = 0; j < choices.length; j += 1) {
            surveyQuestion.choices.push(choices[j]);
          }
          hiddenQuestions.push(surveyQuestion);
        }
        resolve(hiddenQuestions);
      }).catch((requestError) => {
        console.log(requestError);
        reject(requestError);
      });
    });
  }
}

export default MHOToSurveyJSConverter;
