import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as PhoneInput from 'global_elements/Inputs/TextInput/lib/phoneNumber';
import * as EmailInput from 'global_elements/Inputs/TextInput/lib/email';

import './styles.scss';

import DataListsProxy, { DataListType } from 'api/dataLists/dataListsProxy';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';
import UserProxy from 'api/user/userProxy';
import Utilities from 'api/lib/Utilities';

import PageLayout from 'global_elements/Layouts/PageLayout';
import InlineText from 'global_elements/Text/InlineText';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import Button from 'global_elements/Button';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';
import PatientInfoCard from 'global_elements/Layouts/Cards/PatientInfoCard';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { AllRoutes } from 'constants/routes';
import { PatientAccountSetupState } from 'types/patientAccount';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { SelectOption } from 'types/inputProps';
import { PatientAccountData, PatientData } from 'interfaces/patients/patientData';
import { UserContext } from 'context/user';
import { SendEmailInvitationRequest } from 'interfaces/facilityUsers/sendEmailInvitationRequest';
import { NetworkError } from 'api/lib/ApiService/errors';
import { NETWORK_ERROR_MESSAGE } from 'constants/errorMessages';

type uriParams = {
    patient: string;
}

const PatientAccountSetupPage = (): JSX.Element => {
  const history = useHistory();
  const { patient } = useParams<uriParams>();
  const { user } = React.useContext(UserContext);
  const [pageData, setPageData] = React.useState<PatientAccountSetupState>({
    firstName: '',
    lastName: '',
    accountFirstName: '',
    accountLastName: '',
    accountEmail: '',
    accountEmailConfirm: '',
    accountEmailError: false,
    accountEmailConfirmError: false,
    accountEmailIsOpen: true,
    accountEmailIsInUseMessage: null,
    accountPhone: '',
    accountNumber: '',
    medicalRecordNumber: '',
    patientDOB: '',
    patientAdmissionDate: '',
    program: '',
    registrationPIN: '',
    relationship: { label: 'Select an Option', value: '' },
    relationshipOptions: [],
  });
  const [accountSetupError, setAccountSetupError] = React.useState<string | null>(null);

  React.useEffect(() => {
    PatientUsersProxy.getPatient(
      parseInt(patient, 10),
      (response) => {
        if (response.data) {
          const patientData: PatientData = response.data[0];
          setPageData((prevState) => ({
            ...prevState,
            firstName: patientData.patientFirstName,
            lastName: patientData.patientLastName,
            accountNumber: patientData.patientNumber,
            medicalRecordNumber: patientData.medicalRecordNumber,
            patientDOB: patientData.dateOfBirth,
            patientAdmissionDate: patientData.dateAdmitted,
            program: patientData.programName,
            registrationPIN: patientData.registrationPIN?.toString() ?? '',
          }));
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );

    DataListsProxy.getOptionList(
      DataListType.Relationship,
      (response) => {
        const newRelationshipOptions: SelectOption[] = [];

        if (response.data) {
          response.data.forEach((option) => {
            newRelationshipOptions.push({
              label: option.lookupDesc,
              value: option.lookupID.toString(),
            });
          });
        }

        setPageData((prevState) => ({
          ...prevState,
          relationshipOptions: newRelationshipOptions,
        }));
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, [patient]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name !== 'accountPhone') {
      setPageData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    } else if (PhoneInput.validPhoneInput(e)) {
      const maskedInput = PhoneInput.maskInputAsPhoneNumber(e);

      if (maskedInput !== undefined) {
        setPageData((prevState) => ({
          ...prevState, accountPhone: maskedInput,
        }));
      }
    }
  };

  const handleRelationshipChange = (option: SelectOption): void => {
    setPageData((prevState) => ({
      ...prevState,
      relationship: option,
    }));
  };

  const doEmailsMatch = (): boolean => (
    Utilities.stringAreEqual(pageData.accountEmail, pageData.accountEmailConfirm)
  );

  const invalidAccountInfo = (): boolean => {
    if (
      pageData.accountFirstName === ''
        || pageData.accountLastName === ''
        || pageData.accountEmail === ''
        || pageData.accountEmailConfirm === ''
        || pageData.relationship.value === ''
        || pageData.accountEmailConfirmError
        || pageData.accountEmailError
        || !pageData.accountEmailIsOpen
        || !!PhoneInput.invalidInput(pageData.accountPhone)
        || !doEmailsMatch()
    ) {
      return true;
    }

    return false;
  };

  const emailErrorText = (): string | undefined => {
    if (pageData.accountEmailError) {
      return 'Enter a Valid Email Address';
    }

    if (!pageData.accountEmailIsOpen) {
      return pageData.accountEmailIsInUseMessage ?? 'Email already in use';
    }

    return undefined;
  };

  const emailConfirmErrorText = (): string | undefined => {
    if (pageData.accountEmailConfirmError) {
      return 'Confirm the Email Address';
    }

    if (!doEmailsMatch()) {
      return 'Emails do not match';
    }

    return undefined;
  };

  const invalidEmailError = (): void => {
    if (pageData.accountEmail === '' || !EmailInput.validEmail(pageData.accountEmail)) {
      setPageData((prevState) => ({
        ...prevState,
        accountEmailError: true,
      }));
    } else {
      UserProxy.getEmailExistsInDatabase(
        pageData.accountEmail,
        (response) => {
          console.log(response);
          setPageData((prevState) => ({
            ...prevState,
            accountEmailIsOpen: false,
            accountEmailIsInUseMessage: response.data,
            accountEmailConfirmError: false,
            accountEmailError: false,
          }));
        },
        (errorResponse) => {
          console.log(errorResponse);
          setPageData((prevState) => ({
            ...prevState,
            accountEmailIsOpen: true,
            accountEmailIsInUseMessage: null,
            accountEmailError: false,
          }));
        },
      );
    }
  };

  const emptyEmailConfirmError = (): void => {
    if (pageData.accountEmailConfirm === '') {
      setPageData((prevState) => ({
        ...prevState,
        accountEmailConfirmError: true,
      }));
    } else {
      setPageData((prevState) => ({
        ...prevState,
        accountEmailConfirmError: false,
      }));
    }
  };

  const createAccountData = async (): Promise<void> => {
    const patientAccountData: PatientAccountData = {
      mode: 'Patient',
      Account: {
        AccountEmail: pageData.accountEmail,
        AccountID: null,
        UserRoleID: 8,
        FirstName: pageData.accountFirstName,
        LastName: pageData.accountLastName,
        Phone: pageData.accountPhone === '' ? null : pageData.accountPhone,
        CreatedBy: user?.email ?? '',
        UpdatedBy: user?.email ?? '',
        StatusID: 1,
        TextNotify: !!pageData.accountPhone,
        EmailNotify: true,
      },
      AccountPatient: [
        {
          AccountPatientID: null,
          AccountID: null,
          CareID: 0,
          PatientID: parseInt(patient, 10),
          RelationshipID: parseInt(pageData.relationship.value, 10),
          StatusID: 1,
          CreatedBy: user?.email ?? '',
          UpdatedBy: user?.email ?? '',
        },
      ],
    };

    try {
      const response = await PatientUsersProxy.postPatientAccount(
        [patientAccountData],
      );

      if (!response.success) {
        throw new Error(response.message)
      }

      setAccountSetupError(null)

      const sendEmailInvitationRequest: SendEmailInvitationRequest = {
        Name: `${patientAccountData.Account.FirstName} ${patientAccountData.Account.LastName}`,
        Email: patientAccountData.Account.AccountEmail,
        Status: '',
      };

      UserProxy.SendEmailInvitation(
        sendEmailInvitationRequest,
        (sendEmailInvitationResponse) => {
          console.log(sendEmailInvitationResponse);
          history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${patient}`);
        },
        (invitationErrorResponse) => {
          console.error(invitationErrorResponse);
          setAccountSetupError('Account created, but email invitation failed.')
        },
      );
    } catch (error: any) {
      if (error instanceof NetworkError) {
        setAccountSetupError(NETWORK_ERROR_MESSAGE)
      } else {
        setAccountSetupError(error.message)
      }
      console.error(error);
    }
  };

  const cancelAccountSetup = (): void => {
    history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${patient}`);
  };

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Patient Account Setup Page">
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="patient-account-setup-content"
      >
        <PatientInfoCard
          patientID={patient}
          patientFirstName={pageData.firstName}
          patientLastName={pageData.lastName}
          patientDOB={pageData.patientDOB}
          patientAdmissionDate={pageData.patientAdmissionDate}
          patientAccountNumber={pageData.accountNumber}
          patientProgram={pageData.program}
          patientMedicalRecordNumber={pageData.medicalRecordNumber}
          patientRegistrationPIN={pageData.registrationPIN}
        />
        <SecondaryHeader
          text="Account Set-up"
          fontColor={FontColors.PRIMARY}
          marginTopPx={32}
          marginBottomPx={16}
        />
        <form>
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.START}
            extraClasses="patient-account-setup-content__first-row"
          >
            <LabledTextInput
              label="First Name"
              name="accountFirstName"
              value={pageData.accountFirstName}
              onChange={handleInputChange}
              type="text"
              variant={TextInputVariant.PRIMARY}
              autoFocus
            />
            <LabledTextInput
              label="Last Name"
              name="accountLastName"
              value={pageData.accountLastName}
              onChange={handleInputChange}
              type="text"
              variant={TextInputVariant.PRIMARY}
            />
          </FlexContainer>
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.START}
            extraClasses="patient-account-setup-content__second-row"
          >
            <LabledTextInput
              label="Email"
              name="accountEmail"
              value={pageData.accountEmail}
              onChange={handleInputChange}
              type="email"
              variant={TextInputVariant.PRIMARY}
              onBlur={invalidEmailError}
              error={emailErrorText()}
            />
            <LabledTextInput
              label="Confirm Email"
              name="accountEmailConfirm"
              value={pageData.accountEmailConfirm}
              onChange={handleInputChange}
              type="email"
              variant={TextInputVariant.PRIMARY}
              onBlur={emptyEmailConfirmError}
              error={emailConfirmErrorText()}
            />
          </FlexContainer>
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.START}
            extraClasses="patient-account-setup-content__third-row"
          >
            {pageData.relationshipOptions.length === 0
                && (
                  <LabledSingleSelect
                    label="Relationship"
                    styles={SingleStandardDropdownStyles}
                    options={[]}
                    defaultValue={{ label: 'Loading...', value: '' }}
                    value={{ label: 'Loading...', value: '' }}
                    onSelection={handleRelationshipChange}
                  />
                )}
            {pageData.relationshipOptions.length > 0
                && (
                  <LabledSingleSelect
                    label="Relationship"
                    styles={SingleStandardDropdownStyles}
                    options={pageData.relationshipOptions}
                    defaultValue={pageData.relationshipOptions[0]}
                    value={pageData.relationship}
                    onSelection={handleRelationshipChange}
                  />
                )}
            <LabledTextInput
              label="Phone (Optional)"
              placeholder="(123) 456-7890"
              name="accountPhone"
              value={pageData.accountPhone}
              onChange={handleInputChange}
              type="text"
              variant={TextInputVariant.PRIMARY}
              error={PhoneInput.invalidInput(pageData.accountPhone)}
            />
          </FlexContainer>
        </form>
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          justify={JustifyVariant.START}
          align={AlignVariant.START}
          extraClasses="button-container"
        >
          <Button
            variant={ButtonVariant.SECONDARY}
            onClick={cancelAccountSetup}
          >
            <InlineText
              text="Cancel"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={createAccountData}
            disabled={invalidAccountInfo()}
          >
            <InlineText
              text="Register"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
        </FlexContainer>
        {accountSetupError
          && (
            <InlineText
              text={accountSetupError}
              fontColor={FontColors.HIGH_PRIORITY}
              fontSize={FontSizes.LARGE}
              bold
            />
          )}
      </FlexContainer>
    </PageLayout>
  );
};

export default PatientAccountSetupPage;
