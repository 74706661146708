import * as React from 'react';
import moment, { Moment } from 'moment';
import Datetime from 'react-datetime';

import 'react-datetime/css/react-datetime.css';

import { DateTimePickerProps } from 'types/inputProps';

const DateTimePicker = (props: DateTimePickerProps): JSX.Element => {
  const isValidDateDefault = (current: Moment): boolean => (
    current.isAfter(moment().subtract(1, 'day'))
  );

  return (
    <Datetime
      value={props.value}
      onChange={props.handleChange}
      inputProps={props.inputProps}
      isValidDate={props.isValidDate ?? isValidDateDefault}
      initialViewMode="days"
      closeOnClickOutside
    />
  );
};

export default React.memo(
  DateTimePicker,
  (prevProps: Readonly<DateTimePickerProps>, nextProps: Readonly<DateTimePickerProps>) => (
    prevProps.value.getTime() === nextProps.value.getTime()
  ),
);
