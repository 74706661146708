import * as React from 'react';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Paragraph from 'global_elements/Text/Paragraph';
import TextInput from 'global_elements/Inputs/TextInput';
import TextCounter from 'global_elements/Text/TextCounter';

import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { LabledTextInputProps } from 'types/inputProps';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { TextInputVariant } from '../variants';

const LabledTextInput = (props: LabledTextInputProps): JSX.Element => (
  <label>
    <FlexContainer
      display={DisplayVariant.FLEX_ROW}
      align={AlignVariant.CENTER}
      justify={props.maxTextLength ? JustifyVariant.SPACE_BETWEEN : JustifyVariant.START}
      extraClasses="labeled-text-input__header"
    >
      <Paragraph
        text={`${props.label}${props.error ? ` -- ${props.error}` : ''}`}
        fontColor={props.error !== undefined ? FontColors.HIGH_PRIORITY : FontColors.DARK_GRAY}
        fontSize={FontSizes.REGULAR}
        bold
      />
      {props.maxTextLength
        && (
          <TextCounter
            current={props.value ? props.value.length : 0}
            max={props.maxTextLength}
          />
        )}
    </FlexContainer>
    <TextInput
      variant={props.error !== undefined ? TextInputVariant.ERROR : props.variant}
      placeholder={props.placeholder}
      type={props.type}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      onCut={props.onCut}
      onCopy={props.onCopy}
      onPaste={props.onPaste}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      testid={props.testid}
      autoComplete={props.autoComplete}
      inputMode={props.inputMode}
      inputRef={props.inputRef}
    />
    {props.instructions
      && (
        <Paragraph
          text={props.instructions}
          fontColor={FontColors.DARK}
          fontSize={FontSizes.REGULAR}
          extraClasses="labeled-text-input__instructions"
        />
      )}
  </label>
);

export default React.memo(
  LabledTextInput,
  (prevProps: Readonly<LabledTextInputProps>, nextProps: Readonly<LabledTextInputProps>) => (
    prevProps.value === nextProps.value
    && prevProps.error === nextProps.error
  ),
);
