import noUiSlider from 'nouislider';
import ReactDOMServer from 'react-dom/server';
import FeatureFlagManager from 'config/featureFlags/FeatureFlagManager';
import { FeatureFlagName } from 'config/featureFlags';

import { ReactComponent as SvgIcon } from './icons/nouislider.svg';

/**
 * Copied and adapted from https://github.com/surveyjs/custom-widgets/blob/master/src/nouislider.js
 */
export function initSurveyJsSlider(Survey: any): void {
  if (!FeatureFlagManager.get<boolean>(FeatureFlagName.isSliderQuestionEnabled)) {
    return
  }

  const iconId = 'icon-nouislider';
  const componentName = 'slider';

  if (Survey.SvgRegistry) {
    const svg = ReactDOMServer.renderToString(<SvgIcon />);
    Survey.SvgRegistry.registerIconFromSvg(iconId, svg, '');
  }

  const widget = {
    name: componentName,
    title: 'Slider',
    iconName: iconId,
    widgetIsLoaded() {
      return typeof noUiSlider !== 'undefined';
    },
    isFit(question: any) {
      return question.getType() === componentName;
    },
    htmlTemplate: '<div><div></div></div>',
    activatedByChanged() {
      Survey.Serializer.addClass(componentName, [], null, 'empty');
      const registerQuestion = Survey.ElementFactory.Instance.registerCustomQuestion;
      if (registerQuestion) {
        registerQuestion(componentName);
      }
      Survey.Serializer.addProperties(componentName, [
        {
          name: 'step:number',
          category: 'slider',
          categoryIndex: 1,
          default: 1,
        },
        {
          name: 'rangeMin:number',
          category: 'slider',
          default: 0,
        },
        {
          name: 'rangeMax:number',
          category: 'slider',
          default: 5,
        },
      ]);
    },
    afterRender(question: any, el: any) {
      el.style.paddingBottom = '19px';
      el.style.paddingLeft = '20px';
      el.style.paddingRight = '20px';
      el.style.paddingTop = '44px';
      [el] = el.children;
      el.style.marginBottom = '60px';

      if (question.orientation === 'vertical') {
        el.style.height = '250px';
        el.style.marginLeft = '60px';
      }

      const slider = noUiSlider.create(el, {
        start: question.rangeMin,
        connect: [true, false],
        step: question.step,
        tooltips: true,
        pips: {
          mode: 'steps',
          density: 100,
          filter: () => 1,
        },
        range: {
          min: question.rangeMin,
          max: question.rangeMax,
        },
        orientation: 'horizontal',
        direction: 'ltr',
      });

      slider.on('change', () => {
        question.value = Number(slider.get());
      });

      question.updateSliderProperties = function updateSliderProperties() {
        const elems = document.getElementsByClassName('noUi-pips');
        if (elems.length > 0) {
          (elems[elems.length - 1] as HTMLElement).style.display = 'none';
        }
        if (elems.length > 1) {
          (elems[elems.length - 2] as HTMLElement).style.display = 'none';
        }

        slider.updateOptions({
          step: question.step,
          start: question.rangeMin,
          range: {
            min: question.rangeMin,
            max: question.rangeMax,
          },
        }, true);

        slider.pips({
          mode: 'steps',
        });
      };

      const updateValueHandler = function updateValueHandler(): void {
        slider.set(question.value);
      };

      if (question.isReadOnly) {
        el.setAttribute('disabled', 'true');
      }

      updateValueHandler();
      question.noUiSlider = slider;
      question.registerFunctionOnPropertiesValueChanged(
        ['step', 'rangeMin', 'rangeMax'],
        question.updateSliderProperties,
      );

      question.valueChangedCallback = updateValueHandler;
      question.readOnlyChangedCallback = function readOnlyChangedCallback() {
        if (question.isReadOnly) {
          el.setAttribute('disabled', 'true');
        } else {
          el.removeAttribute('disabled');
        }
      };
    },
    willUnmount(question: any) {
      if (question.noUiSlider) {
        question.noUiSlider.destroy();
        question.noUiSlider = null;
      }
      question.readOnlyChangedCallback = null;
      question.valueChangedCallback = null;

      if (!question.updateSliderProperties) return;
      question.unRegisterFunctionOnPropertiesValueChanged(
        ['step', 'rangeMin', 'rangeMax'],
        question.updateSliderProperties,
      );
      question.updateSliderProperties = undefined;
    },
    pdfRender(_: any, options: any) {
      if (options.question.getType() === componentName) {
        const point = options.module.SurveyHelper.createPoint(
          options.module.SurveyHelper.mergeRects.apply(null, options.bricks),
        );
        point.xLeft += options.controller.unitWidth;
        point.yTop += options.controller.unitHeight
          * options.module.FlatQuestion.CONTENT_GAP_VERT_SCALE;

        const rect = options.module.SurveyHelper.createTextFieldRect(
          point,
          options.controller,
        );

        const textboxBrick = new options.module.TextFieldBrick(
          options.question,
          options.controller,
          rect,
          true,
          options.question.id,
          options.question.value || options.question.defaultValue || '',
          '',
          options.question.isReadOnly,
          false,
          'text',
        );

        options.bricks.push(textboxBrick);
      }
    },
  };

  Survey.CustomWidgetCollection.Instance.addCustomWidget(widget, 'customtype');
}
