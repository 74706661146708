import * as React from 'react';

import './styles.scss';

import { ExternalLinkProps } from 'types/navigationProps';

const ExternalLink = (props: ExternalLinkProps): JSX.Element => (
  props.decorativeLink ? (
    <a
      href={props.isPhoneNumber ? `tel:+${props.href}` : `${props.href}`}
      className={props.isPhoneNumber ? 'external-link --phone --disabled' : 'external-link --disabled'}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  ) : (
    <a
      href={props.isPhoneNumber ? `tel:+${props.href}` : `${props.href}`}
      className={props.isPhoneNumber ? 'external-link --phone' : 'external-link'}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  )
);

export default ExternalLink;
