import { useEffect, useState } from 'react'
import * as Survey from 'survey-react'
import { Prompt } from 'react-router-dom'

import AssessmentProxy from 'api/assessments/assessmentProxy'
import { AssessmentResult } from 'api/assessments/types'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import InlineText from 'global_elements/Text/InlineText'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import { AssessmentSurvey, PatientAssessmentData } from 'pages/shared/assessments/assessment'
import AssessmentJSONDefinition from 'interfaces/assessments/AssessmentJSONDefinition'

import './AssessmentForm.scss'

interface Props {
  assessmentLinkHash: string
  assessmentNumber: number
  appliedWhenId: number
  patientAssessmentData: PatientAssessmentData
  assessmentJson: AssessmentJSONDefinition
  dob: string
}

export default function AssessmentForm({
  appliedWhenId,
  assessmentJson,
  assessmentLinkHash,
  assessmentNumber,
  dob,
  patientAssessmentData,
}: Props): JSX.Element {
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [showCompleteMessage, setShowCompleteMessage] = useState<boolean>(false)

  const handlePageClosePrompt = ((event: any): any => {
    event.preventDefault();
    event.returnValue = '';
  })

  useEffect(() => {
    if (isDirty) {
      window.addEventListener('beforeunload', handlePageClosePrompt)
    }

    return () => {
      window.removeEventListener('beforeunload', handlePageClosePrompt)
    }
  }, [isDirty])

  const onComplete = async (survey: Survey.Model, options: any): Promise<void> => {
    options.showDataSaving()
    setIsDirty(false)
    const results = Object.entries(survey.data)

    // Construct the data format for the API.
    const resultsList: AssessmentResult[] = []
    for (let i = 0; i < results.length; i += 1) {
      const resultRow = {
        patientID: patientAssessmentData.patientID,
        assessmentNumber,
        variableName: results[i][0],
        dataValue: String(results[i][1]),
      };
      resultsList.push(resultRow)
    }

    try {
      await AssessmentProxy.saveAnonAssessmentForm(
        resultsList,
        patientAssessmentData.accountID!,
        assessmentLinkHash,
        new Date(dob!),
      )

      options.showDataSavingSuccess()
      setShowCompleteMessage(true)
    } catch (error) {
      console.error(error)

      // Slight delay to show loading message briefly
      setTimeout(() => {
        options.showDataSavingError()
      }, 500);

      throw error
    }
  }

  if (showCompleteMessage) {
    return (
      <PageLayout
        layout={PageLayoutVariant.PADDED}
        testText="Assessment Form Page"
        hideHeader
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="assessment-form-complete-container"
        >
          <InlineText
            text="Thank you for completing the assessment. You may now close this window."
            fontColor={FontColors.PRIMARY}
            fontSize={FontSizes.SUPER_EXTRA_LARGE}
          />
        </FlexContainer>
      </PageLayout>
    )
  }

  return (
    <>
      <AssessmentSurvey
        assessmentNumber={assessmentNumber}
        appliedWhenID={appliedWhenId}
        patientAssessmentData={patientAssessmentData}
        onCompleteCallback={onComplete}
        setIsDirtyCallback={setIsDirty}
        hideHeader
        isPatient
        extraClasses="assessment-form-page"
        showExtremeMessages={false}
        jsonDefinition={assessmentJson}
        addCancelButton={false}
      />
      <Prompt when={isDirty} message="Are you sure you want to close the form? Incomplete assessments will be lost." />
    </>
  )
}
