import React from 'react';
import moment from 'moment';

export const validDateInput = (e: React.ChangeEvent<HTMLInputElement>): boolean => (
  e.target.value === '' || new RegExp(/([0-9])|(\/)/g).test(e.target.value.slice(-1))
);

export const maskTextInputAsDate = (text: string): string | undefined => {
  const strippedInput = text.replaceAll(/(\/)/g, '');
  let maskedOutput = '';

  if (strippedInput.length > 0) {
    maskedOutput = `${strippedInput}`;
  }

  if (strippedInput.length > 2 && strippedInput.length <= 4) {
    maskedOutput = `${maskedOutput.slice(0, 2)}/${maskedOutput.slice(2)}`;
  } else if (strippedInput.length > 4) {
    maskedOutput = `${maskedOutput.slice(0, 2)}/${maskedOutput.slice(2, 4)}/${maskedOutput.slice(4)}`;
  }

  if (strippedInput.length <= 8) {
    return maskedOutput;
  }

  return undefined;
}

export const maskInputAsDate = (e: React.ChangeEvent<HTMLInputElement>): string | undefined => maskTextInputAsDate(e.target.value);

export const validDateString = (date: string) : boolean => (
  new RegExp(/^\d{8}$/).test(date.replaceAll(/(\/)/g, ''))
);

export const validDate = (date: string): boolean => moment(date, 'M/D/yyyy', true).isValid()

export const inputError = (date: string): string | undefined => {
  if (date !== '' && !validDateString(date)) {
    return 'Invalid Date of Birth';
  }
  if (date !== '' && !validDate(date)) {
    return 'Invalid Date Entered';
  }

  return undefined;
};
