import React, { useEffect } from 'react';
import Button from 'global_elements/Button';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

type BasicOption = {
  label: string,
  value: number,
}

type DropdownMenuProps = {
  id: string,
  onSelection: any,
  options: BasicOption[]
  buttonText?: string,
}

export const DropdownMenu = (props: DropdownMenuProps): JSX.Element => {
  const onClickShowMenu = (): void => {
    document.getElementById(`${props.id}-list`)?.classList.toggle('show');
  };

  const itemClicked = (e: any): void => {
    e.preventDefault();
    props.onSelection(props.id, e.currentTarget.parentNode.dataset.value, e.target.innerText);
  };

  useEffect(() => {
    // Close the dropdown menu if the user clicks outside of it
    window.onclick = function (event) {
      const button = (event.target as HTMLButtonElement);
      if (button && !button.classList.contains('dropbtn')) {
        const dropdowns = document.getElementsByClassName('dropdown-content');
        for (let i = 0; i < dropdowns.length; i += 1) {
          const openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    };
  }, []);

  return (
    <div id={props.id} key={props.options.length} className="dropdown">
      <Button
        variant={ButtonVariant.PRIMARY}
        onClick={onClickShowMenu}
        extraClasses="dropbtn"
      >
        {props.buttonText || (<FontAwesomeIcon icon={faChevronDown} />)}
      </Button>
      <ul id={`${props.id}-list`} className="dropdown-content">
        {props.options.map((option: any): JSX.Element => (
          <li key={option.value} data-value={option.value}>
            <Button
              variant={ButtonVariant.INVISIBLE}
              onClick={itemClicked}
            >
              {option.label}
            </Button>
          </li>
        ), this)}
      </ul>
    </div>
  );
};

DropdownMenu.defaultProps = {
  buttonText: '',
};
