import * as React from 'react';

import './styles.scss';

import { TextInputProps } from 'types/inputProps';

const TextInput = (props: TextInputProps): JSX.Element => (
  <input
    className={`input-text ${props.variant}`}
    placeholder={props.placeholder}
    type={props.type}
    name={props.name}
    value={props.value}
    onChange={props.onChange}
    onBlur={props.onBlur}
    onCut={props.onCut}
    onCopy={props.onCopy}
    onPaste={props.onPaste}
    disabled={(props.disabled)}
    autoFocus={props.autoFocus}
    data-testid={props.testid}
    autoComplete={props.autoComplete}
    inputMode={props.inputMode}
    ref={props.inputRef}
  />
);

export default React.memo(
  TextInput,
  (prevProps: Readonly<TextInputProps>, nextProps: Readonly<TextInputProps>) => (
    prevProps.value === nextProps.value
    && prevProps.variant === nextProps.variant
  ),
);
