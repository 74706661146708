export enum DisplayVariant {
    FLEX_ROW = 'flex-row',
    FLEX_COL = 'flex-col',
    HIDE = 'hide',
}

export enum JustifyVariant {
    START = '--justify-start',
    CENTER = '--justify-center',
    END = '--justify-end',
    SPACE_EVENLY = '--justify-space-evenly',
    SPACE_AROUND = '--justify-space-around',
    SPACE_BETWEEN = '--justify-space-between',
}

export enum AlignVariant {
    START = '--align-start',
    CENTER = '--align-center',
    END = '--align-end',
}

export enum PositionVariant {
    RELATIVE = '--position-relative',
    ABSOLUTE = '--position-absolute',
}
