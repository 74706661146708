import * as React from 'react';

import AdminDashboard from 'pages/shared/Admin/dashboard';

const SuperAdminDashboardPage = (): JSX.Element => (
  <AdminDashboard
    testText="Super Admin Dashboard Page"
    title="Site Administration"
  />
);

export default SuperAdminDashboardPage;
