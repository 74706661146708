import * as React from 'react';

import Paragraph from 'global_elements/Text/Paragraph';
import MultiSelect from 'global_elements/Inputs/Dropdown/MultiSelect';

import { LabeledMultiSelectProps } from 'types/inputProps';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import './styles.scss'

const LabeledMultiSelect = (props: LabeledMultiSelectProps): JSX.Element => (
  <label className="labeled-multi-select">
    <Paragraph
      text={props.label}
      fontColor={FontColors.DARK_GRAY}
      fontSize={FontSizes.REGULAR}
      bold
      extraClasses="labeled-multi-select__header"
    />
    <MultiSelect
      styles={props.styles}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      value={props.value}
      options={props.options}
      onSelection={props.onSelection}
    />
  </label>
);

export default LabeledMultiSelect;
