import * as React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { isTestEnvironment } from 'config/msal/configMgmt';
import FeatureFlagManager from 'config/featureFlags/FeatureFlagManager';
import { FeatureFlagName } from 'config/featureFlags';
import { UserContext } from './context/user';
import 'notyf/notyf.min.css';

import LoginPage from './pages/anonymous/Login';
import RegisterByPINPage from './pages/anonymous/RegisterByPIN';
import LegacyLoginPage from './pages/anonymous/Login/legacyLogin';
import Page404 from './pages/errors/404';

import PatientRouting from './pages/patient/Routing';
import ProviderRouting from './pages/provider/Routing';
import FacilityDirectorRouting from './pages/facility_director/Routing';
import FacilityExecutiveRouting from './pages/facility_executive/Routing';
import CorporationRouting from './pages/corporation/Routing';
import SuperCorporationRouting from './pages/super_corporation/Routing';
import AdminRouting from './pages/admin/Routing';
import SuperAdminRouting from './pages/super_admin/Routing';
import { AssessmentPage } from './pages/anonymous/Assessment';
import { StorageConstants } from './constants/storageConstants';
import { AnonymousRoutes } from './constants/routes';

const App = (): JSX.Element => {
  const { user, Login, patientReflectionsConsentAccepted, patientHipaaConsentAccepted } = React.useContext(UserContext)
  const storage = sessionStorage
  const isPatientConsentEnabled = FeatureFlagManager.get<boolean>(FeatureFlagName.isPatientConsentEnabled)

  React.useEffect(() => {
    const persistedUserAccount = storage.getItem(StorageConstants.PersistedUserAccount);

    if (persistedUserAccount) {
      const parsed = JSON.parse(persistedUserAccount);
      Login(parsed);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const DetermineRouting = (): JSX.Element => {
    const isAuthenticated = useIsAuthenticated();
    let routingElement: JSX.Element = (
      <Router>
        <Switch>
          <Route
            exact
            path={AnonymousRoutes.LOGIN}
            component={isTestEnvironment() ? LegacyLoginPage : LoginPage}
          />
          <Route
            exact
            path={AnonymousRoutes.REGISTER_BY_PIN}
            component={RegisterByPINPage}
          />
          <Route
            exact
            path={AnonymousRoutes.ASSESSMENT}
            component={AssessmentPage}
          />
          <Route render={(props) => {
            if (!isAuthenticated) {
              // not logged in so redirect to login page with the return url
              // eslint-disable-next-line react/prop-types
              return <Redirect to={{ pathname: AnonymousRoutes.LOGIN, state: { from: props.location } }} />;
            }
            return <Route component={Page404} />;
          }}
          />
        </Switch>
      </Router>
    );

    if (user) {
      switch (user.role) {
        case 'patient':
          if (isPatientConsentEnabled) {
            routingElement = <PatientRouting patientConsentAccepted={patientReflectionsConsentAccepted} hipaaConsentAccepted={patientHipaaConsentAccepted} />;
          } else if (!isPatientConsentEnabled) {
            routingElement = <PatientRouting />;
          }
          break;
        case 'provider':
          routingElement = <ProviderRouting />;
          break;
        case 'facility-director':
          routingElement = <FacilityDirectorRouting />;
          break;
        case 'facility-executive':
          routingElement = <FacilityExecutiveRouting />;
          break;
        case 'corporation':
          routingElement = <CorporationRouting />;
          break;
        case 'super-corporation':
          routingElement = <SuperCorporationRouting />;
          break;
        case 'admin':
          routingElement = <AdminRouting />;
          break;
        case 'super-admin':
          routingElement = <SuperAdminRouting />;
          break;
        default:
          break;
      }
    }

    return routingElement;
  };

  return DetermineRouting();
};

export default App;
