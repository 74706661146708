/**
 * Insert Emoticons into the assessment that has an emoticon rating questions.
 */
export class EmoticonController {
  static setMaxWidth(questionHTML: any): void {
    const ratingItemElement = questionHTML.querySelectorAll(
      '[role="presentation"] .sd-rating__item, [role="presentation"] .sv_q_rating_item_text',
    );
    let maxWidth = 0;
    ratingItemElement.forEach((element: any) => {
      if (element.offsetWidth > maxWidth) {
        maxWidth = element.offsetWidth;
      }
    });

    ratingItemElement.forEach((element: any) => {
      element.style.width = `${maxWidth}px`; // eslint-disable-line no-param-reassign
    });
  }

  /**
   * Replace the 5 default rating question choices with the 5 emoticon faces.
   *
   * @param ratingOption SurveyJS options tied to 'Rating' question.
   */
  static updateEmoticonRatingStyle(ratingOption: any): void {
    const questionHTML = ratingOption.htmlElement;
    this.setMaxWidth(questionHTML);
    const choices = questionHTML.querySelectorAll('[role="presentation"] .sv-string-viewer');
    const emoticonListSrc = document.getElementById('emoticon-list');

    if (!emoticonListSrc) {
      console.assert(true, 'Missing emoticons');
      return;
    }

    let emoticonList = null;
    if (choices.length === 3) {
      emoticonList = emoticonListSrc.querySelectorAll('svg:not(.limit-5-rating');
    } else if (choices.length === 5) {
      emoticonList = emoticonListSrc.querySelectorAll('svg');
    }
    const listCount = emoticonList ? emoticonList.length : 0;

    console.assert(choices.length === listCount, 'Invalid rating choices count');
    if (!emoticonList || choices.length !== listCount) {
      return;
    }

    for (let index = 0; index < listCount; index += 1) {
      const emoticon = emoticonList[index];
      const emoticonCopy = emoticon?.cloneNode(true) as HTMLElement;

      if (emoticonCopy) {
        // Replace the default text with the icon.
        const choiceNode = choices.item(index);
        const childrenNodes = choiceNode.innerHTML;

        const tooltipNode = emoticonCopy.querySelector('title');
        if (tooltipNode) {
          tooltipNode.innerHTML = childrenNodes;
        }

        choiceNode.innerHTML = `<div class="emoticon-choice-text">${childrenNodes}</div>`;
        choiceNode.appendChild(emoticonCopy);
      }
    }
  }
}
