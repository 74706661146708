import * as React from 'react';
import Select, { MultiValue } from 'react-select';

import { MultiSelectDropdownProps, SelectOption } from 'types/inputProps';

const MultiSelect = (props: MultiSelectDropdownProps): JSX.Element => {
  const [
    selectedOption,
    setSelectedOption,
  ] = React.useState<MultiValue<SelectOption> | null>(props.defaultValue);

  const handleChange = (options: MultiValue<SelectOption>): void => {
    setSelectedOption(options);
    props.onSelection(options as SelectOption[]);
  };

  return (
    <div className="multi-select-dropdown-wrapper">
      <Select<SelectOption, true>
        styles={props.styles}
        value={selectedOption}
        onChange={handleChange}
        options={props.options}
        placeholder={props.placeholder}
        getOptionLabel={(option: SelectOption) => option.label}
        getOptionValue={(option: SelectOption) => option.value}
        isSearchable
        isClearable
        isMulti
      />
    </div>
  );
};

export default MultiSelect;
