import * as React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import PatientDashboardPage from 'pages/patient/PatientDashboard';
import PatientAccountPage from 'pages/patient/PatientAccount';
import HelpPage from 'pages/shared/Help';
import PatientResourcesPage from 'pages/patient/PatientResources';
import LoginIdentityCheckPage from 'pages/patient/LoginIdentityCheck';
import FirstTimeAccountSetupPage from 'pages/patient/FirstTimeAccountSetup';
import Page404 from 'pages/errors/404';

import { AllRoutes, PatientRoutes } from 'constants/routes';
import AssessmentPage from 'pages/shared/Survey';
import AssessmentResultsPage from 'pages/shared/AssessmentResult/assessmentResults';
import { HelpFileDownload } from 'pages/shared/Help/download';
import DaisyChainAssessmentsPage from 'pages/shared/DaisyChainAssessments';
import PatientConsentPage from '../PatientConsent';

interface Props {
  patientConsentAccepted?: boolean,
  hipaaConsentAccepted?: boolean,
}

const PatientRouting = ({ patientConsentAccepted, hipaaConsentAccepted }: Props): JSX.Element => {
  if (patientConsentAccepted === false || hipaaConsentAccepted === false) {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path={PatientRoutes.IDENTITY_CHECK}
            component={LoginIdentityCheckPage}
          />
          <Route
            path={`${AllRoutes.HELP_DOWNLOAD}/:filename`}
            component={HelpFileDownload}
          />
          <Route
            exact
            path={PatientRoutes.CONSENT}
            component={PatientConsentPage}
          />
          <Redirect
            to={PatientRoutes.CONSENT}
          />
          <Route component={Page404} />
        </Switch>
      </Router>
    )
  }

  return (
    <Router>
      <Switch>
        <Redirect
          exact
          path={AllRoutes.LOGIN}
          to={PatientRoutes.DASHBOARD}
        />
        <Redirect
          exact
          path={PatientRoutes.CONSENT}
          to={PatientRoutes.DASHBOARD}
        />
        <Route
          exact
          path={PatientRoutes.DASHBOARD}
          component={PatientDashboardPage}
        />
        <Route
          path={PatientRoutes.ACCOUNT}
          component={PatientAccountPage}
        />
        <Route
          path={PatientRoutes.RESOURCES}
          component={PatientResourcesPage}
        />
        <Route
          path={`${AllRoutes.HELP_DOWNLOAD}/:filename`}
          component={HelpFileDownload}
        />
        <Route
          path={PatientRoutes.HELP}
          component={HelpPage}
        />
        <Route
          exact
          path={PatientRoutes.FIRST_TIME_ACCOUNT_SETUP}
          component={FirstTimeAccountSetupPage}
        />
        <Route
          exact
          path={PatientRoutes.IDENTITY_CHECK}
          component={LoginIdentityCheckPage}
        />
        <Route
          exact
          path={`${PatientRoutes.ASSESSMENT}/:assessmentNumber/:instrumentTypeID/:appliedWhenID`}
          component={AssessmentPage}
        />
        <Route
          path={`${PatientRoutes.ASSESSMENT_RESULTS}/:careID/:instrumentTypeID/:patientID`}
          component={AssessmentResultsPage}
        />
        <Route
          path={`${PatientRoutes.ASSESSMENTS}`}
          component={DaisyChainAssessmentsPage}
        />
        <Route component={Page404} />
      </Switch>
    </Router>
  )
};

export default PatientRouting;
