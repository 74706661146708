import * as React from 'react';
import { FacilityDashboard } from 'pages/shared/Facility/dashboard';

const ProviderDashboardPage = (): JSX.Element => (
  <FacilityDashboard
    testText="Provider Dashboard Page"
    title="Provider Dashboard"
  />
);

export default ProviderDashboardPage;
