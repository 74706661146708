/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import MHODateTime from 'domain/dateTime/MHODateTime';

import './styles.scss';

import DataListsProxy from 'api/dataLists/dataListsProxy';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import PopupWindow from 'global_elements/Layouts/PopupWindow';
import NavBar from 'global_elements/Navigation/NavBar';
import Header from 'global_elements/Header';
import InlineText from 'global_elements/Text/InlineText';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import Paragraph from 'global_elements/Text/Paragraph';
import HiddenTestText from 'global_elements/Text/HiddenTestText';
import Button from 'global_elements/Button';
import { ReactComponent as XIcon } from 'icons/mho-x-icon.svg';
import { ReactComponent as LoggedOutBranding } from 'icons/reflections-logo.svg';

import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { NavBarVariant } from 'global_elements/Navigation/NavBar/variants';
import { PageLayoutProps } from 'types/pageLayoutProps';
import { SystemAlertRow } from 'types/tableProps';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { ListSystemAlert } from 'interfaces/dataLists/listSystemAlert';
import { UserContext } from 'context/user';
import { ActiveFacilityContext } from 'context/activeFacility';
import { Roles } from 'constants/roles';
import { PatientData } from 'interfaces/patients/patientData';

const PageLayout = (props: PageLayoutProps): JSX.Element => {
  const { facility } = React.useContext(ActiveFacilityContext);
  const [alertList, setAlertList] = React.useState<SystemAlertRow[]>([]);
  const [patientName, setPatientName] = React.useState<string>();
  const [showPatientName, setShowPatientName] = React.useState<boolean>();
  const { user, userAccount, isPopupActive, isSystemAlertsActive, DismissSystemAlerts } = React.useContext(UserContext);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (!user) {
      return;
    }

    const facilityID = user.role === 'patient' ? 0 : parseInt(facility?.value, 10);
    const isAdmin = user.role === 'admin' || user.role === 'super-admin';

    if (isSystemAlertsActive && !isAdmin && Number.isInteger(facilityID)) {
      DataListsProxy.getSystemAlertsList(facilityID, undefined, undefined, 3).then((alertsList: ListSystemAlert[]) => {
        if (alertsList) {
          const newAlertList: SystemAlertRow[] = [];
          alertsList.forEach((alert) => {
            newAlertList.push({
              alertID: alert.systemAlertID,
              severity: alert.severity,
              client: alert.clientType,
              platform: alert.platform,
              type: alert.alertType,
              message: alert.alertMessage,
              start: new MHODateTime(alert.startDate),
              end: new MHODateTime(alert.endDate),
            } as SystemAlertRow);
          });

          setAlertList(newAlertList);
        }
      }).catch((errorResponse) => {
        console.log(errorResponse);
      });
    }
  }, [facility, isSystemAlertsActive, user]);

  React.useEffect(() => {
    if (userAccount && userAccount.patientID) {
      PatientUsersProxy.getPatient(
        userAccount.patientID,
        (resp) => {
          if (resp && resp.data && resp.data.length) {
            const patient: PatientData = resp.data[0];
            setPatientName(`${patient.patientFirstName} ${patient.patientLastName}`);
            setShowPatientName(true);
          }
        },
        (e) => {
          setShowPatientName(false);
          console.log('Error getting patient account for header.');
          console.log(e);
        },
      );
    } else {
      setShowPatientName(false);
    }
  }, [userAccount]);

  const ClearAllSystemAlerts = (): void => {
    DismissSystemAlerts();
  };

  const Alert = (alertID: number|null, severity: string, type: string, message: string): JSX.Element => {
    let alertColor = FontColors.DARK;
    const alertKey = alertID || `${severity}${type}${message}`;

    switch (severity) {
      case 'Low':
        alertColor = FontColors.LOW_PRIORITY;
        break;
      case 'Medium':
        alertColor = FontColors.MEDIUM_PRIORITY;
        break;
      case 'High':
        alertColor = FontColors.HIGH_PRIORITY;
        break;
      default:
        console.log(`ERROR: System Alert severity invalid. Expected 'Low', 'Medium', or 'High' but received ${severity}`);
        break;
    }

    return (
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        key={alertKey}
      >
        <InlineText
          text={type}
          fontSize={FontSizes.REGULAR}
          fontColor={alertColor}
        />
        <InlineText
          text={`: ${message}`}
          fontSize={FontSizes.REGULAR}
          fontColor={FontColors.DARK}
        />
      </FlexContainer>
    );
  };

  const TimeoutPopup = (): JSX.Element => (
    <PopupWindow>
      <SecondaryHeader text="Session Timeout Pending" fontColor={FontColors.PRIMARY} />
      <Paragraph
        text="You have been inactive for 15 minutes."
        fontColor={FontColors.SECONDARY}
        fontSize={FontSizes.REGULAR}
      />
      <Paragraph
        text="You will be automatically logged out after 5 more minutes of inactivity."
        fontColor={FontColors.SECONDARY}
        fontSize={FontSizes.REGULAR}
      />
    </PopupWindow>
  );

  const LoadingCurtain = (loadingTitle: string, loadingText: string): JSX.Element => (
    <>
      <article />
      <main>
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="loading-curtain"
        >
          <PrimaryHeader text={loadingTitle} fontColor={FontColors.PRIMARY} />
          <SecondaryHeader text={loadingText} fontColor={FontColors.SECONDARY} />
        </FlexContainer>
      </main>
    </>
  );

  const MainContent = (title?: string, subTitle?: string, banner?: React.ReactNode, children?: React.ReactNode): JSX.Element => (
    <>
      <article className="no-print">
        {isSystemAlertsActive && alertList.length > 0
            && (
              <>
                {alertList.map((alert) => (
                  Alert(alert.alertID, alert.severity, alert.type, alert.message)
                ))}
                <Button
                  variant={ButtonVariant.INVISIBLE}
                  onClick={ClearAllSystemAlerts}
                >
                  <XIcon />
                  <InlineText
                    text="Dismiss All"
                    fontColor={FontColors.PRIMARY}
                    fontSize={FontSizes.SMALL}
                    underlined
                  />
                </Button>
              </>
            )}
      </article>
      <main>
        {title && (<PrimaryHeader text={title} fontColor={FontColors.PRIMARY} />)}
        {subTitle && (<SecondaryHeader text={subTitle} fontColor={FontColors.PRIMARY} extraClasses="print-only" />)}
        {banner}
        {children}
      </main>
    </>
  );

  const getUserName = (): string => {
    if (showPatientName !== undefined) {
      if (patientName) return patientName;
      const isPatient = user?.role === Roles.PATIENT;
      if (isPatient && userAccount && userAccount.firstName && userAccount.lastName) {
        return `${userAccount.firstName} ${userAccount.lastName}`;
      }
      return user?.name ? user.name : 'Name Not Found';
    }
    return '';
  };

  return (
    user && !props.hideHeader ? (
      <div className={`${props.extraClasses ?? ''}`}>
        <HiddenTestText text={props.testText} />
        {isPopupActive && TimeoutPopup()}
        <Header patientName={getUserName()} />
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          justify={JustifyVariant.START}
          align={AlignVariant.START}
        >
          <NavBar
            userRole={user?.role}
            variant={NavBarVariant.PRIMARY}
          />
          <FlexContainer
            display={DisplayVariant.FLEX_COL}
            justify={JustifyVariant.START}
            align={AlignVariant.START}
            extraClasses={`main-wrapper ${props.layout}`}
          >
            {(props.loadingText && props.loadingTitle)
              ? LoadingCurtain(props.loadingTitle, props.loadingText)
              : MainContent(props.title, props.subTitle, props.bannerCard, props.children)}
          </FlexContainer>
        </FlexContainer>
      </div>
    ) : (
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        justify={JustifyVariant.START}
        align={AlignVariant.CENTER}
        extraClasses={`logged-out-page ${props.extraClasses ?? ''}`}
      >
        <HiddenTestText text={props.testText} />
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          justify={JustifyVariant.CENTER}
          align={AlignVariant.CENTER}
          extraClasses="logged-out-page__header"
        >
          <LoggedOutBranding />
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            justify={JustifyVariant.CENTER}
            align={AlignVariant.CENTER}
            extraClasses="logged-out-page__header__text"
          >
            <InlineText
              text="Mental Health Outcomes"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.SUPER_EXTRA_LARGE}
              bold
            />
            <InlineText
              text="Assessment Portal"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.SUPER_EXTRA_LARGE}
            />
          </FlexContainer>
        </FlexContainer>
        <main>
          {(props.loadingText && props.loadingTitle)
            ? LoadingCurtain(props.loadingTitle, props.loadingText)
            : props.children}
        </main>
      </FlexContainer>
    )
  );
};

export default PageLayout;
