import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import * as DateInput from 'global_elements/Inputs/TextInput/lib/date';

import './styles.scss';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Button from 'global_elements/Button';
import InlineText from 'global_elements/Text/InlineText';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import TextInput from 'global_elements/Inputs/TextInput';

import ApiResponse from 'api/lib/models/ApiResponse';
import UserAccount from 'interfaces/users/userAccount';
import { PatientData } from 'interfaces/patients/patientData';
import MHODateTime from 'domain/dateTime/MHODateTime';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';
import UserProxy from 'api/user/userProxy';
import { UserContext } from 'context/user';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontSizes, FontColors } from 'global_elements/Text/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { PatientRoutes } from 'constants/routes';

type RegisterObject = {
    patientDOB: string;
}

const LoginIdentityCheckPage = (): JSX.Element => {
  const [registrationInfo, setRegistrationInfo] = React.useState<RegisterObject>({ patientDOB: '' });
  const [wrongDOB, setWrongDOB] = React.useState<boolean>(false);
  const [patientData, setPatientData] = React.useState<PatientData>();
  const errorMessage = 'The value entered does not match our records. Please try again.';

  const history = useHistory();

  const { user } = useContext(UserContext);

  React.useEffect(() => {
    const userAccountIdStr = user ? user.accountId : '-1';
    const userAccountId = parseInt(userAccountIdStr, 10);

    UserProxy.getUserAccountInfo(
      userAccountId,
      (accountResponse: ApiResponse<UserAccount[]>) => {
        if (accountResponse.data?.length !== 1) return;
        // setPatientData(accountResponse.data[0]);
        console.log(patientData);
      },
      (error) => console.log(error),
    );

    PatientUsersProxy.getPatient(
      8197715,
      (response) => {
        if (response.data) {
          const loadedPatientData: PatientData = response.data[0];
          const formattedDOB: string = new MHODateTime(response.data[0].dateOfBirth).getFormattedCalendarDate();
          const formattedDA: string = loadedPatientData.dateAdmitted && new MHODateTime(response.data[0].dateAdmitted).getFormattedCalendarDate();

          loadedPatientData.dateOfBirth = formattedDOB;
          loadedPatientData.dateAdmitted = formattedDA;
          setPatientData(loadedPatientData);
          // setPatientInfo(loadedPatientData);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === 'patientDOB') {
      if (DateInput.validDateInput(e)) {
        const maskedOutput = DateInput.maskInputAsDate(e);

        if (maskedOutput !== undefined) {
          setRegistrationInfo((prevState) => (
            { ...prevState, [e.target.name]: maskedOutput }
          ));
        }
      }
    }
  };

  const disableNext = (): boolean => (
    registrationInfo.patientDOB === '' || !!DateInput.inputError(registrationInfo.patientDOB)
  );

  const proceedToPatientAccountSetup = (): void => {
    const dob = registrationInfo.patientDOB.split('/');
    const formattedDob = `${dob[2]}-${dob[0]}-${dob[1]}`;
    UserProxy.validateDOB(
      formattedDob,
      (resp) => {
        if (resp.data && resp.data.length) {
          history.push(PatientRoutes.FIRST_TIME_ACCOUNT_SETUP);
        } else {
          setWrongDOB(true);
        }
      },
      (err) => {
        console.log(err);
        setWrongDOB(true);
      },
    );
  };

  return (
    <PageLayout
      layout={PageLayoutVariant.LOGGED_OUT}
      testText="Login Identity Check Page"
      hideHeader
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.CENTER}
        justify={JustifyVariant.CENTER}
        extraClasses="register-by-email-content__inputs"
      >
        <SecondaryHeader text="Account Verification" fontColor={FontColors.PRIMARY} />
        <label>
          <InlineText
            text="Please enter the patient's date of birth"
            fontColor={DateInput.inputError(registrationInfo.patientDOB) ? FontColors.HIGH_PRIORITY : FontColors.DARK}
            fontSize={FontSizes.REGULAR}
            bold
          />
          <InlineText
            text="(mm/dd/yyyy)"
            fontColor={DateInput.inputError(registrationInfo.patientDOB) ? FontColors.HIGH_PRIORITY : FontColors.DARK_GRAY}
            fontSize={FontSizes.REGULAR}
            bold={!!DateInput.inputError(registrationInfo.patientDOB)}
          />
          <TextInput
            variant={TextInputVariant.PRIMARY}
            type="text"
            name="patientDOB"
            value={registrationInfo.patientDOB}
            onChange={handleInputChange}
          />
        </label>
        <Button
          variant={ButtonVariant.DARK}
          onClick={proceedToPatientAccountSetup}
          disabled={disableNext()}
        >
          <InlineText
            text="Next"
            fontColor={FontColors.BACKGROUND}
            fontSize={FontSizes.LARGE}
            bold
          />
        </Button>
      </FlexContainer>
      {wrongDOB
          && (
            <FlexContainer
              display={DisplayVariant.FLEX_COL}
              align={AlignVariant.CENTER}
              justify={JustifyVariant.CENTER}
              extraClasses="register-by-email-content__error"
            >
              <InlineText
                text={errorMessage}
                fontColor={FontColors.HIGH_PRIORITY}
                fontSize={FontSizes.LARGE}
                bold
              />
            </FlexContainer>
          )}
    </PageLayout>
  );
};

export default LoginIdentityCheckPage;
