interface ConstructorParams<T> {
    status: number
    message?: string
    data?: T | null
}

class ApiResponse<T> {
    status: number
    message?: string
    data?: T | null

    constructor({ status, message, data }: ConstructorParams<T>) {
      this.status = status
      this.message = message
      this.data = data
    }

    get success(): boolean {
      return this.status >= 200 && this.status < 300
    }
}

export default ApiResponse
