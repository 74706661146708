import * as React from 'react';

import './styles.scss';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import NavLinkWrapper from 'global_elements/Navigation/NavLinkWrapper';
import Paragraph from 'global_elements/Text/Paragraph';
import InlineText from 'global_elements/Text/InlineText';

import { NavBarProps } from 'types/navigationProps';
import { Roles, UserRole } from 'constants/roles';
import { AnyRoute } from 'constants/routes';

import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontSizes, FontColors } from 'global_elements/Text/variants';
import { NavLinkVariant } from 'global_elements/Navigation/NavLinkWrapper/variants';
import { GetNavOptions, GetNavText, ADMIN_REFERENCE_NAV } from 'global_elements/Navigation/lib/navOptions';
import { ActiveFacilityContext } from 'context/activeFacility';
import { UserContext } from 'context/user';
import { AccessType } from 'types/facilityUserAccount';

const NavBar = (props: NavBarProps): JSX.Element => {
  const { facility } = React.useContext(ActiveFacilityContext);
  const { user } = React.useContext(UserContext);
  const canViewCoreMeasures = Boolean(facility.isCoreMeasures && user && (user.accessType === AccessType.ALL_ACCESS || user.accessType === AccessType.CORE_MEASURES));
  const canViewOutcomes = Boolean(facility.isOutcomes) && Boolean(user && (user.accessType === AccessType.ALL_ACCESS || user.accessType === AccessType.OUTCOMES));
  const navLinks = (role: UserRole | null | undefined): JSX.Element[] => GetNavOptions(role, canViewCoreMeasures, canViewOutcomes).map(
    (route: AnyRoute): JSX.Element => (
      <NavLinkWrapper key={`main-nav-${route}`} variant={NavLinkVariant.MAIN} route={route}>
        <InlineText text={GetNavText(route)} fontColor={FontColors.BACKGROUND} fontSize={FontSizes.EXTRA_LARGE} />
      </NavLinkWrapper>
    ),
  );

  const adminReferences = (): JSX.Element => (
    <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="super-admin-references">
      <Paragraph text="References" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.EXTRA_LARGE} />
      {ADMIN_REFERENCE_NAV.map((route: AnyRoute) => (
        <NavLinkWrapper key={`main-nav-${route}`} variant={NavLinkVariant.SUB} route={route}>
          <InlineText text={GetNavText(route)} fontColor={FontColors.BACKGROUND} fontSize={FontSizes.LARGE} />
        </NavLinkWrapper>
      ))}
    </FlexContainer>
  );

  return (
    <nav id="mho-nav-bar" className={`mho-nav-bar ${props.variant}`}>
      <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.START}>
        {navLinks(props.userRole)}
        {(props.userRole === Roles.SUPER_ADMIN || props.userRole === Roles.ADMIN) && adminReferences()}
      </FlexContainer>
    </nav>
  );
};

export default React.memo(NavBar, (prevProps: Readonly<NavBarProps>, nextProps: Readonly<NavBarProps>) => prevProps.userRole === nextProps.userRole);
