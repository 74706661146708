import * as React from 'react';

import { LabledTextInputProps } from 'types/inputProps';
import LabledTextInput from '../TextInput/LabledTextInput';

const LabeledDateInput = (props: LabledTextInputProps): JSX.Element => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <LabledTextInput {...props} />
);

export default React.memo(
  LabeledDateInput,
  (prevProps: Readonly<LabledTextInputProps>, nextProps: Readonly<LabledTextInputProps>) => (
    prevProps.value === nextProps.value
    && prevProps.error === nextProps.error
  ),
);
