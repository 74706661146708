import React from 'react';
import DualListBox from 'react-dual-listbox';
import { DualListIcons } from './dualListIcons';
import 'react-dual-listbox/src/scss/react-dual-listbox.scss';
import './styles.scss';

type DualListPanelProps = {
  canFilter?: boolean;
  showHeaderLabels?: boolean;
  selected: any[];
  options: any[];
  onChange: any;
};

export const DualListPanel = (props: DualListPanelProps): JSX.Element => (
  <>
    <span className="tablet-info">* Select either box below to view facility options.</span>
    <DualListBox
      className="dual-listbox"
      canFilter={props.canFilter}
      showHeaderLabels={props.showHeaderLabels}
      selected={props.selected}
      options={props.options}
      onChange={props.onChange}
      icons={DualListIcons}
    />
  </>
);

DualListPanel.defaultProps = {
  canFilter: false,
  showHeaderLabels: false,
};
