import { TableStyles } from 'react-data-table-component';

const BrandSecondary = '#337EAD';
const BrandPrimary = '#023860';
const BrandGray = '#8a8a8a';
const BrandNegative = '#e1e4e9';
const BrandDark = '#2d2d2d';
const BrandBackground = '#ffffff';

const mobileBreakpoint = 1024;

const rowStyle = {
  fontFamily: 'noto-sans',
  fontSize: '0.875rem',
  color: BrandSecondary,
  '@media print': {
    fontSize: '0.75rem',
  },
  [`@media (max-width: ${mobileBreakpoint}px)`]: {
    fontSize: '0.65rem',
  },
  '&:not(:last-of-type)': {
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderBottomColor: BrandGray,
  },
};

const clickableRowStyle = {
  ...rowStyle,
  ...{
    '&:hover': {
      cursor: 'pointer',
    },
  },
};

export const BasicTableStyles: TableStyles = {
  headRow: {
    style: {
      fontFamily: 'noto-sans',
      fontSize: '0.875rem',
      '@media print': {
        fontSize: '0.75rem',
        minHeight: '24px',
      },
      [`@media (max-width: ${mobileBreakpoint}px)`]: {
        fontSize: '0.65rem',
        // minHeight: '24px',
      },
      border: 'none',
      backgroundColor: BrandSecondary,
      color: BrandBackground,
    },
    denseStyle: {
      minHeight: '48px',
    },
  },
  rows: {
    style: rowStyle,
    highlightOnHoverStyle: {},
  },
};

export const AlternatingBasicTableStyle: TableStyles = {
  ...BasicTableStyles,
  ...{
    table: {
      style: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: BrandDark,
        overflowY: 'auto',
      },
    },
    rows: {
      style: {
        ...rowStyle,
        ...{
          color: BrandPrimary,
          '&:nth-child(even)': {
            backgroundColor: BrandNegative,
          },
        },
      },
    },
  },
};

export const HoverTableStyles: TableStyles = {
  ...AlternatingBasicTableStyle,
  ...{
    rows: {
      style: {
        ...clickableRowStyle,
        ...{
          color: BrandPrimary,
          '&:nth-child(even)': {
            backgroundColor: BrandNegative,
          },
        },
      },
      highlightOnHoverStyle: {
        color: BrandPrimary,
        textDecoration: 'underline',
        fontWeight: '600',
        backgroundColor: BrandNegative,
        borderBottomColor: BrandGray,
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: BrandGray,
      },
    },
  },
};

export const SystemAlertTableStyles: TableStyles = {
  ...HoverTableStyles,
  ...{
    table: {
      style: {
        ...clickableRowStyle,
        ...{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: BrandDark,
          overflowY: 'auto',
        },
      },
    },
    head: {
      style: {
        position: 'sticky',
        top: '0px',
        zIndex: 1,
      },
    },
    headCells: {
      style: {
        paddingLeft: '16px',
        paddingRight: '0',
      },
    },
    rows: {
      style: {
        fontFamily: 'noto-sans',
        fontSize: '0.875rem',
        color: BrandDark,
        '@media print': {
          fontSize: '0.75rem',
        },
        [`@media (max-width: ${mobileBreakpoint}px)`]: {
          fontSize: '0.65rem',
        },
        '&:not(:last-of-type)': {
          borderBottomStyle: 'solid',
          borderBottomWidth: '1px',
          borderBottomColor: BrandGray,
        },
        '&:nth-child(even)': {
          backgroundColor: BrandNegative,
        },
      },
      highlightOnHoverStyle: {
        color: BrandPrimary,
        textDecoration: 'underline',
        fontWeight: '600',
        borderBottomColor: BrandGray,
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: BrandGray,
        '&:nth-child(even)': {
          backgroundColor: BrandNegative,
        },
        '&:nth-child(odd)': {
          backgroundColor: BrandBackground,
        },
      },
    },
  },
};
