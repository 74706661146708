export enum HiddenQuestionNames {
  PROGRAMCODE = 'PROGCODE',
  ACCOUNTNO = 'PTACCTNO',
  APPLIEDWHEN = 'AWID',
  COMPLETED_DATE = 'DTCDOC',

  LEVELCARE = 'level-of-care-(hidden)',
  CORPORATION = 'corporation-(hidden)',
  POPULATION = 'population-(hidden)',
}

export enum HiddenQuestionTitles {
  LEVELOFCARE = 'Level Of Care (Hidden)',
  CORPORATION = 'Corporation (Hidden)',
  POPULATION = 'Population (Hidden)',
}

export type HiddenQuestionName = `${HiddenQuestionNames}`;
export type HiddenQuestionTitle = `${HiddenQuestionTitles}`;
