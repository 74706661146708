import * as React from 'react';

import PageLayout from 'global_elements/Layouts/PageLayout';
import HiddenTestText from 'global_elements/Text/HiddenTestText';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';

import { FontColors } from 'global_elements/Text/variants';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { Roles } from 'constants/roles';
import { UserContext } from 'context/user';

const Page404 = (): JSX.Element => {
  const { user } = React.useContext(UserContext);

  return (
    (user && user.role !== Roles.NONE)
      ? (
        <PageLayout layout={PageLayoutVariant.PADDED} testText="404 Page">
          <PrimaryHeader text="Page Not Found" fontColor={FontColors.PRIMARY} />
        </PageLayout>
      ) : (
        <>
          <HiddenTestText text="404 Page" />
          <PrimaryHeader text="Page Not Found" fontColor={FontColors.PRIMARY} />
        </>
      )
  );
};

export default Page404;
