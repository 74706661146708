import { SurveyModel } from 'survey-react'

import { HiddenQuestionNames } from 'constants/MHO/hiddenQuestionNames'
import { SurveyJSQuestionTypes } from 'constants/SurveyJS/question_types'
import { StorageConstants } from 'constants/storageConstants'

export interface AssessmentDraftKey {
  assessmentNumber: number
  appliedWhenID: number
  patientID: number
}

export class AssessmentDraftSessionStorage {
  public static async save(
    key: AssessmentDraftKey,
    surveyModel: SurveyModel,
  ): Promise<void> {
    const draft = surveyModel.data

    AssessmentDraftSessionStorage.sanitizeDraft(draft, surveyModel)

    const hash = await AssessmentDraftSessionStorage.getAssessmentHash(key)
    sessionStorage.setItem(StorageConstants.AssessmentDraftHash, hash)
    sessionStorage.setItem(StorageConstants.AssessmentDraft, JSON.stringify(draft))
  }

  private static sanitizeDraft(
    draft: any,
    surveyModel: SurveyModel,
  ): void {
    const allowedHiddenQuestions: string[] = [
      HiddenQuestionNames.COMPLETED_DATE.valueOf(),
    ]
    Object.values(HiddenQuestionNames).forEach((hiddenQuestionName) => {
      if (allowedHiddenQuestions.includes(hiddenQuestionName)) {
        return
      }

      delete draft[hiddenQuestionName]
    })

    const disallowedQuestionTypes: string[] = [
      SurveyJSQuestionTypes.COMMENT.valueOf(),
    ]
    const disallowedTextInputTypes: string[] = [
      'text',
      'password',
    ]
    Object.keys(draft).forEach((k) => {
      const question = surveyModel.getQuestionByName(k)
      if (question && disallowedQuestionTypes.includes(question.getType())) {
        delete draft[k]
      }

      if (question && question.getType() === SurveyJSQuestionTypes.TEXT) {
        const textQuestion = question as any
        if (disallowedTextInputTypes.includes(textQuestion.inputType)) {
          delete draft[k]
        }
      }
    })
  }

  public static async load(key: AssessmentDraftKey): Promise<any> {
    const keyHash = await AssessmentDraftSessionStorage.getAssessmentHash(key)
    const savedHash = sessionStorage.getItem(StorageConstants.AssessmentDraftHash)
    if (savedHash !== keyHash) {
      return null
    }

    const savedAnswers = sessionStorage.getItem(StorageConstants.AssessmentDraft)
    if (savedAnswers) {
      return JSON.parse(savedAnswers)
    }

    return null
  }

  private static async getAssessmentHash({
    assessmentNumber,
    appliedWhenID,
    patientID,
  }: AssessmentDraftKey): Promise<string> {
    const compositeKey = `${assessmentNumber}-${appliedWhenID}-${patientID}`
    const buffer = new TextEncoder().encode(compositeKey)
    const hash = await crypto.subtle.digest('SHA-256', buffer)
    return Array.from(new Uint8Array(hash))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')
  }

  public static clear(): void {
    sessionStorage.removeItem(StorageConstants.AssessmentDraftHash)
    sessionStorage.removeItem(StorageConstants.AssessmentDraft)
  }
}
