import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { useMsal, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { StorageConstants } from 'constants/storageConstants';

import UserHelpers from 'domain/users/userHelpers';
import UserProxy from 'api/user/userProxy';
import { ApiResponseV2 as ApiResponse } from 'api/lib/models/ApiResponse';

import * as EmailInput from 'global_elements/Inputs/TextInput/lib/email';
import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Button from 'global_elements/Button';
import DefaultButton from 'global_elements/Button/defaultButton';
import InlineText from 'global_elements/Text/InlineText';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontSizes, FontColors } from 'global_elements/Text/variants';
import { UserContext } from 'context/user';
import UserEntitlement from 'interfaces/users/userEntitlement';
import UserAccount from 'interfaces/users/userAccount';
import { AnonymousRoutes } from 'constants/routes';
import { Roles } from 'constants/roles';
import FeatureFlagManager from 'config/featureFlags/FeatureFlagManager';
import { FeatureFlagName } from 'config/featureFlags';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

const LoginPage = (): JSX.Element => {
  const { instance, accounts, inProgress } = useMsal();
  const [errorText, setErrorText] = useState('');
  const [loginName, setLoginName] = useState('');
  const { Login, SetAccount } = useContext(UserContext);
  const storage = sessionStorage;
  const history = useHistory();
  const isAuthenticated = useIsAuthenticated();

  let loginRequest: any = null;

  /**
   * Check the status of MSAL/user login or logged in process.
   *
   * @param response returned response from MSAL instance queries.
   */
  function msalInstanceResponse(response: any): void {
    if (response !== null) {
      storage.setItem(StorageConstants.MsalAppProvider, response.idTokenClaims.appprovider);
      storage.setItem(StorageConstants.MsalHomeAccountID, response.account.homeAccountId);
      storage.setItem(StorageConstants.MsalExpiration, response.idTokenClaims.exp);
    } else {
      // In case multiple accounts exist, you can select
      const currentAccounts = instance.getAllAccounts();
      if (currentAccounts.length === 0) {
        // no accounts signed-in, attempt to sign a user in
        instance.loginRedirect(loginRequest);
      } else if (currentAccounts.length > 1) {
        console.warn('Multiple user login detected. Currently unhandled.');
      } else if (currentAccounts.length === 1) {
        // Single account signed in.
        storage.setItem(StorageConstants.MsalHomeAccountID, currentAccounts[0].homeAccountId);
      }
    }
  }

  /**
   * Submit user entered login email/username.
   *
   * @param event keyboard event.
   */
  const handleLoginSubmit = ((event: any): void => {
    event.preventDefault();
    const redirect = (authority: string): void => {
      loginRequest = {
        scopes: [],
        authority,
        prompt: 'login',
        loginHint: loginName,
      };
      storage.setItem(StorageConstants.LoginAuthority, loginRequest.authority);
      instance.handleRedirectPromise()
        .then((r) => { msalInstanceResponse(r); })
        .catch(() => { msalInstanceResponse(null); });
    }
    UserProxy.GetLoginPolicy(loginName)
      .then((r) => {
        if (r.data) {
          redirect(r.data);
        }
      })
      .catch((e) => console.log(e));
  });

  const checkLoginDisable = (): boolean => (!EmailInput.validEmail(loginName));

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event.error && event.error.message.indexOf('AADB2C90118') > -1) {
          if (event.interactionType === InteractionType.Redirect) {
            setErrorText('An unknown error has occurred');
          }
        } else if (event.error && event.error.message.indexOf('AADB2C90027') > -1) {
          setErrorText('An error occurred logging in with that account');
        }
      }

      return '';
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  const setLoginUserInfo = (accountInfo: AccountInfo, accountResponseData: UserAccount[], entitlementData: any, authority: any): void => {
    const user = UserHelpers.getUserFromClaims(accountInfo, accountResponseData, entitlementData, authority);
    Login(user);
    SetAccount(accountResponseData[0]);
    window.dataLayer.push({
      event: 'Login',
      user_id: user.accountId,
      crm_id: user.accountId,
    });
    storage.setItem(StorageConstants.PersistedUserAccount, JSON.stringify(user));
    storage.removeItem(StorageConstants.LoginAuthority);
    instance.handleRedirectPromise().then(msalInstanceResponse);
  };

  const LoginUserCallback = useCallback((accountInfo: AccountInfo, authority: string): void => {
    const claims = accountInfo.idTokenClaims as any;
    const id = parseInt(UserHelpers.getRefId(claims.appid), 10);

    UserProxy.getUserAccountInfo(
      id,
      (accountResponse: ApiResponse<UserAccount[]>) => {
        if (accountResponse.data && UserHelpers.getUserRoleFromAccount(accountResponse.data[0]) === Roles.PATIENT) {
          setLoginUserInfo(accountInfo, accountResponse.data, [], authority);
        } else {
          UserProxy.getUserEntitlements(id).then((userEntitlement: UserEntitlement[]) => {
            if (userEntitlement.length && accountResponse.data) {
              setLoginUserInfo(accountInfo, accountResponse.data, userEntitlement, authority);
            }
          }).catch((error: any) => {
            setErrorText('An error getting users entitlements');
            console.log(error);
          });
        }
      }, () => {
        setErrorText('An error getting users account');
      },
    );
  }, []);

  const logDevDiagsAccessToken = (tokenResponse: AuthenticationResult): void => {
    const isDevDiagsEnabled = FeatureFlagManager.get<boolean>(FeatureFlagName.isDevDiagnosticsEnabled);
    if (isDevDiagsEnabled) {
      console.log(tokenResponse.accessToken);
    }
  }

  useEffect(() => {
    if (accounts.length > 0 && !isAuthenticated) {
      const accountInfo = accounts[0];
      instance.setActiveAccount(accountInfo);
      const auth = storage.getItem(StorageConstants.LoginAuthority);
      const authority = auth || '';

      const accessTokenRequest = {
        scopes: ['https://mhob2c.onmicrosoft.com/api/tasks.read'],
        account: accountInfo,
        authority,
      };

      instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
        logDevDiagsAccessToken(accessTokenResponse);
        LoginUserCallback(accountInfo, authority);
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(accessTokenRequest).then((accessTokenResponse) => {
            logDevDiagsAccessToken(accessTokenResponse);
            LoginUserCallback(accountInfo, authority);
          }).catch((requestError) => {
            console.log(requestError);
            setErrorText('An error acquiring the access token');
          });
        }
        console.log(error);
      });
    }
  }, [accounts]);

  const envName = process.env.REACT_APP_MHO_ENV_NAME;
  let versionNumber = process.env.REACT_APP_MHO_VERSION;
  versionNumber = !envName || envName === 'PROD' ? versionNumber : `${versionNumber}-${envName}`;

  const logginIn = inProgress === 'login' || inProgress === 'handleRedirect';

  const registerByCode = (): void => {
    history.push(AnonymousRoutes.REGISTER_BY_PIN);
  };

  /**
   * Handle login name change to track.
   *
   * @param e Input event.
   */
  const handleLoginNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLoginName(e.target.value.trim());
  };

  return (
    <PageLayout
      layout={PageLayoutVariant.LOGGED_OUT}
      testText="Login Page"
    >
      <AuthenticatedTemplate>
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          justify={JustifyVariant.CENTER}
          align={AlignVariant.CENTER}
          extraClasses="login-content-container"
        >
          <InlineText
            text="Finishing Login..."
            fontColor={FontColors.PRIMARY}
            fontSize={FontSizes.SUPER_EXTRA_LARGE}
          />
        </FlexContainer>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          justify={JustifyVariant.CENTER}
          align={AlignVariant.CENTER}
          extraClasses={logginIn ? 'login-content-container' : 'login-content-container login-panel'}
        >
          {logginIn
            ? (
              <InlineText
                text="Redirecting to Login..."
                fontColor={FontColors.PRIMARY}
                fontSize={FontSizes.SUPER_EXTRA_LARGE}
              />
            ) : (
              <>
                <InlineText
                  text="Sign In"
                  bold
                  fontColor={FontColors.PRIMARY}
                  fontSize={FontSizes.SUPER_EXTRA_LARGE}
                />
                <form onSubmit={handleLoginSubmit}>
                  <FlexContainer
                    display={DisplayVariant.FLEX_COL}
                    justify={JustifyVariant.CENTER}
                    align={AlignVariant.CENTER}
                  >
                    <LabledTextInput
                      label="Login Email"
                      placeholder="Enter your registered login email..."
                      name="loginName"
                      value={loginName}
                      onChange={handleLoginNameChange}
                      type="text"
                      variant={TextInputVariant.PRIMARY}
                      autoFocus
                    />
                    <DefaultButton
                      variant={ButtonVariant.DARK}
                      disabled={checkLoginDisable()}
                    >
                      <InlineText
                        text="Continue"
                        fontColor={FontColors.BACKGROUND}
                        fontSize={FontSizes.LARGE}
                      />
                    </DefaultButton>
                  </FlexContainer>
                </form>
                <Button
                  variant={ButtonVariant.INVISIBLE}
                  onClick={registerByCode}
                >
                  <InlineText
                    text="Register by PIN"
                    fontColor={FontColors.PRIMARY}
                    fontSize={FontSizes.LARGE}
                    bold
                    underlined
                  />
                </Button>
                <InlineText
                  text={errorText}
                  fontColor={FontColors.HIGH_PRIORITY}
                  fontSize={FontSizes.REGULAR}
                />
                <InlineText
                  text={`Version: ${versionNumber}`}
                  fontColor={FontColors.PRIMARY}
                  fontSize={FontSizes.REGULAR}
                />
              </>
            )}
        </FlexContainer>
      </UnauthenticatedTemplate>
    </PageLayout>
  );
};

export default LoginPage;
