import * as React from 'react';

import './index.scss';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';

import { getMsalConfig } from 'config/msal/configMgmt';

import { createRoot } from 'react-dom/client';
import App from './App';
import UserProvider from './context/provider_components/UserProvider';
import ActiveFacilityProvider from './context/provider_components/ActiveFacilityProvider';

const pca = new PublicClientApplication(getMsalConfig());
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <UserProvider>
        <ActiveFacilityProvider>
          <App />
        </ActiveFacilityProvider>
      </UserProvider>
    </MsalProvider>
  </React.StrictMode>,
);
