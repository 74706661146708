import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import Utilities from 'api/lib/Utilities';
import FacilityUsersProxy from 'api/facilityUsers/facilityUsersProxy';
import WorklistProxy from 'api/CoreMeasures/WorklistProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import ProviderWorkList from 'global_elements/Layouts/DataTables/ProviderWorkList';
import SearchBar from 'global_elements/Inputs/SearchBar';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { ProviderWorkListRow, WorkListChartDataType } from 'types/tableProps';
import { ActiveFacilityContext } from 'context/activeFacility';
import { AllRoutes } from 'constants/routes';
import { WorkListChart } from 'pages/shared/WorkCharts/workCharts';
import { MultiStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/MultiSelect/styles';
import { SelectOption } from 'types/inputProps';

import './styles.scss';
import MultiSelect from 'global_elements/Inputs/Dropdown/MultiSelect';
import { UserContext } from 'context/user';
import { AccessType } from 'types/facilityUserAccount';

/**
 * Shared dashboard page for Providers and Facility Directors.
 */
export const FacilityDashboard = (props: any): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [workListLoading, setWorkListLoading] = useState(true);
  const [searchText, setSearchText] = useState<string>('');
  const [workListData, setWorkListData] = useState<ProviderWorkListRow[]>([]);
  const [workListChartData, setWorkListChartData] = useState<WorkListChartDataType>({
    facilityID: 0,
    chartData: [],
  });
  const [filteredList, setFilteredList] = useState<ProviderWorkListRow[]>([]);
  const { facility } = useContext(ActiveFacilityContext);
  const { user } = useContext(UserContext);
  const [isLoadingChart, setIsLoadingChart] = useState<boolean>(false);
  const [showProgramChart, setShowProgramChart] = useState<boolean>(true);
  const history = useHistory();
  const [serviceCodeOptions, setServiceCodeOptions] = useState<SelectOption[]>([]);
  const [selectedServiceCodes, setSelectedServiceCodes] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (user?.accessType === AccessType.CORE_MEASURES) {
      history.push('/core-measures-worklist');
    }
  }, []);

  useEffect(() => {
    const facilityID = parseInt(facility.value, 10);
    if (facilityID === 0 || Number.isNaN(facilityID)) {
      return;
    }

    // Load and setup the Work List Chart data based on changing facility event.
    setIsLoadingChart(true);
    FacilityUsersProxy.getWorkListChart(facilityID)
      .then((chartData) => {
        const data: WorkListChartDataType = { facilityID, chartData };
        setIsLoadingChart(false);
        setWorkListChartData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [facility]);

  useEffect(() => {
    if (!facility.value.length) {
      return;
    }

    const facilityId = parseInt(facility.value, 10);
    WorklistProxy.getServiceCodes(facilityId, undefined).then((results) => {
      if (results.data) {
        const options = results.data.map((x) => ({
          label: x.unitCode,
          value: x.unitCode,
        }));
        setServiceCodeOptions(options);
      }
    });
  }, [facility]);

  useEffect(() => {
    if (!facility.value.length) {
      return;
    }

    setWorkListLoading(true);
    const unitCodes = selectedServiceCodes.map((o) => o.value);
    FacilityUsersProxy.getProviderWorkList(facility.value, undefined, unitCodes)
      .then((resp) => {
        setLoading(false);
        if (resp) {
          const filteredWorkList: ProviderWorkListRow[] = resp
            .filter((row: any) => row.patientName !== null)
            .map((row: any) => ({
              patientName: row.patientName,
              accountNumber: row.patientNumber,
              patientID: row.patientID,
              medicalRecord: row.medicalRecordNumber,
              admissionDate: row.dateAdmitted,
              program: row.programName,
              unit: row.unitCode,
              provider: row.providerName,
              patientStatus: row.lookupDesc,
            }));
          setWorkListData(filteredWorkList);
          setFilteredList(filteredWorkList);
        }

        setWorkListLoading(false);
      })
      .catch((err) => {
        setWorkListLoading(false);
        console.log(err);
      });
  }, [facility, selectedServiceCodes]);

  useEffect(() => {
    if (!workListLoading) {
      Utilities.setCellTitles();
    }
  }, [workListLoading]);

  // Client-side search/filter. This should ideally be a client-side search.
  const searchWorkList = (searchTerm: string): void => {
    if (searchTerm === '') {
      setFilteredList(workListData);
      return;
    }

    const tempFilteredData: ProviderWorkListRow[] = workListData.filter((row) => {
      const accountNo = String(row.accountNumber);
      const medicalRecord = String(row.medicalRecord);
      return row.patientName.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || accountNo.indexOf(searchTerm) !== -1 || medicalRecord.indexOf(searchTerm) !== -1;
    });
    setFilteredList(tempFilteredData);
  };

  useEffect(() => {
    searchWorkList(searchText);
  }, [workListData]);

  // CVS Export button
  const CSVDownloadLink = (): JSX.Element => (
    <CSVLink data={workListData} filename="worklist.csv" className="btn --primary" target="_blank">
      <InlineText text="Export to CSV" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
    </CSVLink>
  );

  // Setup click event to view the patient
  const goToPatientDetails = (row: ProviderWorkListRow, e: React.MouseEvent<Element, MouseEvent>): void => {
    e.preventDefault();
    history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${row.patientID}`);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e.target.value);
    searchWorkList(e.target.value);
  };

  /**
   * Handle "View By" filter change between 'Program' and 'Service Code'.
   *
   * @param e click event
   */
  const chartFilterChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setShowProgramChart(e.target.value === 'program');
  };

  if (user?.accessType === AccessType.CORE_MEASURES) {
    return (
      <PageLayout layout={PageLayoutVariant.PADDED} testText={props.testText} title={props.title}>
        Redirecting...
      </PageLayout>
    );
  }

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText={props.testText} title={props.title} loadingText={loading ? 'Loading...' : undefined}>
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.CENTER} justify={JustifyVariant.START} extraClasses="facility-dashboard-content">
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="card-primary chart-container">
          <SecondaryHeader text="Reports" fontColor={FontColors.PRIMARY} />
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="card-primary">
            <div className="control-bar-top">
              <InlineText text="View By:" fontColor={FontColors.PRIMARY} fontSize={FontSizes.REGULAR} bold />
              <input type="radio" id="program-work-list-rb" name="work-list-type-rb" value="program" onChange={chartFilterChanged} checked={showProgramChart} />
              <label htmlFor="program-work-list-rb">Program</label>
              <input type="radio" id="service-work-list-rb" name="work-list-type-rb" value="service-code" onChange={chartFilterChanged} checked={!showProgramChart} />
              <label htmlFor="service-work-list-rb">Service Code</label>
            </div>
          </FlexContainer>
          <WorkListChart workListChartData={workListChartData} isLoadingChart={isLoadingChart} isProgram={showProgramChart} />
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="work-list-section">
          <SecondaryHeader text="Work List" fontColor={FontColors.PRIMARY} />
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.CENTER} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="work-list-section__buttons">
            <MultiSelect
              placeholder="Select or Search for Service Codes"
              styles={MultiStandardDropdownStyles}
              options={serviceCodeOptions}
              defaultValue={null}
              value={selectedServiceCodes}
              onSelection={setSelectedServiceCodes}
            />
            <SearchBar placeholderText="Search Work List" searchText={searchText} inputName="PatientWorklist" handleSearchChange={handleSearchChange} />
            <CSVDownloadLink />
          </FlexContainer>
        </FlexContainer>
        <ProviderWorkList isLoading={workListLoading} data={filteredList} onRowClicked={goToPatientDetails} onChangePage={Utilities.setCellTitles} />
      </FlexContainer>
    </PageLayout>
  );
};
