import moment from 'moment';

export default class MHODateTime {
    private date: Date;

    constructor(mhoStringOrDate: string | Date) {
      if (typeof mhoStringOrDate === 'string') {
        const mmnt = moment(mhoStringOrDate);
        this.date = mmnt.toDate();
      } else {
        this.date = mhoStringOrDate;
      }
    }

    private addLeadingZeros(num: number): string {
      return num > 9 ? num.toString() : `0${num}`;
    }

    private convertToMidnight(num: number): number {
      return num === 0 ? 12 : num;
    }

    private appendMeridiem(): string {
      return this.getRawHours() < 12 ? 'am' : 'pm';
    }

    getJSDate(): Date {
      return this.date;
    }

    getRawDate(): number {
      return this.date.getDate();
    }

    getRawJSMonth(): number {
      return this.date.getMonth();
    }

    getRawMHOMonth(): number {
      return this.date.getMonth() + 1;
    }

    getRawFullYear(): number {
      return this.date.getFullYear();
    }

    getRawHours(): number {
      return this.date.getHours();
    }

    getRawMinutes(): number {
      return this.date.getMinutes();
    }

    getRawSeconds(): number {
      return this.date.getSeconds();
    }

    getFormattedDate(): string {
      return this.addLeadingZeros(this.getRawDate());
    }

    getFormattedMonth(): string {
      return this.addLeadingZeros(this.getRawMHOMonth());
    }

    getFormattedCalendarDate(): string {
      return `${
        this.getFormattedMonth()}/${this.getFormattedDate()}/${this.getRawFullYear()
      }`;
    }

    getFormattedDisplayHours(): string {
      return this.addLeadingZeros(this.convertToMidnight(this.getRawHours()));
    }

    getFormattedPostHours(): string {
      return this.addLeadingZeros(this.getRawHours());
    }

    getFormattedMinutes(): string {
      return this.addLeadingZeros(this.getRawMinutes());
    }

    getFormattedSeconds(): string {
      return this.addLeadingZeros(this.getRawSeconds());
    }

    getFormattedTime(): string {
      return `${
        this.getFormattedDisplayHours()}:${this.getFormattedMinutes()}${this.appendMeridiem()
      }`;
    }

    getDateTimeForPost(): string {
      return `${
        this.getRawFullYear()}-${this.getFormattedMonth()}-${this.getFormattedDate()}T${this.getFormattedPostHours()}:${this.getFormattedMinutes()}:${this.getFormattedSeconds()
      }`;
    }

    getDateTimeForDisplay(): string {
      return `${
        this.getFormattedCalendarDate()} ${this.getFormattedTime()
      }`;
    }

    isUninitializedMHODate(): boolean {
      return this.date.getFullYear() < 1000; // MHO uninitialized date is 01-01-0001
    }
}
