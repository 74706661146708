/* eslint-disable no-fallthrough */
import { SelectOption } from 'types/inputProps';
import { UserRole, Roles } from 'constants/roles';

export const NarrowRoleOptions = (
  role: UserRole | number | undefined,
  masterList: SelectOption[],
  includeAllOption = false,
): SelectOption[] => {
  const options: SelectOption[] = [];
  let roleID = 0;

  if (includeAllOption) {
    options.push({
      label: 'All Roles', value: Roles.NONE,
    });
  }

  if (typeof role === 'number') {
    roleID = (role === 1) ? 0 : role;
  } else if (role) {
    switch (role) {
      case Roles.SUPER_ADMIN:
        roleID = 0;
        break;
      case Roles.ADMIN:
        roleID = 2;
        break;
      case Roles.SUPER_CORPORATION:
        roleID = 3;
        break;
      case Roles.CORPORATION:
        roleID = 4;
        break;
      case Roles.FACILITY_EXECUTIVE:
        roleID = 5;
        break;
      case Roles.FACILITY_DIRECTOR:
        roleID = 6;
        break;
      default:
        break;
    }
  } else {
    return [{ label: 'ERROR', value: 'ERROR' }];
  }

  masterList.forEach((option) => {
    if (parseInt(option.value, 10) > roleID) {
      options.push(option);
    }
  });

  return options;
};
