import { SurveyJSQuestionTypes } from 'constants/SurveyJS/question_types';

type Choice = {
  value: string,
  text: string
}

/**
 * An instance of a hidden question, along with the identifying Name, Title, Type, and Desc, along with the list of choices.
 */
export class HiddenQuestion {
  QuestionName: string;
  QuestionTitle: string;
  QuestionDesc: string;
  QuestionType: string = SurveyJSQuestionTypes.RADIO_GROUP;
  ChoiceList: Choice[];

  constructor(title: string, desc: string, choiceList: Choice[]) {
    this.QuestionName = title.toLowerCase().replace(/ /g, '-');
    this.QuestionTitle = title;
    this.QuestionDesc = desc;
    this.ChoiceList = choiceList;
  }

  public getQuestionTitle(): string {
    return this.QuestionTitle;
  }

  public getQuestionName(): string {
    return this.QuestionName;
  }

  public getQuestionDesc(): string {
    return this.QuestionDesc;
  }

  public getQuestionType(): string {
    return this.QuestionType;
  }

  public getChoiceList(): Choice[] {
    return this.ChoiceList;
  }
}
