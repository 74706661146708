import * as React from 'react';

import './styles.scss';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import TextInput from 'global_elements/Inputs/TextInput';
import { ReactComponent as SearchIcon } from 'icons/mho-magnifying-glass.svg';

import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { SearchBarProps } from 'types/inputProps';

const SearchBar = (props: SearchBarProps): JSX.Element => (
  <label>
    <FlexContainer
      display={DisplayVariant.FLEX_ROW}
      align={AlignVariant.START}
      justify={JustifyVariant.START}
      extraClasses="search-bar"
    >
      <TextInput
        variant={TextInputVariant.PRIMARY}
        placeholder={props.placeholderText}
        type="text"
        name={props.inputName}
        value={props.searchText}
        onChange={props.handleSearchChange}
        autoFocus={props.autoFocus}
      />
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.CENTER}
        justify={JustifyVariant.CENTER}
        extraClasses="search-bar__icon"
      >
        <SearchIcon />
      </FlexContainer>
    </FlexContainer>
  </label>
);

export default React.memo(SearchBar);
