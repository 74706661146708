import React from 'react';
import {
  Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineController, LineElement, BarController, BarElement,
  ScatterController, Title, Tooltip, Legend, InteractionItem, Plugin,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import MHODateTime from 'domain/dateTime/MHODateTime';
import { AssessmentScoreDefinition } from 'domain/Forms/MHO/AssessmentScoreDefinition';
import AssessmentProxy from 'api/assessments/assessmentProxy';
import Utilities from 'api/lib/Utilities';
import colors from 'global_elements/variables/colors.module.scss';
import { awSortOrders } from 'pages/shared/AssessmentResult/appliedWhenSortOrder';
import { AssessmentCallbackData } from './assessmentCallbackData';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineController, LineElement, BarController, BarElement, ScatterController, Title, Tooltip, Legend, ChartDataLabels);

/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
let chartWidth: number;
let chartHeight: number;

window.addEventListener('beforeprint', () => {
  for (const id in ChartJS.instances) {
    const instance = ChartJS.instances[id];
    if (!chartWidth) chartWidth = instance.width;
    if (!chartHeight) chartHeight = instance.height;
    ChartJS.instances[id].resize(1000, 220);
  }
});

window.addEventListener('afterprint', () => {
  for (const id in ChartJS.instances) {
    ChartJS.instances[id].resize(chartWidth, chartHeight);
  }
});

const arrowPlugin: Plugin = {
  id: 'arrowPlugin',
  afterDraw: (chart: ChartJS, _args: any, options: any) => {
    if (!options.enabled) {
      return
    }

    if (!options.topText || !options.bottomText) {
      return
    }

    const { ctx } = chart;
    const { y: yAxis } = chart.scales;
    const arrowSize = 5;

    const x = yAxis.left + 25;
    const yOffSet = 15;
    const yTop = yAxis.top + yOffSet;
    const yBottom = yAxis.bottom - yOffSet;

    const prevFillStyle = ctx.fillStyle;
    const prevStrokeStyle = ctx.strokeStyle;

    ctx.fillStyle = '#999999';
    ctx.strokeStyle = '#999999';

    // Draw top arrow
    // Gray color
    ctx.beginPath();
    ctx.moveTo(x, yTop - arrowSize);
    ctx.lineTo(x - arrowSize, yTop + arrowSize);
    ctx.lineTo(x + arrowSize, yTop + arrowSize);
    ctx.closePath();
    ctx.fill();

    // Draw bottom arrow
    ctx.beginPath();
    ctx.moveTo(x, yBottom + arrowSize);
    ctx.lineTo(x - arrowSize, yBottom - arrowSize);
    ctx.lineTo(x + arrowSize, yBottom - arrowSize);
    ctx.closePath();
    ctx.fill();

    // Draw text
    const textWidth = 50;
    ctx.textAlign = 'center';
    const splitTopText = ctx.measureText(options.topText).width > textWidth ? options.topText.split(' ') : [options.topText];
    const splitBottomText = ctx.measureText(options.bottomText).width > textWidth ? options.bottomText.split(' ') : [options.bottomText];
    ctx.fillText(splitTopText[0], x, yAxis.top - 2 * arrowSize, textWidth);
    ctx.fillText(splitTopText[1], x, yAxis.top, textWidth);
    ctx.fillText(splitBottomText[0], x, yAxis.bottom, textWidth);
    ctx.fillText(splitBottomText[1], x, yAxis.bottom + 2 * arrowSize, textWidth);

    // Draw line
    ctx.beginPath();
    ctx.moveTo(x, yTop);
    ctx.lineTo(x, yBottom);
    ctx.closePath();
    ctx.stroke();

    ctx.fillStyle = prevFillStyle;
    ctx.strokeStyle = prevStrokeStyle;
  },
}

ChartJS.register(arrowPlugin);

/**
 * Chart display/layout options.
 */
const getDefaultChartOptions = (isPrint: boolean): any => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    arrowPlugin: {
      enabled: true,
    },
    legend: {
      display: !isPrint,
      position: 'top' as const,
      labels: {
        padding: 30,
        usePointStyle: true,
        sort: (a: any, b: any, data: any): number => {
          const aFound = data.datasets.find((i: any) => i.label === a.text);
          const bFound = data.datasets.find((i: any) => i.label === b.text);
          return aFound.legendOrder < bFound.legendOrder ? -1 : 1;
        },
      },
    },
    datalabels: {
      display: false,
    },
  },
  layout: {
    padding: {
      top: 5,
      left: 25,
      right: 5,
      bottom: 5,
    },
  },
  scales: {
    x: {
      stacked: true,
      offset: true,
      ticks: {
        padding: 20,
        // font: {
        //   // size: 10,
        //   // size: isPrint ? 10 : 10,
        // },
      },
    },
    y: {
      title: {
        display: true,
        text: 'Score',
      },
      beginAtZero: true,
      // max: 100,
      ticks: {
        padding: 35,
      },
    },
  },
  elements: {
    point: {
      borderWidth: 2,
      hoverRadius: 12,
    },
  },
});

type chartDataType = {
  labels: string[][],
  datasets: any,
}

enum ScoreColumnIndex {
  PATIENT = 0,
  COMPARISON = 1,
  PROGRAM = 2,
  MID_PERCENTILE = 3,
  MAX_PERCENTILE = 4,
}

const getDefaultChartData = (isPatientView: boolean): chartDataType => {
  const midPercentileColor = Utilities.hexToRGBA(colors.colorMainPercentile, 0.9);
  const maxPercentileColor = Utilities.hexToRGBA(colors.colorSecondaryPercentile, 0.9);

  const defaultChartData: chartDataType = {
    labels: [],
    datasets: [{
      label: isPatientView ? 'Score' : 'Patient',
      type: 'line' as const,
      assessmentCallbackData: [],
      data: [],
      borderColor: `${colors.colorPatientColor}`,
      borderWidth: 2,
      pointRadius: 8,
      order: 3,
      legendOrder: 1,
      pointBackgroundColor: `${colors.colorPatientColor}`,
      pointStyle: 'circle',
    }],
  };

  // Add to show comparison and program scores for non-patients.
  if (!isPatientView) {
    defaultChartData.datasets[ScoreColumnIndex.COMPARISON] = {
      label: 'Comparison',
      type: 'scatter' as const,
      assessmentCallbackData: [],
      data: [],
      borderColor: `${colors.colorCompareColor}`,
      borderWidth: 5,
      pointRadius: 15,
      order: 1,
      legendOrder: 5,
      pointBackgroundColor: `${colors.colorCompareColor}`,
      pointStyle: () => 'line',
    };

    defaultChartData.datasets[ScoreColumnIndex.PROGRAM] = {
      label: 'Program',
      type: 'scatter' as const,
      assessmentCallbackData: [],
      data: [],
      borderColor: `${colors.colorProgramColor}`,
      borderWidth: 2,
      pointRadius: 15,
      order: 2,
      legendOrder: 4,
      pointBackgroundColor: `${colors.colorProgramColor}`,
      pointStyle: () => 'crossRot',
    };

    defaultChartData.datasets[ScoreColumnIndex.MID_PERCENTILE] = {
      label: '25th-75th Percentile',
      type: 'bar' as const,
      stacked: 'PERCENTILE_BAR',
      borderSkipped: false,
      barThickness: 40,
      maxBarThickness: 50,
      assessmentCallbackData: [],
      data: [],
      borderColor: `${colors.colorMainPercentile}`,
      borderWidth: 1,
      pointRadius: 10,
      order: 4,
      legendOrder: 3,
      backgroundColor: `${midPercentileColor}`,
      pointStyle: () => 'rectRounded',
    };

    defaultChartData.datasets[ScoreColumnIndex.MAX_PERCENTILE] = {
      label: '1st-99th Percentile',
      type: 'bar' as const,
      stacked: 'PERCENTILE_BAR',
      borderSkipped: false,
      barThickness: 30,
      maxBarThickness: 40,
      assessmentCallbackData: [],
      data: [],
      borderColor: `${colors.colorSecondaryPercentile}`,
      borderWidth: 1,
      pointRadius: 10,
      order: 5,
      legendOrder: 2,
      pointStyleWidth: 160,
      backgroundColor: `${maxPercentileColor}`,
      pointStyle: () => 'rectRounded',
    };
  }
  return defaultChartData;
};

type ChartProps = {
  careID: number,
  instrumentTypeID: number,
  isPatientView: boolean,
  patientID: number,
  selectAssessmentCallback: any,
  isOverallView?: boolean,
  sequence?: number,
  maxDate?: string|null,
  isPrint?: boolean,
  maxAppliedWhenId?: number,
  onLoaded?: () => void;
};

type ChartState = {
  chartData: chartDataType,
  chartOptions: any,
  assessmentScores: AssessmentScoreDefinition[],
}

/**
 * Compomnet for Chart Management for the assessments.
 *
 * @param props established data container for the chart data and the click callback event handler.
 */
class AssessmentResultsChart extends React.Component<ChartProps, ChartState> {
    parentSelectAssessmentCallback: any;

    // eslint-disable-next-line react/static-property-placement
    static defaultProps = {
      isOverallView: true,
      sequence: 0,
      maxDate: null,
      maxAppliedWhenId: undefined,
      isPrint: false,
      onLoaded: () => ({}),
    };

    constructor(props: ChartProps) {
      super(props);
      this.parentSelectAssessmentCallback = props.selectAssessmentCallback;

      // Setup an onclick event for the node to send back to chart parent.
      const chartOptions = getDefaultChartOptions(!!props.isPrint);
      chartOptions.onClick = (event: any, element: InteractionItem[]) => {
        this.loadAssessment(element);
      };

      // Chart data
      this.state = {
        chartData: getDefaultChartData(props.isPatientView),
        chartOptions,
        assessmentScores: [],
      };
    }

    componentDidMount = (): void => {
      let scorePromise: Promise<AssessmentScoreDefinition[]>;

      if (this.props.isPatientView) {
        scorePromise = AssessmentProxy.getUserAssessmentScore(
          this.props.careID, this.props.instrumentTypeID, this.props.isOverallView,
        );
      } else {
        scorePromise = AssessmentProxy.getPatientAssessmentScore(
          this.props.patientID, this.props.instrumentTypeID, this.props.isOverallView,
        );
      }

      const maxAppliedWhenSort = awSortOrders.find((c) => c.awid === this.props.maxAppliedWhenId);

      scorePromise.then((scores: AssessmentScoreDefinition[]) => {
        let filteredScores: AssessmentScoreDefinition[] = scores
          .filter((v) => v.showScores !== 0)
          .map((v) => {
            const awidSortOrder = awSortOrders.find((c) => c.awid === v.appliedWhenID);
            return { ...v, awidSort: awidSortOrder ? awidSortOrder.sortOrder : 8 };
          })
          .filter((score) => score.awidSort <= (maxAppliedWhenSort ? maxAppliedWhenSort.sortOrder : 999));

        if (this.props.isOverallView) {
          filteredScores = filteredScores.filter((score) => (
            !this.props.maxDate || (moment(score.dateProcessed).isSameOrBefore(this.props.maxDate))
          ));
        } else {
          filteredScores = filteredScores.filter((score) => (
            score.subscaleSequence === this.props.sequence
              && (!this.props.maxDate || (moment(score.dateProcessed).isSameOrBefore(this.props.maxDate)))
          ));
        }

        // filteredScores = this.props.isPrint ? filteredScores.slice(-10) : filteredScores;
        filteredScores = filteredScores.slice(-10);

        this.handleUserAssessmentScores(filteredScores);
        if (this.props.onLoaded) this.props.onLoaded();
      }).catch((jsonDefinitionError: any): void => {
        console.log(jsonDefinitionError);
        if (this.props.onLoaded) this.props.onLoaded();
      });
    }

    /**
     * Retrieves the sequence number if set, or 0 if it's undefined.
     *
     * @returns the sequence number. 0, if undefined.
     */
    private getSequenceNumber(): number {
      return this.props.sequence || 0;
    }

    /**
     * Initialize all of the user's chart data for this graph.
     *
     * @param userScores data points for the graph.
     */
    private setUserChartData(userScores: any[]): void {
      const callbackData: AssessmentCallbackData[] = [];
      const patientScores: number[] = [];
      const programScores: number[] = [];
      const comparisonScores: number[] = [];
      const maxRange: number[][] = [];
      const midRange: number[][] = [];
      const labels: string[][] = [];

      userScores.forEach((patient: any) => {
        const readableDate: string = new MHODateTime(patient.date).getFormattedCalendarDate();
        // Hide applied when text from label in patient view.
        const label: string[] = this.props.isPatientView ? ['', readableDate] : [patient.name, readableDate];
        labels.push(label);
        patientScores.push(patient.patientScore || 0);
        programScores.push(patient.programScore);
        comparisonScores.push(patient.comparisonScore);
        maxRange.push(patient.percentileMaxRange);
        midRange.push(patient.percentileMidRange);

        callbackData.push({
          label,
          name: '',
          patientID: patient.patientID,
          assessmentNumber: patient.assessmentNumber,
          instrumentTypeID: patient.instrumentTypeID,
          appliedWhenID: patient.appliedWhenID,
          appliedWhenSequenceNumber: -1,
          dateProcessed: '',
        });
      });

      // new updated Chart data
      const newChartData: chartDataType = getDefaultChartData(this.props.isPatientView);
      newChartData.labels = labels;
      newChartData.datasets[ScoreColumnIndex.PATIENT].assessmentCallbackData = callbackData;
      newChartData.datasets[ScoreColumnIndex.PATIENT].data = patientScores;
      if (!this.props.isPatientView) {
        newChartData.datasets[ScoreColumnIndex.COMPARISON].data = comparisonScores;
        newChartData.datasets[ScoreColumnIndex.PROGRAM].data = programScores;
        newChartData.datasets[ScoreColumnIndex.MAX_PERCENTILE].data = maxRange;
        newChartData.datasets[ScoreColumnIndex.MID_PERCENTILE].data = midRange;
      }

      this.setState((prevState) => {
        const chartOptions = { ...prevState.chartOptions };
        if (userScores.length) {
          chartOptions.plugins.arrowPlugin.topText = userScores[0].topAxis;
          chartOptions.plugins.arrowPlugin.bottomText = userScores[0].bottomAxis;
        }

        return { chartData: newChartData, chartOptions }
      }, (): void => {
        const loading = document.getElementById(`loading-${this.getSequenceNumber()}`);
        if (loading) {
          loading.classList.add('hidden');
          if (newChartData.labels.length !== 0) {
            const chartContainer = loading.closest('.chart-container');
            chartContainer?.classList.remove('hidden');
          }
        }
      });
    }

    /**
     * Grab the scores that matches the sequence and populate the chart.
     *
     * @param scores user scores pulled from the API for this assessment.
     */
    private handleUserAssessmentScores = (scores: AssessmentScoreDefinition[]): void => {
      this.setState({ assessmentScores: scores }, (() => {
        // When the score is loaded, update the chart to reflect the score data.
        const userScores: any[] = this.state.assessmentScores.map((score) => {
          const container: any = {
            name: score.appliedWhenDesc,
            patientScore: score.patientScore,
            programScore: score.programScore,
            comparisonScore: score.comparisonScore,
            date: score.dateProcessed,
            patientID: score.patientID,
            assessmentNumber: score.assessmentNumber,
            instrumentTypeID: this.props.instrumentTypeID,
            appliedWhenID: score.appliedWhenID,
            percentileMaxRange: [score.percentile1, score.percentile99],
            percentileMidRange: [score.percentile25, score.percentile75],
            topAxis: score.topAxis,
            bottomAxis: score.bottomAxis,
          };
          return container;
        });
        this.setUserChartData(userScores);
      }));
    }

    /**
     * Retrieve the assessment data set necessary to async call for the assessment question/response.
     *
     * @param element InteractionItem of the clicked node in the chart.
     * @returns a data set with the patientID, assessmentNumber, instrumentTypeID, and the appliedWhenID.
     */
    getAssessmentHeaderDataAtEvent = (element: InteractionItem[]): AssessmentCallbackData | null => {
      if (!element.length) {
        return null;
      }
      const { datasetIndex, index } = element[0];
      return this.state.chartData.datasets[datasetIndex].assessmentCallbackData[index];
    };

    /**
     * Callback handler for onclick event to load the 'selected assessment'.
     *
     * @param element clicked ChartJS element.
     */
    loadAssessment = (element: InteractionItem[]): void => {
      const assessmentIdentifier: AssessmentCallbackData | null = this.getAssessmentHeaderDataAtEvent(element);
      if (assessmentIdentifier) {
        this.parentSelectAssessmentCallback(assessmentIdentifier);
      }
    };

    render(): JSX.Element {
      return (
        <div className="loading-icon-overlay-container">
          <FontAwesomeIcon id={`loading-${this.getSequenceNumber()}`} icon={faSpinner} className="fa-spin loading-icon-overlay" />
          <Chart
            type="line"
            key={this.getSequenceNumber()}
            options={this.state.chartOptions}
            data={this.state.chartData}
            redraw
          />
        </div>
      );
    }
}

export default AssessmentResultsChart;
