import React, { useEffect, useState, useCallback, useContext } from 'react';
import { plainToClass } from 'class-transformer';

import AssessmentDefinitionProxy from 'api/assessments/assessmentDefinitionProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Button from 'global_elements/Button';
import InlineText from 'global_elements/Text/InlineText';
import TextInput from 'global_elements/Inputs/TextInput';
import AssessmentEditorTable from 'global_elements/Layouts/DataTables/AssessmentDefinitionsTable';
import MHOToSurveyJSConverter from 'domain/Forms/Converters/MHOToSurveyJSConverter';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AssessmentEditorRow } from 'types/tableProps';
import { MHOFormDataRow } from 'domain/Forms/MHO/MHOFormDataRow';
import { ButtonVariant } from 'global_elements/Button/variants';
import { SearchAssessmentEditorProps } from 'interfaces/assessments/searchAssessmentEditorProps';
import { AssessmentDefinition } from 'interfaces/assessments/assessmentDefinitionSearchResponse';
import { SuperAdminRoutes } from 'constants/routes';
import { MHOSurveyJSFormDefinition } from 'domain/Forms/MHO/MHOSurveyJSFormDefinition';
import { UserContext } from 'context/user';
import { AssessmentCreator } from './surveyCreator';

import './styles.scss';
import './customExtremeResponseStyles.scss';

function SuperAdminAssessmentsEditorPage(props: SearchAssessmentEditorProps): JSX.Element {
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState<AssessmentEditorRow[]>([]);
  const [selectedRow, setSelectedRow] = useState<AssessmentEditorRow|null>(null);
  const [loading, setLoading] = useState(false);
  const [surveyJSFormDefinition, setSurveyJSFormDefinition] = useState<MHOSurveyJSFormDefinition>();
  const [assessmentJSON, setAssessmentJSON] = useState<any | null>(null);
  const [rawAssessmentJSON, setRawAssessmentJSON] = useState<any | null>(null);
  const [typeaheadTimeout, setTypeaheadTimeout] = useState<any>(null);
  const { user } = useContext(UserContext);

  /**
   * Callback to add a new url path with the definition ID into the history stack.
   *
   * @param definitionID ID to retrieve the definitions fom the DB.
   */
  const updateURIWithDefinitionID = useCallback((definitionID: number): void => {
    const newAssessmentPath = `${SuperAdminRoutes.ASSESSMENT_EDITOR}/${definitionID}`;
    // if the path are identical, then it's most-likely a page reload. Don't add duplicates
    // into the stack unnecessarily.
    if (window.location.pathname !== newAssessmentPath) {
      props.history.push(newAssessmentPath);
    }
  }, [props.history]);

  /**
   * Callback to load both the Assessment's SurveyJS form (if it exists), and the raw definition. The raw
   * form is a back up incase the SurveyJS form doesn't exist, as well as if they decide to reset the
   * questions back to the raw form.
   *
   * @param definitionID ID to retrieve the definitions fom the DB.
   */
  const loadAssessmendDefinitionFromAPI = useCallback((definitionID: number): void => {
    const loadRawAssessmentPromise = AssessmentDefinitionProxy.getAssessmentBaseDefinition(definitionID);
    const loadAssessmentPromise = AssessmentDefinitionProxy.getAssessmentJSONDefinition(definitionID);

    Promise.all([loadAssessmentPromise, loadRawAssessmentPromise]).then(([assessmentDefinition, rawQuestionsList]) => {
      const surveyJSDefinition: MHOSurveyJSFormDefinition = {
        AssessmentNumber: definitionID,
        AssessmentEditorID: undefined,
        AssessmentEditorJSON: undefined,
        StatusID: 0,
      };

      if (rawQuestionsList) {
        const dataRows: MHOFormDataRow[] = [];
        rawQuestionsList.forEach((dataRow) => {
          dataRows.push(plainToClass(MHOFormDataRow, dataRow, {}));
        });

        MHOToSurveyJSConverter.ConvertToSurveyJSFormat(dataRows).then((assessmentJSONObject) => {
          setRawAssessmentJSON(assessmentJSONObject);
        });
      } else {
        // Clear raw assessment json
        setRawAssessmentJSON(null);
      }

      if (assessmentDefinition) {
        try {
          const surveyJSON = JSON.parse(assessmentDefinition.assessmentEditorJSON);
          setAssessmentJSON(surveyJSON);
          surveyJSDefinition.AssessmentEditorID = assessmentDefinition.assessmentEditorID;
          surveyJSDefinition.StatusID = assessmentDefinition.statusID;
        } catch (parseError: any) {
          // do nothing
        }
      } else {
        // Clear assessment surveyjs json
        setAssessmentJSON(null);
      }

      setSurveyJSFormDefinition(surveyJSDefinition);
      updateURIWithDefinitionID(definitionID);
      setLoading(false);
    }).catch((loadAssessmentError) => {
      console.log(loadAssessmentError);
      setLoading(false);
    });
  }, [updateURIWithDefinitionID]);

  const searchResultSelected = useCallback((row: AssessmentEditorRow): void => {
    setSelectedRow(row);
  }, [loadAssessmendDefinitionFromAPI]);

  useEffect(() => {
    setSearchText('');
    setSearchResults([]);

    if (selectedRow) {
      setLoading(true);
      loadAssessmendDefinitionFromAPI(selectedRow.assessmentNumber);
    } else {
      // Clear the jsons
      setRawAssessmentJSON(null);
      setAssessmentJSON(null);
    }
  }, [selectedRow]);

  useEffect(() => {
    if (props.match.params.assessmentDefinitionID) {
      document.title = `Assessment Editor - ${props.match.params.assessmentDefinitionID}`;
      const parsedDefinitionID = parseInt(props.match.params.assessmentDefinitionID, 10);
      if (!selectedRow || (!Number.isNaN(parsedDefinitionID) && selectedRow.assessmentNumber !== parsedDefinitionID)) {
        if (Number.isInteger(parsedDefinitionID)) {
          const placeholderRow: AssessmentEditorRow = { assessmentNumber: parsedDefinitionID, assessmentName: '', assessmentDescription: '' };
          setSelectedRow(placeholderRow);
        }
      }
    } else {
      document.title = 'Assessment Editor Search';
      setSelectedRow(null);
    }
  }, [props.match.params.assessmentDefinitionID, searchResultSelected]);

  const searchAssessments = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setSearchText(value);

    if (typeaheadTimeout) {
      clearTimeout(typeaheadTimeout);
      setTypeaheadTimeout(null);
    }

    if (value.length > 0) {
      setTypeaheadTimeout(setTimeout((): void => {
        AssessmentDefinitionProxy.searchAssessmentDefinitions(
          value,
        ).then((foundAssessments: AssessmentDefinition[]) => {
          if (foundAssessments) {
            setSearchResults(foundAssessments);
          }
        }).catch(() => {
          setSearchResults([]);
        });
      }, 150));
    } else if (value.length === 0) {
      setSearchResults([]);
    }
  };

  const closeAssessmentEditor = (withAlert = true): void => {
    if (withAlert) {
      // eslint-disable-next-line no-alert
      const closeEditor = window.confirm('Are you sure you want to close the editor? Any unsaved changes will be lost.');

      if (closeEditor) {
        setSelectedRow(null);
        props.history.push(SuperAdminRoutes.ASSESSMENT_EDITOR);
      }
    } else {
      setSelectedRow(null);
      props.history.push(SuperAdminRoutes.ASSESSMENT_EDITOR);
    }
  };

  if (selectedRow === null) {
    return (
      <PageLayout layout={PageLayoutVariant.PADDED} testText="Assessment Editor Page">
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          justify={JustifyVariant.START}
          align={AlignVariant.CENTER}
          extraClasses="assessment-definition-search-page"
        >
          <InlineText
            text="Assessment Definition Search"
            bold
            fontColor={FontColors.PRIMARY}
            fontSize={FontSizes.LARGE}
          />
          <InlineText
            text="Type either an Assessment ID or Assessment Name below to search available definitions."
            fontColor={FontColors.DARK}
            fontSize={FontSizes.REGULAR}
          />
          <TextInput
            placeholder="Search Form Name or Number"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => searchAssessments(event)}
            variant={TextInputVariant.PRIMARY}
            type="text"
            name="search"
            value={searchText}
            autoFocus
          />
          <AssessmentEditorTable
            data={searchResults}
            onRowClicked={searchResultSelected}
          />
        </FlexContainer>
      </PageLayout>
    );
  }

  if (loading) {
    return (
      <PageLayout layout={PageLayoutVariant.PADDED} testText="Assessment Editor Page">
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          justify={JustifyVariant.CENTER}
          align={AlignVariant.CENTER}
        >
          <InlineText
            text="Loading Assessment..."
            fontColor={FontColors.PRIMARY}
            fontSize={FontSizes.SUPER_EXTRA_LARGE}
          />
        </FlexContainer>
      </PageLayout>
    );
  }

  if (assessmentJSON !== null || rawAssessmentJSON !== null) {
    return (
      <PageLayout layout={PageLayoutVariant.FULL_SCREEN} testText="Assessment Editor Page">
        <AssessmentCreator
          surveyJSFormDefinition={surveyJSFormDefinition}
          editingUserEmail={user?.email}
          assessmentDefinitionJSON={assessmentJSON}
          rawAssessmentJson={rawAssessmentJSON}
          closeEditorEvent={closeAssessmentEditor}
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Assessment Editor Page">
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        justify={JustifyVariant.CENTER}
        align={AlignVariant.CENTER}
        extraClasses="search-assessment-error-container"
      >
        <InlineText
          text={`Error: No additional data could be found for the assessment "${selectedRow?.assessmentName}" (ID: ${selectedRow?.assessmentNumber})`}
          fontColor={FontColors.PRIMARY}
          fontSize={FontSizes.EXTRA_LARGE}
        />
        <Button
          variant={ButtonVariant.DARK}
          onClick={() => closeAssessmentEditor(false)}
        >
          <InlineText
            text="Return to Search"
            fontColor={FontColors.BACKGROUND}
            fontSize={FontSizes.LARGE}
          />
        </Button>
      </FlexContainer>
    </PageLayout>
  );
}

export default SuperAdminAssessmentsEditorPage;
