import { Environments } from '../../constants/environments';

export enum FeatureFlagName {
  isGroupTherapyEnabled = 'isGroupTherapyEnabled',
  isCommentQuestionEnabled = 'isCommentQuestionEnabled',
  isSliderQuestionEnabled = 'isSliderQuestionEnabled',
  isPatientConsentEnabled = 'isPatientConsentEnabled',
  isCoreMeasuresEnabled = 'isCoreMeasuresEnabled',
  isDevDiagnosticsEnabled = 'isDevDiagnosticsEnabled'
}

export type FeatureFlags = {
  [key in FeatureFlagName]: {
    [env in Environments]: any;
  };
};

export const featureFlags: FeatureFlags = {
  isGroupTherapyEnabled: {
    [Environments.LOCAL]: true,
    [Environments.TEST]: true,
    [Environments.DEVELOPMENT]: true,
    [Environments.UAT]: true,
    [Environments.PRODUCTION]: true,
  },
  isCommentQuestionEnabled: {
    [Environments.LOCAL]: true,
    [Environments.TEST]: true,
    [Environments.DEVELOPMENT]: true,
    [Environments.UAT]: true,
    [Environments.PRODUCTION]: true,
  },
  isSliderQuestionEnabled: {
    [Environments.LOCAL]: true,
    [Environments.TEST]: true,
    [Environments.DEVELOPMENT]: true,
    [Environments.UAT]: true,
    [Environments.PRODUCTION]: true,
  },
  isPatientConsentEnabled: {
    [Environments.LOCAL]: true,
    [Environments.TEST]: true,
    [Environments.DEVELOPMENT]: true,
    [Environments.UAT]: true,
    [Environments.PRODUCTION]: true,
  },
  isCoreMeasuresEnabled: {
    [Environments.LOCAL]: true,
    [Environments.TEST]: true,
    [Environments.DEVELOPMENT]: true,
    [Environments.UAT]: true,
    [Environments.PRODUCTION]: false,
  },
  isDevDiagnosticsEnabled: {
    [Environments.LOCAL]: true,
    [Environments.TEST]: true,
    [Environments.DEVELOPMENT]: true,
    [Environments.UAT]: true,
    [Environments.PRODUCTION]: false,
  },
};
