import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import * as EmailInput from 'global_elements/Inputs/TextInput/lib/email';

import UserProxy from 'api/user/userProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Paragraph from 'global_elements/Text/Paragraph';
import Button from 'global_elements/Button';
import InlineText from 'global_elements/Text/InlineText';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabledAsyncSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect/asyncLabledSingleSelct';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { DualListPanel } from 'global_elements/Inputs/DualListPanel';
import { DropdownMenu } from 'global_elements/Inputs/DropdownMenu';

import { SingleErrorDropdownStyles, SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { UserContext } from 'context/user';
import { SendEmailInvitationRequest } from 'interfaces/facilityUsers/sendEmailInvitationRequest';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import { FacilityUserPage, FacilityUserPageUrlParams } from '../Facility/FacilityUser/FacilityUser';
import './styles.scss';
import PopupWindow from '../../../global_elements/Layouts/PopupWindow';

class FacilityUserRegistrationPage extends FacilityUserPage {
  // eslint-disable-next-line react/static-property-placement
  static contextType = UserContext;

  constructor(props: RouteComponentProps<FacilityUserPageUrlParams>) {
    super(props, true);
  }

  componentDidMount = (): void => {
    this.initializePrograms();
  };

  invalidEmailError = (): void => {
    if (this.state.email === '' || !EmailInput.validEmail(this.state.email)) {
      this.setState((current) => ({
        ...current,
        emailError: true,
      }));
    } else {
      UserProxy.getEmailExistsInDatabase(
        this.state.email,
        (response) => {
          console.log(response);
          this.setState((current) => ({
            ...current,
            emailIsOpen: false,
            emailConfirmError: false,
          }));
          this.verifyEmailDomain(this.state.email);
        },
        (errorResponse) => {
          console.log(errorResponse);
          this.setState((current) => ({
            ...current,
            emailIsOpen: true,
            emailError: false,
          }));
          this.verifyEmailDomain(this.state.email);
        },
      );
    }
  };

  emptyEmailConfirmError = (): void => {
    if (this.state.emailConfirm === '') {
      this.setState((current) => ({
        ...current,
        emailConfirmError: true,
      }));
    } else {
      this.setState((current) => ({
        ...current,
        emailConfirmError: false,
      }));
    }
  };

  emptyRoleError = (): void => {
    setTimeout(() => {
      if (this.state.userRole === null) {
        this.setState((current) => ({
          ...current,
          userRoleError: true,
        }));
      } else {
        this.setState((current) => ({
          ...current,
          userRoleError: false,
        }));
      }
    }, 50);
  };

  emailErrorText = (): string | undefined => {
    if (this.state.emailError) {
      return 'Enter a Valid Email Address';
    }

    if (!this.state.emailIsOpen) {
      return 'Email already in use';
    }

    if (this.state.emailDomainError) {
      return 'Email domain is invalid';
    }

    return undefined;
  };

  emailConfirmErrorText = (): string | undefined => {
    if (this.state.emailConfirmError) {
      return 'Confirm the Email Address';
    }

    if (!this.doEmailsMatch()) {
      return 'Emails do not match';
    }

    return undefined;
  };

  verifyEmailDomain = (email: string): void => {
    UserProxy.verifyEmailDomain(
      email,
      (r) => {
        console.log(r);
        this.setState((current) => ({
          ...current,
          emailDomainError: false,
        }));
      },
      (e) => {
        console.error(e);
        this.setState((current) => ({
          ...current,
          emailDomainError: true,
        }));
      },
    );
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if ((e.target.name === 'email' || e.target.name === 'emailConfirm') && e.target.value !== '') {
      if ((e.target.name === 'email' && e.target.value === this.state.emailConfirm) || (e.target.name === 'emailConfirm' && e.target.value === this.state.email)) {
        UserProxy.getEmailExistsInDatabase(
          e.target.value,
          (response) => {
            console.log(response);
            this.setState((current) => ({
              ...current,
              emailIsOpen: false,
              emailConfirmError: false,
            }));
          },
          (errorResponse) => {
            console.log(errorResponse);
            this.setState((current) => ({
              ...current,
              emailIsOpen: true,
            }));
          },
        );
      } else {
        this.setState((current) => ({
          ...current,
          emailIsOpen: true,
        }));
      }
    }

    this.setState((current) => ({
      ...current,
      [e.target.name]: e.target.value,
    }));
  };

  cancelRegistration = (): void => {
    this.setState({ saveError: false, saveErrorMessage: undefined });
    this.returnToFacilityUserAccounts();
  };

  submitRegistration = (): void => {
    this.setState({ saveError: false, saveErrorMessage: undefined });
    this.submitUserChanges()
      .then(() => {
        const sendEmailInvitationRequest: SendEmailInvitationRequest = {
          Name: `${this.state.firstName} ${this.state.lastName}`,
          Email: this.state.email,
          Status: '',
        };

        UserProxy.SendEmailInvitation(
          sendEmailInvitationRequest,
          () => {
            this.afterSaveProcess(false);
          },
          (invitationErrorResponse) => {
            this.setState({ saveError: true });
            console.log(invitationErrorResponse);
          },
        );
      })
      .catch((error: any) => {
        console.log(error);
        this.setState({ saveError: true, saveErrorMessage: error.message });
      });
  };

  render(): JSX.Element {
    return (
      <UserContext.Consumer>
        {() => (
          <PageLayout layout={PageLayoutVariant.PADDED} testText="Facility User Registration Page">
            <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="non-patient-registration-content">
              <PrimaryHeader
                text="Facility User Registration"
                fontColor={FontColors.PRIMARY}
                // ***Adding space 2/2024
                marginBottomPx={16}
              />
              <form autoComplete="off">
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START}>
                  <LabledTextInput
                    label="First Name"
                    placeholder="User First Name"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.handleInputChange}
                    type="text"
                    variant={TextInputVariant.PRIMARY}
                    onBlur={this.emptyFirstNameError}
                    error={this.state.firstNameError ? 'Enter a First Name' : undefined}
                  />
                  <LabledTextInput
                    label="Last Name"
                    placeholder="User Last Name"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.handleInputChange}
                    type="text"
                    variant={TextInputVariant.PRIMARY}
                    onBlur={this.emptyLastNameError}
                    error={this.state.lastNameError ? 'Enter a Last Name' : undefined}
                  />
                </FlexContainer>
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START}>
                  <LabledTextInput
                    label="Email"
                    placeholder="User Email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    type="email"
                    variant={TextInputVariant.PRIMARY}
                    onBlur={this.invalidEmailError}
                    error={this.emailErrorText()}
                  />
                  <LabledTextInput
                    label="Confirm Email"
                    placeholder="User Confirm Email"
                    name="emailConfirm"
                    value={this.state.emailConfirm}
                    onChange={this.handleInputChange}
                    type="email"
                    variant={TextInputVariant.PRIMARY}
                    onBlur={this.emptyEmailConfirmError}
                    error={this.emailConfirmErrorText()}
                  />
                </FlexContainer>
                <hr />
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} wrap>
                  <LabledAsyncSingleSelect
                    label="User Role"
                    styles={this.state.userRoleError ? SingleErrorDropdownStyles : SingleStandardDropdownStyles}
                    loadOptions={this.loadRoleOptionsPromise}
                    defaultValue={{ label: 'Select a User Role...', value: '' }}
                    value={this.state.userRole || undefined}
                    onSelection={this.handleRoleSelectChange}
                    onblur={this.emptyRoleError}
                    error={this.state.userRoleError ? 'Select a User Role' : undefined}
                  />
                  {this.state.userRole && parseInt(this.state.userRole.value, 10) > 2 && (
                    <LabledAsyncSingleSelect
                      label="Corporation"
                      styles={SingleStandardDropdownStyles}
                      loadOptions={this.loadCorporationPromise}
                      defaultValue={this.state.corporation || null}
                      value={this.state.corporation || undefined}
                      onSelection={this.handleCorporationSelectChange}
                    />
                  )}
                </FlexContainer>
                {this.state.userRole && parseInt(this.state.userRole.value, 10) > 2 && this.state.corporation && (
                  <>
                    <Paragraph text="Programs" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.REGULAR} bold />
                    <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.START} extraClasses="filter-controls">
                      <div className="filter-controls-left">
                        <DropdownMenu
                          id="available-search-dropdown"
                          buttonText="Available Facility List Filter"
                          options={this.state.filteredFacilityProgramList}
                          onSelection={this.handleAvailableFacilitySelectChange}
                        />
                      </div>
                      <div className="filter-controls-right">
                        <DropdownMenu
                          id="selected-search-dropdown"
                          buttonText="Selected Facility List Filter"
                          options={this.state.filteredFacilityProgramList}
                          onSelection={this.handleSelectedFacilitySelectChange}
                        />
                      </div>
                    </FlexContainer>
                    <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.START} extraClasses="listbox-flex-container">
                      <DualListPanel
                        canFilter
                        selected={this.state.selectedProgramIDs}
                        options={this.state.filteredFacilityProgramList}
                        onChange={this.onProgramSelectionChange}
                        // onFilterChange={this.onFilterInputChange}
                      />
                    </FlexContainer>
                  </>
                )}
                <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.START} extraClasses="button-container">
                  <Button variant={ButtonVariant.SECONDARY} onClick={this.cancelRegistration}>
                    <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                  <Paragraph
                    text="You are responsible for ensuring that only
                                        permitted facility users are granted access to the
                                        Reflections site. Any unauthorized review, use,
                                        disclosure or distribution of this information is
                                        prohibited, and may be punishable by law."
                    fontColor={FontColors.DARK}
                    fontSize={FontSizes.REGULAR}
                    bold
                  />
                  <Button variant={ButtonVariant.PRIMARY} onClick={this.submitRegistration} disabled={this.disableRegistration()}>
                    <InlineText text="Register" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                  </Button>
                </FlexContainer>

                {this.state.saveError && (
                  <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.CENTER} align={AlignVariant.START} extraClasses="button-container">
                    <InlineText
                      text={this.state.saveErrorMessage ?? 'There was an error creating the user account, please try again.'}
                      fontColor={FontColors.HIGH_PRIORITY}
                      fontSize={FontSizes.REGULAR}
                      bold
                    />
                  </FlexContainer>
                )}

                {this.state.showAccessTypeSelectionWindow && (
                  <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="popup-window-wrapper">
                    <PopupWindow>
                      <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="popup-window-content">
                        <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.CENTER} extraClasses="popup-window-header">
                          <TertiaryHeader text="Facility User Registration" fontColor={FontColors.PRIMARY} />
                          <Button
                            variant={ButtonVariant.INVISIBLE}
                            onClick={(e) => {
                              e?.preventDefault();
                              e?.stopPropagation();
                              this.closeAccessTypeSelectionWindow();
                            }}
                          />
                        </FlexContainer>
                        <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="dropdown-input">
                          <LabledAsyncSingleSelect
                            label="Access Type"
                            styles={SingleStandardDropdownStyles}
                            loadOptions={this.loadAccessTypeOptionsPromise}
                            defaultValue={{ label: 'Select an Access Type...', value: '' }}
                            value={this.state.accessType || undefined}
                            onSelection={this.handleAccessTypeSelectChange}
                          />
                        </FlexContainer>
                      </FlexContainer>
                      <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="popup-window-content">
                        <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="popup-window-buttons">
                          <Button variant={ButtonVariant.PRIMARY} disabled={this.invalidAccessType()} onClick={this.saveAccessTypeSelection}>
                            <InlineText text="Save" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                          </Button>
                        </FlexContainer>
                      </FlexContainer>
                    </PopupWindow>
                  </FlexContainer>
                )}
              </form>
            </FlexContainer>
          </PageLayout>
        )}
      </UserContext.Consumer>
    );
  }
}

FacilityUserRegistrationPage.contextType = UserContext;

export default withRouter(FacilityUserRegistrationPage);
