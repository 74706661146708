/**
 * Sort orders for each appliedWhenId
 * awid: the applied when ID from the database
 * sortOrder: the appropriate sortOrder for the AWID.
 */
export const awSortOrders = [
  { awid: 1, sortOrder: 1 },
  { awid: 26, sortOrder: 2 },
  { awid: 8, sortOrder: 3 },
  { awid: 2, sortOrder: 4 },
  { awid: 27, sortOrder: 5 },
];
