import axios, { AxiosResponse, AxiosError } from 'axios';
import { isTestEnvironment } from 'config/msal/configMgmt';
import acquireAccessToken from '../apiTokenLoader';
import ApiResponse from '../models/ApiResponse';

/**
 * @deprecated use v2 instead
 */
class ApiService {
  static get<T>(url: string,
    success: (e: ApiResponse<T>) => void,
    failure: (e: ApiResponse<T>) => void,
    normalizeData = false): void {
    if (isTestEnvironment()) {
      failure(new ApiResponse<T>(401, '', null));
    }

    acquireAccessToken().then((accessToken) => {
      const config = ApiService.getRequestConfig(accessToken);

      axios.get(url, config)
        .then((response: AxiosResponse<T>) => {
          const result = ApiService.parseResponse(response, normalizeData);
          success(result);
        })
        .catch((error: AxiosError) => {
          const result = ApiService.getErrorFromResponse<T>(error);
          failure(result);
        });
    });
  }

  /**
   * An alternative method to 'get', with an option to pass in additional configuration.  Possibly look into
   * merging this and the other 'get'.
   *
   * @param url API url
   * @param additionalConfig Additional request configuration, such as response types, content types, etc.
   * @param normalizeData normalize data for parsing.
   * @returns {Promise} The api response with the successful/failed results and data.
   */
  static alternativeGet<T>(url: string, additionalConfig: any, normalizeData = false): Promise<ApiResponse<T>> {
    return new Promise((resolve, reject) => {
      if (isTestEnvironment()) {
        reject(new ApiResponse<T>(401, '', null));
      }

      acquireAccessToken().then((accessToken) => {
        const config = ApiService.getRequestConfig(accessToken);
        const updatedConfig = {
          ...config,
          ...additionalConfig,
        };

        axios.get(url, updatedConfig)
          .then((response: AxiosResponse<T>) => {
            const result = ApiService.parseResponse(response, normalizeData);
            resolve(result);
          })
          .catch((error: AxiosError) => {
            const result = ApiService.getErrorFromResponse<T>(error);
            reject(result);
          });
      });
    });
  }

  static post<T>(url: string, data: any|null = null,
    success: (e: ApiResponse<T>) => void,
    failure: (e: ApiResponse<T>) => void): void {
    if (isTestEnvironment()) {
      failure(new ApiResponse<T>(401, '', null));
    }

    acquireAccessToken().then((accessToken) => {
      const config = ApiService.getRequestConfig(accessToken);

      axios.post(url, data, config)
        .then((response: AxiosResponse<T>) => {
          const result = ApiService.parseResponse(response);
          success(result);
        })
        .catch((error: AxiosError) => {
          const result = ApiService.getErrorFromResponse<T>(error);
          failure(result);
        });
    });
  }

  private static parseResponse<T>(response: AxiosResponse<T>, normalizeData = false) : ApiResponse<T> {
    if (normalizeData && response.data && Array.isArray(response.data) && response.data.length > 0) {
      return new ApiResponse<T>(response.status, response.statusText, response.data[0]);
    }

    return new ApiResponse<T>(response.status, response.statusText, response.data);
  }

  private static getErrorFromResponse<T>(error: AxiosError) : ApiResponse<T> {
    let responseStatus: number | null = null;
    let errorMessage = error.message;

    if (error.response) {
      responseStatus = error.response.status;

      if (error.response.request) {
        errorMessage = error.response.request.errorMessage;
      }
    }

    return new ApiResponse<T>(
      responseStatus,
      errorMessage,
      null,
    );
  }

  private static getRequestConfig(apiToken: string): any {
    return {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
        App: `REFL_Portal_v${process.env.REACT_APP_MHO_VERSION}`,
      },
    };
  }
}

export default ApiService;
