import { SurveyJSQuestionTypes } from 'constants/SurveyJS/question_types';
import { SurveyJSInputTypes } from 'constants/SurveyJS/input_types';
import { SurveyJSQuestionChoice } from './SurveyJSQuestionChoice';

export class SurveyJSQuestion {
  constructor() {
    this.choices = [];
    this.rateValues = [];
    this.visible = true;
    this.readOnly = false;
    this.isRequired = false;
    this.visibleIf = '';
    this.useEmoticon = false;
  }

  type: SurveyJSQuestionTypes|undefined;
  name: string|undefined;
  title: string|undefined;
  description: string|undefined;
  visible: boolean;
  readOnly: boolean;
  isRequired: boolean;
  visibleIf: string;
  inputType: SurveyJSInputTypes|undefined;
  variableID: number|undefined;
  questionNumber: number|undefined;
  choices: SurveyJSQuestionChoice[];
  rateValues: SurveyJSQuestionChoice[];
  html: string|undefined;

  // For rating
  useEmoticon: boolean;
}
