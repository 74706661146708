import React, { useEffect, useState } from 'react';

import DataListsProxy from 'api/dataLists/dataListsProxy';
import { ListForm } from 'interfaces/dataLists/listForm';
import { AllRoutes } from 'constants/routes';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import CollapsibleSection from 'global_elements/Layouts/CollapsibleSection';
import SearchBar from 'global_elements/Inputs/SearchBar';
import { ReactComponent as DocumentIcon } from 'icons/mho-document-icon.svg';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';

import './styles.scss';

type PrintableProps = {
  facilityID?: number,
  showCardTitle?: boolean,
}

/**
 * Modularized table for the printable assessments.
 *
 * @param props contains the selected facilityID
 * @returns React component with the organized printable assessments
 */
export const PrintableAssessments = (props: PrintableProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formList, setFormList] = useState<ListForm[][]>([]);
  const [filteredFormList, setFilteredFormList] = React.useState<ListForm[][]>([]);
  const [searchText, setSearchText] = React.useState<string>('');
  const TABLE_TITLE = props.showCardTitle ? 'Printable Assessments' : '';
  const LOADING_TITLE = 'Loading Printable Assessments...';

  const searchFormList = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === '') {
      setFilteredFormList([]);
      return;
    }

    const tempFilteredData: ListForm[][] = [];

    formList.forEach((formType) => {
      if (formType[0].instrumentType.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1) {
        tempFilteredData.push(formType);
      } else {
        const tempFilteredForms: ListForm[] = [];

        formType.forEach((form) => {
          if (
            form.formDesc.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
            || form.formNumber.toString().indexOf(e.target.value.toLowerCase()) !== -1
          ) {
            tempFilteredForms.push(form);
          }
        });

        if (tempFilteredForms.length > 0) {
          tempFilteredData.push(tempFilteredForms);
        }
      }
    });

    setFilteredFormList(tempFilteredData);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e.target.value);
    searchFormList(e);
  };

  /**
   * Create a link for a pdf form using the metadata.
   *
   * @param formData individual pdf form's meta data.
   * @returns a link to the download file.
   */
  const formLink = (formData: ListForm): JSX.Element => (
    <a
      className="external-link"
      key={`${formData.formNumber}-${formData.pdfFileHash}`}
      href={`${AllRoutes.DOWNLOAD}/${formData.pdfFileHash}`}
    >
      <DocumentIcon />
      <InlineText
        text={`${formData.formDesc.toString()}-${formData.formNumber.toString()}`}
        fontColor={FontColors.SECONDARY}
        fontSize={FontSizes.REGULAR}
      />
    </a>
  );

  const instrumentTypeSection = (forms: ListForm[]): JSX.Element => (
    <CollapsibleSection
      key={`${forms[0].instrumentType}-${forms.length}`}
      banner={
        <TertiaryHeader text={forms[0].instrumentTypeDesc} fontColor={FontColors.PRIMARY} alignLeft />
      }
    >
      {forms.map((form) => (
        formLink(form)
      ))}
    </CollapsibleSection>
  );

  useEffect(() => {
    if (props.facilityID === null || props.facilityID === undefined) {
      return;
    }
    DataListsProxy.getFacilityFormList(props.facilityID)
      .then((response) => {
        const newFormList: ListForm[][] = [];
        if (response.data) {
          response.data.forEach((form) => {
            if (newFormList.length === 0) {
              newFormList.push([form]);
            } else {
              for (let i = 0; i < newFormList.length; i += 1) {
                if (form.instrumentType === newFormList[i][0].instrumentType) {
                  newFormList[i].push(form);
                  return;
                }
              }

              newFormList.push([form]);
            }
          });
        }

        setFormList(newFormList);
        setIsLoading(false);
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
      });
  }, [props.facilityID]);

  return (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.CENTER}
      justify={JustifyVariant.SPACE_BETWEEN}
      extraClasses="printable-assessments-widget"
    >
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.CENTER}
        justify={JustifyVariant.SPACE_BETWEEN}
        extraClasses="printable-assessments-widget__header"
      >
        <SecondaryHeader text={TABLE_TITLE} fontColor={FontColors.PRIMARY} />
        <SearchBar
          placeholderText="Search for Form Name or Number"
          inputName="searchText"
          searchText={searchText}
          handleSearchChange={handleSearchChange}
          autoFocus
        />
      </FlexContainer>
      {isLoading ? (
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="loading-curtain"
        >
          <SecondaryHeader text={LOADING_TITLE} fontColor={FontColors.SECONDARY} />
        </FlexContainer>
      ) : (
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          wrap
          extraClasses="printable-assessments-widget__content"
        >
          {
            filteredFormList.length > 0
              ? filteredFormList.map((section) => (instrumentTypeSection(section)))
              : formList.map((section) => (instrumentTypeSection(section)))
          }
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

PrintableAssessments.defaultProps = {
  facilityID: undefined,
  showCardTitle: true,
};
