import { PatientAssessmentCardProps, ProviderAssessmentCardProps } from 'types/cardProps';
import { AssessmentResultRow } from 'types/tableProps';
import { AssessmentData } from 'interfaces/assessments/assessmentData';
import { AssessmentTypes, ProviderAssessmentTypes } from 'constants/assessment_types';
import { AssessmentResultsWithExtremeDefinition } from 'domain/Forms/MHO/AssessmentResultsDefinition';

/**
 * Convert API Data to UI Props and other UI related format
 */
class ApiDataUiConverter {
  /**
   * Parses out Assessment Data retrieved from the API to Assessment Card Prop format.
   *
   * @param assessmentList list of API assessment data.
   * @param assessmentFilter optional filter to narrow the type 'ToDo', 'SelfCheck' and 'History'.
   * @returns list of assessment card props converted from the API data.
   */
  static convertAssessmentsToCardProps(assessmentList: AssessmentData[], assessmentFilter?: AssessmentTypes): PatientAssessmentCardProps[] {
    const filtler = assessmentFilter || AssessmentTypes.HISTORY;
    return assessmentList
      .filter((assessment) => {
        switch (filtler) {
          // return 'TO-DO' assessments.
          case AssessmentTypes.DAILY:
          case AssessmentTypes.FOLLOWUP:
            return assessment.sortOrder === 1 || assessment.sortOrder === 2;

          // Return self check assessments only.
          case AssessmentTypes.SELFCHECK:
            return assessment.sortOrder === 3;

          // History is unhandled
          case AssessmentTypes.HISTORY:
          default:
            return true;
        }
      })
      .map((assessment) => ({
        id: assessment.assessmentNumber,
        appliedWhenID: assessment.appliedWhenID,
        title: assessment.assessmentDescription,
        type: assessment.instrumentType,
        typeID: assessment.instrumentTypeID,
        careID: assessment.careID,
        patientID: assessment.patientID,
        assessmentNumber: assessment.assessmentNumber,
        cta: filtler === AssessmentTypes.HISTORY ? 'view' : 'start',
        lastCompleted: assessment.dateProcessed,
        isTodo: assessment.sortOrder === 1 || assessment.sortOrder === 2,
      }));
  }

  /**
   * Parses out Assessment Data retrieved from the API to Assessment Card Prop format.
   *
   * @param assessmentList list of API assessment data.
   * @param assessmentFilter optional filter to narrow the type 'ToDo', 'SelfCheck' and 'History'.
   * @param isCoreMeasuresMode if the api was called using the /CoreMeasures mode, set all isCoreMeasures fields to true. Otherwise, need to look at instrumentSetup.
   * @returns list of assessment card props converted from the API data.
   */
  static convertProviderAssessmentsToCardProps(assessmentList: AssessmentData[], assessmentFilter?: ProviderAssessmentTypes, isCoreMeasuresMode?: boolean): ProviderAssessmentCardProps[] {
    let ctas: string[] = [];
    if (assessmentFilter === ProviderAssessmentTypes.SUBMITTED) {
      ctas = isCoreMeasuresMode ? ['Results', 'Edit'] : ['Results', 'Redo'];
    } else if (assessmentFilter === ProviderAssessmentTypes.HISTORY) {
      ctas = ['Results'];
    } else {
      ctas = ['Start'];
    }

    return assessmentList
      .filter((assessment) => {
        switch (assessmentFilter) {
          case ProviderAssessmentTypes.TODO:
            return assessment.assessmentStatus === '';

          case ProviderAssessmentTypes.SUBMITTED:
            return assessment.assessmentStatus === 'Completed';
          default:
            return true;
        }
      })
      .map((assessment) => ({
        id: assessment.assessmentNumber,
        title: assessment.assessmentDescription,
        appliedWhen: assessment.appliedWhen,
        appliedWhenID: assessment.appliedWhenID,
        instrumentTypeID: assessment.instrumentTypeID,
        instrumentType: assessment.instrumentType,
        assessmentNumber: assessment.assessmentNumber.toString(),
        careID: assessment.careID,
        patientID: assessment.patientID,
        ctas,
        completed: assessmentFilter === ProviderAssessmentTypes.TODO ? '' : assessment.dateProcessed,
        dateAdmitted: assessmentFilter === ProviderAssessmentTypes.TODO ? '' : assessment.dateAdmitted,
        dateDischarged: assessmentFilter === ProviderAssessmentTypes.TODO ? '' : assessment.dateDischarged,
        assessmentLinkSentDate: assessment.assessmentLinkSentDate,
        assessmentLinkEmail: assessment.assessmentLinkEmail,
        availableForPatient: assessment.availableForPatient,
        isCoreMeasures: isCoreMeasuresMode || assessment.instrumentSetup === 'CoreMeasures',
        sequenceNumber: assessment.sequenceNumber,
      }));
  }

  static convertAssessmentQuestionToTableProp(results: AssessmentResultsWithExtremeDefinition[]): AssessmentResultRow[] {
    return results.map((result: AssessmentResultsWithExtremeDefinition) => ({
      name: result.variableName,
      question: result.questionText,
      selectedAnswer: '---',
      selectedExtremeMessage: '',
      latestAnswer: String(result.responseText || '---'),
      latestExtremeMessage: result.extremeResponseMessage,
      sequenceNumber: result.sequenceNumber,
    }));
  }
}

export default ApiDataUiConverter;
