import acquireAccessToken from '../apiTokenLoader'
import { ApiResponseV2 as ApiResponse } from '../models/ApiResponse'
import { NetworkError } from './errors'

export default class ApiService {
  static async get<Res>(url: string, queryParams?: Record<string, string>, isBlob = false, isString = false): Promise<ApiResponse<Res>> {
    const accessToken = await acquireAccessToken()

    const config = ApiService.getRequestConfig(accessToken)

    if (queryParams) {
      const params = new URLSearchParams(queryParams);
      url += `?${params.toString()}`
    }

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: config.headers,
      }).catch((error: any) => {
        console.error(error)
        throw new NetworkError(error.message)
      })

      if (!response.ok) {
        const error = await response.text()
        return new ApiResponse<Res>({ status: response.status, message: error })
      }

      if (isBlob) {
        const blob = await response.blob();
        return new ApiResponse<Res>({ status: response.status, data: blob as Res });
      }

      if (isString) {
        const str = await response.text();
        return new ApiResponse<Res>({ status: response.status, data: str as Res });
      }

      const json = await response.json()
      return new ApiResponse<Res>({ status: response.status, data: json as Res });
    } catch (error: any) {
      console.error(error)
      throw error
    }
  }

  static async post<Req>(url: string, data: Req): Promise<ApiResponse<undefined>> {
    const accessToken = await acquireAccessToken()

    const config = ApiService.getRequestConfig(accessToken)

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: config.headers,
        body: data ? JSON.stringify(data) : undefined,
      }).catch((error: any) => {
        console.error(error)
        throw new NetworkError(error.message)
      })

      if (!response.ok) {
        const error = await response.text()
        return new ApiResponse<undefined>({ status: response.status, message: error })
      }

      return new ApiResponse<undefined>({ status: response.status })
    } catch (error: any) {
      console.error(error)
      throw error
    }
  }

  static async postWithResponse<Req, Res>(url: string, data: Req): Promise<ApiResponse<Res>> {
    const accessToken = await acquireAccessToken()

    const config = ApiService.getRequestConfig(accessToken)

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: config.headers,
        body: data ? JSON.stringify(data) : undefined,
      }).catch((error: any) => {
        console.error(error)
        throw new NetworkError(error.message)
      })

      if (!response.ok) {
        const error = await response.text()
        return new ApiResponse<Res>({ status: response.status, message: error })
      }

      const json = await response.json()

      return new ApiResponse<Res>({ status: response.status, data: json as Res })
    } catch (error: any) {
      console.error(error)
      throw error
    }
  }

  static async postFormData(url: string, data: FormData): Promise<ApiResponse<number>> {
    const accessToken = await acquireAccessToken();

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        App: `REFL_Portal_v${process.env.REACT_APP_MHO_VERSION}`,
      },
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: config.headers,
        body: data,
      }).catch((error: any) => {
        console.error(error);
        throw new NetworkError(error.message);
      });

      if (!response.ok) {
        const error = await response.text();
        return new ApiResponse<number>({ status: response.status, message: error });
      }

      return new ApiResponse<number>({ status: response.status });
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

  private static getRequestConfig(apiToken: string): RequestInit {
    return {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
        App: `REFL_Portal_v${process.env.REACT_APP_MHO_VERSION}`,
      },
    }
  }
}
