import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import MHODateTime from 'domain/dateTime/MHODateTime';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import Button from 'global_elements/Button';

import { PatientInfoCardProps } from 'types/cardProps';
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { AllRoutes } from 'constants/routes';
import { RegistrationPIN } from 'interfaces/patients/patientData';
import { UserContext } from 'context/user';
import { Roles } from 'constants/roles';
import './styles.scss';

const PatientInfoCard = (props: PatientInfoCardProps): JSX.Element => {
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const [isPatientView, setIsPatientView] = useState<boolean>(true);
  const [dobDate, setDobDate] = useState<string>('');
  const [admittedDate, setAdmittedDate] = useState<string>('');
  const [dischargedDate, setDischargedDate] = useState<string>('');

  useMemo(() => {
    setIsPatientView(user?.role === Roles.PATIENT);
  }, []);

  useEffect(() => {
    if (props.patientDOB) {
      const birthDate = new MHODateTime(props.patientDOB).getFormattedCalendarDate();
      setDobDate(birthDate);
    }
    if (props.patientAdmissionDate) {
      const admissionDate = new MHODateTime(props.patientAdmissionDate).getFormattedCalendarDate();
      setAdmittedDate(admissionDate);
    }
    if (props.patientDischargeDate) {
      const dischargeDate = new MHODateTime(props.patientDischargeDate).getFormattedCalendarDate();
      setDischargedDate(dischargeDate);
    }
  }, [props.patientDOB, props.patientAdmissionDate, props.patientDischargeDate]);

  const getPatientName = (): string => {
    if (isPatientView) {
      return `${props.patientFirstName} ${props.patientLastName}`;
    }

    return props.patientLastName ? `${props.patientLastName}, ${props.patientFirstName}` : '';
  };

  const goToPatientAccountSetup = (): void => {
    history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${props.patientID}/account-setup`);
  };

  const generateRegistrationPIN = (): void => {
    const data: RegistrationPIN[] = [
      {
        patientRegPINID: null,
        patientID: parseInt(props.patientID, 10),
        registrationPIN: null,
        statusID: 1,
        createdBy: user?.email ?? '',
      },
    ];

    if (props.patientRegistrationPIN === null || props.patientRegistrationPIN === '') {
      PatientUsersProxy.postRegistrationPIN(
        data,
        (success) => {
          if (success) {
            goToPatientAccountSetup();
          }
        },
        (failure) => {
          console.log(failure);
        },
      );
    } else {
      goToPatientAccountSetup();
    }
  };

  return (
    <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-information-card">
      <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.CENTER} justify={JustifyVariant.CENTER} extraClasses="patient-information-card__print-label">
        <SecondaryHeader text="Patient Sticker" fontColor={FontColors.BRAND_GRAY} />
      </FlexContainer>
      <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="patient-information-card__header">
        <SecondaryHeader text="Patient Information" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
        {props.showRegisterButton && (
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={generateRegistrationPIN}
            // disabled={props.patientRegistrationPIN !== ''}
            disabled={props.disableRegisterButton ? props.disableRegisterButton : false}
          >
            <InlineText text="Reflections Registration" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
        )}
        {!isPatientView && !props.showRegisterButton && (
          <Button variant={ButtonVariant.PRIMARY} onClick={() => history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${props.patientID}`)}>
            <InlineText text="Back to Patient Dashboard" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
        )}
      </FlexContainer>
      <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="patient-information-card__columns">
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START}>
          <InlineText text="Name" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.SMALL} bold />
          <InlineText text={getPatientName()} fontColor={FontColors.SECONDARY} fontSize={FontSizes.LARGE} />
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START}>
          <InlineText text="Date of Birth" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.SMALL} bold />
          <InlineText text={dobDate} fontColor={FontColors.SECONDARY} fontSize={FontSizes.LARGE} />
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START}>
          <InlineText text="Account Number" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.SMALL} bold />
          <InlineText text={props.patientAccountNumber} fontColor={FontColors.SECONDARY} fontSize={FontSizes.LARGE} />
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START}>
          <InlineText text="Medical Record Number" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.SMALL} bold />
          <InlineText text={props.patientMedicalRecordNumber} fontColor={FontColors.SECONDARY} fontSize={FontSizes.LARGE} />
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START}>
          <InlineText text="Program" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.SMALL} bold />
          <InlineText text={props.patientProgram} fontColor={FontColors.SECONDARY} fontSize={FontSizes.LARGE} />
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START}>
          <InlineText text="Admission Date" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.SMALL} bold />
          <InlineText text={admittedDate} fontColor={FontColors.SECONDARY} fontSize={FontSizes.LARGE} />
        </FlexContainer>
        {dischargedDate && (
          <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START}>
            <InlineText text="Discharge Date" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.SMALL} bold />
            <InlineText text={dischargedDate} fontColor={FontColors.SECONDARY} fontSize={FontSizes.LARGE} />
          </FlexContainer>
        )}
        <FlexContainer display={props.patientRegistrationPIN ? DisplayVariant.FLEX_COL : DisplayVariant.HIDE} align={AlignVariant.START} justify={JustifyVariant.START}>
          <InlineText text="Registration PIN" fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.SMALL} bold />
          <InlineText text={props.patientRegistrationPIN} fontColor={FontColors.LOW_PRIORITY} fontSize={FontSizes.LARGE} />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default PatientInfoCard;
