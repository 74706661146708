import ApiProxy from 'api/lib/ApiService/v2';
import ApiResponseV2 from 'api/lib/models/ApiResponse/v2';
import { URLS } from 'constants/appUrls';
import { CoreMeasuresPatientSearchCriteria, CoreMeasuresPatientSearchResult } from 'interfaces/coreMeasures/coreMeasuresPatientSearchResult';
import { CoreMeasuresCompletionGridResult, CoreMeasuresIncompletePatientResult } from 'interfaces/coreMeasures/coreMeasuresCompletionGridModel';
import { ListServiceCode } from 'interfaces/dataLists/listServiceCode';

class WorklistProxy {
  static Search(searchArgs: CoreMeasuresPatientSearchCriteria): Promise<ApiResponseV2<CoreMeasuresPatientSearchResult[]>> {
    const url = `${URLS.MHO_API}/CoreWkList`;

    return ApiProxy.postWithResponse<CoreMeasuresPatientSearchCriteria[], CoreMeasuresPatientSearchResult[]>(url, [searchArgs]);
  }

  static getServiceCodes(fid: number, mode?: string): Promise<ApiResponseV2<ListServiceCode[]>> {
    const url = `${URLS.MHO_API}/CoreSvcCodes`;
    const params: Record<string, string> = {};
    params.fid = fid.toString();
    if (mode) {
      params.mode = mode;
    }

    return ApiProxy.get<ListServiceCode[]>(url, params);
  }

  static GetCompletionGrid(fid: number): Promise<ApiResponseV2<CoreMeasuresCompletionGridResult[]>> {
    const url = `${URLS.MHO_API}/CmComp/${fid}`;

    return ApiProxy.get<CoreMeasuresCompletionGridResult[]>(url, undefined);
  }

  static GetIncompletePatients(fid: number, year: number, month: number): Promise<ApiResponseV2<CoreMeasuresIncompletePatientResult[]>> {
    const url = `${URLS.MHO_API}/CmIncomp/${fid}/${year}?mm=${month}`;

    return ApiProxy.get<CoreMeasuresIncompletePatientResult[]>(url, undefined);
  }
}

export default WorklistProxy;
