import * as React from 'react';
import Select, { SingleValue } from 'react-select';

import { SingleSelectDropdownProps, SelectOption } from 'types/inputProps';

const SingleSelect = (props: SingleSelectDropdownProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = React.useState<SelectOption | null>(props.value || props.defaultValue);

  const handleChange = (option: SingleValue<SelectOption>): void => {
    setSelectedOption(option);
    props.onSelection(option as SelectOption);
  };

  return (
    <div
      className="single-select-dropdown-wrapper"
      data-testid={props.testid}
    >
      <Select<SelectOption>
        styles={props.styles}
        value={selectedOption}
        onChange={handleChange}
        options={props.options}
        getOptionLabel={(option: SelectOption) => option.label}
        getOptionValue={(option: SelectOption) => option.value}
        isSearchable
        onBlur={props.onblur}
        blurInputOnSelect
        tabIndex={0}
        menuPlacement={props.menuPlacement}
        isDisabled={props.isDisabled}
      />
    </div>
  );
};

export default React.memo(
  SingleSelect,
  (prevProps: Readonly<SingleSelectDropdownProps>, nextProps: Readonly<SingleSelectDropdownProps>) => (
    typeof prevProps.value?.value === typeof nextProps.value?.value
  ),
);
