export enum AssessmentTypes {
  DAILY = 'To-Do Daily Check',
  FOLLOWUP = 'To-Do Follow-Up',
  SELFCHECK = 'Self-Check',
  HISTORY = 'History',
}

export enum ProviderAssessmentTypes {
  TODO = 'To-DO',
  SUBMITTED = 'Submitted',
  HISTORY = 'History',
}

export enum AssessmentSetupMode {
  OVERALL = 'Overall',
  SUBSCALES = 'Subscales',
  COMPONENTS = 'Components',
  CORE_MEASURES = 'CoreMeasures',
}

export const AssessmentSortOrder: AssessmentTypes[] = [AssessmentTypes.DAILY, AssessmentTypes.FOLLOWUP, AssessmentTypes.SELFCHECK, AssessmentTypes.HISTORY];

export const getAssessmentType = (sortOrder: number): AssessmentTypes | null => {
  if (sortOrder > AssessmentSortOrder.length || sortOrder < 1) {
    return null;
  }

  return AssessmentSortOrder[sortOrder - 1];
};

/**
 * Converts the assessment setup string to the enum.
 *
 * @param modeString an instrument setup string.
 * @returns the Assessment setup mode enum.
 */
export const getAssessmentSetupMode = (modeString?: string): AssessmentSetupMode => {
  const mode = modeString || '';
  switch (mode.toLowerCase().trim()) {
    case 'subscales':
      return AssessmentSetupMode.SUBSCALES;
    case 'components':
      return AssessmentSetupMode.COMPONENTS;
    case 'coremeasures':
      return AssessmentSetupMode.CORE_MEASURES;
    case 'overall':
    default:
      return AssessmentSetupMode.OVERALL;
  }
};

export type AssessmentSetupModeSequence = {
  mode: AssessmentSetupMode;
  subscaleSequence: number;
};

export type AssessmentAppliedWhen = {
  appliedWhenID: number;
  appliedWhen: string;
};
