import AssessmentProxy from 'api/assessments/assessmentProxy'
import GroupTherapyProxy from 'api/groupTherapy/GroupTherapyProxy'
import { GroupTherapyIncompleteAssessment } from 'api/groupTherapy/types'
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy'
import { AllRoutes } from 'constants/routes'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import InlineText from 'global_elements/Text/InlineText'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import { PatientData } from 'interfaces/patients/patientData'
import { AssessmentSurvey, PatientAssessmentData } from 'pages/shared/assessments/assessment'
import { useContext, useEffect, useState } from 'react'
import { Prompt, useHistory, useParams } from 'react-router-dom'
import * as Survey from 'survey-react'
import './styles.scss'
import { UserContext } from 'context/user'
import { AssessmentResult } from 'api/assessments/types'

interface UriParams {
  groupId: string
}

const GroupTherapyAssessmentsPage = (): JSX.Element => {
  const history = useHistory()
  const { groupId } = useParams<UriParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [assessments, setAssessments] = useState<GroupTherapyIncompleteAssessment[]>([])
  const [assessmentIndex, setAssessmentIndex] = useState<number>(0)
  const [patient, setPatient] = useState<PatientAssessmentData | null>(null)
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const advanceAssessmentOrFinish = (delayMs = 1000): void => {
    if (assessmentIndex < assessments.length - 1) {
      setAssessmentIndex(assessmentIndex + 1)
      window.scrollTo(0, 0)
    } else {
      // Brief delay to show thank you message
      setTimeout(() => {
        history.push(AllRoutes.GROUP_THERAPY_PARTICIPANTS.replace(':groupId', groupId))
      }, delayMs)
    }
  }

  const onCancel = (): void => {
    advanceAssessmentOrFinish(0)
  }

  const { activeGroupTherapyPatientId: patientIdString } = useContext(UserContext)
  if (patientIdString === null) {
    console.error('No patient ID found in context')
    onCancel()
  }
  const patientId = parseInt(patientIdString!, 10)

  useEffect(() => {
    GroupTherapyProxy.getIncompleteAssessments(groupId).then((results) => {
      const patientAssessments = results.filter((assessment) => assessment.patientID === patientId)

      if (!patientAssessments.length) {
        console.error('No assessments found for patient')
        onCancel()
        return
      }

      setAssessments(patientAssessments)

      setLoading(false)
    })
  }, [])

  useEffect(() => {
    PatientUsersProxy.getPatient(
      patientId,
      (response: any) => {
        if (response.data) {
          const patientData: PatientData = response.data[0]
          setPatient({
            accountID: patientData.accountID!,
            patientID: patientData.patientID!,
            programCode: patientData.programCode,
            patientNumber: patientData.patientNumber,
            careID: patientData.careID,
            treatmentTypeID: patientData.treatmentTypeID,
            corporationID: patientData.corporationID,
            populationTypeID: patientData.populationTypeID,
          })
        }
      },
      (errorResponse: any) => {
        console.log(errorResponse)
      },
    )
  }, [patientId])

  const handlePageClosePrompt = ((event: any): any => {
    event.preventDefault();
    event.returnValue = '';
  })

  useEffect(() => {
    window.addEventListener('beforeunload', handlePageClosePrompt)
    return () => {
      setIsDirty(false);
      window.removeEventListener('beforeunload', handlePageClosePrompt)
    }
  }, [])

  const onComplete = async (survey: Survey.Model, options: any): Promise<void> => {
    options.showDataSaving()
    setIsDirty(false)
    const results = Object.entries(survey.data)

    // Construct the data format for the API.
    const resultsList: AssessmentResult[] = []
    for (let i = 0; i < results.length; i += 1) {
      const resultRow = {
        patientID: patientId,
        assessmentNumber: assessments[assessmentIndex].assessmentNumber,
        variableName: results[i][0],
        dataValue: String(results[i][1]),
      };
      resultsList.push(resultRow)
    }

    try {
      await AssessmentProxy.saveAssessmentForm(resultsList, groupId)
      options.showDataSavingSuccess()
      advanceAssessmentOrFinish()
    } catch (error) {
      console.log(error)

      // Slight delay to show loading message briefly
      setTimeout(() => {
        options.showDataSavingError()
      }, 500);

      throw error
    }
  }

  if (loading || !patient) {
    return (
      <PageLayout
        layout={PageLayoutVariant.PADDED}
        testText="Group Therapy Assessments Page"
        loadingText={loading ? 'Loading...' : undefined}
        hideHeader
      >
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
        >
          <InlineText
            text="Loading Assessments..."
            fontColor={FontColors.PRIMARY}
            fontSize={FontSizes.SUPER_EXTRA_LARGE}
          />
        </FlexContainer>
      </PageLayout>
    )
  }

  return (
    <>
      <AssessmentSurvey
        assessmentNumber={assessments[assessmentIndex].assessmentNumber}
        appliedWhenID={assessments[assessmentIndex].appliedWhenID}
        patientAssessmentData={patient}
        onCompleteCallback={onComplete}
        setIsDirtyCallback={setIsDirty}
        onCancelCallback={onCancel}
        hideHeader
        isPatient
        extraClasses="group-therapy-assessments-page"
        showExtremeMessages={false}
      />
      <Prompt when={isDirty} message="Are you sure you want to close the form? Incomplete assessments will be lost." />
    </>
  )
}

export default GroupTherapyAssessmentsPage
