import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput'
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PrimaryHeader from 'global_elements/Text/PrimaryHeader'
import { FontColors, FontSizes } from 'global_elements/Text/variants'

import './styles.scss'
import LabeledTextArea from 'global_elements/Inputs/TextArea/LabeledTextArea'
import { TextAreaVariant } from 'global_elements/Inputs/TextArea/variants'
import DataListsProxy, { ProgramListMode } from 'api/dataLists/dataListsProxy'
import { SelectOption } from 'types/inputProps'
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect'
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles'
import Button from 'global_elements/Button'
import { ButtonVariant } from 'global_elements/Button/variants'
import InlineText from 'global_elements/Text/InlineText'
import { GroupTherapySessionStatus } from 'api/groupTherapy/types'
import { useHistory } from 'react-router-dom'
import { AllRoutes } from 'constants/routes'
import { ActiveFacilityContext } from 'context/activeFacility'
import { useContext, useEffect, useState } from 'react'
import { UserContext } from 'context/user'
import GroupTherapyProxy from 'api/groupTherapy/GroupTherapyProxy'

const MAX_TITLE_LENGTH = 100
const MAX_COMMENTS_LENGTH = 1000
const PIN_LENGTH = 6
const PIN_INSTRUCTIONS = 'Choose a 6 digit numeric PIN for this session. Remember this PIN; it will be required to exit Group Therapy mode.'

const GroupTherapyStartSessionPage = (): JSX.Element => {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(true)
  const [title, setTitle] = useState<string>('')
  const [comments, setComments] = useState<string>('')
  const [programOptions, setProgramOptions] = useState<SelectOption[]>([])
  const [program, setProgram] = useState<SelectOption | undefined>(undefined)
  const [exitPin, setExitPin] = useState<string>('')
  const { facility } = useContext(ActiveFacilityContext)
  const { user } = useContext(UserContext)

  const setProgramAndTitle = (option: SelectOption): void => {
    if (option) {
      setProgram(option)

      // Have to use Central timezone since MHO backend saves as Central Time
      const dateString = new Date().toLocaleString('en-US', { timeZone: 'US/Central', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit' })
      setTitle(`${option.label} - ${dateString} (CT)`)
    }
  }

  useEffect(() => {
    if (!facility.value) {
      return
    }

    DataListsProxy.getPrograms(
      parseInt(facility.value, 10),
      ProgramListMode.Group,
    ).then((results) => {
      const options = results.map((x) => ({
        label: x.programName,
        value: x.programID.toString(),
      }))

      setProgramOptions(options)
      if (options.length > 0) {
        setProgramAndTitle(options[0])
      }

      setLoading(false)
    })
  }, [facility])

  const submit = async (): Promise<void> => {
    try {
      const response = await GroupTherapyProxy.createGroupTherapySession({
        GroupTherapyTitle: title,
        GroupTherapyComments: comments,
        ProgramID: parseInt(program?.value ?? '', 10),
        StatusID: GroupTherapySessionStatus.Active,
        AccountID: parseInt(user?.accountId || '', 10),
        GroupTherapyID: null,
        StartDate: null,
        EndDate: null,
        CreatedBy: null,
        UpdatedBy: null,
        GroupTherapyPIN: exitPin,
      })

      history.push(`${AllRoutes.GROUP_THERAPY_BASE}/${response.id}/add-patients`)
    } catch (error) {
      console.log(error)
    }
  }

  const inputValid = (): boolean => {
    if (title.length === 0 || title.length > MAX_TITLE_LENGTH) {
      return false
    }

    if (exitPin.length !== PIN_LENGTH) {
      return false
    }

    if (program === undefined) {
      return false
    }

    return true
  }

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Start Group Therapy Session Page"
      loadingText={loading ? 'Loading...' : undefined}
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
      >
        <PrimaryHeader text="Start Group Therapy Session" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
        <form className="group-therapy-form">
          <FlexContainer
            display={DisplayVariant.FLEX_COL}
            align={AlignVariant.START}
            justify={JustifyVariant.START}
            extraClasses="group-therapy-form-container"
          >
            {programOptions.length === 0 && (
              <LabledSingleSelect
                label="Program"
                options={[]}
                onSelection={(option) => setProgramAndTitle(option)}
                defaultValue={{ label: 'Loading...', value: '' }}
                styles={SingleStandardDropdownStyles}
                value={program}
                testid="program-select"
              />
            )}
            {programOptions.length > 0 && (
              <LabledSingleSelect
                label="Program"
                options={programOptions}
                onSelection={(option) => setProgramAndTitle(option)}
                defaultValue={programOptions[0]}
                styles={SingleStandardDropdownStyles}
                value={program}
                testid="program-select"
              />
            )}
            <LabledTextInput
              label="Title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              type="text"
              variant={TextInputVariant.PRIMARY}
              autoFocus
              maxTextLength={MAX_TITLE_LENGTH}
              testid="title-input"
            />
            <LabledTextInput
              label="Session PIN (6 digits)"
              instructions={PIN_INSTRUCTIONS}
              name="exit-pin"
              value={exitPin}
              type="text"
              onChange={(e) => setExitPin(e.target.value.replace(/[^0-9]/g, '').substring(0, PIN_LENGTH))}
              variant={TextInputVariant.PRIMARY}
              maxTextLength={PIN_LENGTH}
              testid="exit-pin-input"
              autoComplete="off"
              inputMode="numeric"
            />
            <LabeledTextArea
              label="Comments"
              name="comments"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              maxTextLength={MAX_COMMENTS_LENGTH}
              variant={TextAreaVariant.PRIMARY}
              testid="comments-input"
              labelId="comments-label"
            />
            <Button
              variant={ButtonVariant.PRIMARY}
              onClick={submit}
              disabled={!inputValid()}
              testid="submit-button"
            >
              <InlineText
                text="Submit"
                fontColor={FontColors.BACKGROUND}
                fontSize={FontSizes.REGULAR}
                bold
              />
            </Button>
          </FlexContainer>
        </form>
      </FlexContainer>
    </PageLayout>
  )
}

export default GroupTherapyStartSessionPage
