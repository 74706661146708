import React from 'react';

import { ReactComponent as LaughIcon } from 'icons/assessments/emoticon-excited.svg';
import { ReactComponent as SmileIcon } from 'icons/assessments/emoticon-happy.svg';
import { ReactComponent as NeutralIcon } from 'icons/assessments/emoticon-neutral.svg';
import { ReactComponent as SadIcon } from 'icons/assessments/emoticon-sad.svg';
import { ReactComponent as FrownIcon } from 'icons/assessments/emoticon-frown.svg';
import '../../../AssessmentResult/styles.scss';

/**
 * Create a 'source' list of emoticon icons to copy, to replace the existing rating.
 *
 * @returns {JSX.Element} A list of emoticon face fontawesome iconds.
 */
export function RatingEmoticons(): JSX.Element {
  return (
    <div id="emoticon-list" className="hidden">
      <LaughIcon className="emoticon-list-1 icon-emoticon" title="great" />
      <SmileIcon className="emoticon-list-2 icon-emoticon limit-5-rating" title="good" />
      <NeutralIcon className="emoticon-list-3 icon-emoticon" title="ok" />
      <SadIcon className="emoticon-list-4 icon-emoticon limit-5-rating" title="alright" />
      <FrownIcon className="emoticon-list-5 icon-emoticon" title="sad" />

    </div>
  );
}
