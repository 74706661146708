import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { UserContext } from 'context/user';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Button from 'global_elements/Button';
import InlineText from 'global_elements/Text/InlineText';

import {
  DisplayVariant,
  JustifyVariant,
  AlignVariant,
} from 'global_elements/Layouts/FlexContainer/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontSizes, FontColors } from 'global_elements/Text/variants';

import { Roles } from 'constants/roles';

import { UserInterface } from 'interfaces/userInterface';
import HiddenTestText from 'global_elements/Text/HiddenTestText';

const samplePatientUser: UserInterface = {
  name: 'Patient User',
  role: Roles.PATIENT,
  email: '',
  accountId: '',
  facilities: [],
  authority: '',
};

const sampleProviderUser: UserInterface = {
  name: 'Provider User',
  role: Roles.PROVIDER,
  email: '',
  accountId: '',
  facilities: [],
  authority: '',
};

const sampleFacDirUser: UserInterface = {
  name: 'Facility Director User',
  role: Roles.FACILITY_DIRECTOR,
  email: '',
  accountId: '',
  facilities: [],
  authority: '',
};

const sampleFacExecUser: UserInterface = {
  name: 'Facility Executive User',
  role: Roles.FACILITY_EXECUTIVE,
  email: '',
  accountId: '',
  facilities: [],
  authority: '',
};

const sampleCorpUser: UserInterface = {
  name: 'Corporation User',
  role: Roles.CORPORATION,
  email: '',
  accountId: '',
  facilities: [],
  authority: '',
};

const sampleSuperCorpUser: UserInterface = {
  name: 'Super Corporation User',
  role: Roles.SUPER_CORPORATION,
  email: '',
  accountId: '',
  facilities: [],
  authority: '',
};

const sampleAdminUser: UserInterface = {
  name: 'Admin User',
  role: Roles.ADMIN,
  email: '',
  accountId: '',
  facilities: [],
  authority: '',
};

const sampleSuperAdminUser: UserInterface = {
  name: 'Super Admin User',
  role: Roles.SUPER_ADMIN,
  email: '',
  accountId: '',
  facilities: [],
  authority: '',
};

const LegacyLoginPage = (): JSX.Element => {
  const { Login } = React.useContext(UserContext);
  const history = useHistory();

  const LoginAsPatient = (): void => {
    Login(samplePatientUser);
    history.push('/');
  };

  const LoginAsProvider = (): void => {
    Login(sampleProviderUser);
    history.push('/');
  };

  const LoginAsFacDir = (): void => {
    Login(sampleFacDirUser);
    history.push('/');
  };

  const LoginAsFacExec = (): void => {
    Login(sampleFacExecUser);
    history.push('/');
  };

  const LoginAsCorp = (): void => {
    Login(sampleCorpUser);
    history.push('/');
  };

  const LoginAsSuperCorp = (): void => {
    Login(sampleSuperCorpUser);
    history.push('/');
  };

  const LoginAsAdmin = (): void => {
    Login(sampleAdminUser);
    history.push('/');
  };

  const LoginAsSuperAdmin = (): void => {
    Login(sampleSuperAdminUser);
    history.push('/');
  };

  return (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      justify={JustifyVariant.CENTER}
      align={AlignVariant.CENTER}
    >
      <HiddenTestText text="Login Page" />
      <Button
        variant={ButtonVariant.PRIMARY}
        onClick={LoginAsPatient}
      >
        <InlineText
          text="Login As Patient"
          fontColor={FontColors.BACKGROUND}
          fontSize={FontSizes.LARGE}
        />
      </Button>
      <Button
        variant={ButtonVariant.PRIMARY}
        onClick={LoginAsProvider}
      >
        <InlineText
          text="Login As Provider"
          fontColor={FontColors.BACKGROUND}
          fontSize={FontSizes.LARGE}
        />
      </Button>
      <Button
        variant={ButtonVariant.PRIMARY}
        onClick={LoginAsFacDir}
      >
        <InlineText
          text="Login As Facility Director"
          fontColor={FontColors.BACKGROUND}
          fontSize={FontSizes.LARGE}
        />
      </Button>
      <Button
        variant={ButtonVariant.PRIMARY}
        onClick={LoginAsFacExec}
      >
        <InlineText
          text="Login As Facility Executive"
          fontColor={FontColors.BACKGROUND}
          fontSize={FontSizes.LARGE}
        />
      </Button>
      <Button
        variant={ButtonVariant.PRIMARY}
        onClick={LoginAsCorp}
      >
        <InlineText
          text="Login As Corporation"
          fontColor={FontColors.BACKGROUND}
          fontSize={FontSizes.LARGE}
        />
      </Button>
      <Button
        variant={ButtonVariant.PRIMARY}
        onClick={LoginAsSuperCorp}
      >
        <InlineText
          text="Login As Super Corporation"
          fontColor={FontColors.BACKGROUND}
          fontSize={FontSizes.LARGE}
        />
      </Button>
      <Button
        variant={ButtonVariant.PRIMARY}
        onClick={LoginAsAdmin}
      >
        <InlineText
          text="Login As Admin"
          fontColor={FontColors.BACKGROUND}
          fontSize={FontSizes.LARGE}
        />
      </Button>
      <Button
        variant={ButtonVariant.PRIMARY}
        onClick={LoginAsSuperAdmin}
      >
        <InlineText
          text="Login As Super Admin"
          fontColor={FontColors.BACKGROUND}
          fontSize={FontSizes.LARGE}
        />
      </Button>
    </FlexContainer>
  );
};

export default LegacyLoginPage;
