import ApiProxy from 'api/lib/ApiService';
import { AssessmentDefinition } from 'interfaces/assessments/assessmentDefinitionSearchResponse';
import { MHOFormDataRow } from 'domain/Forms/MHO/MHOFormDataRow';
import { MHOSurveyJSFormDefinition } from 'domain/Forms/MHO/MHOSurveyJSFormDefinition';
import AssessmentJSONDefinition from 'interfaces/assessments/AssessmentJSONDefinition';
import { URLS } from 'constants/appUrls';

/**
 * Proxy container to handle all of the assessment definition APIs.
 */
class AssessmentDefinitionProxy {
  /**
   * Search for a assessment using the partial name/number search for the assessment. Matches assessment number
   * or the assessment name.
   *
   * @param searchTerm partial text to search by.
   * @returns list of found assessments.
   */
  static searchAssessmentDefinitions(searchTerm: string): Promise<AssessmentDefinition[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/ListForm?form=${searchTerm}`;

      ApiProxy.get<AssessmentDefinition[]>(url, (response: any) => {
        resolve(response?.data || []);
      }, (response: any) => {
        reject(response);
      });
    });
  }

  /**
   * Find the assessment with the exact assessment number.
   *
   * @param assessmentNumber assessment's number.
   * @returns a single assessment with the exact matching assessment number or null.
   */
  static findExactAssessment(assessmentNumber: number): Promise<AssessmentDefinition | null> {
    return new Promise((resolve, reject) => {
      this.searchAssessmentDefinitions(`${assessmentNumber}`).then((assessments: AssessmentDefinition[]) => {
        const assessment: AssessmentDefinition | null = assessments.find((assessmentDef) => (assessmentDef.assessmentNumber === assessmentNumber)) || null;
        resolve(assessment);
      }).catch((error: any) => {
        reject(error);
      });
    });
  }

  /**
   * Retrieve the assessment json definition based on the assessment number, if it exists.
   *
   * @param assessmentNumber assessment number associated with the surveyjs json saved data.
   * @returns {Promise} assessment json definition, if it's found.
   */
  static getAssessmentJSONDefinition(assessmentNumber: number): Promise<AssessmentJSONDefinition> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/FormEditorJson/${assessmentNumber}`;

      ApiProxy.get<AssessmentJSONDefinition>(url, (response: any) => {
        resolve(response?.data);
      }, (response: any) => {
        reject(response);
      }, true);
    });
  }

  /**
   * Retrieve a list of raw assessment questions associated with the definition ID.
   *
   * @param definitionId definition identifier to retrieve.
   * @returns {Promise} the list of raw assessment questions.
   */
  static getAssessmentBaseDefinition(definitionId: number): Promise<MHOFormDataRow[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/FormEditorRaw/${definitionId}`;

      ApiProxy.get<MHOFormDataRow[]>(url, (response: any) => {
        resolve(response?.data || []);
      }, (response: any) => {
        reject(response);
      });
    });
  }

  /**
   * Save the surveyjs json definition of the assessment to the database.
   *
   * @param data a list form of a single assessment json data to be saved into the database.
   * @returns {Promise} definition ID, if the save was successful.
   */
  static saveAssessmentJSONDefinition(data: MHOSurveyJSFormDefinition[]): Promise<number> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/FormEditor`;

      ApiProxy.post<void>(url, data, (response: any) => {
        resolve(response?.data);
      }, (response: any) => {
        reject(response);
      });
    });
  }
}

export default AssessmentDefinitionProxy;
