import React from 'react';
import { DefaultButtonProps } from 'types/buttonProps';
import './styles.scss';

const DefaultButton = (props: DefaultButtonProps): JSX.Element => (
  <button
    type="submit"
    className={`btn ${props.variant} ${props.extraClasses || ''}`}
    disabled={props.disabled ?? false}
    tabIndex={0}
    title={props.tooltipText}
  >
    {props.children}
  </button>
);

export default DefaultButton;
