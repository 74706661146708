import { AccountInfo } from '@azure/msal-browser';
import { Roles } from 'constants/roles';
import { UserInterface } from 'interfaces/userInterface';
import Facility from 'interfaces/users/facility';
import UserAccount from 'interfaces/users/userAccount';
import UserEntitlement from 'interfaces/users/userEntitlement';

class UserHelpers {
  static getUserRoleFromAccount(account: UserAccount): Roles {
    if (account) {
      if (account.userRoleID !== undefined) {
        switch (account.userRoleID) {
          case 1:
            return Roles.SUPER_ADMIN;
          case 2:
            return Roles.ADMIN;
          case 3:
            return Roles.SUPER_CORPORATION;
          case 4:
            return Roles.CORPORATION;
          case 5:
            return Roles.FACILITY_EXECUTIVE;
          case 6:
            return Roles.FACILITY_DIRECTOR;
          case 7:
            return Roles.PROVIDER;
          case 8:
            return Roles.PATIENT;
          default:
            return Roles.NONE;
        }
      }
    }

    return Roles.NONE;
  }

  static getRefId(fullId: string): string {
    let refId = fullId;
    if (fullId) {
      const splitOnRef = refId.split('REF-');
      if (splitOnRef.length > 1) {
        const partAfterRef = splitOnRef[1];
        if (partAfterRef.length) {
          const splitOnPipes = partAfterRef.split('|');
          if (splitOnPipes.length) {
            // eslint-disable-next-line
            refId = splitOnPipes[0];
          }
        }
      } else {
        // eslint-disable-next-line
        refId = splitOnRef[0];
      }
    }
    return refId;
  }

  static getFacilitiesFromEntitlements(entitlements: UserEntitlement[]): Facility[] {
    const parsedFacilities: number[] = [];
    const facilities: Facility[] = [];

    entitlements.forEach((entitlement) => {
      const facilityIdx = parsedFacilities.indexOf(entitlement.facilityID);
      if (facilityIdx === -1) {
        facilities.push({
          Id: entitlement.facilityID,
          Name: entitlement.facilityName,
          coreMeasures: entitlement.coreMeasures,
          svcCode: entitlement.svcCode,
          outcomes: entitlement.outcomes,
        });
        parsedFacilities.push(entitlement.facilityID);
      } else {
        const facility = facilities[facilityIdx];
        if (!facility.coreMeasures && entitlement.coreMeasures) {
          facility.coreMeasures = entitlement.coreMeasures;
        }
      }
    });
    return facilities;
  }

  static getUserFromClaims(user: AccountInfo, accounts: UserAccount[], entitlements: UserEntitlement[], authority: string): UserInterface {
    const appRole = UserHelpers.getUserRoleFromAccount(accounts[0]);
    const facilities = entitlements.length > 0 ? UserHelpers.getFacilitiesFromEntitlements(entitlements) : [];
    let email = '';
    let accountId = '';
    let displayName = '';

    if (user.idTokenClaims) {
      const claims = user.idTokenClaims as any;

      if (claims.email) {
        email = claims.email;
        displayName = claims.email;
      }

      if (claims.given_name && claims.family_name) {
        displayName = `${claims.family_name}, ${claims.given_name}`;
      }

      if (claims.appid) {
        accountId = UserHelpers.getRefId(claims.appid);
      }
    }

    const loggedInUser: UserInterface = {
      name: displayName,
      email,
      role: appRole,
      facilities,
      accountId,
      authority,
      accessType: accounts[0].accessTypeID,
    };

    return loggedInUser;
  }
}

export default UserHelpers;
