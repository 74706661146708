import * as React from 'react';
import { NavLink } from 'react-router-dom';

import './styles.scss';

import { NavLinkProps } from 'types/navigationProps';
import { StorageConstants } from 'constants/storageConstants';
import { NavLinkVariant } from './variants';

const NavLinkWrapper = (props: NavLinkProps): JSX.Element => (
  <NavLink
    exact
    className={`nav-link ${props.variant}`}
    to={props.route}
    activeClassName="--active"
    data-testid={`NavLink${props.route}`}
    onClick={() => sessionStorage.setItem(StorageConstants.DaisyChainCancelled, 'true')}
    tabIndex={props.variant === NavLinkVariant.DECORATIVE ? -1 : 0}
  >
    {props.children}
  </NavLink>
);

export default NavLinkWrapper;
