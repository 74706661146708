import * as React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { ReactComponent as SortIcon } from 'icons/mho-sort-icon-white.svg';
import Button from 'global_elements/Button';
import { ButtonVariant } from 'global_elements/Button/variants';

import { CMPatientCompletionDataRow, CMPatientCompletionTableProps } from 'types/tableProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import Utilities from 'api/lib/Utilities';

import { AlternatingBasicTableStyle } from '../styles';

const NoDataComponent: JSX.Element = <InlineText text="No Data Found." fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} />;

const columns = (onChangeAccessType: (row: CMPatientCompletionDataRow) => any): TableColumn<CMPatientCompletionDataRow>[] => {
  const cols: TableColumn<CMPatientCompletionDataRow>[] = [
    {
      name: 'Month',
      selector: (row) => row.month,
      sortable: true,
    },
    {
      name: 'Complete',
      selector: (row) => row.complete,
      sortable: true,
    },
    {
      name: 'Total',
      selector: (row) => row.total,
      sortable: true,
    },
    {
      name: 'Completion %',
      selector: (row) => row.completionPercent,
      sortable: true,
      cell: (row) => (
        <div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {row.completionPercent && parseFloat(row.completionPercent) < 100 ? (
            <span>
              <Button extraClasses="completion-grid-button" variant={ButtonVariant.INVISIBLE} onClick={() => onChangeAccessType(row)}>
                {`${Utilities.roundToTwoDecimal(parseFloat(row.completionPercent))}%`}
              </Button>
            </span>
          ) : row.completionPercent && parseFloat(row.completionPercent) >= 100 ? (
            <span>{`${Utilities.roundToTwoDecimal(parseFloat(row.completionPercent))}%`}</span>
          ) : (
            <span>{row.completionPercent}</span>
          )}
        </div>
      ),
    },
    {
      name: 'Leave Days',
      selector: (row) => row.leaveDays,
      sortable: true,
    },
  ];

  return cols;
};

const CMPatientCompletionTable = (props: CMPatientCompletionTableProps): JSX.Element => (
  <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.END} extraClasses="patient-completion-table">
    <DataTable
      dense
      persistTableHead
      sortIcon={<SortIcon />}
      customStyles={AlternatingBasicTableStyle}
      columns={columns(props.openIncompletePatients)}
      data={props.data}
      noDataComponent={NoDataComponent}
    />
  </FlexContainer>
);

export default CMPatientCompletionTable;
