import { ListFacilityProgram } from 'interfaces/dataLists/listFacilityProgram';
import { ListFacility } from 'interfaces/dataLists/listFacility';
import Facility from 'interfaces/users/facility';
import Utilities from 'api/lib/Utilities';
import DataListsProxy from 'api/dataLists/dataListsProxy';
import { FacilitySelectOption } from 'types/inputProps';
import { FacilityProgramList, ProgramEnableMap, FacilityProgramOption } from 'types/facilityUserAccount';
import { FacilityConst } from './facilityConsts';

/**
 * Several shared useful tools and functions that used on Facility User related pages.
 */
export class FacilityUserTools {
  static createFacilityProgram = (program: ListFacilityProgram): FacilityProgramList => {
    let programsList: ProgramEnableMap[] = [];

    if (program.facilityName !== FacilityConst.ALL_FACILITY_OPTION.label) {
      programsList = [
        {
          program: {
            sortOrder: program.sortOrder,
            programID: program.programID,
            programName: program.programName,
          },
          isChecked: false,
        },
      ];
    }

    const facilityProgram: FacilityProgramList = {
      facility: {
        sortOrder: program.sortOrder,
        facilityID: program.facilityID,
        facilityName: program.facilityName,
      },
      facilityChecked: false,
      programList: programsList,
    };
    return facilityProgram;
  };

  static createFacilityProgramOption = (program: ListFacilityProgram): FacilityProgramOption => {
    const facilityProgram: FacilityProgramOption = {
      label: program.facilityName,
      value: program.facilityID,
      facilityID: program.facilityID,
      options: [
        {
          value: program.programID,
          label: program.programName,
        },
      ],
    };

    return facilityProgram;
  };

  // Deprecated
  static getFacilityProgramList = (programList: ListFacilityProgram[] | null): FacilityProgramList[] => {
    const newProgramLists: FacilityProgramList[] = [];

    // Empty list found.
    if (!programList || Utilities.isEmpty(programList)) {
      return newProgramLists;
    }

    type facilityMap = {
      [facilityID: number]: {
        facilityProgram: FacilityProgramList;
        programIDs: { [programID: number]: boolean };
      };
    };
    const foundPrograms: facilityMap = {};

    for (let i = 0; i < programList.length; i += 1) {
      const program = programList[i];

      if (foundPrograms[program.facilityID] === undefined) {
        const newProgram: FacilityProgramList = this.createFacilityProgram(program);

        foundPrograms[program.facilityID] = { facilityProgram: newProgram, programIDs: {} };
        foundPrograms[program.facilityID].programIDs[program.programID] = true;
        newProgramLists.push(newProgram);
      } else if (foundPrograms[program.facilityID].programIDs[program.programID] === undefined) {
        foundPrograms[program.facilityID].programIDs[program.programID] = true;
        foundPrograms[program.facilityID].facilityProgram.programList.push({
          program: {
            sortOrder: program.sortOrder,
            programID: program.programID,
            programName: program.programName,
          },
          isChecked: false,
        });
      }
    }

    return newProgramLists;
  };

  static getFacilityProgramOptions = (programList: ListFacilityProgram[] | null): FacilityProgramOption[] => {
    const newProgramList: FacilityProgramOption[] = [];

    // Empty list found.
    if (!programList || Utilities.isEmpty(programList)) {
      return newProgramList;
    }

    type facilityMap = {
      [facilityID: number]: {
        facilityProgram: FacilityProgramOption;
        programIDs: { [programID: number]: boolean };
      };
    };

    const foundPrograms: facilityMap = {};

    for (let i = 0; i < programList.length; i += 1) {
      const program = programList[i];

      if (foundPrograms[program.facilityID] === undefined) {
        const newProgram: FacilityProgramOption = this.createFacilityProgramOption(program);
        foundPrograms[program.facilityID] = { facilityProgram: newProgram, programIDs: {} };
        foundPrograms[program.facilityID].programIDs[program.programID] = true;
        newProgramList.push(newProgram);
      } else if (foundPrograms[program.facilityID].programIDs[program.programID] === undefined) {
        foundPrograms[program.facilityID].programIDs[program.programID] = true;
        foundPrograms[program.facilityID].facilityProgram.options.push({
          value: program.programID,
          label: program.programName,
        });
      }
    }

    return newProgramList;
  };

  /**
   * Retrieve a list of facilities associated with the corporationID, sorted by alphabetical order.
   *
   * @param corporationID optional corporation ID associated with the returned facilities.
   * @returns {Promise} list of found facilities associated with the corporationID.
   */
  static getSortedUserFacilities = (corporationID?: number): Promise<ListFacility[]> => new Promise((resolve, reject) => {
    DataListsProxy.getUserFacilityList(corporationID)
      .then((foundFacilities: ListFacility[]) => {
        const sortedListFacilities: ListFacility[] = foundFacilities.sort((a: ListFacility, b: ListFacility) => {
          if (a.facilityName.toLowerCase() < b.facilityName.toLowerCase()) return -1;
          if (a.facilityName.toLowerCase() > b.facilityName.toLowerCase()) return 1;
          return 0;
        });

        resolve(sortedListFacilities);
      })
      .catch((errorResponse: any) => {
        console.log(errorResponse);
        reject(errorResponse);
      });
  });

  /**
   * Construct and return a list of Select Options for the ListFacilities.
   *
   * @param facilityList list of facilities to convert into Select Options
   * @param includeAllFacilities check to see 'All Facilities' should be included.
   * @returns a list of Facility select options.
   */
  static getListFacilityOptions = (facilityList: ListFacility[], includeAllFacilities = true): FacilitySelectOption[] => {
    const facilityOptions: FacilitySelectOption[] = includeAllFacilities ? [FacilityConst.ALL_FACILITY_OPTION] : [];
    facilityList.forEach((element: any) => {
      facilityOptions.push({
        label: element.facilityName,
        value: element.facilityID.toString(),
        isCoreMeasures: element.coreMeasures,
        hasServiceCodes: element.svcCode,
        isOutcomes: element.outcomes,
      });
    });

    return facilityOptions;
  };

  /**
   * Construct and return a list of Select Options for the Facilities.
   *
   * @param facilityList list of facilities to convert into Select Options.
   * @param isSorted sort the facility alphabetically.
   * @param includeAllFacilities check to see 'All Facilities' should be included.
   * @returns a list of Facility select options.
   */
  static getFacilityOptions = (facilityList: Facility[], isSorted = true, includeAllFacilities = true): FacilitySelectOption[] => {
    const adjustedAllFacilities: FacilitySelectOption = this.getAllFacilitiesOption(facilityList);

    const facilityOptions: FacilitySelectOption[] = includeAllFacilities ? [adjustedAllFacilities] : [];
    let facilities: Facility[] = facilityList;
    if (isSorted) {
      facilities = facilities.sort((a, b) => {
        if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
        if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
        return 0;
      });
    }

    facilities.forEach((element: any) => {
      facilityOptions.push({
        label: element.Name,
        value: element.Id.toString(),
        isCoreMeasures: element.coreMeasures,
        hasServiceCodes: element.svcCode,
        isOutcomes: element.outcomes,
      });
    });

    return facilityOptions;
  };

  /**
   * Create an "All Facilities" select option based on whether the user has core measures facilities
   * and facilities with service codes.
   *
   * @param facilityList list of facilities to iterate through.
   * @returns an "All Facilities" select option.
   */
  static getAllFacilitiesOption = (facilityList: Facility[]): FacilitySelectOption => {
    let hasCoreMeasuresFacility = false;
    let hasServiceCodes = false;
    let hasOutcomes = false;

    for (let i = 0; i < facilityList.length; i += 1) {
      if (facilityList[i].coreMeasures) {
        hasCoreMeasuresFacility = true;
      }
      if (facilityList[i].svcCode) {
        hasServiceCodes = true;
      }
      if (facilityList[i].outcomes) {
        hasOutcomes = true;
      }

      // If both conditions are true, break out of the for loop
      if (hasCoreMeasuresFacility && hasServiceCodes && hasOutcomes) {
        break;
      }
    }

    return { ...FacilityConst.ALL_FACILITY_OPTION, isCoreMeasures: hasCoreMeasuresFacility, hasServiceCodes, isOutcomes: hasOutcomes };
  };

  /**
   * Construct and retrieve a list of facility Select Options with facility name and ID.
   *
   * @param corporationID optional corporation ID associated with the returned facilities.
   * @returns {Promise} Select Option list with facility name and ID.
   */
  static getUserFacilityOptions = (corporationID?: number): Promise<FacilitySelectOption[]> => new Promise((resolve, reject) => {
    this.getSortedUserFacilities(corporationID)
      .then((facilityList: ListFacility[]) => {
        const facilityOptions: FacilitySelectOption[] = this.getListFacilityOptions(facilityList);
        resolve(facilityOptions);
      })
      .catch((errorResponse: any) => {
        console.log(errorResponse);
        reject(errorResponse);
      });
  });
}
