import * as React from 'react';

import TableauReportView from 'global_elements/TableauReportView/index.js';
import ReportsProxy from 'api/reports/reportsProxy';
import PageLayout from 'global_elements/Layouts/PageLayout';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader/index';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { ActiveFacilityContext } from 'context/activeFacility';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import { UserContext } from 'context/user';
import { Roles } from 'constants/roles';
import SingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { SelectOption } from 'types/inputProps';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import './styles.scss';
import Paragraph from 'global_elements/Text/Paragraph';

const ERROR_MESSAGE = 'An error occurred while attempting to load Tableau. Please refresh the page to try again.';
const ERROR_MESSAGE_401 = 'An error occurred while attempting to load the report. Please contact your Administrator.';

const ReportsPage = (): JSX.Element => {
  const { facility } = React.useContext(ActiveFacilityContext);
  const { user } = React.useContext(UserContext);
  const [selectedFacilityId, setSelectedFacilityId] = React.useState<number>(-1);
  const [showSelectFacility, setShowSelectFacility] = React.useState<boolean>(false);
  const [canSelectReport, setCanSelectReport] = React.useState<boolean>(false);
  const [isLoadingReportOptions, setIsLoadingReportOptions] = React.useState<boolean>(false);
  const [reportOptions, setReportOptions] = React.useState<SelectOption[]>([]);
  const [selectedReportOption, setSelectedReportOption] = React.useState<SelectOption | null>(null);
  const [isLoadingReport, setIsLoadingReport] = React.useState<boolean>(false);
  const [report, setReport] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    const facilityId = parseInt(facility.value, 10);
    setSelectedFacilityId(facilityId);
    const canSelectAllFacilities = user && (user.role === Roles.ADMIN || user.role === Roles.SUPER_ADMIN || user.role === Roles.CORPORATION || user.role === Roles.SUPER_CORPORATION);
    if ((facilityId === 0 || Number.isNaN(facilityId)) && !canSelectAllFacilities) {
      setShowSelectFacility(true);
      return;
    }
    setShowSelectFacility(false);
  }, [facility, user]);

  React.useEffect(() => {
    const allow = !!user && (user.role === Roles.FACILITY_DIRECTOR || user.role === Roles.FACILITY_EXECUTIVE || user.role === Roles.CORPORATION || user.role === Roles.SUPER_CORPORATION);
    setCanSelectReport(allow);
  }, [user]);

  React.useEffect(() => {
    setIsLoading(false);
    if (!canSelectReport || selectedFacilityId === -1 || Number.isNaN(selectedFacilityId)) {
      return;
    }
    setIsLoadingReportOptions(true);
    ReportsProxy.getTypes(selectedFacilityId).then((response) => {
      const options = response.map((o) => ({
        label: o.reportType,
        value: o.reportTypeID.toString(),
      }));
      setReportOptions(options);
      setSelectedReportOption(options[0]);
      setIsLoadingReportOptions(false);
    });
  }, [selectedFacilityId, canSelectReport]);

  React.useEffect(() => {
    setIsLoading(false);
    if (selectedFacilityId === -1 || Number.isNaN(selectedFacilityId) || (canSelectReport && selectedReportOption == null)) {
      return;
    }
    setIsLoadingReport(true);
    const reportType = canSelectReport && selectedReportOption !== null ? Number(selectedReportOption.value) : null;
    ReportsProxy.getReport(selectedFacilityId, reportType)
      .then((reportData) => {
        if (reportData !== null && reportData !== '') {
          setReport(reportData);
          setErrorMessage('');
        } else {
          setErrorMessage(ERROR_MESSAGE);
        }
        setIsLoadingReport(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 401) {
          setErrorMessage(ERROR_MESSAGE_401);
        } else {
          setErrorMessage(ERROR_MESSAGE);
        }

        setIsLoadingReport(false);
      });
  }, [selectedFacilityId, selectedReportOption]);

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Reports Page">
      <div>
        <PrimaryHeader text="Reports" fontColor={FontColors.PRIMARY} />
        {(isLoading || isLoadingReportOptions) && <Paragraph text="Loading..." fontColor={FontColors.PRIMARY} fontSize={FontSizes.REGULAR} />}
        {showSelectFacility && <TertiaryHeader text="Please select a facility" fontColor={FontColors.HIGH_PRIORITY} />}
        {!showSelectFacility && canSelectReport && reportOptions.length > 0 && !isLoadingReportOptions && (
          <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="reports-page__report-type-dropdown-container">
            <SingleSelect
              testid="report-type-dropdown"
              styles={SingleStandardDropdownStyles}
              defaultValue={reportOptions[0]}
              value={selectedReportOption === null ? undefined : selectedReportOption}
              onSelection={(option) => setSelectedReportOption(option)}
              options={reportOptions}
            />
          </FlexContainer>
        )}
        {!showSelectFacility && errorMessage !== '' && <Paragraph text={errorMessage} fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.REGULAR} />}
        {!showSelectFacility && errorMessage === '' && isLoadingReport && <Paragraph text="Loading..." fontColor={FontColors.PRIMARY} fontSize={FontSizes.REGULAR} />}
        {!showSelectFacility && errorMessage === '' && !isLoadingReport && report && <TableauReportView url={report} />}
      </div>
    </PageLayout>
  );
};

export default ReportsPage;
