import { UHSDomainList } from 'constants/uhsdomain';

export const validEmail = (email: string): boolean => (
  new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
);

export const inputError = (email: string): string | undefined => {
  if (email !== '' && !validEmail(email)) {
    return 'Invalid Email';
  }

  return undefined;
};

/**
 * Validates and checks if the email matches the uhsinc domain.
 *
 * @param email email to validate and check to see if the domain falls under the listed uhsinc domain.
 * @returns true if the email matches the uhsinc.com or horizonhealth.com domain.
 */
export const isUHSEmail = (email: string): boolean => {
  if (email && validEmail(email)) {
    for (let index = 0; index < UHSDomainList.length; index += 1) {
      const domain = UHSDomainList[index];
      if (email.toLowerCase().endsWith(`@${domain}`)) {
        return true;
      }
    }
  }

  return false;
};
