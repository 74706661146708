import React, { useEffect, useState, useContext, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.scss';

import DataListsProxy from 'api/dataLists/dataListsProxy';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import Paragraph from 'global_elements/Text/Paragraph';
import Button from 'global_elements/Button';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';
import PatientAccountsTable from 'global_elements/Layouts/DataTables/PatientAccountsTable';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { AllRoutes } from 'constants/routes';
import { PatientDataRow } from 'types/tableProps';
import { PatientSearchState } from 'types/patientAccount';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { FacilitySelectOption, SelectOption } from 'types/inputProps';
import { UserContext } from 'context/user';
import { ActiveFacilityContext } from 'context/activeFacility';
import { PatientSearchCriteria } from 'interfaces/patients/patientSearchResult';
import Utilities from 'api/lib/Utilities';

const PatientAccountsPage = (): JSX.Element => {
  const { user, patientSearchData, SetPatientSearchData } = useContext(UserContext);
  const [searchState, setSearchState] = useState<PatientSearchState>(
    patientSearchData?.query || {
      firstName: '',
      lastName: '',
      accountEmail: '',
      accountNumber: '',
      medicalRecordNumber: '',
      executed: false,
      facility: undefined,
      currentPage: 1,
    },
  );
  const [patientData, setPatientData] = useState<PatientDataRow[]>(patientSearchData?.data ?? []);
  const [availableFacilities, setAvailableFacilities] = useState<SelectOption[]>(patientSearchData?.availableFacilities ?? []);
  const { facility } = useContext(ActiveFacilityContext);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const contextFacilityFiltered = facility.value !== '' ? facility : undefined;
  const isAdmin = user?.role === 'admin' || user?.role === 'super-admin';

  const history = useHistory();
  const inputRef = React.useRef<any>();

  useEffect(() => {
    if (!isAdmin && contextFacilityFiltered !== searchState.facility) {
      setPatientData([]);
      inputRef.current?.focus();
      setSearchState((prevState) => ({
        ...prevState,
        executed: false,
        facility: contextFacilityFiltered,
      }));
    }
  }, [contextFacilityFiltered]);

  useEffect(() => {
    if (searchState.facility?.value && !searchState.facility?.isOutcomes) {
      setErrorMessage('Outcomes features are not available for this facility.');
    } else {
      setErrorMessage('');
    }
  }, [searchState.facility]);

  useEffect(() => {
    if (searchState.executed) {
      Utilities.setCellTitles();
    }
  }, [searchState]);

  useEffect(() => {
    if (searchState.executed) {
      SetPatientSearchData({
        query: searchState,
        data: patientData,
        availableFacilities,
      });
    } else {
      SetPatientSearchData(null);
    }
  }, [patientData, searchState]);

  useEffect(() => {
    if (patientData.length > 0) {
      Utilities.setCellTitles();
    }
  }, [patientData]);

  useEffect(() => {
    DataListsProxy.getAllUserFacilities(
      (response) => {
        if (response?.data) {
          const facilityOptions: FacilitySelectOption[] = [];
          if (isAdmin) {
            facilityOptions.push({
              label: 'All Facilities',
              value: '0',
              isCoreMeasures: true,
              isOutcomes: true,
            });
          }

          response.data
            .sort((a, b) => {
              if (a.facilityName.toLowerCase() < b.facilityName.toLowerCase()) {
                return -1;
              }

              if (a.facilityName.toLowerCase() > b.facilityName.toLowerCase()) {
                return 1;
              }

              return 0;
            })
            .forEach((element) => {
              if (element.facilityName && element.facilityID) {
                facilityOptions.push({
                  label: element.facilityName,
                  value: element.facilityID.toString(),
                  isCoreMeasures: !!element.coreMeasures,
                  isOutcomes: !!element.outcomes,
                });
              }
            });

          setAvailableFacilities(facilityOptions);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, []);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
      executed: false,
    }));
  };

  const handleSelectChange = (option: SelectOption): void => {
    setPatientData([]);
    setSearchState((prevState) => ({
      ...prevState,
      facility: option,
      executed: false,
    }));
  };

  const addPatient = (): void => {
    if (searchState.facility) {
      history.push(`${AllRoutes.PATIENT_REGISTRATION}/${searchState.facility.value}`);
    } else {
      history.push(`${AllRoutes.PATIENT_REGISTRATION}/${facility.value}`);
    }
  };

  const goToPatientDetails = (row: PatientDataRow, e: React.MouseEvent<Element, MouseEvent>): void => {
    e.preventDefault();
    history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${row.patientID}`);
  };

  // ****clear data
  const clearSearchCriteria = (): void => {
    setPatientData([]);
    setSearchState((prevState) => ({
      ...prevState,
      firstName: '',
      lastName: '',
      accountEmail: '',
      accountNumber: '',
      medicalRecordNumber: '',
      facility: undefined,
      executed: false,
    }));
  };

  const disableClearButton = (): boolean => {
    if (
      searchState.firstName === ''
      && searchState.lastName === ''
      && searchState.accountEmail === ''
      && searchState.accountNumber === ''
      && searchState.medicalRecordNumber === ''
      && (!isAdmin || !searchState.facility)
    ) {
      return true;
    }

    return false;
  };

  const disableSearchButton = (): boolean => {
    if (errorMessage || ((facility.value === '' || facility.value === '0') && !searchState.facility)) {
      return true;
    }

    return false;
  };

  const search = (): void => {
    setSearchState((prevState) => ({
      ...prevState,
      executed: true,
    }));
    // ***added dischargeDate 2/2024
    setPatientData([
      {
        name: 'Searching...',
        accountNumber: '',
        medicalRecord: '',
        admissionDate: '',
        dischargeDate: '',
        program: '',
        unit: '',
        provider: '',
      } as PatientDataRow,
    ]);

    const searchCriteria: PatientSearchCriteria = {
      fid: searchState.facility ? parseInt(searchState.facility.value, 10) : parseInt(facility.value, 10),
      fname: searchState.firstName.trim(),
      lname: searchState.lastName.trim(),
      email: searchState.accountEmail.trim(),
      acctnum: searchState.accountNumber.trim(),
      medrecnum: searchState.medicalRecordNumber.trim(),
    };

    PatientUsersProxy.Search(
      searchCriteria,
      (response) => {
        if (response.data) {
          const patientList: PatientDataRow[] = [];

          for (let i = 0; i < response.data.length; i += 1) {
            const userData = response.data[i];

            if (userData) {
              patientList.push({
                patientID: userData.patientID,
                name: userData.patientName,
                accountEmail: userData.accountEmail,
                accountNumber: userData.accountNumber?.toString(),
                medicalRecord: userData.medicalRecordNumber,
                admissionDate: userData.dateAdmitted,
                dischargeDate: userData.dateDischarged,
                program: userData.programName,
                unit: userData.unitCode,
                provider: userData.providerName,
              } as PatientDataRow);
            }
          }
          setPatientData(patientList);
        }
      },
      (errorResponse) => {
        console.error(errorResponse);
        setPatientData([]);
      },
    );
  };

  const onChangePage = (page: number): void => {
    Utilities.setCellTitles();

    setSearchState((prevState) => ({
      ...prevState,
      currentPage: page,
    }));
  };

  // ***Patient Accounts / Patient Search Page
  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Patient Search Page">
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-search-content">
        <form onSubmit={search}>
          <PrimaryHeader text="Patient Search" fontColor={FontColors.PRIMARY} marginTopPx={16} marginBottomPx={16} />
          {isAdmin && availableFacilities.length === 0 && (
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Facility"
              options={[{ label: 'Loading...', value: '' }]}
              defaultValue={{ label: 'Loading...', value: '' }}
              value={{ label: 'Loading...', value: '' }}
              onSelection={handleSelectChange}
            />
          )}
          {isAdmin && availableFacilities.length > 0 && (
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Facility"
              options={availableFacilities}
              defaultValue={searchState.facility ?? { label: 'Select a Facility', value: '' }}
              value={searchState.facility}
              onSelection={handleSelectChange}
            />
          )}
          {!isAdmin && (facility.value === '' || facility.value === '0') && (
            <Paragraph text="Please select a facility in the header to search" fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.EXTRA_LARGE} />
          )}
          {errorMessage ? (
            <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.CENTER}>
              <InlineText text={errorMessage} fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.REGULAR} />
            </FlexContainer>
          ) : (
            <>
              <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-search-content__text-inputs">
                <LabledTextInput
                  label="First Name"
                  placeholder="Search By First Name..."
                  name="firstName"
                  value={searchState.firstName}
                  onChange={handleInputChange}
                  type="text"
                  variant={TextInputVariant.PRIMARY}
                  inputRef={inputRef}
                  autoFocus
                />
                <LabledTextInput
                  label="Last Name"
                  placeholder="Search By Last Name..."
                  name="lastName"
                  value={searchState.lastName}
                  onChange={handleInputChange}
                  type="text"
                  variant={TextInputVariant.PRIMARY}
                />
                <LabledTextInput
                  label="Account Number"
                  placeholder="Search By Account Number..."
                  name="accountNumber"
                  value={searchState.accountNumber}
                  onChange={handleInputChange}
                  type="text"
                  variant={TextInputVariant.PRIMARY}
                />
                <LabledTextInput
                  label="Medical Record"
                  placeholder="Search By Medical Record..."
                  name="medicalRecordNumber"
                  value={searchState.medicalRecordNumber}
                  onChange={handleInputChange}
                  type="text"
                  variant={TextInputVariant.PRIMARY}
                />
              </FlexContainer>
              {isAdmin && (
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-search-content__text-inputs">
                  <LabledTextInput
                    label="Account Email"
                    placeholder="Search By Account Email..."
                    name="accountEmail"
                    value={searchState.accountEmail}
                    onChange={handleInputChange}
                    type="text"
                    variant={TextInputVariant.PRIMARY}
                  />
                </FlexContainer>
              )}
              <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.END} align={AlignVariant.END} extraClasses="button-container__search">
                <Button variant={ButtonVariant.SECONDARY} onClick={clearSearchCriteria} disabled={disableClearButton()}>
                  <InlineText text="Clear" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                </Button>
                <Button variant={ButtonVariant.PRIMARY} onClick={search} disabled={disableSearchButton()} submit>
                  <InlineText text="Search" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
                </Button>
              </FlexContainer>
            </>
          )}
        </form>

        {(patientData.length > 0 || searchState.executed) && (
          <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="card-primary">
            <PatientAccountsTable data={patientData} onRowClicked={goToPatientDetails} onChangePage={onChangePage} defaultPage={searchState.currentPage} />
          </FlexContainer>
        )}
        <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="button-container__add-account">
          <Button variant={ButtonVariant.PRIMARY} onClick={addPatient} disabled={!searchState.executed}>
            <InlineText text="Add Patient" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
        </FlexContainer>
      </FlexContainer>
    </PageLayout>
  );
};

export default PatientAccountsPage;
