import { HttpStatus } from 'constants/httpStatus';

class HttpStatusConverter {
  static intToHttpStatus(status: number | null): HttpStatus {
    switch (status) {
      case 200:
        return HttpStatus.OK;
      case 201:
        return HttpStatus.CREATED;
      case 400:
        return HttpStatus.BADREQUEST;
      case 401:
        return HttpStatus.UNAUTHORIZED;
      case 404:
        return HttpStatus.NOTFOUND;
      case 409:
        return HttpStatus.CONFLICT;
      case 500:
        return HttpStatus.SERVERERROR;
      case null:
        return HttpStatus.REQUEST_ERROR;
      default:
        return HttpStatus.BADREQUEST;
    }
  }
}

export default HttpStatusConverter;
