import { SelectOption } from './inputProps';
import { FacilityUserDataRow } from './tableProps';
import { ListProgram } from '../interfaces/dataLists/listFacilityProgram';
import { ListFacility } from '../interfaces/dataLists/listFacility';

export type ProgramEnableMap = {
  program: ListProgram;
  isChecked: boolean;
};

export type FacilityProgramList = {
  facility: ListFacility;
  facilityChecked: boolean;
  programList: ProgramEnableMap[];
};

export type corporateFacilityList = {
  corporationID: number;
  facilityIDs: number[];
};

export type corporateFacilityOptionsMap = {
  [corporateID: number]: SelectOption[];
};

export type ProgramFacilityIDMap = {
  [programID: number]: number;
};

export type ProgramOptions = {
  value: number;
  label: string;
};

export type FacilityProgramOption = {
  label: string;
  value: number;
  facilityID: number;
  options: ProgramOptions[];
};

export type FacilityUserAccountState = {
  statusOptions: SelectOption[];
  roleOptions: SelectOption[];
  accessTypeOptions: SelectOption[];
  corporateFacilitiesIDMap: corporateFacilityList[];
  availableCorporations: SelectOption[];
  firstName: string;
  firstNameError: boolean;
  lastName: string;
  lastNameError: boolean;
  email: string;
  emailError: boolean;
  emailConfirm: string;
  emailConfirmError: boolean;
  emailIsOpen: boolean;
  emailDomainError: boolean;
  userRole: SelectOption | null;
  userRoleError: boolean;
  accessType: SelectOption | null;
  selectedAccessType: SelectOption | null;
  corporation: SelectOption | null;
  accountStatus: SelectOption;
  loading?: boolean;
  saveError?: boolean;
  saveErrorMessage?: string;
  hasLoggedIn?: boolean;
  showAccessTypeSelectionWindow: boolean | false;
  facilityProgramLists: FacilityProgramOption[];
  filteredFacilityProgramList: FacilityProgramOption[];
  selectedProgramIDs: number[];
  programToFacilitiesIDMap: ProgramFacilityIDMap;

  accountId: string;
};

export type FacilityUserSearchState = {
  availableCorporations: SelectOption[];
  availableFacilities: SelectOption[];
  firstName: string;
  lastName: string;
  email: string;
  corporation: SelectOption | null;
  facility: SelectOption | null;
  role: SelectOption;
  roleOptions: SelectOption[];
  data: FacilityUserDataRow[];
  firstSearch: boolean;
  currentPage: number;
  accessType: SelectOption | null;

  showAccessTypeSelectionWindow: boolean | false;
  accessTypeSelectionWindowAccountId: number;
  accessTypeSelectionWindowAccessTypeOptions: SelectOption[];
  accessTypeSelectionWindowSelectedAccessType: SelectOption | null;
};

export enum AccessType {
  ALL_ACCESS,
  OUTCOMES,
  CORE_MEASURES,
}
