import * as React from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as PhoneInput from 'global_elements/Inputs/TextInput/lib/phoneNumber';

import './styles.scss';

import DataListsProxy, { DataListType } from 'api/dataLists/dataListsProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Button from 'global_elements/Button';
import InlineText from 'global_elements/Text/InlineText';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabeledDateInput from 'global_elements/Inputs/LabeledDateInput';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';

import ApiResponse from 'api/lib/models/ApiResponse';
import UserAccount from 'interfaces/users/userAccount';
import { PatientData } from 'interfaces/patients/patientData';
import MHODateTime from 'domain/dateTime/MHODateTime';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';
import UserProxy from 'api/user/userProxy';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { SelectOption } from 'types/inputProps';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { UserContext } from 'context/user';
import { PatientRoutes } from 'constants/routes';
import { CreateAccountRequest } from 'interfaces/facilityUsers/createAccountRequest';
import UserPatientRelationship from 'interfaces/users/userPatientRelationship';

type AccountInfoObject = {
    patientName: string;
    patientDOB: string;
    accountEmail: string;
    accountFirstName: string;
    accountLastName: string;
    relationship: SelectOption;
    phone: string;
    EmailNotify: boolean;
    TextNotify: boolean;
}

const FirstTimeAccountSetupPage = (): JSX.Element => {
  const history = useHistory();
  const { Logout } = React.useContext(UserContext);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [patientData, setPatientData] = React.useState<PatientData>();
  const [accountData, setAccountData] = React.useState<UserAccount>();
  const { user } = useContext(UserContext);
  const [relationshipOptions, setRelationshipOptions] = React.useState<SelectOption[]>([]);
  const [userPatientRelationship, setUserPatientRelationship] = React.useState<UserPatientRelationship>({
    accountEmail: '',
    accountID: 0,
    accountPatientID: 0,
    careID: 0,
    patientID: 0,
    statusID: 0,
    relationship: 'Select a Relationship',
    relationshipID: 0,
  });
  const [registrationInfo, setRegistrationInfo] = React.useState<AccountInfoObject>({
    patientName: 'Doe, John',
    patientDOB: '01/01/2000',
    accountEmail: 'john.doe@email.test',
    accountFirstName: '',
    accountLastName: '',
    relationship: { label: 'Select a Relationship', value: '' },
    phone: '',
    EmailNotify: true,
    TextNotify: false,
  });

  const getPatientData = (patientID: number): void => {
    PatientUsersProxy.getPatient(
      patientID,
      (response) => {
        if (response.data && response.data.length) {
          const loadedPatientData: PatientData = response.data[0];
          const formattedDOB: string = new MHODateTime(response.data[0].dateOfBirth).getFormattedCalendarDate();
          const formattedDA: string = loadedPatientData.dateAdmitted && new MHODateTime(response.data[0].dateAdmitted).getFormattedCalendarDate();
          loadedPatientData.dateOfBirth = formattedDOB;
          loadedPatientData.dateAdmitted = formattedDA;
          setPatientData(loadedPatientData);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  };

  React.useEffect(() => {
    const userAccountIdStr = user ? user.accountId : '-1';
    const userAccountId = parseInt(userAccountIdStr, 10);

    UserProxy.getUserAccountInfo(
      userAccountId,
      (accountResponse: ApiResponse<UserAccount[]>) => {
        if (accountResponse.data?.length !== 1) return;
        const userAccount: UserAccount = accountResponse.data[0];
        setAccountData(accountResponse.data[0]);
        setRegistrationInfo({
          patientName: '',
          patientDOB: '',
          accountEmail: `${userAccount.accountEmail}`,
          accountFirstName: '',
          accountLastName: '',
          relationship: { label: 'Select a Relationship', value: '' },
          phone: '',
          EmailNotify: true,
          TextNotify: false,
        });
        UserProxy.getUserPatientRelationshipInfo(
          userAccount.accountID,
          userAccount.patientID,
          (resp: ApiResponse<UserPatientRelationship[]>) => {
            console.log(resp);
            if (resp.data && resp.data.length) {
              const option = resp.data[0];
              setUserPatientRelationship(option);
            }
          },
          (err) => {
            console.log(err);
          },
        );
        getPatientData(userAccount.patientID);
      },
      (error) => console.log(error),
    );
  }, []);

  React.useEffect(() => {
    DataListsProxy.getOptionList(
      DataListType.Relationship,
      (response) => {
        const newRelationshipOptions: SelectOption[] = [];

        if (response.data) {
          response.data.forEach((option) => {
            newRelationshipOptions.push({
              label: option.lookupDesc,
              value: option.lookupID.toString(),
            });
          });
        }

        setRelationshipOptions(newRelationshipOptions);
        setLoading(false);
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name !== 'phone') {
      setRegistrationInfo((prevState) => (
        { ...prevState, [e.target.name]: e.target.value }
      ));
    } else if (PhoneInput.validPhoneInput(e)) {
      const maskedInput = PhoneInput.maskInputAsPhoneNumber(e);

      if (maskedInput !== undefined) {
        setRegistrationInfo((prevState) => (
          { ...prevState, [e.target.name]: maskedInput }
        ));
      }
    }
  };

  const handleDropdownChange = (target: keyof AccountInfoObject, value: string | SelectOption): void => {
    setRegistrationInfo((prevState) => (
      { ...prevState, [target]: value }
    ));
  };

  const handleRelationshipSelect = (option: SelectOption): void => {
    handleDropdownChange('relationship', option);
  };

  const invalidRegistrationData = (): boolean => (
    registrationInfo.accountFirstName === ''
      || registrationInfo.accountLastName === ''
      || registrationInfo.relationship.value === ''
      || !!PhoneInput.invalidInput(registrationInfo.phone)
  );

  const disableRegistration = (): boolean => (
    invalidRegistrationData()
  );

  const getUpdatedAccountInfo = (): CreateAccountRequest => {
    const acct = accountData!;
    return {
      AccountEmail: acct.accountEmail,
      AccountID: acct.accountID ?? null,
      UserRoleID: acct.userRoleID,
      FirstName: registrationInfo.accountFirstName,
      LastName: registrationInfo.accountLastName,
      StatusID: acct.statusID,
      UpdatedBy: acct.accountEmail,
      Phone: registrationInfo.phone,
      RelationshipID: parseInt(registrationInfo.relationship.value, 10),
      EmailNotify: true,
      TextNotify: !!registrationInfo.phone,
      EmailSentDate: new MHODateTime(acct.emailSentDate || new Date()).getDateTimeForPost(),
      LastLoginDate: new MHODateTime(acct.lastLoginDate || new Date()).getDateTimeForPost(),
    };
  };

  const saveUserPatientRelationship = (): void => {
    UserProxy.saveUserPatientRelationshipInfo(
      [{
        accountID: userPatientRelationship?.accountID,
        accountPatientID: userPatientRelationship?.accountPatientID,
        careID: userPatientRelationship?.careID,
        patientID: userPatientRelationship?.patientID,
        relationshipID: parseInt(registrationInfo.relationship.value, 10),
        statusID: userPatientRelationship?.statusID,
        UpdatedBy: user?.email,
      }],
      (resp) => console.log(resp),
      (e) => console.log(e),
    );
  };

  const submitAccountInfo = (): void => {
    UserProxy.postUserAccountInfo(
      [getUpdatedAccountInfo()],
      () => {
        saveUserPatientRelationship();
        history.push(PatientRoutes.DASHBOARD);
      },
      (resp) => console.log('Error', resp),
    );
  };

  const getPatientNameFormatted = (): string => (
    patientData
      ? `${patientData.patientLastName}, ${patientData.patientFirstName}`
      : ''
  );

  return (
    <PageLayout
      layout={PageLayoutVariant.LOGGED_OUT}
      testText="First Time Account Setup Page"
      loadingTitle={loading ? 'First-Time Account Setup' : undefined}
      loadingText={loading ? 'Loading...' : undefined}
      hideHeader
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="first-time-account-setup"
      >
        <SecondaryHeader text="Patient Information" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="first-time-account-setup__patient-info"
        >
          <LabledTextInput
            variant={TextInputVariant.READONLY}
            label="Patient Name"
            type="text"
            name="patientName"
            value={getPatientNameFormatted()}
          />
          <LabeledDateInput
            variant={TextInputVariant.READONLY}
            label="Patient Date of Birth"
            type="text"
            name="patientDOB"
            value={patientData?.dateOfBirth}
          />
          <LabledTextInput
            variant={TextInputVariant.READONLY}
            label="Patient Email"
            type="email"
            name="accountEmail"
            value={registrationInfo.accountEmail}
          />
        </FlexContainer>
        <SecondaryHeader text="Account Set-up" fontColor={FontColors.PRIMARY} marginBottomPx={16} marginTopPx={32} />
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="first-time-account-setup__account-info"
        >
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.SPACE_BETWEEN}
            extraClasses="first-time-account-setup__account-info__first-row"
          >
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="First Name"
              type="text"
              name="accountFirstName"
              value={registrationInfo.accountFirstName}
              onChange={handleInputChange}
            />
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Last Name"
              type="text"
              name="accountLastName"
              value={registrationInfo.accountLastName}
              onChange={handleInputChange}
            />
          </FlexContainer>
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.SPACE_BETWEEN}
            extraClasses="first-time-account-setup__account-info__second-row"
          >
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Relationship to Patient"
              options={relationshipOptions}
              defaultValue={registrationInfo.relationship}
              value={registrationInfo.relationship}
              onSelection={handleRelationshipSelect}
            />
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Phone (Optional)"
              type="text"
              name="phone"
              value={registrationInfo.phone}
              onChange={handleInputChange}
              error={PhoneInput.invalidInput(registrationInfo.phone)}
            />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="first-time-account-setup__buttons"
        >
          <Button
            variant={ButtonVariant.SECONDARY}
            onClick={Logout}
          >
            <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={submitAccountInfo}
            disabled={disableRegistration()}
          >
            <InlineText text="Submit" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
        </FlexContainer>
      </FlexContainer>
    </PageLayout>
  );
};

export default FirstTimeAccountSetupPage;
