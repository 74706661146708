import * as React from 'react';

import '../styles.scss';

import { HiddenTextProps } from 'types/textProps';

const HiddenTestText = (props: HiddenTextProps): JSX.Element => (
  <span className="hidden-test-text">
    {props.text}
  </span>
);

export default React.memo(HiddenTestText);
