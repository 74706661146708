import * as React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { ReactComponent as SortIcon } from 'icons/mho-sort-icon-white.svg';

import { AssessmentEditorRow, AssessmentEditorTableProps } from 'types/tableProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';

import { HoverTableStyles } from '../styles';
import '../styles.scss';

const NoDataComponent: JSX.Element = (
  <InlineText
    text="No Results Found."
    fontColor={FontColors.PRIMARY}
    fontSize={FontSizes.LARGE}
  />
);

const columns: TableColumn<AssessmentEditorRow>[] = [
  {
    name: 'Number',
    selector: (row) => row.assessmentNumber,
    sortable: true,
    grow: 0,
    width: '120px',
  },
  {
    name: 'Name',
    selector: (row) => row.assessmentName,
    sortable: true,
    maxWidth: '35%',
    grow: 2,
    wrap: true,
  },
  {
    name: 'Description',
    selector: (row) => row.assessmentDescription,
    sortable: true,
    width: 'auto',
    wrap: true,
  },
];

const AssessmentEditorTable = (props: AssessmentEditorTableProps): JSX.Element => (
  <FlexContainer
    display={DisplayVariant.FLEX_COL}
    justify={JustifyVariant.CENTER}
    align={AlignVariant.END}
    extraClasses="assessment-editor-table"
  >
    <DataTable
      dense
      highlightOnHover
      persistTableHead
      pagination
      sortIcon={<SortIcon />}
      customStyles={HoverTableStyles}
      columns={columns}
      data={props.data}
      noDataComponent={NoDataComponent}
      onRowClicked={props.onRowClicked}
    />
  </FlexContainer>
);

export default AssessmentEditorTable;
