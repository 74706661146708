import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataListsProxy from 'api/dataLists/dataListsProxy';

import './styles.scss';

type FileDownloadProp = {
  filename: string
}

/**
 * Retrieves the PDF file from the API/DB and inserts it into an IFrame to display.
 *
 * @returns iframe component with the pdf file data loaded.
 */
export const PDFFileDownload = (): JSX.Element => {
  const { filename } = useParams<FileDownloadProp>();
  const [pdfFileData, setPDFFileData] = useState<any>();

  /**
   * Retrieve the pdf file from the api/db then convert into a base64 to load.
   */
  useEffect(() => {
    const filehash = filename;
    DataListsProxy.getDownloadPDFForm(filehash).then((response) => {
      if (response.data) {
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setPDFFileData(base64String);
        };
      }
    }).catch((error) => {
      console.log(error);
    });
  }, [filename]);

  return (
    <iframe title={filename} src={pdfFileData} className="pdf-frame" />
  );
};
