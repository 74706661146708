import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import moment from 'moment';

import { ContextProviderProps } from 'types/contextProviderProps';
import { UserInterface } from 'interfaces/userInterface';
import { UserContext } from 'context/user';
import UserAccount from 'interfaces/users/userAccount';
import { StorageConstants } from 'constants/storageConstants';
import Utilities from 'api/lib/Utilities';
import { SavedPatientSearchData } from 'types/patientAccount';
import { SavedCoreMeasurePatientSearchData } from 'types/CoreMeasurePatient';
import { FacilityUserSearchState } from 'types/facilityUserAccount';

const UserProvider = (props: ContextProviderProps): JSX.Element => {
  const [events] = useState(['click', 'load', 'scroll', 'keypress', 'keydown']);
  const [currentUser, setCurrentUser] = useState<UserInterface | null>(null);
  const [currentAccount, setCurrentAccount] = useState<UserAccount| null>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [showSystemAlerts, setShowSystemAlerts] = useState<boolean>(true);
  const [patientReflectionsConsentAccepted, setPatientReflectionsConsentAccepted] = useState<boolean>(false);
  const [patientHipaaConsentAccepted, setPatientHipaaConsentAccepted] = useState<boolean>(false);
  const [activeGroupTherapySessionId, setActiveGroupTherapySessionId] = useState<string | null>(
    sessionStorage.getItem(StorageConstants.ActiveGroupTherapySessionId),
  );
  const [activeGroupTherapyPatientId, setActiveGroupTherapyPatientId] = useState<string | null>(
    sessionStorage.getItem(StorageConstants.ActiveGroupTherapyPatientId),
  );
  const { instance } = useMsal();
  const storage = sessionStorage;

  const currentUserRef = useRef<UserInterface | null>();
  const checkTimerIntervalRef = useRef<any>();
  const isAuthenticatedRef = useRef<boolean>(false);
  isAuthenticatedRef.current = useIsAuthenticated();

  const [triggerLogout, setTriggerLogout] = useState<boolean>(false);

  const [patientSearchData, setPatientSearchData] = useState<SavedPatientSearchData | null>(null);
  const [coreMeasuresPatientSearchData, setCoreMeasuresPatientSearchData] = useState<SavedCoreMeasurePatientSearchData | null>(null);
  const [facilityUserSearchQuery, setFacilityUserSearchQuery] = useState<FacilityUserSearchState | null>(null);

  const LOGOUT_TIME_LIMIT = 20; // 20 minutes
  const WARNING_TIME_LIMIT = 15; // 15 minutes

  /**
   * App logout callback.
   */
  const azureLogout = (): void => {
    setTriggerLogout(true);
    // setCurrentUser(null);
    // clearTimeout(checkTimerIntervalRef.current);
    // storage.removeItem(StorageConstants.Timestamp);
    // storage.removeItem(StorageConstants.MsalExpiration);
  };

  /**
   * Check the timer to see if it exceeded the warning/logout timer.
   */
  const checkForExpirationTime = (): void => {
    const timestampStr = storage.getItem(StorageConstants.Timestamp);
    const exp = storage.getItem(StorageConstants.MsalExpiration);
    if (timestampStr && exp) {
      const timestamp = Number.parseInt(timestampStr, 10);
      const diff = moment.duration(moment().diff(moment(timestamp)));
      const expTimestamp = new Date(0).setUTCSeconds(Number.parseInt(exp, 10));

      if (timestamp >= expTimestamp) {
        azureLogout();
      }

      if (diff.minutes() >= LOGOUT_TIME_LIMIT) {
        azureLogout();
      } else if (diff.minutes() >= WARNING_TIME_LIMIT) {
        setShowPopup(true);
      }
    }
  };

  /**
   * When one of the listed event is triggered, update the last activity time stamp and reset
   * time timeout timers.
   */
  const resetTimer = useCallback(() => {
    if (isAuthenticatedRef.current) {
      storage.setItem(StorageConstants.Timestamp, String(moment().valueOf()));
      setShowPopup(false);

      if (currentUser) {
        checkForExpirationTime();
      }
    } else {
      clearTimeout(checkTimerIntervalRef.current);
      storage.removeItem(StorageConstants.Timestamp);
    }
  }, [isAuthenticatedRef.current]);

  /**
   * Login event initialization.
   */
  const setupLoginEvent = (newUser: UserInterface): void => {
    setCurrentUser(newUser);
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    // Check if the previous timestamp already exists.  If it exists, check to see if it's expired.
    if (storage.getItem(StorageConstants.Timestamp)) {
      checkForExpirationTime();
    } else {
      storage.setItem(StorageConstants.Timestamp, String(moment().valueOf()));
    }

    checkTimerIntervalRef.current = setTimeout(function timeoutFnc() {
      checkForExpirationTime();
      checkTimerIntervalRef.current = setTimeout(timeoutFnc, 5000);
    }, 5000);
  };

  const DismissAlerts = (): void => {
    setShowSystemAlerts(false);
  };

  useEffect(() => {
    // If the user auth is set, but then current user is cleared, then it's in the process of logging out.
    const userLogoutAuth = storage.getItem(StorageConstants.UserAuthority);

    // if (triggerLogout && userLogoutAuth && currentUser === null) {
    if (triggerLogout && userLogoutAuth) {
      const logoutRequest: any = {
        onRedirectNavigate: () => {
          storage.clear();
          setCurrentUser(null);
          clearTimeout(checkTimerIntervalRef.current);
          storage.removeItem(StorageConstants.Timestamp);
          storage.removeItem(StorageConstants.MsalExpiration);
          return true;
        },
      };

      const msalHomeAccountID = storage.getItem(StorageConstants.MsalHomeAccountID) || '';
      const userAccount = instance.getAccountByHomeId(msalHomeAccountID);
      const appProvider = storage.getItem(StorageConstants.MsalAppProvider);

      // AD logout differs from B2C logout.  Checking the appProvider seems like the best choice, unless it's proven
      // to be unreliable.
      if (userAccount && Utilities.stringAreEqual(appProvider, 'REF-ADD')) {
        logoutRequest.account = userAccount;
      } else {
        logoutRequest.authority = userLogoutAuth;
      }
      instance.logoutRedirect(logoutRequest);
    }
  }, [currentUser, triggerLogout]);

  useEffect(() => {
    currentUserRef.current = currentUser;
    if (currentUser) {
      storage.setItem(StorageConstants.UserAuthority, currentUser.authority);
    }
  }, [currentUser]);

  if (window && !window.onfocus) {
    window.onfocus = checkForExpirationTime;
  }

  const changeActiveGroupTherapySessionId = (groupId: string | null): void => {
    if (groupId === null) {
      sessionStorage.removeItem(StorageConstants.ActiveGroupTherapySessionId)
    } else {
      sessionStorage.setItem(StorageConstants.ActiveGroupTherapySessionId, groupId)
    }

    setActiveGroupTherapySessionId(groupId)
  }

  const changeActiveGroupTherapyPatientId = (patientId: string | null): void => {
    if (patientId === null) {
      sessionStorage.removeItem(StorageConstants.ActiveGroupTherapyPatientId)
    } else {
      sessionStorage.setItem(StorageConstants.ActiveGroupTherapyPatientId, patientId)
    }

    setActiveGroupTherapyPatientId(patientId)
  }

  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        userAccount: currentAccount,
        isPopupActive: showPopup,
        isSystemAlertsActive: showSystemAlerts,
        activeGroupTherapySessionId,
        activeGroupTherapyPatientId,
        patientReflectionsConsentAccepted,
        patientHipaaConsentAccepted,
        patientSearchData,
        coreMeasuresPatientSearchData,
        facilityUserSearchQuery,
        SetPatientSearchData: setPatientSearchData,
        SetCoreMeasuresPatientSearchData: setCoreMeasuresPatientSearchData,
        SetFacilityUserSearchQuery: setFacilityUserSearchQuery,
        SetPatientReflectionsConsentAccepted: setPatientReflectionsConsentAccepted,
        SetPatientHipaaConsentAccepted: setPatientHipaaConsentAccepted,
        SetActiveGroupTherapySessionId: changeActiveGroupTherapySessionId,
        SetActiveGroupTherapyPatientId: changeActiveGroupTherapyPatientId,
        DismissSystemAlerts: DismissAlerts,
        Logout: () => azureLogout(),
        Login: (newUser: UserInterface) => setupLoginEvent(newUser),
        SetAccount: (newAccount: UserAccount) => setCurrentAccount(newAccount),
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
