export enum GroupTherapySessionStatus {
  Active = 1,
}

export enum GroupTherapyPatientStatus {
  Active = 1,
}

export enum GroupTherapyAssessmentsStatus {
  Active = 1,
}

export interface UriParams {
  groupId: string
}

export interface CreateGroupTherapySessionArgs {
  AccountID: number
  ProgramID: number
  GroupTherapyTitle: string
  GroupTherapyComments: string | null
  StatusID: GroupTherapySessionStatus
  GroupTherapyID: null
  StartDate: null
  EndDate: null
  CreatedBy: null
  UpdatedBy: null
  GroupTherapyPIN: string
}

export interface GroupTherapyAddPatientsArgs {
  GroupTherapyID: number
  PatientID: number
  StatusID: GroupTherapyPatientStatus
}

export interface GroupTherapyAddAssessmentsArgs {
  GroupTherapyAssessmentID: null
  GroupTherapyID: number
  AssessmentNumber: number
  AppliedWhenID: number
  StatusID: GroupTherapyAssessmentsStatus
  CreatedBy: null
  UpdatedBy: null
}

export interface CreateGroupTherapySessionResponse {
  id: number
}

export interface GroupTherapyAddPatientsResponse {
  count: number
}

export interface GroupTherapyAddAssessmentsResponse {
  id: number
}

export interface GroupTherapyIncompleteAssessment {
  patientID: number
  patientFirstName: string
  patientLastName: string
  dateOfBirth: string
  assessmentNumber: number
  appliedWhenID: number
}

export interface GroupTherapyGetSessionResponse {
  groupTherapyID: number
  accountID: number
  startDate: string
  programID: number
  groupTherapyTitle: string
  groupTherapyComments: string
  groupTherapyPIN: string
  statusID: number
  statusName: string
}

export interface GroupTherapySessionWorkListRow {
  groupTherapyID: number
  groupTherapyTitle: string
  groupTherapyPIN: string
  startDate: string
  programLabel: string
}
