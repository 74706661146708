export enum HttpStatus {
    OK = 200,
    CONFLICT = 409,
    CREATED = 201,
    BADREQUEST = 400,
    UNAUTHORIZED = 401,
    NOTFOUND = 404,
    SERVERERROR = 500,
    REQUEST_ERROR = 0,
}
