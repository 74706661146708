import * as React from 'react';

import './styles.scss';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Paragraph from 'global_elements/Text/Paragraph';
import SingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect';

import { LabledSingleSelectProps } from 'types/inputProps';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';

const LabledSingleSelect = (props: LabledSingleSelectProps): JSX.Element => (
  <label className="labeled-single-select">
    <FlexContainer
      display={DisplayVariant.FLEX_ROW}
      align={AlignVariant.CENTER}
      justify={JustifyVariant.START}
      extraClasses="labeled-single-select__header"
    >
      <Paragraph
        text={`${props.label}${props.error ? ` -- ${props.error}` : ''}`}
        fontColor={props.error ? FontColors.HIGH_PRIORITY : FontColors.DARK_GRAY}
        fontSize={FontSizes.REGULAR}
        bold
      />
    </FlexContainer>
    <SingleSelect
      styles={props.styles}
      defaultValue={props.defaultValue}
      value={props.value}
      options={props.options}
      onSelection={props.onSelection}
      onblur={props.onblur}
      testid={props.testid}
      isDisabled={props.isDisabled}
    />
  </label>
);

export default React.memo(
  LabledSingleSelect,
  (prevProps: Readonly<LabledSingleSelectProps>, nextProps: Readonly<LabledSingleSelectProps>) => (
    prevProps.value?.value === nextProps.value?.value
    && prevProps.error === nextProps.error
  ),
);
