import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.scss';

import { AllRoutes } from 'constants/routes';
import DataListsProxy from 'api/dataLists/dataListsProxy';
import FacilityInfoProxy from 'api/CoreMeasures/FacilityInfoProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import Paragraph from 'global_elements/Text/Paragraph';
import DataTable, { TableColumn } from 'react-data-table-component';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { FacilitySelectOption } from 'types/inputProps';

import { UserContext } from 'context/user';
import { ActiveFacilityContext } from 'context/activeFacility';

import moment from 'moment';
import { HoverTableStyles } from 'global_elements/Layouts/DataTables/styles';
import { CoreMeasuresMonthlyPopulationSummaryModel } from '../../../interfaces/coreMeasures/coreMeasuresMonthlyPopulationSummaryModel';

const CoreMeasuresFacilityInfoPage = (): JSX.Element => {
  const { user } = useContext(UserContext);
  const [availableFacilities, setAvailableFacilities] = useState<FacilitySelectOption[]>([]);
  const { facility } = useContext(ActiveFacilityContext);
  const [monthlyPopulationSummaryModels, setMonthlyPopulationSummaryModels] = useState<CoreMeasuresMonthlyPopulationSummaryModel[]>([]);
  const [selectedFacility, setSelectedFacility] = useState<FacilitySelectOption>();
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const isAdmin = user?.role === 'admin' || user?.role === 'super-admin';

  useEffect(() => {
    DataListsProxy.getAllUserFacilities(
      (response) => {
        if (response?.data) {
          const facilityOptions: FacilitySelectOption[] = [];

          response.data
            .sort((a, b) => {
              if (a.facilityName.toLowerCase() < b.facilityName.toLowerCase()) {
                return -1;
              }

              if (a.facilityName.toLowerCase() > b.facilityName.toLowerCase()) {
                return 1;
              }

              return 0;
            })
            .forEach((element) => {
              if (element.facilityName && element.facilityID) {
                facilityOptions.push({
                  label: element.facilityName,
                  value: element.facilityID.toString(),
                  isCoreMeasures: !!element.coreMeasures, // Convert numbers to boolean
                  hasServiceCodes: !!element.svcCode,
                  isOutcomes: !!element.outcomes,
                });
              }
            });

          setAvailableFacilities(facilityOptions);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, []);

  useEffect(() => {
    if (selectedFacility?.value) {
      if (!selectedFacility.isCoreMeasures) {
        setErrorMessage('Core Measures features are not available for this facility.');
      } else {
        setErrorMessage('');
      }
    }
  }, [selectedFacility]);

  const handleFacilitySelectChange = (option: FacilitySelectOption): void => {
    setSelectedFacility(option);
    const facilityId = parseInt(option.value, 10);
    FacilityInfoProxy.getMonthlyPopulationSummary(facilityId).then((results) => {
      let rows: CoreMeasuresMonthlyPopulationSummaryModel[] = [];
      if (results.data) {
        rows = results.data;
      }
      setMonthlyPopulationSummaryModels(rows);
    });
  };

  useEffect(() => {
    handleFacilitySelectChange(facility);
  }, [facility]);

  const columns: TableColumn<CoreMeasuresMonthlyPopulationSummaryModel>[] = [
    {
      name: 'Month',
      selector: (row) => (row.popMonth ? row.popMonth : -1),
      format: (row) => moment(row.popMonth, 'M').format('MMMM'),
    },
    {
      name: 'Year',
      selector: (row) => (row.popYear ? row.popYear : -1),
      sortable: true,
    },
    {
      name: 'Complete',
      selector: (row) => row.complete,
      sortable: true,
    },
  ];

  const NoDataComponent: JSX.Element = <InlineText text="No population summaries found." fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} />;

  const goToPopulationEntry = (row: CoreMeasuresMonthlyPopulationSummaryModel, e: React.MouseEvent<Element, MouseEvent>): void => {
    e.preventDefault();
    const summaryId = row.summaryID.toString();
    const facilityId = selectedFacility ? selectedFacility.value : '0';
    const year = row.popYear ? row.popYear.toString() : '-1';
    const month = row.popMonth ? row.popMonth.toString() : '-1';
    const isFormFacility = row.isFormFacility || row.isFormFacility === 0 ? row.isFormFacility.toString() : '';
    const route = AllRoutes.CORE_MEASURES_FACILITY_INFO_POPULATION_ENTRY.replace(':summaryId', summaryId)
      .replace(':facilityId', facilityId)
      .replace(':year', year)
      .replace(':month', month)
      .replace(':isFormFacility', isFormFacility);
    history.push(route);
  };

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Core Measures Facility Info">
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-facility-info-content">
        <form>
          <PrimaryHeader text="Core Measures Facility Info" fontColor={FontColors.PRIMARY} marginTopPx={16} marginBottomPx={16} />
          {isAdmin && availableFacilities.length === 0 && (
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Facility"
              options={[{ label: 'Loading...', value: '' }]}
              defaultValue={{ label: 'Loading...', value: '' }}
              value={{ label: 'Loading...', value: '' }}
              onSelection={handleFacilitySelectChange}
            />
          )}
          {isAdmin && availableFacilities.length > 0 && (
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Facility"
              options={availableFacilities}
              defaultValue={selectedFacility ?? { label: 'Select a Facility', value: '' }}
              value={selectedFacility}
              onSelection={handleFacilitySelectChange}
            />
          )}
          {!isAdmin && (facility.value === '' || facility.value === '0') && (
            <Paragraph text="Please select a facility in the header to search" fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.EXTRA_LARGE} />
          )}
          {errorMessage && (
            <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.START} align={AlignVariant.CENTER}>
              <InlineText text={errorMessage} fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.REGULAR} />
            </FlexContainer>
          )}
        </form>
        {!errorMessage && selectedFacility && selectedFacility.value !== '0' && (
          <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.CENTER}>
            <SecondaryHeader text="Monthly Population Summary" fontColor={FontColors.PRIMARY} />
            <DataTable
              dense
              highlightOnHover
              persistTableHead
              customStyles={HoverTableStyles}
              columns={columns}
              data={monthlyPopulationSummaryModels}
              noDataComponent={NoDataComponent}
              onRowClicked={goToPopulationEntry}
            />
          </FlexContainer>
        )}
      </FlexContainer>
    </PageLayout>
  );
};

export default CoreMeasuresFacilityInfoPage;
