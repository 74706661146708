import * as React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { ReactComponent as SortIcon } from 'icons/mho-sort-icon-white.svg';

import { SystemAlertRow, SystemAlertsTableProps } from 'types/tableProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';

import { SystemAlertTableStyles } from '../styles';

const NoDataComponent: JSX.Element = (
  <InlineText
    text="No alerts are currently scheduled or active."
    fontColor={FontColors.PRIMARY}
    fontSize={FontSizes.LARGE}
  />
);

const isMobile = window.innerWidth <= 1024;

const columns: TableColumn<SystemAlertRow>[] = [
  {
    id: 'severity',
    name: 'Severity',
    selector: (row) => row.severity,
    sortable: true,
    grow: 0,
    minWidth: isMobile ? '60px' : '90px',
  },
  {
    id: 'client',
    name: 'Client',
    selector: (row) => row.client,
    sortable: true,
    grow: 0,
    minWidth: isMobile ? '80px' : '140px',
  },
  {
    id: 'plateform',
    name: 'Platform',
    selector: (row) => row.platform,
    sortable: true,
    grow: 0,
    minWidth: isMobile ? '70px' : '90px',
  },
  {
    id: 'startDate',
    name: 'Start Date/Time',
    selector: (row) => row.start.getDateTimeForPost(),
    sortable: true,
    format: (row) => row.start.getDateTimeForDisplay(),
    grow: 0,
    minWidth: isMobile ? '120px' : '180px',
    omit: isMobile,
  },
  {
    id: 'endDate',
    name: 'End Date/Time',
    selector: (row) => row.end.getDateTimeForPost(),
    sortable: true,
    format: (row) => row.end.getDateTimeForDisplay(),
    grow: 0,
    minWidth: isMobile ? '120px' : '180px',
  },
  {
    id: 'alertText',
    name: 'Alert Text',
    selector: (row) => row.message,
    grow: 1,
  },
];

const SystemAlertsTable = (props: SystemAlertsTableProps): JSX.Element => (
  <FlexContainer
    display={DisplayVariant.FLEX_COL}
    justify={JustifyVariant.CENTER}
    align={AlignVariant.END}
    extraClasses="system-alerts-table table-wrapper card-content"
  >
    <DataTable
      dense
      highlightOnHover
      persistTableHead
      pagination
      sortIcon={<SortIcon />}
      customStyles={SystemAlertTableStyles}
      columns={columns}
      data={props.data}
      noDataComponent={NoDataComponent}
      onRowClicked={props.onRowClicked}
    />
  </FlexContainer>
);

export default SystemAlertsTable;
