import React from 'react';

export const validPhoneInput = (e: React.ChangeEvent<HTMLInputElement>): boolean => (
  new RegExp(/([0-9])|(\()|(\))|(\s)|(-)/g).test(e.target.value.slice(-1))
);

export const maskInputAsPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>): string | undefined => {
  const inputNumber = e.target.value.replaceAll(/(\()|(\))|(-)|(\s)/g, '');
  let maskedNumber = '';

  if (inputNumber.length > 0) {
    maskedNumber = `(${inputNumber}`;
  }

  if (inputNumber.length > 3 && inputNumber.length <= 6) {
    maskedNumber = `${maskedNumber.slice(0, 4)}) ${maskedNumber.slice(4)}`;
  } else if (inputNumber.length > 6) {
    maskedNumber = `${maskedNumber.slice(0, 4)}) ${maskedNumber.slice(4, 7)}-${maskedNumber.slice(7)}`;
  }

  if (inputNumber.length <= 10) {
    return maskedNumber;
  }

  return undefined;
};

export const validPhoneNumber = (phone: string): boolean => {
  if (phone === null || phone === '') return true;
  const num = phone.replaceAll(/(\()|(\))|(-)|(\s)/g, '');

  const allNumbersAreSame = (phoneNumber: string): boolean => {
    for (let i = 0; i < num.length; i += 1) {
      if (phoneNumber[i] !== phoneNumber[0]) {
        return false;
      }
    }
    return true;
  };

  return !allNumbersAreSame(num) && new RegExp(/^(1|)[2-9]\d{2}[2-9]\d{6}$/, '').test(num);
};

export const invalidInput = (phone: string): string | undefined => {
  if (!validPhoneNumber(phone)) {
    return 'Invalid Phone Number';
  }

  return undefined;
};
