import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Moment } from 'moment';
import { useHistory } from 'react-router-dom';
import MHODateTime from 'domain/dateTime/MHODateTime';

import DataListsProxy, { DataListType } from 'api/dataLists/dataListsProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import Paragraph from 'global_elements/Text/Paragraph';
import Button from 'global_elements/Button';
import SystemAlertsTable from 'global_elements/Layouts/DataTables/SystemAlertsTable';
import PopupWindow from 'global_elements/Layouts/PopupWindow';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabeledMultiSelect from 'global_elements/Inputs/Dropdown/MultiSelect/LabeledMultiSelect';
import LabledAsyncSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect/asyncLabledSingleSelct';
import DateTimePicker from 'global_elements/Inputs/DateTimePicker';
import { ReactComponent as AddIcon } from 'icons/mho-add.svg';

import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { SystemAlertRow } from 'types/tableProps';
import { SelectOption } from 'types/inputProps';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { UserContext } from 'context/user';
import { SystemAlertData, ListSystemAlert } from 'interfaces/dataLists/listSystemAlert';
import { MultiStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/MultiSelect/styles';
import { PrintableAssessments } from 'pages/shared/PrintableAssessments/printableAssessment';

import './styles.scss';
import { AccessType } from 'types/facilityUserAccount';

const SELECT_TEXT = 'Select...';

const newAlertTemplate: SystemAlertRow = {
  alertID: null,
  groupID: null,
  facilityID: [],
  severity: SELECT_TEXT,
  client: SELECT_TEXT,
  platform: SELECT_TEXT,
  start: new MHODateTime(new Date(Date.now())),
  end: new MHODateTime(new Date(Date.now())),
  type: SELECT_TEXT,
  message: '',
};

const AdminDashboard = (props: any): JSX.Element => {
  const [systemAlertsList, setSystemAlertsList] = useState<SystemAlertRow[]>([]);
  const [isAlertEditorOpen, setIsAlertEditorOpen] = useState<boolean>(false);
  const [systemAlertEditorContent, setSystemAlertEditorContent] = useState<SystemAlertRow>(newAlertTemplate);
  const [alertTypeOptions, setAlertTypeOptions] = useState<SelectOption[]>([]);
  const [severityOptions, setSeverityOptions] = useState<SelectOption[]>([]);
  const [clientOptions, setClientOptions] = useState<SelectOption[]>([]);
  const [platformOptions, setPlatformOptions] = useState<SelectOption[]>([]);
  const [facilityOptions, setFacilityOptions] = useState<SelectOption[]>([]);
  const [loadingAlerts, setLoadingAlerts] = useState<boolean>(true);
  const { user } = useContext(UserContext);
  const history = useHistory();

  const lookupFacilityByID = (ID: number, facilityList: SelectOption[]): SelectOption | null => {
    let temp: SelectOption | null = null;

    facilityList.forEach((option) => {
      if (option.value === ID.toString()) {
        temp = option;
      }
    });

    return temp;
  };

  /**
   * Convert the format of all imported alerts.
   *
   * @param alertsList list of alerts from the backend.
   * @param facilities a list facility with ID and name
   */
  const setNewAlertList = (alertsList: ListSystemAlert[], facilities: SelectOption[]): void => {
    const newAlertList: SystemAlertRow[] = [];
    if (alertsList?.length) {
      for (let i = 0; i < alertsList.length; i += 1) {
        const alert = alertsList[i];
        let groupExists = false;

        for (let k = 0; k < newAlertList.length; k += 1) {
          if (alert.systemAlertID !== newAlertList[k].alertID && newAlertList[k].groupID === alert.systemAlertGroupID) {
            groupExists = true;
            const tempFacilityOption = lookupFacilityByID(alert.facilityID, facilities);

            if (tempFacilityOption !== null) {
              newAlertList[k].facilityID.push(tempFacilityOption);
            }
          }
        }

        if (!groupExists) {
          newAlertList.push({
            alertID: alert.systemAlertID,
            groupID: alert.systemAlertGroupID,
            facilityID: [lookupFacilityByID(alert.facilityID, facilities)],
            severity: alert.severity,
            client: alert.clientType,
            platform: alert.platform,
            type: alert.alertType,
            message: alert.alertMessage,
            start: new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/).test(alert.startDate) ? new MHODateTime(alert.startDate) : '0000-00-00T00:00:00',
            end: new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/).test(alert.startDate) ? new MHODateTime(alert.endDate) : '0000-00-00T00:00:00',
          } as SystemAlertRow);
        }
      }
    }
    setSystemAlertsList(newAlertList);
  };

  const updateSystemAlertsAndFacilityList = useCallback((): void => {
    const newFacilityOptions: SelectOption[] = [];

    DataListsProxy.getSystemAlertsList()
      .then((alertsList: ListSystemAlert[]) => {
        DataListsProxy.getAllUserFacilities(
          (innerResponse) => {
            if (innerResponse.data) {
              innerResponse.data.forEach((facility) => {
                if (facility.facilityID !== 0) {
                  newFacilityOptions.push({
                    label: facility.facilityName,
                    value: facility.facilityID.toString(),
                  } as SelectOption);
                }
              });
            }

            let sortedAlerts = alertsList.sort((a, b) => (new Date(b.startDate) as any) - (new Date(a.startDate) as any));
            sortedAlerts = alertsList.sort((a, b) => (new Date(b.endDate) as any) - (new Date(a.endDate) as any));
            setFacilityOptions(newFacilityOptions);
            setNewAlertList(sortedAlerts, newFacilityOptions);

            if (loadingAlerts === true) {
              setLoadingAlerts(false);
            }
          },
          (errorResponse) => {
            console.log(errorResponse);
          },
        );
      })
      .catch((errorResponse: any) => {
        console.log(errorResponse);
        setSystemAlertsList([]);
        setLoadingAlerts(false);
      });
  }, [loadingAlerts]);

  /**
   * Shared function to load the various drop downs for the System Alert Editor.
   *
   * @param optionType The 4 types of options: Platform, Severity, ClientType, and AlertType
   * @returns {Promise} the retrieved options list.
   */
  const loadDropdownOptions = (optionType: DataListType): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    DataListsProxy.getOptionList(
      optionType,
      (response) => {
        const dropdownOptions: SelectOption[] = [];

        if (response.data) {
          response.data.forEach((option) => {
            dropdownOptions.push({
              label: option.lookupDesc,
              value: option.lookupID.toString(),
            });
          });
        }
        resolve(dropdownOptions);
      },
      (error) => {
        console.log(error);
        reject(error);
      },
    );
  });

  /**
   * Async call to load the Platform options to the dropdown.
   *
   * @returns {Promise} created options for the Platform.
   */
  const loadPlatformPromise = (): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    loadDropdownOptions(DataListType.Platform)
      .then((options: SelectOption[]) => {
        setPlatformOptions(options);
        resolve(options);
      })
      .catch((error: any) => {
        reject(error);
      });
  });

  /**
   * Async call to load the Severity options to the dropdown.
   *
   * @returns {Promise} created options for the Severity.
   */
  const loadSeverityPromise = (): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    loadDropdownOptions(DataListType.Severity)
      .then((options: SelectOption[]) => {
        setSeverityOptions(options);
        resolve(options);
      })
      .catch((error: any) => {
        reject(error);
      });
  });

  /**
   * Async call to load the Client options to the dropdown.
   *
   * @returns {Promise} created options for the Client.
   */
  const loadClientPromise = (): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    loadDropdownOptions(DataListType.ClientType)
      .then((options: SelectOption[]) => {
        setClientOptions(options);
        resolve(options);
      })
      .catch((error: any) => {
        reject(error);
      });
  });

  /**
   * Async call to load the Alert Type options to the dropdown.
   *
   * @returns {Promise} created options for the Alert Types.
   */
  const loadAlertTypePromise = (): Promise<SelectOption[]> => new Promise<SelectOption[]>((resolve, reject) => {
    loadDropdownOptions(DataListType.AlertType)
      .then((options: SelectOption[]) => {
        setAlertTypeOptions(options);
        resolve(options);
      })
      .catch((error: any) => {
        reject(error);
      });
  });

  useEffect(() => {
    if (user?.accessType === AccessType.CORE_MEASURES) {
      history.push('core-measures-worklist');
    }
  }, []);

  useEffect(() => {
    updateSystemAlertsAndFacilityList();
  }, [updateSystemAlertsAndFacilityList, user]);

  const handleSeveritySelect = (option: SelectOption): void => {
    setSystemAlertEditorContent((prevState) => ({
      ...prevState,
      severity: option.label,
    }));
  };

  const handleClientSelect = (option: SelectOption): void => {
    setSystemAlertEditorContent((prevState) => ({
      ...prevState,
      client: option.label,
    }));
  };

  const handlePlatformSelect = (option: SelectOption): void => {
    setSystemAlertEditorContent((prevState) => ({
      ...prevState,
      platform: option.label,
    }));
  };

  const handleTypeSelect = (option: SelectOption): void => {
    setSystemAlertEditorContent((prevState) => ({
      ...prevState,
      type: option.label,
    }));
  };

  const handleAlertTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSystemAlertEditorContent((prevState) => ({
      ...prevState,
      message: e.target.value,
    }));
  };

  const handleFacilitySelect = (options: SelectOption[]): void => {
    setSystemAlertEditorContent((prevState) => ({
      ...prevState,
      facilityID: options,
    }));
  };

  const handleDateTimeChange = (moment: string | Moment, field: 'start' | 'end'): void => {
    if (typeof moment === 'string') {
      console.log(`Invalid Date/Time Selected: ${moment}`);
    } else {
      setSystemAlertEditorContent((prevState) => ({
        ...prevState,
        [field]: new MHODateTime(moment.toDate()),
      }));
    }
  };

  const handleStartChange = (moment: string | Moment): void => {
    handleDateTimeChange(moment, 'start');
  };

  const handleEndChange = (moment: string | Moment): void => {
    handleDateTimeChange(moment, 'end');
  };

  const addAlert = (): void => {
    setIsAlertEditorOpen(true);
    setSystemAlertEditorContent(newAlertTemplate);
  };

  const editAlert = (row: SystemAlertRow, e: React.MouseEvent<Element, MouseEvent>): void => {
    e.preventDefault();
    setIsAlertEditorOpen(true);
    setSystemAlertEditorContent(row);
  };

  const closePopup = (): void => {
    setIsAlertEditorOpen(false);
    setSystemAlertEditorContent(newAlertTemplate);
  };

  const getOptionID = (value: string, optionType: 'severity' | 'client' | 'platform' | 'type'): number => {
    let ID = 0;

    switch (optionType) {
      case 'platform':
        platformOptions.forEach((option) => {
          if (option.label === value) {
            ID = parseInt(option.value, 10);
          }
        });
        break;
      case 'client':
        clientOptions.forEach((option) => {
          if (option.label === value) {
            ID = parseInt(option.value, 10);
          }
        });
        break;
      case 'type':
        alertTypeOptions.forEach((option) => {
          if (option.label === value) {
            ID = parseInt(option.value, 10);
          }
        });
        break;
      case 'severity':
        severityOptions.forEach((option) => {
          if (option.label === value) {
            ID = parseInt(option.value, 10);
          }
        });
        break;
      default:
        break;
    }

    return ID;
  };

  const saveNewAlert = (): void => {
    const alertDataList: SystemAlertData[] = [];

    const severityID = getOptionID(systemAlertEditorContent.severity, 'severity');
    const clientID = getOptionID(systemAlertEditorContent.client, 'client');
    const platformID = getOptionID(systemAlertEditorContent.platform, 'platform');
    const alertTypeID = getOptionID(systemAlertEditorContent.type, 'type');
    const startDate = systemAlertEditorContent.start.getDateTimeForPost();
    const endDate = systemAlertEditorContent.end.getDateTimeForPost();

    // Save the facility only if the specify facility option is selected.
    const specificFacilityClientID = getOptionID('Facility Specific', 'client');

    if (clientID === specificFacilityClientID && systemAlertEditorContent.facilityID.length > 0) {
      systemAlertEditorContent.facilityID.forEach((FID) => {
        alertDataList.push({
          SystemAlertID: systemAlertEditorContent.alertID,
          SystemAlertGroupID: systemAlertEditorContent.groupID,
          facilityID: FID ? parseInt(FID.value, 10) : 0,
          SeverityID: severityID,
          ClientTypeID: clientID,
          PlatformID: platformID,
          StartDate: startDate,
          EndDate: endDate,
          AlertTypeID: alertTypeID,
          AlertMessage: systemAlertEditorContent.message,
          CreatedBy: user?.email ?? 'user not found',
          UpdatedBy: user?.email ?? 'user not found',
        } as SystemAlertData);
      });
    } else {
      alertDataList.push({
        SystemAlertID: systemAlertEditorContent.alertID,
        SystemAlertGroupID: systemAlertEditorContent.groupID,
        facilityID: 0,
        SeverityID: severityID,
        ClientTypeID: clientID,
        PlatformID: platformID,
        StartDate: startDate,
        EndDate: endDate,
        AlertTypeID: alertTypeID,
        AlertMessage: systemAlertEditorContent.message,
        CreatedBy: user?.email ?? 'user not found',
        UpdatedBy: user?.email ?? 'user not found',
      } as SystemAlertData);
    }

    DataListsProxy.postSystemAlert(
      alertDataList,
      (response) => {
        console.log(response);
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );

    // Give back-end time to respond to new saved alert.
    setTimeout(() => {
      closePopup();
      updateSystemAlertsAndFacilityList();
    }, 1000);
  };

  /**
   * Check to see the text has a user selected text.
   *
   * @param choice string text to see if it's empty or only has the default 'Select'.
   * @returns true if it has the user select value.
   */
  const isValidChoice = (choice: string): boolean => !!choice && choice !== SELECT_TEXT;

  /**
   * Check for all required field to be filled. Disable the save button until they're complete.
   *
   * @returns true if not all required fields are filled in.
   */
  const disableSave = (): boolean => !isValidChoice(systemAlertEditorContent.severity)
    || !isValidChoice(systemAlertEditorContent.client)
    || !isValidChoice(systemAlertEditorContent.platform)
    || !isValidChoice(systemAlertEditorContent.type)
    || systemAlertEditorContent.message.length === 0
    || systemAlertEditorContent.message.length > 140;

  const alertEditorPopup = (): JSX.Element => (
    <PopupWindow>
      <TertiaryHeader text="System Alert Editor" fontColor={FontColors.PRIMARY} />
      <form>
        <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="popup-window__first-row">
          <LabledAsyncSingleSelect
            label="Severity"
            styles={SingleStandardDropdownStyles}
            loadOptions={loadSeverityPromise}
            defaultValue={{ label: SELECT_TEXT, value: '' }}
            value={{
              label: systemAlertEditorContent.severity,
              value: getOptionID(systemAlertEditorContent.severity, 'severity').toString(),
            }}
            onSelection={handleSeveritySelect}
          />
          <LabledAsyncSingleSelect
            label="Client"
            styles={SingleStandardDropdownStyles}
            loadOptions={loadClientPromise}
            defaultValue={{ label: SELECT_TEXT, value: '' }}
            value={{
              label: systemAlertEditorContent.client,
              value: getOptionID(systemAlertEditorContent.client, 'client').toString(),
            }}
            onSelection={handleClientSelect}
          />
          <LabledAsyncSingleSelect
            label="Platform"
            styles={SingleStandardDropdownStyles}
            loadOptions={loadPlatformPromise}
            defaultValue={{ label: SELECT_TEXT, value: '' }}
            value={{
              label: systemAlertEditorContent.platform,
              value: getOptionID(systemAlertEditorContent.platform, 'platform').toString(),
            }}
            onSelection={handlePlatformSelect}
          />
        </FlexContainer>
        {systemAlertEditorContent.client === 'Facility Specific' && (
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="popup-window__facility-picker">
            <LabeledMultiSelect
              label="Facilities"
              placeholder="Select or Search for Facilities for Alert"
              styles={MultiStandardDropdownStyles}
              options={facilityOptions}
              defaultValue={systemAlertEditorContent.facilityID}
              value={systemAlertEditorContent.facilityID}
              onSelection={handleFacilitySelect}
            />
          </FlexContainer>
        )}
        <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="popup-window__second-row">
          <label>
            <Paragraph text="Start Date/Time" fontColor={FontColors.SECONDARY} fontSize={FontSizes.REGULAR} bold />
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.CENTER} justify={JustifyVariant.CENTER} extraClasses="datetime-picker-custom-input">
              <InlineText
                text={`${systemAlertEditorContent.start.getFormattedCalendarDate()} | ${systemAlertEditorContent.start.getFormattedTime()}
                `}
                fontColor={FontColors.DARK}
                fontSize={FontSizes.REGULAR}
              />
            </FlexContainer>
            <DateTimePicker value={systemAlertEditorContent.start.getJSDate()} handleChange={handleStartChange} inputProps={{ style: { display: 'none' } }} />
          </label>
          <label>
            <Paragraph text="End Date/Time" fontColor={FontColors.SECONDARY} fontSize={FontSizes.REGULAR} bold />
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.CENTER} justify={JustifyVariant.CENTER} extraClasses="datetime-picker-custom-input">
              <InlineText
                text={`${systemAlertEditorContent.end.getFormattedCalendarDate()} | ${systemAlertEditorContent.end.getFormattedTime()}
                `}
                fontColor={FontColors.DARK}
                fontSize={FontSizes.REGULAR}
              />
            </FlexContainer>
            <DateTimePicker value={systemAlertEditorContent.end.getJSDate()} handleChange={handleEndChange} inputProps={{ style: { display: 'none' } }} />
          </label>
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="popup-window__third-row">
          <LabledAsyncSingleSelect
            label="Alert Type"
            styles={SingleStandardDropdownStyles}
            loadOptions={loadAlertTypePromise}
            defaultValue={{ label: SELECT_TEXT, value: '' }}
            value={{
              label: systemAlertEditorContent.type,
              value: getOptionID(systemAlertEditorContent.type, 'type').toString(),
            }}
            onSelection={handleTypeSelect}
          />
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="popup-window__fourth-row">
          <LabledTextInput
            label="Alert Text"
            placeholder=""
            name="message"
            value={systemAlertEditorContent.message}
            onChange={handleAlertTextChange}
            type="text"
            variant={TextInputVariant.PRIMARY}
            maxTextLength={140}
            error={systemAlertEditorContent.message.length > 140 ? '' : undefined}
          />
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.END} extraClasses="button-container">
          <Button variant={ButtonVariant.SECONDARY} onClick={closePopup}>
            <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
          <Button variant={ButtonVariant.PRIMARY} onClick={saveNewAlert} disabled={disableSave()}>
            <InlineText text="Save" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
        </FlexContainer>
      </form>
    </PopupWindow>
  );

  if (user?.accessType === AccessType.CORE_MEASURES) {
    return (
      <PageLayout layout={PageLayoutVariant.PADDED} testText={props.testText} title={props.title}>
        Redirecting...
      </PageLayout>
    );
  }

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText={props.testText} title={props.title} loadingText={loadingAlerts ? 'Loading Widgets...' : undefined}>
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="admin-dashboard-content">
        {isAlertEditorOpen && alertEditorPopup()}
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="system-alerts-widget table-widget card-primary">
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.CENTER} justify={JustifyVariant.SPACE_BETWEEN} extraClasses="card-primary__header">
            <SecondaryHeader text="System Alerts" fontColor={FontColors.PRIMARY} />
            <Button variant={ButtonVariant.INVISIBLE} onClick={addAlert}>
              <AddIcon />
              <InlineText text="Add System Alert" fontColor={FontColors.SECONDARY} fontSize={FontSizes.REGULAR} />
            </Button>
          </FlexContainer>
          {loadingAlerts ? (
            <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.CENTER} justify={JustifyVariant.START} extraClasses="loading-curtain">
              <SecondaryHeader text="Loading System Alerts..." fontColor={FontColors.SECONDARY} bold />
            </FlexContainer>
          ) : (
            <SystemAlertsTable data={systemAlertsList} onRowClicked={editAlert} />
          )}
        </FlexContainer>
        <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="printable-assessments-page-content">
          <PrintableAssessments facilityID={0} />
        </FlexContainer>
      </FlexContainer>
    </PageLayout>
  );
};

export default AdminDashboard;
