import * as React from 'react';

import InlineText from 'global_elements/Text/InlineText';
import FlexContainer from 'global_elements/Layouts/FlexContainer';

import { TextCounterProps } from 'types/textProps';
import { JustifyVariant, AlignVariant, DisplayVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from '../variants';

const TextCounter = (props: TextCounterProps): JSX.Element => (
  <FlexContainer
    display={DisplayVariant.FLEX_ROW}
    align={AlignVariant.CENTER}
    justify={JustifyVariant.END}
    extraClasses="text-counter"
  >
    <InlineText
      text={`${props.current.toString()}`}
      fontColor={props.current <= props.max ? FontColors.DARK_GRAY : FontColors.HIGH_PRIORITY}
      fontSize={FontSizes.REGULAR}
    />
    <InlineText
      text={`/${props.max.toString()}`}
      fontColor={FontColors.DARK_GRAY}
      fontSize={FontSizes.REGULAR}
    />
  </FlexContainer>
);

export default React.memo(
  TextCounter,
  (prevProps: Readonly<TextCounterProps>, nextProps: Readonly<TextCounterProps>) => (
    prevProps.current === nextProps.current
  ),
);
