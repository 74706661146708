import * as React from 'react'

import './styles.scss'

import { TextAreaProps } from 'types/inputProps'

const TextArea = (props: TextAreaProps): JSX.Element => (
  <textarea
    className={`input-text-area ${props.variant}`}
    placeholder={props.placeholder}
    name={props.name}
    value={props.value}
    onChange={props.onChange}
    onBlur={props.onBlur}
    onCut={props.onCut}
    onCopy={props.onCopy}
    onPaste={props.onPaste}
    disabled={(props.disabled)}
    autoFocus={props.autoFocus}
    data-testid={props.testid}
  />
)

export default React.memo(
  TextArea,
  (prevProps: Readonly<TextAreaProps>, nextProps: Readonly<TextAreaProps>) => (
    prevProps.value === nextProps.value
    && prevProps.variant === nextProps.variant
  ),
)
