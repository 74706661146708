/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment';

import './styles.scss';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import Button from 'global_elements/Button';
import InlineText from 'global_elements/Text/InlineText';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';

import FacilityInfoProxy from 'api/CoreMeasures/FacilityInfoProxy';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { SelectOption } from 'types/inputProps';

import { UserContext } from 'context/user';
import { CoreMeasuresMonthlyPopulationModel, CoreMeasuresMonthlyPopulationPostModel, CoreMeasuresDiagnosticCategoryCountsModel, CoreMeasuresDiagnosticCategoryCountsPostModel, CoreMeasuresMonthlyPopulationSummaryModel } from '../../../interfaces/coreMeasures/coreMeasuresMonthlyPopulationSummaryModel';
import TextInput from '../../../global_elements/Inputs/TextInput';
import { TextInputVariant } from '../../../global_elements/Inputs/TextInput/variants';
import { AllRoutes } from '../../../constants/routes';

interface UriParams {
  summaryId: string,
  facilityId: string,
  year: string,
  month: string,
  isFormFacility: string,
}

interface StringArray {
  [index: string]: string,
}

const CoreMeasuresFacilityInfoPopulationEntryPage = (): JSX.Element => {
  const history = useHistory();
  const { summaryId, facilityId, year, month, isFormFacility } = useParams<UriParams>();
  const [monthModels, setMonthModels] = useState<CoreMeasuresMonthlyPopulationSummaryModel[]>([]);
  const [months, setMonths] = useState<SelectOption[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<SelectOption>();
  const [monthlyPopulationModel, setMonthlyPopulationModel] = useState<CoreMeasuresMonthlyPopulationModel | null>();
  const [diagnosticCategoryCountsModel, setDiagnosticCategoryCountsModel] = useState<CoreMeasuresDiagnosticCategoryCountsModel | null>();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const { user } = useContext(UserContext);

  const [inputVals, setInputVals] = useState<StringArray>({
    // eslint-disable-next-line object-property-newline
    val1: '', val2: '', val3: '', val4: '', val5: '', val6: '',
    // eslint-disable-next-line object-property-newline
    val7: '', val8: '', val9: '', val10: '', val11: '', val12: '',
    // eslint-disable-next-line object-property-newline
    val13: '', val14: '', val15: '', val16: '', val17: '', val18: '',
    // eslint-disable-next-line object-property-newline
    val19: '', val20: '', val21: '', val22: '', val23: '', val24: '',
    // eslint-disable-next-line object-property-newline
    val25: '', val26: '', val27: '', val28: '', val29: '', val30: '', val31: '',
  });

  const formatInputVal = (val: number | null | undefined): string => {
    const ret = val !== undefined && val != null ? val.toString() : '';
    return ret;
  };

  useEffect(() => {
    FacilityInfoProxy.getMonthlyPopulationSummary(Number(facilityId))
      .then((response) => {
        setMonthModels(response.data ? response.data : []);

        let options: SelectOption[] = [];
        if (response.data) {
          options = response.data.map((s) => {
            const option = ({
              label: `${moment(s.popMonth, 'M').format('MMMM')} - ${s.popYear}`,
              value: s.summaryID.toString(),
            });
            if (s.summaryID === Number(summaryId)) {
              setSelectedMonth(option);
            }
            return option;
          });
        }
        setMonths(options);
      });
  }, []);

  useEffect(() => {
    const monthModel = selectedMonth ? monthModels.find((m) => m.summaryID === Number(selectedMonth.value)) : null;
    const p1 = FacilityInfoProxy.getMonthlyPopulation(monthModel ? monthModel.summaryID : Number(summaryId));
    const p2 = FacilityInfoProxy.getDiagnosticCategoryCounts(Number(facilityId), Number(monthModel ? monthModel.popYear : year), Number(monthModel ? monthModel.popMonth : month));

    Promise.all([p1, p2]).then(([r1, r2]) => {
      const m1: CoreMeasuresMonthlyPopulationModel | null = r1.data && r1.data.length > 0 ? r1.data[0] : null;
      setMonthlyPopulationModel(m1);
      const m2: CoreMeasuresDiagnosticCategoryCountsModel | null = r2.data && r2.data.length > 0 ? r2.data[0] : null;
      setDiagnosticCategoryCountsModel(m2);

      setInputVals((prev) => ({
        ...prev,
        val1: formatInputVal(m1?.popCount_MedOnly_Child),
        val2: formatInputVal(m1?.popCount_NonMedOnly_Child),
        val3: formatInputVal(m1?.psychInpDays_MedOnly_Child),
        val4: formatInputVal(m1?.psychInpDays_NonMedOnly_Child),
        val5: formatInputVal(m1?.leaveDays_MedOnly_Child),
        val6: formatInputVal(m1?.leaveDays_NonMedOnly_Child),

        val7: formatInputVal(m1?.popCount_MedOnly_Adol),
        val8: formatInputVal(m1?.popCount_NonMedOnly_Adol),
        val9: formatInputVal(m1?.psychInpDays_MedOnly_Adol),
        val10: formatInputVal(m1?.psychInpDays_NonMedOnly_Adol),
        val11: formatInputVal(m1?.leaveDays_MedOnly_Adol),
        val12: formatInputVal(m1?.leaveDays_NonMedOnly_Adol),

        val13: formatInputVal(m1?.popCount_MedOnly_Adult),
        val14: formatInputVal(m1?.popCount_NonMedOnly_Adult),
        val15: formatInputVal(m1?.psychInpDays_MedOnly_Adult),
        val16: formatInputVal(m1?.psychInpDays_NonMedOnly_Adult),
        val17: formatInputVal(m1?.leaveDays_MedOnly_Adult),
        val18: formatInputVal(m1?.leaveDays_NonMedOnly_Adult),

        val19: formatInputVal(m1?.popCount_MedOnly_Gero),
        val20: formatInputVal(m1?.popCount_NonMedOnly_Gero),
        val21: formatInputVal(m1?.psychInpDays_MedOnly_Gero),
        val22: formatInputVal(m1?.psychInpDays_NonMedOnly_Gero),
        val23: formatInputVal(m1?.leaveDays_MedOnly_Gero),
        val24: formatInputVal(m1?.leaveDays_NonMedOnly_Gero),

        val25: formatInputVal(m2?.count_Anxiety),
        val26: formatInputVal(m2?.count_Cognitive),
        val27: formatInputVal(m2?.count_Mood),
        val28: formatInputVal(m2?.count_Psychotic),
        val29: formatInputVal(m2?.count_Alcohol),
        val30: formatInputVal(m2?.count_Substance),
        val31: formatInputVal(m2?.count_Other),
      }));

      setLoaded(true);
    });
  }, [selectedMonth]);

  const handleMonthChange = (option: SelectOption): void => {
    setSelectedMonth(option);
  };

  const setLeaveDaysTo0 = (): void => {
    setInputVals((prev) => ({
      ...prev,
      val5: '0',
      val6: '0',
      val11: '0',
      val12: '0',
      val17: '0',
      val18: '0',
      val23: '0',
      val24: '0',
    }));
  };

  const setInputVal = (num: number, val: string): void => {
    const valNum = Number(val.replace(/[^0-9]/g, '').substring(0, 6));
    const newVal = formatInputVal(valNum);
    setInputVals((prev) => ({
      ...prev,
      [`val${num}`]: newVal,
    }));
  };

  const buildInput = (num: number, isCalculated: boolean): JSX.Element => (
    <TextInput
      name={`inputVal${num}`}
      type="text"
      variant={TextInputVariant.PRIMARY}
      value={inputVals[`val${num}`]}
      onChange={(e) => { setInputVal(num, e.target.value); }}
      inputMode="numeric"
      disabled={isCalculated && Boolean(Number(isFormFacility)) === false}
    />
  );

  const cancel = (): void => {
    history.push(AllRoutes.CORE_MEASURES_FACILITY_INFO);
  };

  const save = (): void => {
    const popData: CoreMeasuresMonthlyPopulationPostModel = {
      summaryID: monthlyPopulationModel ? monthlyPopulationModel.summaryID : -1,

      popCount_MedOnly_Child: Number(inputVals.val1),
      popCount_NonMedOnly_Child: Number(inputVals.val2),
      psychInpDays_MedOnly_Child: Number(inputVals.val3),
      psychInpDays_NonMedOnly_Child: Number(inputVals.val4),
      leaveDays_MedOnly_Child: Number(inputVals.val5),
      leaveDays_NonMedOnly_Child: Number(inputVals.val6),

      popCount_MedOnly_Adol: Number(inputVals.val7),
      popCount_NonMedOnly_Adol: Number(inputVals.val8),
      psychInpDays_MedOnly_Adol: Number(inputVals.val9),
      psychInpDays_NonMedOnly_Adol: Number(inputVals.val10),
      leaveDays_MedOnly_Adol: Number(inputVals.val11),
      leaveDays_NonMedOnly_Adol: Number(inputVals.val12),

      popCount_MedOnly_Adult: Number(inputVals.val13),
      popCount_NonMedOnly_Adult: Number(inputVals.val14),
      psychInpDays_MedOnly_Adult: Number(inputVals.val15),
      psychInpDays_NonMedOnly_Adult: Number(inputVals.val16),
      leaveDays_MedOnly_Adult: Number(inputVals.val17),
      leaveDays_NonMedOnly_Adult: Number(inputVals.val18),

      popCount_MedOnly_Gero: Number(inputVals.val19),
      popCount_NonMedOnly_Gero: Number(inputVals.val20),
      psychInpDays_MedOnly_Gero: Number(inputVals.val21),
      psychInpDays_NonMedOnly_Gero: Number(inputVals.val22),
      leaveDays_MedOnly_Gero: Number(inputVals.val23),
      leaveDays_NonMedOnly_Gero: Number(inputVals.val24),

      errorMessage: null,

      statusID: 1,
      createdBy: user?.email ?? 'user not found',
      updatedBy: user?.email ?? 'user not found',
    };
    const countData: CoreMeasuresDiagnosticCategoryCountsPostModel = {
      facilityID: diagnosticCategoryCountsModel ? diagnosticCategoryCountsModel.facilityID : -1,
      dxCatYear: diagnosticCategoryCountsModel ? diagnosticCategoryCountsModel.dxCatYear : -1,
      dxCatMonth: diagnosticCategoryCountsModel ? diagnosticCategoryCountsModel.dxCatMonth : -1,

      count_Anxiety: Number(inputVals.val25),
      count_Cognitive: Number(inputVals.val26),
      count_Mood: Number(inputVals.val27),
      count_Psychotic: Number(inputVals.val28),
      count_Alcohol: Number(inputVals.val29),
      count_Substance: Number(inputVals.val30),
      count_Other: Number(inputVals.val31),
      calculated: diagnosticCategoryCountsModel ? diagnosticCategoryCountsModel.calculated : 'N',

      statusID: 1,
      createdBy: user?.email ?? 'user not found',
      updatedBy: user?.email ?? 'user not found',
    };
    FacilityInfoProxy.postMonthlyPopulation([popData]);
    FacilityInfoProxy.postDiagnosticCategoryCounts([countData]);
  }

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Core Measures Facility Info Population Entry">
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="core-measures-facility-info-population-entry-content">
        <form>
          <PrimaryHeader text="Core Measures Facility Info Population Entry" fontColor={FontColors.PRIMARY} marginTopPx={16} marginBottomPx={16} />

          <div style={{ backgroundColor: '#f4f4f4', paddingLeft: '15px', paddingRight: '15px', paddingTop: '1px', paddingBottom: '1px', marginBottom: '15px' }}>
            <p>Select a month and year from the available options (NOTE: Months with previously submitted data will not be displayed)</p>
            <p>
              <strong>Initial Patient Population Count:</strong>
              <span>&nbsp;Indicate the <span style={{ textDecoration: 'underline' }}>number of episode of care records</span> for patients discharged during the current month <span style={{ textDecoration: 'underline' }}>with</span> Medicare as a payment source and for patients <span style={{ textDecoration: 'underline' }}>without</span> Medicare as a payment source, <span style={{ textDecoration: 'underline' }}>by age groups</span> as designated below.</span>
            </p>
            <p>
              <strong>Psychiatric Inpatient Days:</strong>
              <span>&nbsp;Indicate the <span style={{ textDecoration: 'underline' }}>total number of patient days</span> for the <span style={{ textDecoration: 'underline' }}>psychatric inpatient</span> census during the month <span style={{ textDecoration: 'underline' }}>by age groups</span> as designated below. Record the total number of patient days for patients <span style={{ textDecoration: 'underline' }}>with</span> Medicare as a payment source and for patients <span style={{ textDecoration: 'underline' }}>without</span> Medicare as a payment source separately.</span>
            </p>
            <p>
              <strong>Total Leave Days:</strong>
              <span>&nbsp;Indicate the <span style={{ textDecoration: 'underline' }}>total number of &quot;absent&quot; and/or &quot;leave&quot; days</span> for the <span style={{ textDecoration: 'underline' }}>psychiatric inpatient</span> census during the month <span style={{ textDecoration: 'underline' }}>by age groups</span> as designated below. Record the total number of patient days for patients <span style={{ textDecoration: 'underline' }}>with</span> Medicare as a payment source and for patients <span style={{ textDecoration: 'underline' }}>without</span> Medicare as a payment source separately.</span>
            </p>
            <p>
              <strong>Diagnostic Category Count:</strong>
              <span>&nbsp;Indicate the <span style={{ textDecoration: 'underline' }}>total number of discharges</span> for the current month with a <span style={{ textDecoration: 'underline' }}>primary</span> diagnosis falling into each category.</span>
            </p>
          </div>

          {months.length > 0 && (
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Month (Yr):"
              options={months}
              defaultValue={{ label: 'Loading...', value: '' }}
              value={selectedMonth}
              onSelection={handleMonthChange}
            />
          )}

          {loaded && (
            <div style={{ marginTop: '15px' }}>
              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th colSpan={2} style={{ verticalAlign: 'top' }}>Initial Patient Population Count</th>
                    <th colSpan={2} style={{ verticalAlign: 'top' }}>Psychiatric Inpatient Days</th>
                    <th colSpan={2}>
                      <div>Total Leave Days</div>
                      <div>
                        <a href="https://validurl.com" onClick={(e) => { e.preventDefault(); setLeaveDaysTo0(); }}>Set all Leave Days to 0</a>
                      </div>
                    </th>
                  </tr>
                  <tr>
                    <th> </th>
                    <th>Medicare Only</th>
                    <th>Non-Medicare Only</th>
                    <th>Medicare Only</th>
                    <th>Non-Medicare Only</th>
                    <th>Medicare Only</th>
                    <th>Non-Medicare Only</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Child (1 - 12 years):</td>
                    <td>{buildInput(1, true)}</td>
                    <td>{buildInput(2, true)}</td>
                    <td>{buildInput(3, true)}</td>
                    <td>{buildInput(4, true)}</td>
                    <td>{buildInput(5, false)}</td>
                    <td>{buildInput(6, false)}</td>
                  </tr>
                  <tr>
                    <td>Adolescent (13 - 17 years):</td>
                    <td>{buildInput(7, true)}</td>
                    <td>{buildInput(8, true)}</td>
                    <td>{buildInput(9, true)}</td>
                    <td>{buildInput(10, true)}</td>
                    <td>{buildInput(11, false)}</td>
                    <td>{buildInput(12, false)}</td>
                  </tr>
                  <tr>
                    <td>Adult (18 - 64 years):</td>
                    <td>{buildInput(13, true)}</td>
                    <td>{buildInput(14, true)}</td>
                    <td>{buildInput(15, true)}</td>
                    <td>{buildInput(16, true)}</td>
                    <td>{buildInput(17, false)}</td>
                    <td>{buildInput(18, false)}</td>
                  </tr>
                  <tr>
                    <td>Geriatric (&gt;= 65 years):</td>
                    <td>{buildInput(19, true)}</td>
                    <td>{buildInput(20, true)}</td>
                    <td>{buildInput(21, true)}</td>
                    <td>{buildInput(22, true)}</td>
                    <td>{buildInput(23, false)}</td>
                    <td>{buildInput(24, false)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {loaded && (
            <div style={{ marginTop: '15px' }}>
              <table>
                <thead>
                  <tr>
                    <th> </th>
                    <th>Diagnostic Category Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Anxiety disorders (651):</td>
                    <td>{buildInput(25, true)}</td>
                  </tr>
                  <tr>
                    <td>Delirium, dementia, and amnestic and other cognitive disorders (653):</td>
                    <td>{buildInput(26, true)}</td>
                  </tr>
                  <tr>
                    <td>Mood disorders (657):</td>
                    <td>{buildInput(27, true)}</td>
                  </tr>
                  <tr>
                    <td>Schizophrenia and other psychotic disorders (662):</td>
                    <td>{buildInput(28, true)}</td>
                  </tr>
                  <tr>
                    <td>Alcohol-related disorders (660):</td>
                    <td>{buildInput(29, true)}</td>
                  </tr>
                  <tr>
                    <td>Substance-related disorders (661):</td>
                    <td>{buildInput(30, true)}</td>
                  </tr>
                  <tr>
                    <td>Other diagnosis - not included above:</td>
                    <td>{buildInput(31, true)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            justify={JustifyVariant.START}
            align={AlignVariant.START}
            extraClasses="button-container"
          >
            <Button
              variant={ButtonVariant.PRIMARY}
              onClick={save}
            >
              <InlineText
                text="Save"
                fontColor={FontColors.BACKGROUND}
                fontSize={FontSizes.REGULAR}
                bold
              />
            </Button>
            <Button
              variant={ButtonVariant.SECONDARY}
              onClick={cancel}
            >
              <InlineText
                text="Cancel"
                fontColor={FontColors.BACKGROUND}
                fontSize={FontSizes.REGULAR}
                bold
              />
            </Button>
          </FlexContainer>
        </form>
      </FlexContainer>
    </PageLayout>
  );
};

export default CoreMeasuresFacilityInfoPopulationEntryPage;
