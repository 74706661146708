import React, { useState, useEffect, useContext } from 'react';

import './styles.scss';

import DataListsProxy from 'api/dataLists/dataListsProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import Paragraph from 'global_elements/Text/Paragraph';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { FacilitySelectOption } from 'types/inputProps';

import { UserContext } from 'context/user';
import { ActiveFacilityContext } from 'context/activeFacility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faFolder, faFolderOpen, faFolderPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faFile, faFileExcel, faFilePdf, faFileVideo, faFileWord } from '@fortawesome/free-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import AttachmentsProxy from '../../../api/attachments/AttachmentsProxy';
import { AttachmentFolderContentsModel, AttachmentFolderModel, AttachmentModel } from '../../../interfaces/attachments/AttachmentFolderContentsModel';
import TertiaryHeader from '../../../global_elements/Text/TertiaryHeader';
import { AllRoutes } from '../../../constants/routes';

type FolderWrapper = {
  folder: AttachmentFolderModel;
  subfolders: FolderWrapper[];
};

const FacilityLibraryPage = (): JSX.Element => {
  const { user } = useContext(UserContext);
  const isAdmin = user?.role === 'admin' || user?.role === 'super-admin';
  const [availableFacilities, setAvailableFacilities] = useState<FacilitySelectOption[]>([]);
  const { facility } = useContext(ActiveFacilityContext);
  const [selectedFacility, setSelectedFacility] = useState<FacilitySelectOption>();
  const [facilityFolders, setFacilityFolders] = useState<FolderWrapper[]>();
  const [currentFolder, setCurrentFolder] = useState<FolderWrapper>();
  const [currentFolderContents, setCurrentFolderContents] = useState<AttachmentFolderContentsModel>();
  const history = useHistory();
  const [dropzoneHoverClass, setDropzoneHoverClass] = useState<string>('');

  useEffect(() => {
    DataListsProxy.getAllUserFacilities(
      (response) => {
        if (response?.data) {
          const facilityOptions: FacilitySelectOption[] = [];

          response.data
            .sort((a, b) => {
              if (a.facilityName.toLowerCase() < b.facilityName.toLowerCase()) {
                return -1;
              }

              if (a.facilityName.toLowerCase() > b.facilityName.toLowerCase()) {
                return 1;
              }

              return 0;
            })
            .forEach((element) => {
              if (element.facilityName && element.facilityID) {
                facilityOptions.push({
                  label: element.facilityName,
                  value: element.facilityID.toString(),
                  isCoreMeasures: !!element.coreMeasures, // Convert numbers to boolean
                  hasServiceCodes: !!element.svcCode,
                  isOutcomes: !!element.outcomes,
                });
              }
            });

          setAvailableFacilities(facilityOptions);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, []);

  const handleFacilitySelectChange = (option: FacilitySelectOption): void => {
    setSelectedFacility(option);
    setFacilityFolders(undefined);
    setCurrentFolder(undefined);
    setCurrentFolderContents(undefined);
    const facilityId = parseInt(option.value, 10);
    if (Number.isNaN(facilityId) || facilityId === 0) {
      return;
    }
    AttachmentsProxy.getFacilityContents(facilityId)
      .then((r) => {
        if (r.data) {
          const facilityFeatureFolders = r.data.folders.filter((f) => {
            if (option.isOutcomes && f.facilityFolderType === 0) {
              return true;
            }
            if (option.isCoreMeasures && f.facilityFolderType === 1) {
              return true;
            }
            return false;
          });
          const wrappers = facilityFeatureFolders.map<FolderWrapper>((f) => ({ folder: f, subfolders: [] }));
          setFacilityFolders(wrappers);
        }
      });
  };

  useEffect(() => {
    handleFacilitySelectChange(facility);
  }, [facility]);

  const LoadFolder = (folder: FolderWrapper): void => {
    AttachmentsProxy.getFolderContents(folder.folder.attachmentFolderId)
      .then((c) => {
        if (c.data) {
          const newSubfolders = c.data.folders.map<FolderWrapper>((f) => ({ folder: f, subfolders: [] }));
          for (let i = 0; i < newSubfolders.length; i += 1) {
            const oldSubfolders = folder.subfolders.filter((sf) => sf.folder.attachmentFolderId === newSubfolders[i].folder.attachmentFolderId);
            if (oldSubfolders.length === 1) {
              newSubfolders[i].subfolders = oldSubfolders[0].subfolders;
            }
          }
          folder.subfolders = newSubfolders;
          setCurrentFolder(folder);
          setCurrentFolderContents(c.data);
        }
      })
      .catch((e) => console.log(e));
  }

  useEffect(() => {
    if (facilityFolders && facilityFolders.length > 0) {
      LoadFolder(facilityFolders[0]);
    }
  }, [facilityFolders]);

  const NewFolder = (): void => {
    const name = window.prompt('New folder name?', 'New folder');
    if (!name || !currentFolder) {
      return;
    }
    AttachmentsProxy.saveFolder(undefined, currentFolder.folder.attachmentFolderId, name)
      .then(() => {
        LoadFolder(currentFolder);
      });
  }

  const EditFolder = (folder: AttachmentFolderModel): void => {
    const newName = window.prompt('New name?', folder.name);
    if (!newName || !currentFolder) {
      return;
    }
    AttachmentsProxy.saveFolder(folder.attachmentFolderId, currentFolder.folder.attachmentFolderId, newName)
      .then(() => {
        LoadFolder(currentFolder)
      });
  }

  const DeleteFolder = (folder: AttachmentFolderModel): void => {
    if (!currentFolder || !window.confirm(`Are you sure you want to delete, '${folder.name}'?`)) {
      return;
    }
    AttachmentsProxy.deleteFolder(folder.attachmentFolderId)
      .then(() => {
        LoadFolder(currentFolder);
      });
  };

  const GetFileIcon = (contentType: string): IconDefinition => {
    const lowerContentType = contentType.toLowerCase();
    switch (lowerContentType) {
      case 'application/msword': return faFileWord;
      case 'application/pdf': return faFilePdf;
      case 'application/vnd.ms-excel': return faFileExcel;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': return faFileWord;
      case 'video/mp4': return faFileVideo;
      default: return faFile;
    }
  }

  const OpenAttachment = (file: AttachmentModel): void => {
    if (file.contentType === 'application/pdf') {
      history.push(`${AllRoutes.ATTACHMENT_DOWNLOAD}/${file.attachmentId}`);
    } else {
      AttachmentsProxy.download(file.attachmentId)
        .then((response) => {
          if (response.data) {
            const dl = new File([response.data], file.name, { type: file.contentType });
            const dlUrl = URL.createObjectURL(dl);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = dlUrl;
            a.download = file.name;
            a.target = '_self';
            a.click();
            URL.revokeObjectURL(dlUrl);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const EditAttachment = (file: AttachmentModel): void => {
    const newName = window.prompt('New name?', file.name);
    if (!newName) {
      return;
    }
    AttachmentsProxy.saveAttachment(file.attachmentId, newName)
      .then(() => {
        LoadFolder(currentFolder!);
      });
  }

  const DeleteAttachment = (file: AttachmentModel): void => {
    if (!window.confirm(`Are you sure you want to delete, '${file.name}'?`)) {
      return;
    }
    AttachmentsProxy.deleteAttachment(file.attachmentId)
      .then(() => {
        LoadFolder(currentFolder!);
      });
  }

  const UploadFiles = (files: FileList | null): void => {
    if (!files || files.length < 1 || !currentFolder) {
      return;
    }
    AttachmentsProxy.upload(currentFolder.folder.attachmentFolderId, files)
      .then(() => {
        LoadFolder(currentFolder);
      });
  };

  const RenderFolder = (folder: FolderWrapper): JSX.Element => (
    <>
      <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses={`facility-library-folder ${currentFolder?.folder.attachmentFolderId === folder.folder.attachmentFolderId ? 'facility-library-folder-selected' : ''}`}>
        <a href="https://validurl.com" onClick={(e) => { e.preventDefault(); LoadFolder(folder); }}>
          <FontAwesomeIcon icon={currentFolder?.folder.attachmentFolderId === folder.folder.attachmentFolderId ? faFolderOpen : faFolder} style={{ paddingRight: '5px' }} />
          <span>{folder.folder.name}</span>
        </a>
      </FlexContainer>
      <div style={{ marginLeft: '15px' }}>
        {folder.subfolders.length > 0 && folder.subfolders.map((sf) => RenderFolder(sf))}
      </div>
    </>
  );

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Facility Library">
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="facility-library-content">
        <form>
          <PrimaryHeader text="Facility Library" fontColor={FontColors.PRIMARY} marginTopPx={16} marginBottomPx={16} />
          {isAdmin && availableFacilities.length === 0 && (
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Facility"
              options={[{ label: 'Loading...', value: '' }]}
              defaultValue={{ label: 'Loading...', value: '' }}
              value={{ label: 'Loading...', value: '' }}
              onSelection={handleFacilitySelectChange}
            />
          )}
          {isAdmin && availableFacilities.length > 0 && (
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Facility"
              options={availableFacilities}
              defaultValue={selectedFacility ?? { label: 'Select a Facility', value: '' }}
              value={selectedFacility}
              onSelection={handleFacilitySelectChange}
            />
          )}
          {!isAdmin && (facility.value === '' || facility.value === '0') && (
            <Paragraph text="Please select a facility in the header to search" fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.EXTRA_LARGE} />
          )}
        </form>
        {selectedFacility && selectedFacility.value !== '0' && facilityFolders && (
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="facility-library-container">
            <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="facility-library-folders-container">
              {facilityFolders.map((f) => RenderFolder(f))}
            </FlexContainer>
            {currentFolder && currentFolderContents && (
              <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="facility-library-contents">
                <TertiaryHeader text={currentFolder.folder.name} fontColor={FontColors.PRIMARY} />
                {isAdmin && (
                  <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="facility-library-admin">
                    <button type="button" aria-label="New Folder" onClick={() => { NewFolder(); }}>
                      <FontAwesomeIcon icon={faFolderPlus} />
                      &nbsp;
                      <span>New Folder</span>
                    </button>
                  </FlexContainer>
                )}
                <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="facility-library-contents-container">
                  {currentFolderContents.folders.map((f) => (
                    <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="facility-library-contents-folder">
                      <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="facility-library-contents-folder-name">
                        <span>
                          <a href="https://validurl.com" onClick={(e) => { e.preventDefault(); LoadFolder(currentFolder.subfolders.filter((sf) => sf.folder.attachmentFolderId === f.attachmentFolderId)[0]); }}>
                            <FontAwesomeIcon icon={faFolder} style={{ paddingRight: '5px' }} />
                            <span>{f.name}</span>
                          </a>
                          {isAdmin && (
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <FontAwesomeIcon icon={faPencil} style={{ paddingLeft: '5px', cursor: 'pointer' }} onClick={() => { EditFolder(f); }} />
                              <FontAwesomeIcon icon={faTrash} style={{ paddingLeft: '3px', cursor: 'pointer' }} onClick={() => { DeleteFolder(f); }} />
                            </span>
                          )}
                        </span>
                      </FlexContainer>
                    </FlexContainer>
                  ))}
                  {currentFolderContents.files.map((f) => (
                    <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="facility-library-contents-file">
                      <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="facility-library-contents-file-name">
                        <span>
                          <a href="https://validurl.com" onClick={(e) => { e.preventDefault(); OpenAttachment(f); }}>
                            <FontAwesomeIcon icon={GetFileIcon(f.contentType)} style={{ paddingRight: '5px' }} />
                            <span>{f.name}</span>
                          </a>
                          {isAdmin && (
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <FontAwesomeIcon icon={faPencil} style={{ paddingLeft: '5px', cursor: 'pointer' }} onClick={() => { EditAttachment(f); }} />
                              <FontAwesomeIcon icon={faTrash} style={{ paddingLeft: '3px', cursor: 'pointer' }} onClick={() => { DeleteAttachment(f); }} />
                            </span>
                          )}
                        </span>
                      </FlexContainer>
                    </FlexContainer>
                  ))}
                </FlexContainer>
                {isAdmin && (
                  <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses={`facility-library-dropzone ${dropzoneHoverClass}`}>
                    <span>Drop Files Here (or click)</span>
                    <input type="file" multiple onChange={(e) => { UploadFiles(e.target.files); e.currentTarget.value = ''; setDropzoneHoverClass(''); }} onDragEnter={() => setDropzoneHoverClass('facility-library-dropzone-hover')} onDragOver={() => setDropzoneHoverClass('facility-library-dropzone-hover')} onDragLeave={() => setDropzoneHoverClass('')} />
                  </FlexContainer>
                )}
              </FlexContainer>
            )}
          </FlexContainer>
        )}
      </FlexContainer>
    </PageLayout>
  );
};

export default FacilityLibraryPage;
