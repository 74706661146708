export enum Roles {
    NONE = '',
    PATIENT = 'patient',
    PROVIDER = 'provider',
    FACILITY_DIRECTOR = 'facility-director',
    FACILITY_EXECUTIVE = 'facility-executive',
    CORPORATION = 'corporation',
    SUPER_CORPORATION = 'super-corporation',
    ADMIN = 'admin',
    SUPER_ADMIN = 'super-admin',
}

export type UserRole = `${Roles}`;
