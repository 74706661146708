import * as React from 'react';

import './styles.scss';

import { ButtonProps } from 'types/buttonProps';

const Button = React.forwardRef((props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>): JSX.Element => {
  const buttonClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    e.currentTarget.blur();
    props.onClick(e);
  };

  return (
    <button
      ref={ref}
      type={props.submit ? 'submit' : 'button'}
      onClick={buttonClick}
      className={`btn ${props.variant} ${props.extraClasses || ''}`}
      disabled={props.disabled ?? false}
      tabIndex={0}
      title={props.tooltipText}
      data-testid={props.testid}
    >
      {props.children}
    </button>
  );
});

export default Button;
