import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useOutsideClick } from 'rooks';

import './styles.scss';

import { ReactComponent as BellIcon } from 'icons/bell-icon.svg';
import { ReactComponent as AccountIcon } from 'icons/account-icon.svg';
import { ReactComponent as Branding } from 'icons/reflections-logo.svg';
import { ReactComponent as X } from 'icons/mho-x-icon.svg';
import { ReactComponent as Hamburger } from 'icons/hamburger-menu-icon.svg';

import { UserContext } from 'context/user';
import { ActiveFacilityContext } from 'context/activeFacility';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import NavLinkWrapper from 'global_elements/Navigation/NavLinkWrapper';
import Button from 'global_elements/Button';
import SingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect';
import PopupWindow from 'global_elements/Layouts/PopupWindow';
import Paragraph from 'global_elements/Text/Paragraph';

import { ButtonVariant } from 'global_elements/Button/variants';
import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { NavLinkVariant } from 'global_elements/Navigation/NavLinkWrapper/variants';
import { FontSizes, FontColors } from 'global_elements/Text/variants';

import { HeaderProps } from 'types/headerProps';
import { SingleHeaderDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { AdminRoutes, AllRoutes } from 'constants/routes';
import { Roles } from 'constants/roles';
import { FacilitySelectOption } from 'types/inputProps';
import HiddenTestText from 'global_elements/Text/HiddenTestText';
import { FacilityUserTools } from 'lib/Facility/tools';
import { FacilityConst } from 'lib/Facility/facilityConsts';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';
import MHODateTime from 'domain/dateTime/MHODateTime';
import { NotificationData } from 'interfaces/patients/notificationData';

const Header = (props: HeaderProps): JSX.Element => {
  const { user, userAccount, Logout } = React.useContext(UserContext);
  const { facility, SwitchFacility } = React.useContext(ActiveFacilityContext);
  const [facilityOptions, setFacilityOptions] = React.useState<FacilitySelectOption[]>([]);
  const [accountDropActive, setAccountDropActive] = React.useState(false);
  const [notificationsDropActive, setNotificationDropActive] = React.useState(false);
  const [notifications, setNotifications] = React.useState<NotificationData[]>([]);
  const [selectedNotification, setSelectedNotification] = React.useState<NotificationData>();
  const history = useHistory();
  const accountRef = React.useRef(null);
  const notificationsRef = React.useRef(null);
  const notificationDetailRef = React.useRef(null);
  const isPatientRegistrationPage = useRouteMatch(`${AllRoutes.PATIENT_REGISTRATION}/:facilityID`);
  const isPatientAccountView = useRouteMatch(`${AdminRoutes.PATIENT_ACCOUNTS}/:patient`);
  const isPatientAccountSetup = useRouteMatch(`${AdminRoutes.PATIENT_ACCOUNTS}/:patient/account-setup`);

  React.useEffect(() => {
    if (userAccount && userAccount.patientID) {
      PatientUsersProxy.getPatientNotifications(
        userAccount.patientID,
        (resp) => {
          const allNotifications = resp.data ? resp.data : [];
          setNotifications(allNotifications);
        },
        (e) => console.error(e),
      );
    }
  }, [userAccount, selectedNotification]);

  React.useEffect(() => {
    if (!user || user.facilities.length <= 0) {
      return;
    }

    if (user.role === Roles.SUPER_ADMIN || user.role === Roles.ADMIN) {
      FacilityUserTools.getUserFacilityOptions()
        .then((newFacilityOptions: FacilitySelectOption[]) => {
          setFacilityOptions(newFacilityOptions);
          if (facility.value === '') {
            SwitchFacility(FacilityConst.ALL_FACILITY_OPTION);
          }
        })
        .catch((err: any) => console.log(err));
    } else {
      const showAllFacilities = user.facilities.length > 3 && (user.role === Roles.CORPORATION || user.role === Roles.SUPER_CORPORATION);
      const newFacilityOptions: FacilitySelectOption[] = FacilityUserTools.getFacilityOptions(user.facilities, true, showAllFacilities);
      setFacilityOptions(newFacilityOptions);
      if (facility.value === '') {
        SwitchFacility(newFacilityOptions[0]);
      }
    }
  }, [user]);

  const ShowAccountMenu = (): void => {
    setAccountDropActive(!accountDropActive);
  };

  const ShowNotificationsMenu = (): void => {
    setNotificationDropActive(!notificationsDropActive);
  };

  const HideAccountMenu = (): void => {
    setTimeout(() => {
      if (accountDropActive) {
        setAccountDropActive(false);
      }
    }, 1);
  };

  const HideNotifcationsMenu = (): void => {
    setTimeout(() => {
      if (notificationsDropActive) {
        setNotificationDropActive(false);
      }
    }, 1);
  };

  const ToggleHamburgerMenu = (): void => {
    const nav = document.getElementById('mho-nav-bar');
    if (nav) {
      nav.classList.toggle('shown');
    }
  };

  const ShowSelectedNotification = (notification: NotificationData): void => {
    const updatedNotification = notification;
    updatedNotification.activatedDate = notification.activatedDate || new MHODateTime(new Date()).getDateTimeForPost();
    updatedNotification.statusID = 1;
    PatientUsersProxy.savePatientNotification(
      updatedNotification,
      (resp) => console.log(resp),
      (resp) => console.error(resp),
    );
    setSelectedNotification(notification);
  };

  useOutsideClick(notificationDetailRef, () => setSelectedNotification(undefined));
  useOutsideClick(accountRef, HideAccountMenu);
  useOutsideClick(notificationsRef, HideNotifcationsMenu);

  const NavToAccount = (): void => {
    history.push(AllRoutes.ACCOUNT);
    HideNotifcationsMenu();
    HideAccountMenu();
  };

  const NavToLogin = (): void => {
    Logout();
    HideNotifcationsMenu();
    HideAccountMenu();
  };

  const newNotifications = (): string => {
    let num = 0;

    for (let i = 0; i < notifications.length; i += 1) {
      if (notifications[i].newNotification) {
        num += 1;
      }
    }

    return num > 0 ? `${num}` : '';
  };

  const notificationBlock = (content: NotificationData): JSX.Element => (
    <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="notification">
      <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.START} extraClasses="notification__clickable">
        <Button variant={ButtonVariant.INVISIBLE} onClick={() => ShowSelectedNotification(content)}>
          <InlineText text={content.notificationTitle} fontColor={FontColors.SECONDARY} fontSize={FontSizes.REGULAR} underlined />
        </Button>
        {content.newNotification && <div className="new" />}
      </FlexContainer>
      {/* {content.dateCreated
              && (
              <InlineText
                  text={new MHODateTime(content.dateCreated).getDateTimeForDisplay()}
                  fontColor={FontColors.DARK_GRAY}
                  fontSize={FontSizes.SMALL}
              />
              )} */}
    </FlexContainer>
  );

  const renderNotificationDetailPopup = (): JSX.Element => (
    <div ref={notificationDetailRef} className="notification-detail-wrapper">
      <Button
        variant={ButtonVariant.INVISIBLE}
        onClick={() => {
          history.push('/');
          setSelectedNotification(undefined);
        }}
        extraClasses="notification-nav-button"
      >
        <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.CENTER} extraClasses="notification-detail">
          <PopupWindow>
            <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="close-button">
              <Button
                variant={ButtonVariant.INVISIBLE}
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  setSelectedNotification(undefined);
                }}
              >
                <X />
              </Button>
            </FlexContainer>
            <InlineText text={selectedNotification?.notificationTitle || ''} fontColor={FontColors.PRIMARY} fontSize={FontSizes.EXTRA_LARGE} />
            {/* <InlineText
                            text={new MHODateTime(selectedNotification ? selectedNotification.dateCreated : new Date()).getDateTimeForDisplay()}
                            fontColor={FontColors.DARK_GRAY}
                            fontSize={FontSizes.SMALL}
                        /> */}
            <Paragraph text={selectedNotification?.notificationMessage || ''} fontColor={FontColors.DARK} fontSize={FontSizes.LARGE} />
            <Paragraph
              // text={selectedNotification?.notificationAdditional || ''}
              text=""
              fontColor={FontColors.DARK}
              fontSize={FontSizes.LARGE}
            />
            {/* <Paragraph
                            text={selectedNotification?.notificationFooter || ''}
                            fontColor={FontColors.DARK_GRAY}
                            fontSize={FontSizes.SMALL}
                            extraClasses="footer"
                        /> */}
            <Paragraph text="Click to view available assessments" fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} extraClasses="footer underlined" />
          </PopupWindow>
        </FlexContainer>
      </Button>
    </div>
  );

  const renderAccountDropdown = (): JSX.Element => (
    <PopupWindow>
      <div ref={accountRef}>
        <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="header-menu account-menu">
          <Button variant={ButtonVariant.INVISIBLE} onClick={NavToAccount}>
            <InlineText text="Account" fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} />
          </Button>
          <Button variant={ButtonVariant.INVISIBLE} onClick={NavToLogin}>
            <InlineText text="Logout" fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} />
          </Button>
        </FlexContainer>
      </div>
    </PopupWindow>
  );

  const renderNotificationsDropdown = (): JSX.Element => (
    <PopupWindow>
      <div ref={notificationsRef}>
        <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="header-menu notifications-menu">
          <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="close-button">
            <Button variant={ButtonVariant.INVISIBLE} onClick={() => setNotificationDropActive(false)}>
              <X />
            </Button>
          </FlexContainer>
          <Paragraph text="Notifications" fontColor={FontColors.DARK} fontSize={FontSizes.EXTRA_LARGE} />
          <Paragraph text={newNotifications() ? `${newNotifications()} new` : 'No new notifications'} fontColor={FontColors.DARK_GRAY} fontSize={FontSizes.SMALL} />
          {notifications.map((note, index) => (
            <React.Fragment key={`${note.notificationTitle}-${note.dateCreated}`}>
              {notificationBlock(note)}
              {index < notifications.length - 1 && <hr />}
            </React.Fragment>
          ))}
        </FlexContainer>
      </div>
    </PopupWindow>
  );

  const renderAccountButton = (): JSX.Element => (
    <NavLinkWrapper variant={NavLinkVariant.DECORATIVE} route={AllRoutes.ACCOUNT}>
      <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="account-button-container">
        <Button variant={ButtonVariant.INVISIBLE} onClick={ShowAccountMenu}>
          <HiddenTestText text="AccountButton" />
          <AccountIcon title="Account" />
        </Button>
        {accountDropActive && renderAccountDropdown()}
      </FlexContainer>
    </NavLinkWrapper>
  );

  const renderNotifcationsButton = (): any => userAccount
    && userAccount?.patientID && (
    <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="notifications-button-container">
      {newNotifications() && (
        <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="message-counter">
          <InlineText text={newNotifications()} fontColor={FontColors.BACKGROUND} fontSize={FontSizes.SMALL} />
        </FlexContainer>
      )}
      <Button variant={ButtonVariant.INVISIBLE} onClick={ShowNotificationsMenu}>
        <BellIcon title="Notifications" />
      </Button>
      {notificationsDropActive && renderNotificationsDropdown()}
    </FlexContainer>
  );

  const renderHeaderRight = (): JSX.Element => (
    <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.SPACE_BETWEEN} align={AlignVariant.CENTER} extraClasses="header-right-wrapper">
      <InlineText fontColor={FontColors.BACKGROUND} fontSize={FontSizes.EXTRA_LARGE} text={props.patientName} />
      <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER}>
        {user?.role !== Roles.PATIENT
          && user?.role !== Roles.ADMIN
          && user?.role !== Roles.SUPER_ADMIN
          && facility.value !== ''
          && facilityOptions.length > 0
          && !isPatientRegistrationPage
          && !isPatientAccountSetup
          && !isPatientAccountView && <SingleSelect styles={SingleHeaderDropdownStyles} defaultValue={facility} value={facility} onSelection={SwitchFacility} options={facilityOptions} />}
        {user?.role !== Roles.PATIENT
          && user?.role !== Roles.ADMIN
          && user?.role !== Roles.SUPER_ADMIN
          && facility.value !== ''
          && facilityOptions.length > 0
          && (isPatientRegistrationPage || isPatientAccountSetup || isPatientAccountView) && (
          <InlineText fontColor={FontColors.BACKGROUND} fontSize={FontSizes.EXTRA_LARGE} text={`${facility.label} (${facility.value})`} />
        )}
        {renderAccountButton()}
        {renderNotifcationsButton()}
      </FlexContainer>
    </FlexContainer>
  );

  return (
    <>
      <header>
        <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER}>
          <FlexContainer display={DisplayVariant.FLEX_ROW} justify={JustifyVariant.CENTER} align={AlignVariant.CENTER} extraClasses="branding">
            <Button variant={ButtonVariant.INVISIBLE} onClick={ToggleHamburgerMenu}>
              <Hamburger width="28px" height="28px" />
            </Button>
            <Branding />
          </FlexContainer>
          {renderHeaderRight()}
        </FlexContainer>
      </header>
      {selectedNotification && renderNotificationDetailPopup()}
    </>
  );
};

export default Header;
