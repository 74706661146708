import React from 'react';

import { Chart as ChartJS, BarController, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { WorkListChartRow, WorkListChartDataType } from 'types/tableProps';
import Utilities from 'api/lib/Utilities';

ChartJS.register(BarController, BarElement, Title, Tooltip, Legend, ChartDataLabels);

type ChartProps = {
  workListChartData: WorkListChartDataType,
  isLoadingChart: boolean,
  isProgram: boolean,
}

type chartDataType = {
  labels: string[][],
  datasets: any,
}

type ChartMetaData = {
  chartData: chartDataType,
  tooltipData: {[label: string]: any},
}

type ChartState = {
  programChartData: ChartMetaData,
  serviceChartData: ChartMetaData,
  tooltipData: any,
  chartOptions: any,
}

/**
 * Compomnet for Chart Management for the work list.
 *
 * @param props established data container for the chart data.
 */
export class WorkListChart extends React.Component<ChartProps, ChartState> {
  private loaderID = 'loading-worklist-chart';

  // background and foreground colors for each bar.
  private backgroundColors = ['teal', 'orange', 'lightblue', 'aquamarine', 'blue', 'purple', 'mediumseagreen', 'tan'];
  private valueColors = ['white', 'white', 'gray', 'gray', 'white', 'white', 'white', 'white'];

  constructor(props: any) {
    super(props);

    const tempChartMetaData: ChartMetaData = {
      tooltipData: {},
      chartData: {
        labels: [],
        datasets: [],
      },
    };

    this.state = {
      programChartData: tempChartMetaData,
      serviceChartData: tempChartMetaData,
      tooltipData: [],
      chartOptions: this.getDefaultChartOptions(),
    };
    const loading = document.getElementById(this.loaderID);
    loading?.classList.add('hidden');
  }

  componentDidUpdate(prevProps: any): void {
    if (prevProps.workListChartData.facilityID !== this.props.workListChartData.facilityID) {
      // Chart data
      this.parseWorkListData(this.props.workListChartData.chartData);
    }

    const loading = document.getElementById(this.loaderID);
    if (this.props.isLoadingChart) {
      loading?.classList.remove('hidden');
    } else {
      loading?.classList.add('hidden');
    }
  }

  /**
   * Construct and return a label list and dataset necessary to populate the chartjs.
   *
   * @param worklistData WorkList chart data from the database.
   * @returns the labels, datasets, and tooltip derieved from the Database.
   */
  private getChartData(worklistData: WorkListChartRow[]): ChartMetaData {
    const graphData: {[label: string]: number[]} = {};
    const tooltipDataInfo: {[label: string]: any} = {};
    const labels = new Set<string>();

    for (let index = 0; index < worklistData.length; index += 1) {
      const data = worklistData[index];
      if (graphData[data.barLabel] === undefined) {
        graphData[data.barLabel] = [];
        tooltipDataInfo[data.barLabel] = [];
      }

      labels.add(data.chartGroup);
      graphData[data.barLabel].push(data.dataValue);
      tooltipDataInfo[data.barLabel].push({
        monthly: data.dataValueMonthly,
        YTD: data.dataValueYTD,
      });
    }

    const datasets: any[] = [];
    Object.entries(graphData).forEach(([key, dataValues], index) => {
      datasets.push({
        backgroundColor: this.backgroundColors[index] || 'lightgray',
        label: key,
        data: dataValues,
        datalabels: {
          color: (context: any) => {
            const valueDataIndex = context.dataIndex;
            const value = context.dataset.data[valueDataIndex];
            return (value < 5 || !this.valueColors[index]) ? 'grey' : this.valueColors[index];
          },
        },
      });
    });

    const labelsList = Array.from(labels);
    return {
      chartData: {
        labels: labelsList.map((label: string) => label.split('\\n')),
        datasets,
      },
      tooltipData: tooltipDataInfo,
    };
  }

  /**
   * Create and retrieve the default options for chartjs.
   *
   * @returns The defined default options for chartjs.
   */
  private getDefaultChartOptions(): any {
    return {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          min: 0,
          max: 100,
          ticks: {
            stepSize: 25,
            callback: (value: any) => (`${value}%`),
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: 'Assessment Compliance (%)',
          font: {
            weight: 'bold',
            size: 24,
          },
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: this.chartTooltipCallback,
          },
        },
        legend: {
          position: 'top' as const,
        },
        datalabels: {
          formatter: (value: any) => {
            const adjustedValue = Math.round(value);
            return `${adjustedValue}%`;
          },
        },
      },
    };
  }

  private getChartPlugin(): any {
    return [{
      afterDraw: (chart: any) => {
        const { datasets } = chart.data;
        if (Utilities.isEmpty(datasets) || Utilities.isEmpty(datasets[0].data)) {
          const { ctx, width, height } = chart;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = '30px Arial';
          ctx.fillText('No Data to Display', width / 2, height / 2);
          ctx.restore();
        }
      },
    }];
  }

  /**
   *
   * @param context Chart JS callback data with context.
   * @returns tool tip text
   */
  private chartTooltipCallback = (context: any): string[] => {
    const { label } = context.dataset;
    const tooltipData = this.state.tooltipData[label] ? this.state.tooltipData[label][context.dataIndex] : undefined;

    let monthlyStr = 'N/A';
    let ytdStr = 'N/A';
    if (tooltipData) {
      monthlyStr = `${Utilities.roundToTwoDecimal(tooltipData.monthly)}%`;
      ytdStr = `${Utilities.roundToTwoDecimal(tooltipData.YTD)}%`;
    }

    return [
      `${label}: ${context.formattedValue}%`,
      `Monthly Compliance: ${monthlyStr}`,
      `YTD Compliance: ${ytdStr}`,
    ];
  }

  /**
   * Parse the worklist data from the database and create a label, dataset, tooltip data necessary to populate the chartjs.
   *
   * @param worklistData WorkList chart data from the database.
   */
  private parseWorkListData(worklistData: WorkListChartRow[]): void {
    const programList: WorkListChartRow[] = [];
    const serviceList: WorkListChartRow[] = [];
    worklistData.forEach((data) => {
      if (data.chartLevel === 'Program') {
        programList.push(data);
      } else if (data.chartLevel === 'ServiceCode') {
        serviceList.push(data);
      }
    });

    this.setState({
      programChartData: this.getChartData(programList),
      serviceChartData: this.getChartData(serviceList),
    });
  }

  render(): JSX.Element {
    return (
      <div className="loading-icon-overlay-container">
        <FontAwesomeIcon id={this.loaderID} icon={faSpinner} className="fa-spin loading-icon-overlay" />
        <Bar
          options={this.state.chartOptions}
          data={this.props.isProgram ? this.state.programChartData.chartData : this.state.serviceChartData.chartData}
          plugins={this.getChartPlugin()}
          redraw
        />
      </div>
    );
  }
}
