import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ApiResponse from 'api/lib/models/ApiResponse';
import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import PrimaryHeader from 'global_elements/Text/PrimaryHeader';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import PatientAssessmentCard from 'global_elements/Layouts/Cards/PatientAssessmentCard';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';
import UserProxy from 'api/user/userProxy';
import ApiDataUiConverter from 'pages/shared/assessments/ApiDataUiConvert';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { PatientAssessmentCardProps } from 'types/cardProps';
import { AssessmentData } from 'interfaces/assessments/assessmentData';
import { UserContext } from 'context/user';
import { AssessmentTypes, getAssessmentType } from 'constants/assessment_types';
import UserAccount from 'interfaces/users/userAccount';
import { PatientRoutes } from 'constants/routes';
import { StorageConstants } from 'constants/storageConstants';
import Paragraph from '../../../global_elements/Text/Paragraph';

/**
 * Checks and gets the list of assessment card props from the API assessment list.
 *
 * @param assessmentList list of API assessment list. Could be null.
 * @param assessmentTypeFilter optional filter to narrow the type 'ToDo', 'SelfCheck' and 'History'.
 * @returns a converted API assessment list to Card Prop.
 */
function getAssessmentCardProps(assessmentList: AssessmentData[] | null, assessmentTypeFilter?: AssessmentTypes): PatientAssessmentCardProps[] {
  let cardProps: PatientAssessmentCardProps[] = [];
  if (assessmentList) {
    cardProps = ApiDataUiConverter.convertAssessmentsToCardProps(assessmentList, assessmentTypeFilter);
  }
  return cardProps;
}

const PatientDashboardPage = (): JSX.Element => {
  const history = useHistory();
  const [isLoadingToDoCards, setIsLoadingToDoCards] = useState<boolean>(true);
  const [toDoCards, setToDoCards] = useState<PatientAssessmentCardProps[]>([]);
  const [isLoadingSelfCheckCards, setIsLoadingSelfCheckCards] = useState<boolean>(true);
  const [selfCheckCards, setSelfCheckCards] = useState<PatientAssessmentCardProps[]>([]);
  const [isLoadingHistoryCards, setIsLoadingHistoryCards] = useState<boolean>(true);
  const [historyCards, setHistoryCards] = useState<PatientAssessmentCardProps[]>([]);
  const { user, SetAccount } = useContext(UserContext);

  useEffect(() => {
    const userAccountIdStr = user ? user.accountId : '-1';
    const userAccountId = parseInt(userAccountIdStr, 10);

    UserProxy.getUserAccountInfo(
      userAccountId,
      (accountResponse: ApiResponse<UserAccount[]>) => {
        if (accountResponse.data?.length !== 1) return;
        const patientData: UserAccount = accountResponse.data[0];
        SetAccount(patientData);

        // If this is the user's first login or they haven't completed registration,
        // redirect them to the identity check flow.
        if (patientData.firstName === null && patientData.lastName === null) {
          history.replace(PatientRoutes.IDENTITY_CHECK);
        }

        PatientUsersProxy.getPatientAssessments(patientData.patientID).then((assessmentList: AssessmentData[]) => {
          const userCancelledDaisyChain = sessionStorage.getItem(StorageConstants.DaisyChainCancelled) === 'true'
          if (assessmentList.find((assessment) => (getAssessmentType(assessment.sortOrder) === AssessmentTypes.DAILY || getAssessmentType(assessment.sortOrder) === AssessmentTypes.FOLLOWUP)) && !userCancelledDaisyChain) {
            history.push(PatientRoutes.ASSESSMENTS)
          }
          const toDoCardProps: PatientAssessmentCardProps[] = getAssessmentCardProps(assessmentList, AssessmentTypes.DAILY);
          const selfCheckCardProps = getAssessmentCardProps(assessmentList, AssessmentTypes.SELFCHECK);
          setToDoCards(toDoCardProps);
          setSelfCheckCards(selfCheckCardProps);
        }).catch((errorResponse) => {
          console.log(errorResponse);
        }).finally(() => {
          setIsLoadingToDoCards(false);
          setIsLoadingSelfCheckCards(false);
        });

        PatientUsersProxy.getPatientCompletedAssessments(
          patientData.patientID,
        ).then((assessmentList: AssessmentData[]) => {
          const cardProps: PatientAssessmentCardProps[] = getAssessmentCardProps(assessmentList);
          setHistoryCards(cardProps);
        }).catch((errorResponse) => {
          console.log(errorResponse);
        }).finally(() => {
          setIsLoadingHistoryCards(false);
        });
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, [user]);

  const assessmentCard = (content: PatientAssessmentCardProps): JSX.Element => (
    <PatientAssessmentCard
      id={content.id}
      appliedWhenID={content.appliedWhenID}
      title={content.title}
      type={content.type}
      typeID={content.typeID}
      careID={content.careID}
      patientID={content.patientID}
      assessmentNumber={content.assessmentNumber}
      cta={content.cta}
      lastCompleted={content.lastCompleted}
      isTodo={content.isTodo}
    />
  );

  const currentAssessments = (): JSX.Element => (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.START}
      justify={JustifyVariant.START}
      extraClasses="current-assessments-panel"
    >
      <TertiaryHeader text="To-Do" fontColor={FontColors.DARK} marginBottomPx={16} />
      {isLoadingToDoCards && (
        <Paragraph text="Loading..." fontColor={FontColors.PRIMARY} fontSize={FontSizes.REGULAR} />
      )}
      {!isLoadingToDoCards && toDoCards && toDoCards.length > 0 && (
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          wrap
          extraClasses="assessment-card-container"
        >
          {toDoCards.map((card) => (assessmentCard(card)))}
        </FlexContainer>
      )}
      {!isLoadingToDoCards && (!toDoCards || toDoCards.length < 1) && (
        <Paragraph text="Nothing available at this time, please check back later." fontColor={FontColors.PRIMARY} fontSize={FontSizes.REGULAR} />
      )}
      <hr />
      <TertiaryHeader text="Self-Check" fontColor={FontColors.DARK} marginBottomPx={16} />
      {isLoadingSelfCheckCards && (
        <Paragraph text="Loading..." fontColor={FontColors.PRIMARY} fontSize={FontSizes.REGULAR} />
      )}
      {!isLoadingSelfCheckCards && selfCheckCards && selfCheckCards.length > 0 && (
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          wrap
          extraClasses="assessment-card-container"
        >
          {selfCheckCards.map((card) => (assessmentCard(card)))}
        </FlexContainer>
      )}
      {!isLoadingSelfCheckCards && (!selfCheckCards || selfCheckCards.length < 1) && (
        <Paragraph text="Nothing available at this time, please check back later." fontColor={FontColors.PRIMARY} fontSize={FontSizes.REGULAR} />
      )}
    </FlexContainer>
  );

  const historyAssessments = (): JSX.Element => (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.START}
      justify={JustifyVariant.START}
      extraClasses="history-assessments-panel"
    >
      {isLoadingHistoryCards && (
        <Paragraph text="Loading..." fontColor={FontColors.PRIMARY} fontSize={FontSizes.REGULAR} />
      )}
      {!isLoadingHistoryCards && historyCards && historyCards.length > 0 && (
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          wrap
          extraClasses="assessment-card-container"
        >
          {historyCards.map((card) => (assessmentCard(card)))}
        </FlexContainer>
      )}
      {!isLoadingHistoryCards && (!historyCards || historyCards.length < 1) && (
        <Paragraph text="Nothing available at this time, please check back later." fontColor={FontColors.PRIMARY} fontSize={FontSizes.REGULAR} />
      )}
    </FlexContainer>
  );

  return (
    <PageLayout layout={PageLayoutVariant.PADDED} testText="Patient Dashboard Page">
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="patient-dashboard-patient-view"
      >
        <PrimaryHeader text="Assessments" fontColor={FontColors.PRIMARY} />
        <Tabs>
          <TabList>
            <Tab>
              <InlineText
                text="Current"
                fontColor={FontColors.DARK}
                fontSize={FontSizes.LARGE}
              />
            </Tab>
            <Tab>
              <InlineText
                text="History"
                fontColor={FontColors.DARK}
                fontSize={FontSizes.LARGE}
              />
            </Tab>
          </TabList>

          <TabPanel>
            {currentAssessments()}
          </TabPanel>
          <TabPanel>
            {historyAssessments()}
          </TabPanel>
        </Tabs>
      </FlexContainer>
    </PageLayout>
  );
};

export default PatientDashboardPage;
