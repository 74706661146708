import DataListsProxy from 'api/dataLists/dataListsProxy';
import Utilities from 'api/lib/Utilities';
import { ListCorporation } from 'interfaces/dataLists/listCorporation';
import { ListLevelOfCare } from 'interfaces/dataLists/listLevelOfCare';
import { ListPopulation } from 'interfaces/dataLists/listPopulation';
import { HiddenQuestion } from './hidden_question';
import { HiddenQuestionTitles } from './hiddenQuestionNames';

/**
 * The singleton definition class for the HiddenQuestions.  To retain a list of the hidden questions and their definitions.
 */
export class HiddenQuestions {
  private static instance: HiddenQuestions;
  private QuestionsList: HiddenQuestion[] = [];

  private listPromises: Promise<any[]>[];

  private constructor() {
    this.listPromises = [
      DataListsProxy.getLevelOfCareList(),
      DataListsProxy.getUserCorpList(),
      DataListsProxy.getListPopulation(),
    ];
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation let you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): HiddenQuestions {
    if (!HiddenQuestions.instance) {
      HiddenQuestions.instance = new HiddenQuestions();
    }

    return HiddenQuestions.instance;
  }

  /**
   * Create a hidden question for the Level of Care and add it to the list of hidden questions.
   *
   * @param levelOfCareList List of level of care for the user from the API.
   * @param questionList buffer list to maintain a temp list of the hidden questions;
   */
  private createLevelCareQuestion(levelOfCareList: ListLevelOfCare[], questionList: HiddenQuestion[]): void {
    if (levelOfCareList && levelOfCareList.length > 0) {
      const choices = levelOfCareList.map((level) => ({
        value: level.treatmentTypeID.toString(),
        text: level.treatmentType,
      }));
      const questions = new HiddenQuestion(
        HiddenQuestionTitles.LEVELOFCARE, 'aff77b1e-b93a-4ac0-b40b-d8854e187f6f', choices,
      );
      questionList.push(questions);
    }
  }

  /**
   * Create a hidden question for the Corporations and add it to the list of hidden questions.
   *
   * @param corporations List of Corporations for the user from the API.
   * @param questionList buffer list to maintain a temp list of the hidden questions;
   */
  private createCorporationQuestion(corporations: ListCorporation[], questionList: HiddenQuestion[]): void {
    if (corporations && corporations.length > 0) {
      const choices = corporations.map((corporation) => ({
        value: corporation.corporationID.toString(),
        text: corporation.corporationName,
      }));
      const questions = new HiddenQuestion(
        HiddenQuestionTitles.CORPORATION, '72582944-77fc-470a-8143-6a4ada6dcb9b', choices,
      );
      questionList.push(questions);
    }
  }

  /**
   * Create a hidden question for the Population and add it to the list of hidden questions.
   *
   * @param populations List of Population for the user from the API.
   * @param questionList buffer list to maintain a temp list of the hidden questions;
   */
  private createPopulationQuestion(populations: ListPopulation[], questionList: HiddenQuestion[]): void {
    if (populations && populations.length > 0) {
      const choices = populations.map((population) => ({
        value: population.populationTypeID.toString(),
        text: population.populationType,
      }));
      const questions = new HiddenQuestion(
        HiddenQuestionTitles.POPULATION, 'd6dc82cf-fd08-48ab-a882-316c433ac4fc', choices,
      );
      questionList.push(questions);
    }
  }

  public getQuestionsList(): Promise<HiddenQuestion[]> {
    return new Promise<HiddenQuestion[]>((resolve, reject) => {
      // All questions are fully populated by the api.
      if (Utilities.isEmpty(this.QuestionsList)) {
        // one or more promises are still outstanding.  wait for completion.
        Promise.all(this.listPromises).then(([careList, corporations, populations]) => {
          const questionList: HiddenQuestion[] = [];
          this.createLevelCareQuestion(careList, questionList);
          this.createCorporationQuestion(corporations, questionList);
          this.createPopulationQuestion(populations, questionList);

          this.QuestionsList = questionList;
          resolve(this.QuestionsList);
        }).catch((errorResponse) => {
          reject(errorResponse);
        });
      } else {
        resolve(this.QuestionsList);
      }
    });
  }

  /**
   * Find and retrieve an instance of a hidden question if the question title compared against exists, or null
   * if the title isn't found.
   *
   * @param title question title to search against.
   * @returns an instance of a hidden question if the question is found, or null.
   */
  public getQuestion(title: string): HiddenQuestion | null {
    for (let i = 0; i < this.QuestionsList.length; i += 1) {
      if (this.QuestionsList[i].getQuestionTitle() === title) {
        return this.QuestionsList[i];
      }
    }

    return null;
  }

  /**
   * Checks to see if one of the question's ID matches the argument passed in.
   *
   * @param questionID question ID to compare against.
   * @returns true if the question ID exists for one of the hidden question.
   */
  public includesQuestionID(questionID: string): boolean {
    for (let i = 0; i < this.QuestionsList.length; i += 1) {
      if (this.QuestionsList[i].getQuestionDesc() === questionID) {
        return true;
      }
    }

    return false;
  }
}
