import * as React from 'react';

import './styles.scss';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';

import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { FacilityProgramList } from 'types/facilityUserAccount';
import { CheckboxTreeProps } from 'types/inputProps';

const CheckboxTree = (props: CheckboxTreeProps): JSX.Element => {
  const checkboxItemList = props.defaultItems ? { ...props.defaultItems, ...props.items } : props.items;
  const checkboxLabel = (label: string): JSX.Element => (
    <InlineText
      text={label}
      fontColor={FontColors.DARK}
      fontSize={FontSizes.REGULAR}
    />
  );

  const checkbox = (name: string, label: string, checked: boolean): JSX.Element => (
    <label className="checkbox-tree__checkbox">
      <input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={props.onChange}
      />
      <div className="checkbox-tree__custom-box" />
      {checkboxLabel(label)}
    </label>
  );

  const editableSection = (content: FacilityProgramList, keyNum: number): JSX.Element => (
    <div
      key={`${content.facility.facilityID}-${content.programList.length}-${keyNum}`}
      className="checkbox-tree__section"
    >
      {checkbox(content.facility.facilityName, content.facility.facilityName, content.facilityChecked)}
      <div className="checkbox-tree__list" hidden={content.facilityChecked}>
        {content.programList.map((facilityProgram) => (
          <div
            key={`${content.facility.facilityID}-${facilityProgram.program.programID}`}
            className="checkbox-tree__item"
          >
            {checkbox(`${content.facility.facilityName}-${facilityProgram.program.programName}`, facilityProgram.program.programName, facilityProgram.isChecked)}
          </div>
        ))}
      </div>
      <hr />
    </div>
  );

  const displaySection = (content: FacilityProgramList, keyNum: number): JSX.Element => (
    <div
      key={`${content.facility.facilityID}-${content.programList.length}-${keyNum}`}
      className="checkbox-tree__section__static"
    >
      {checkboxLabel(content.facility.facilityName)}
      <div className="checkbox-tree__list" hidden={content.facilityChecked}>
        {content.programList.map((facilityProgram) => (
          <div
            key={`${content.facility.facilityID}-${facilityProgram.program.programID}`}
            className="checkbox-tree__item__static"
          >
            {checkboxLabel(facilityProgram.program.programName)}
          </div>
        ))}
      </div>
      <hr />
    </div>
  );

  return (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      align={AlignVariant.START}
      justify={JustifyVariant.START}
      extraClasses="checkbox-tree"
    >
      {props.editable
        ? (
          checkboxItemList.map((item, index) => (
            editableSection(item, index)
          ))
        ) : (
          checkboxItemList.map((item, index) => (
            displaySection(item, index)
          ))
        )}
    </FlexContainer>
  );
};

export default CheckboxTree;
