import * as React from 'react';
import { FacilityDashboard } from 'pages/shared/Facility/dashboard';

const FacilityDirectorDashboardPage = (): JSX.Element => (
  <FacilityDashboard
    testText="Facility Director Dashboard Page"
    title="Facility Director Dashboard"
  />
);

export default FacilityDirectorDashboardPage;
