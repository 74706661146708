const DEFAULT_MAX_LENGTH = 1000

export class CommentController {
  static updateQuestionStyle(option: any): void {
    const { htmlElement: questionHTML, question } = option
    let maxLength = question.getPropertyValue('maxLength')

    const textAreaElement = questionHTML.querySelector('textarea')
    if (!textAreaElement) {
      console.error('Missing input element')
      return
    }

    if (!maxLength || maxLength === -1) {
      maxLength = DEFAULT_MAX_LENGTH
      textAreaElement.setAttribute('maxlength', `${DEFAULT_MAX_LENGTH}`)
    }

    if (maxLength > 0) {
      const characterCountElement = document.createElement('div')
      characterCountElement.setAttribute('style', 'text-align: right')
      characterCountElement.innerHTML = `0/${maxLength}`

      textAreaElement.parentNode.appendChild(characterCountElement)

      textAreaElement.addEventListener('input', () => {
        const characterCount = textAreaElement.value.length
        characterCountElement.innerHTML = `${characterCount}/${maxLength}`
      })
    }
  }
}
