import { ChangeEvent, useContext, useEffect, useState } from 'react'

import './styles.scss'

import PageLayout from 'global_elements/Layouts/PageLayout'
import FlexContainer from 'global_elements/Layouts/FlexContainer'

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import { DisplayVariant, JustifyVariant, AlignVariant } from 'global_elements/Layouts/FlexContainer/variants'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy'
import Paragraph from 'global_elements/Text/Paragraph'
import { PatientConsentData, PatientConsentPostData } from 'interfaces/patients/patientData'
import Button from 'global_elements/Button'
import { ButtonVariant } from 'global_elements/Button/variants'
import InlineText from 'global_elements/Text/InlineText'
import { useHistory } from 'react-router-dom'
import { PatientRoutes } from 'constants/routes'
import { UserContext } from 'context/user'
import UserProxy from 'api/user/userProxy'
import UserAccount from 'interfaces/users/userAccount'
import SecondaryHeader from 'global_elements/Text/SecondaryHeader'
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput'
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants'
import PopupWindow from 'global_elements/Layouts/PopupWindow'
import MHODateTime from 'domain/dateTime/MHODateTime'
import Signatures from './signatures'

const PatientConsentPage = (): JSX.Element => {
  const [reflectionsConsent, setReflectionsConsent] = useState<PatientConsentData | null>(null)
  const [hipaaConsent, setHipaaConsent] = useState<PatientConsentData | null>(null)
  const [hipaaFormAgreed, setHipaaFormAgreed] = useState<string>('')
  const [hipaaFormGuardian, setHipaaFormGuardian] = useState<string>('')
  const [isOtherGuardianChecked, setIsOtherGuardianChecked] = useState<boolean>(false)
  const [hipaaOtherGuardian, setHipaaOtherGuardian] = useState<string>('')
  const [ipAddress, setIpAddress] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [declineTermsMessage, setDeclineTermsMessage] = useState<string>('');
  const history = useHistory()
  const { user, patientReflectionsConsentAccepted, patientHipaaConsentAccepted, SetPatientReflectionsConsentAccepted, SetPatientHipaaConsentAccepted } = useContext(UserContext)

  const isInHipaaDeclineWindow = (dateDischarged: string | null): boolean => {
    const declinedDate = new Date(dateDischarged || '')
    const today = new Date()
    const difference = today.getTime() - declinedDate.getTime()
    const days = difference / (1000 * 3600 * 24);
    return days < 30
  }

  useEffect(() => {
    if (user && (!patientReflectionsConsentAccepted || !reflectionsConsent)) {
      const userAccountIdStr = user ? user.accountId : '-1'
      const userAccountId = parseInt(userAccountIdStr, 10)

      if (!ipAddress) {
        UserProxy.getClientIp().then((ip) => setIpAddress(ip))
      }

      if (userAccountIdStr && userAccountId) {
        UserProxy.getUserAccountInfoV2(userAccountId)
          .then(async (accountResponse) => {
            if (accountResponse.data?.length !== 1) return
            const patientData: UserAccount = accountResponse.data[0]
            const patientConsent = await PatientUsersProxy.getPatientConsent(patientData.patientID)

            if (patientConsent.length) {
              const reflectionsConsentData = patientConsent.find((c) => c.consentTypeID === 1)
              setReflectionsConsent(reflectionsConsentData ?? null)
              const hipaaConsentData = patientConsent.find((c) => c.consentTypeID === 2)
              setHipaaConsent(hipaaConsentData ?? null)

              const isAccepted = reflectionsConsentData ? reflectionsConsentData.consentObtained === 1 : false
              if (isAccepted) {
                SetPatientReflectionsConsentAccepted(isAccepted)
              }

              const hipaaAccepted = hipaaConsentData ? hipaaConsentData.consentObtained === 1 : false;
              const hipaaAnswered = hipaaConsentData && hipaaConsentData.consentAcceptedDate;
              const hipaaAnsweredWithin30Days = hipaaConsentData && isInHipaaDeclineWindow(hipaaConsentData.consentAcceptedDate);
              const dischargedWithin30Days = hipaaConsentData && isInHipaaDeclineWindow(hipaaConsentData.dateDischarged);
              const bypassHipaa = hipaaAccepted
                || (hipaaAnswered && dischargedWithin30Days)
                || (hipaaAnswered && hipaaAnsweredWithin30Days && !hipaaConsentData.dateDischarged);
              if (bypassHipaa) {
                SetPatientHipaaConsentAccepted(true)
              }

              if (isAccepted && bypassHipaa) {
                history.push(PatientRoutes.DASHBOARD)
              }

              if (dischargedWithin30Days) {
                setDeclineTermsMessage('You will have another opportunity to accept or decline these terms at 30 days post-discharge.');
              } else {
                setDeclineTermsMessage('You will not be able to use Reflections without accepting these terms.');
              }
            }
            setLoading(false)
          })
          .catch((errorResponse) => {
            console.log(errorResponse)
            setErrorMessage('There was an error loading the patient consent information.')
            setLoading(false)
          });
      }
    } else if (patientReflectionsConsentAccepted) {
      history.push(PatientRoutes.DASHBOARD)
    }
  }, [user, patientReflectionsConsentAccepted, patientHipaaConsentAccepted])

  const acceptConsent = (): void => {
    if (!reflectionsConsent) return

    setSaving(true)
    const postReflectionsConsent: PatientConsentPostData = {
      patientConsentID: reflectionsConsent.patientConsentID,
      accountID: reflectionsConsent.accountID,
      careID: reflectionsConsent.careID,
      consentTypeID: reflectionsConsent.consentTypeID,
      consentAgreementTypeID: 1,
      consentVersionID: reflectionsConsent.consentVersionID,
      consentAuthorityTypeID: reflectionsConsent.consentAuthorityTypeID,
      consentAuthorityOtherText: reflectionsConsent.consentAuthorityOtherText,
      consentAcceptedDate: reflectionsConsent.consentAcceptedDate,
      consentIPAddress: ipAddress,
      consentDeviceID: null,
      statusID: 1,
      createdBy: null,
      updatedBy: null,
    }
    PatientUsersProxy.acceptPatientConsent([postReflectionsConsent]).then(() => {
      SetPatientReflectionsConsentAccepted(true)
      history.push(PatientRoutes.DASHBOARD)
    }).catch((error) => {
      console.error(error)
      setErrorMessage('There was an unexpected error. Please try again.')
    }).finally(() => {
      setSaving(false)
    })
  }

  const submitHipaa = (accepted: boolean): void => {
    if (!hipaaConsent) return;
    setSaving(true)
    const postReflectionsConsent: PatientConsentPostData = {
      patientConsentID: hipaaConsent.patientConsentID,
      accountID: hipaaConsent.accountID,
      careID: hipaaConsent.careID,
      consentAgreementTypeID: accepted ? 1 : 2, // 1 = agree, 2 = decline
      consentTypeID: hipaaConsent.consentTypeID,
      consentVersionID: hipaaConsent.consentVersionID,
      consentAuthorityTypeID: Number.parseInt(hipaaFormGuardian, 10),
      consentAuthorityOtherText: hipaaOtherGuardian,
      consentAcceptedDate: new MHODateTime(new Date()).getDateTimeForPost(),
      consentIPAddress: ipAddress,
      consentDeviceID: null,
      statusID: 1,
      createdBy: null,
      updatedBy: null,
    }

    PatientUsersProxy.acceptPatientConsent([postReflectionsConsent]).then(() => {
      SetPatientHipaaConsentAccepted(accepted);
      history.push(PatientRoutes.DASHBOARD)
    }).catch((error) => {
      console.error(error)
      setErrorMessage('There was an unexpected error. Please try again.')
    }).finally(() => {
      setSaving(false)
    })
  }

  const continueHipaa = (): void => {
    if (hipaaFormAgreed === '2') {
      setShowPopup(true);
    } else {
      submitHipaa(hipaaFormAgreed === '1');
    }
  };

  const getConsentTitle = (): string => (reflectionsConsent?.consentType ?? '')
  const getConsentText = (): string => (reflectionsConsent?.consentVersionText ?? '')
  const getHipaaConsentTitle = (): string => (hipaaConsent?.consentType ?? '')
  const getHipaaConsentText = (): string => (hipaaConsent?.consentVersionText ?? '')
  const todaysDate = new Date().toLocaleDateString('en-us', { year: 'numeric', month: 'numeric', day: 'numeric' })

  /**
   * Handle "Agree / Decline" radio buttons.
   *
   * @param e click event
   */
  const hipaaConsentChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    setHipaaFormAgreed(e.target.value)
  };

  /**
   * Handle "Guardian" radio buttons.
   *
   * @param e click event
   */
  const guardianChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsOtherGuardianChecked(e.target.value === '4')
    setHipaaFormGuardian(e.target.value)
  };

  /**
   * If the continue button should be disabled based on form selection.
   * @returns boolean
   */
  const isContinueDisabled = (): boolean => {
    const disabled = hipaaFormAgreed === '';
    return disabled;
  };

  const getPopupWindow = (): JSX.Element => (
    <PopupWindow>
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        justify={JustifyVariant.CENTER}
        align={AlignVariant.CENTER}
        extraClasses="close-button"
        testid="hipaa-decline-terms-popup"
      >
        <h2>Decline Terms?</h2>
        <Paragraph
          text={declineTermsMessage}
          fontSize={FontSizes.REGULAR}
          fontColor={FontColors.DARK}
          testId="hipaa-decline-terms-popup-message"
          dangerouslySetInnerHTML
        />
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          justify={JustifyVariant.END}
          align={AlignVariant.END}
        >
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              setShowPopup(false)
            }}
            extraClasses="consent-btns--margin-right"
          >
            <InlineText
              text="Cancel"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
          <Button
            variant={ButtonVariant.DARK}
            onClick={(e) => {
              e?.preventDefault();
              e?.stopPropagation();
              submitHipaa(false);
            }}
            testid="hipaa-decline-terms-popup-decline-button"
          >
            <InlineText
              text="Decline"
              fontColor={FontColors.BACKGROUND}
              fontSize={FontSizes.REGULAR}
              bold
            />
          </Button>
        </FlexContainer>
      </FlexContainer>
    </PopupWindow>
  );

  return (
    <PageLayout
      layout={PageLayoutVariant.LOGGED_OUT}
      testText="Patient Consent Page"
      loadingTitle={loading ? 'Patient Consent' : undefined}
      loadingText={loading ? 'Loading...' : undefined}
      hideHeader
    >
      {showPopup && getPopupWindow()}
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="patient-consent"
      >
        {errorMessage && (
          <Paragraph text={errorMessage} fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.REGULAR} />
        )}

        {!loading && reflectionsConsent && !patientReflectionsConsentAccepted && !errorMessage && (
          <>
            <SecondaryHeader
              text={getConsentTitle()}
              fontColor={FontColors.SECONDARY}
              marginBottomPx={16}
              testId="header"
            />
            <FlexContainer
              display={DisplayVariant.FLEX_ROW}
              align={AlignVariant.START}
              justify={JustifyVariant.START}
              extraClasses="patient-consent__terms"
            >
              <Paragraph
                text={getConsentText()}
                fontSize={FontSizes.REGULAR}
                fontColor={FontColors.DARK}
                dangerouslySetInnerHTML
              />
            </FlexContainer>
            <FlexContainer
              display={DisplayVariant.FLEX_ROW}
              align={AlignVariant.CENTER}
              justify={JustifyVariant.END}
              extraClasses="patient-consent__buttons"
            >
              <Button
                onClick={() => acceptConsent()}
                variant={ButtonVariant.PRIMARY}
                disabled={saving}
                testid="accept-consent-button"
              >
                <InlineText
                  text={saving ? 'Saving...' : 'Next'}
                  fontColor={FontColors.BACKGROUND}
                  fontSize={FontSizes.REGULAR}
                  bold
                />
              </Button>
            </FlexContainer>
          </>
        )}
        {!loading && !reflectionsConsent && !errorMessage && (
          <Paragraph text="No patient consent information found." fontColor={FontColors.DARK} fontSize={FontSizes.REGULAR} />
        )}

        {!loading && patientReflectionsConsentAccepted && hipaaConsent && !errorMessage && (
          <>
            <SecondaryHeader
              text={getHipaaConsentTitle()}
              fontColor={FontColors.SECONDARY}
              marginBottomPx={16}
              testId="header"
            />
            <FlexContainer
              display={DisplayVariant.FLEX_ROW}
              align={AlignVariant.START}
              justify={JustifyVariant.START}
              extraClasses="patient-consent__terms"
            >
              <Paragraph
                text={getHipaaConsentText()}
                fontSize={FontSizes.REGULAR}
                fontColor={FontColors.DARK}
                dangerouslySetInnerHTML
              />
            </FlexContainer>
            <FlexContainer
              display={DisplayVariant.FLEX_COL}
              align={AlignVariant.START}
              justify={JustifyVariant.SPACE_BETWEEN}
              extraClasses="patient-search-content__text-inputs"
            >
              <Paragraph
                text={hipaaConsent.consentAuthorizationText || ''}
                fontSize={FontSizes.REGULAR}
                fontColor={FontColors.DARK}
                dangerouslySetInnerHTML
              />
              <form>
                <div className="control-bar-top">
                  <input type="radio" id="authority-parent-rb" name="work-list-type-rb" value="2" onChange={guardianChanged} />
                  <label htmlFor="program-work-list-rb">Parent</label>
                </div>
                <div className="control-bar-top">
                  <input type="radio" id="authority-guardian-rb" name="work-list-type-rb" value="3" onChange={guardianChanged} />
                  <label htmlFor="service-work-list-rb">Legal Guardian</label>
                </div>
                <div className="control-bar-top">
                  <input type="radio" id="authority-other-rb" name="work-list-type-rb" value="4" onChange={guardianChanged} />
                  <label htmlFor="service-work-list-rb">Other (describe authority)</label>
                </div>
                {isOtherGuardianChecked && (
                  <LabledTextInput
                    label=""
                    placeholder="Describe authority..."
                    name="describeAuthority"
                    value={hipaaOtherGuardian}
                    onChange={(e) => setHipaaOtherGuardian(e.target.value)}
                    type="text"
                    variant={TextInputVariant.PRIMARY}
                  />
                )}
              </form>
              <Paragraph
                text={hipaaConsent.consentAgreementText || ''}
                fontSize={FontSizes.REGULAR}
                fontColor={FontColors.DARK}
                dangerouslySetInnerHTML
              />
              <form>
                <div className="control-bar-top">
                  <input type="radio" id="authority-agree-rb" name="work-list-type-rb" data-testid="hipaa-agree-radio" value="1" onChange={hipaaConsentChanged} />
                  <label htmlFor="service-work-list-rb">Agree</label>
                </div>
                <div className="control-bar-top inline-flex">
                  <input type="radio" id="authority-decline-rb" name="work-list-type-rb" data-testid="hipaa-decline-radio" value="2" onChange={hipaaConsentChanged} />
                  <label htmlFor="service-work-list-rb">I decline consent at this time and understand I will not be able to use this site after the initial period</label>
                </div>
              </form>
              <Signatures email={user?.email} date={todaysDate} />
              <Paragraph
                text={hipaaConsent.consentFooterText || ''}
                fontSize={FontSizes.REGULAR}
                fontColor={FontColors.DARK}
                dangerouslySetInnerHTML
              />
            </FlexContainer>
            <FlexContainer
              display={DisplayVariant.FLEX_ROW}
              align={AlignVariant.CENTER}
              justify={JustifyVariant.END}
              extraClasses="patient-consent__buttons"
            >
              <Button
                onClick={() => continueHipaa()}
                variant={ButtonVariant.PRIMARY}
                disabled={isContinueDisabled()}
                testid="accept-consent-button"
              >
                <InlineText
                  text={saving ? 'Saving...' : 'Continue'}
                  fontColor={FontColors.BACKGROUND}
                  fontSize={FontSizes.REGULAR}
                  bold
                />
              </Button>
            </FlexContainer>
          </>
        )}

        {!loading && reflectionsConsent && !hipaaConsent && !errorMessage && (
          <Paragraph text="No HIPAA consent information found." fontColor={FontColors.DARK} fontSize={FontSizes.REGULAR} />
        )}

      </FlexContainer>
    </PageLayout>
  )
}

export default PatientConsentPage
