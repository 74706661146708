import * as React from 'react';
import InlineText from '../InlineText';
import { FontSizes, FontColors } from '../variants';

import '../styles.scss';
import './styles.scss';

const PrintFooter = (props: { patientInfo: string }): JSX.Element => (
  <footer className="print-only print-footer">
    <div className="footer-name-wrapper">
      <div className="name-container">
        <InlineText
          text={props.patientInfo}
          fontColor={FontColors.DARK}
          fontSize={FontSizes.LARGE}
        />
      </div>
    </div>
  </footer>
);

export default React.memo(PrintFooter);
