import * as React from 'react';

import './styles.scss';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import Paragraph from 'global_elements/Text/Paragraph';
import AsyncSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/asyncSingleSelect';

import { AsyncLabledSingleSelectProps } from 'types/inputProps';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';

const LabledAsyncSingleSelect = (props: any): JSX.Element => (
  <label className="labeled-single-select">
    <FlexContainer
      display={DisplayVariant.FLEX_ROW}
      align={AlignVariant.CENTER}
      justify={JustifyVariant.START}
      extraClasses="labeled-single-select__header"
    >
      <Paragraph
        text={`${props.label}${props.error ? ` -- ${props.error}` : ''}`}
        fontColor={props.error ? FontColors.HIGH_PRIORITY : FontColors.DARK_GRAY}
        fontSize={FontSizes.REGULAR}
        bold
      />
    </FlexContainer>
    <AsyncSingleSelect
      key={props.propsKey}
      propsKey={props.propsKey}
      styles={props.styles}
      defaultValue={props.defaultValue}
      value={props.value}
      loadOptions={props.loadOptions}
      onSelection={props.onSelection}
      onblur={props.onblur}
      menuPlacement={props.menuPlacement}
    />
  </label>
);

export default React.memo(
  LabledAsyncSingleSelect,
  (prevProps: Readonly<AsyncLabledSingleSelectProps>, nextProps: Readonly<AsyncLabledSingleSelectProps>) => (
    prevProps.value?.value === nextProps.value?.value
      && prevProps.error === nextProps.error
  ),
);
