import * as React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import AssessmentPage from 'pages/shared/Survey';
import AssessmentResultsPage from 'pages/shared/AssessmentResult/assessmentResults';
import SuperCorporationDashboardPage from 'pages/super_corporation/SuperCorporationDashboard';
import FacilityUserSettingsPage from 'pages/shared/FacilityUserSettings';
import PatientAccountsPage from 'pages/shared/PatientAccounts';
import PatientDetailsPage from 'pages/shared/PatientDetails';
import PatientRegistrationPage from 'pages/shared/PatientRegistration';
import PatientAccountSetupPage from 'pages/shared/PatientAccountSetup';
import FacilityUserAccountsPage from 'pages/shared/FacilityUserAccounts';
import FacilityUserRegistrationPage from 'pages/shared/FacilityUserRegistration';
import FacilityUserEditPage from 'pages/shared/FacilityUserEdit';
import PrintableAssessmentsPage from 'pages/shared/PrintableAssessments';
import ReportsPage from 'pages/shared/Reports';
import HelpPage from 'pages/shared/Help';
import Page404 from 'pages/errors/404';
import CoreMeasuresWorklistPage from 'pages/shared/CoreMeasuresWorklist';
import CoreMeasuresFacilityInfoPage from 'pages/shared/CoreMeasuresFacilityInfo';
import CoreMeasuresFacilityInfoPopulationEntryPage from 'pages/shared/CoreMeasuresFacilityInfoPopulationEntry'

import { AllRoutes, ExecutiveRoutes } from 'constants/routes';
import { PDFFileDownload } from 'pages/shared/PrintableAssessments/download';
import { HelpFileDownload } from 'pages/shared/Help/download';
import { AttachmentDownload } from '../../shared/Attachments/download';
import FacilityLibraryPage from '../../shared/FacilityLibrary';

const SuperCorporationRouting = (): JSX.Element => (
  <Router>
    <Switch>
      <Redirect
        exact
        path={AllRoutes.LOGIN}
        to={ExecutiveRoutes.DASHBOARD}
      />
      <Route
        exact
        path={ExecutiveRoutes.DASHBOARD}
        component={SuperCorporationDashboardPage}
      />
      <Route
        exact
        path={ExecutiveRoutes.PATIENT_ACCOUNTS}
        component={PatientAccountsPage}
      />
      <Route
        exact
        path={`${ExecutiveRoutes.PATIENT_REGISTRATION}/:facilityID`}
        component={PatientRegistrationPage}
      />
      <Route
        exact
        path={`${ExecutiveRoutes.PATIENT_ACCOUNTS}/:patient`}
        component={PatientDetailsPage}
      />
      <Route
        exact
        path={`${ExecutiveRoutes.PATIENT_ACCOUNTS}/:patient/account-setup`}
        component={PatientAccountSetupPage}
      />
      <Route
        exact
        path={ExecutiveRoutes.FACILITY_USER_ACCOUNTS}
        component={FacilityUserAccountsPage}
      />
      <Route
        path={ExecutiveRoutes.FACILITY_USER_REGISTRATION}
        component={FacilityUserRegistrationPage}
      />
      <Route
        path={`${ExecutiveRoutes.FACILITY_USER_EDIT}/:accountID`}
        component={FacilityUserEditPage}
      />
      <Route
        path={ExecutiveRoutes.PRINTABLE_ASSESSMENTS}
        component={PrintableAssessmentsPage}
      />
      <Route
        path={ExecutiveRoutes.REPORTS}
        component={ReportsPage}
      />
      <Route
        path={ExecutiveRoutes.ACCOUNT}
        component={FacilityUserSettingsPage}
      />
      <Route
        path={`${AllRoutes.HELP_DOWNLOAD}/:filename`}
        component={HelpFileDownload}
      />
      <Route
        path={ExecutiveRoutes.HELP}
        component={HelpPage}
      />
      <Route
        path={`${AllRoutes.ATTACHMENT_DOWNLOAD}/:attachmentId`}
        component={AttachmentDownload}
      />
      <Route
        path={`${AllRoutes.DOWNLOAD}/:filename`}
        component={PDFFileDownload}
      />
      <Route
        path={`${ExecutiveRoutes.ASSESSMENT}/:assessmentNumber/:instrumentTypeID/:appliedWhenID/:patientID`}
        component={AssessmentPage}
      />
      <Route
        path={`${ExecutiveRoutes.ASSESSMENT_RESULTS}/:careID/:instrumentTypeID/:patientID`}
        component={AssessmentResultsPage}
      />
      <Route
        path={ExecutiveRoutes.CORE_MEASURES_WORKLIST}
        component={CoreMeasuresWorklistPage}
      />
      <Route
        path={ExecutiveRoutes.CORE_MEASURES_FACILITY_INFO}
        component={CoreMeasuresFacilityInfoPage}
      />
      <Route
        path={`${ExecutiveRoutes.CORE_MEASURES_FACILITY_INFO_POPULATION_ENTRY}`}
        component={CoreMeasuresFacilityInfoPopulationEntryPage}
      />
      <Route
        path={`${ExecutiveRoutes.FACILITY_LIBRARY}`}
        component={FacilityLibraryPage}
      />
      <Route component={Page404} />
    </Switch>
  </Router>
);

export default React.memo(SuperCorporationRouting);
