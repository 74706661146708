import * as React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import MHODateTime from 'domain/dateTime/MHODateTime';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { ReactComponent as SortIcon } from 'icons/mho-sort-icon-white.svg';

import { ProviderWorkListProps, ProviderWorkListRow } from 'types/tableProps';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';

import { HoverTableStyles } from '../styles';

const NoDataComponent: JSX.Element = (
  <InlineText
    text="No Results Found."
    fontColor={FontColors.PRIMARY}
    fontSize={FontSizes.LARGE}
  />
);

export enum WorkListColumnNames {
  PATIENT_NAME = 'Patient Name',
  ACCOUNT_NUMBER = 'Account Number',
  MEDICAL_RECORD = 'Medical Record',
  ADMISSION_DATE = 'Admission Date',
  PROGRAM = 'Program',
  UNIT = 'Unit',
  PROVIDER = 'Provider',
  PATIENT_STATUS = 'Patient Status',
}

const columns: TableColumn<ProviderWorkListRow>[] = [
  {
    name: WorkListColumnNames.PATIENT_NAME,
    selector: (row) => row.patientName,
    sortable: true,
  },
  {
    name: WorkListColumnNames.ACCOUNT_NUMBER,
    selector: (row) => row.accountNumber,
    sortable: true,
  },
  {
    name: WorkListColumnNames.MEDICAL_RECORD,
    selector: (row) => row.medicalRecord,
    sortable: true,
  },
  {
    name: WorkListColumnNames.ADMISSION_DATE,
    selector: (row) => row.admissionDate,
    sortable: true,
    format: (row) => (new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/).test(row.admissionDate)
      ? new MHODateTime(row.admissionDate).getFormattedCalendarDate()
      : row.admissionDate),
  },
  {
    name: WorkListColumnNames.PROGRAM,
    selector: (row) => row.program,
    sortable: true,
  },
  {
    name: WorkListColumnNames.UNIT,
    selector: (row) => row.unit,
    sortable: true,
  },
  {
    name: WorkListColumnNames.PROVIDER,
    selector: (row) => row.provider,
    sortable: true,
  },
  {
    name: WorkListColumnNames.PATIENT_STATUS,
    selector: (row) => row.patientStatus,
    sortable: true,
  },
];

const ProviderWorkList = (props: ProviderWorkListProps): JSX.Element => {
  let tableColumns = columns
  if (props.getColumns) {
    tableColumns = props.getColumns(columns)
  }

  return (
    <FlexContainer
      display={DisplayVariant.FLEX_COL}
      justify={JustifyVariant.START}
      align={AlignVariant.CENTER}
      extraClasses="provider-work-list"
    >
      {props.isLoading ? (
        <div className="loading-icon-overlay-container">
          <FontAwesomeIcon icon={faSpinner} className="fa-spin loading-icon-overlay" />
        </div>
      ) : (
        <DataTable
          dense
          highlightOnHover
          persistTableHead
          pagination
          sortIcon={<SortIcon />}
          customStyles={HoverTableStyles}
          columns={tableColumns}
          data={props.data}
          noDataComponent={NoDataComponent}
          onRowClicked={props.onRowClicked}
          selectableRows={props.selectableRows}
          selectableRowSelected={props.selectableRowSelected}
          onSelectedRowsChange={props.onSelectedRowsChange}
          onChangePage={props.onChangePage}
        />
      )}
    </FlexContainer>
  )
};

export default ProviderWorkList;
