/* eslint-disable */
import { Component } from 'react';
import { Prompt } from 'react-router-dom';
import * as Survey from 'survey-core';
import { SurveyCreator } from 'survey-creator-react';
import 'nouislider/distribute/nouislider.min.css';
import 'pages/shared/assessments/questions/slider/styles.scss';
import { HiddenQuestions } from 'constants/MHO/hidden_questions';
import { SurveyJSQuestionTypes } from 'constants/SurveyJS/question_types';
import { AssessmentEditorState, AssessmentEditorProps } from 'types/assessments/assessmentEditor';
import { AdditionalSurveyAttrHandler } from 'pages/shared/assessments/assessmentSurveyAttr';
import { EmoticonController } from 'pages/shared/assessments/questions/emoticon/emoticonController';
import { RatingEmoticons } from 'pages/shared/assessments/questions/emoticon/emoticon-ratings';
import AssessmentDefinitionProxy from 'api/assessments/assessmentDefinitionProxy';
import Utilities from 'api/lib/Utilities';
import NotyfContext from 'context/NotyfContext';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import './styles.scss';
import { CommentController } from 'pages/shared/assessments/questions/comment/commentController';
import { initSurveyJsSlider } from 'pages/shared/assessments/questions/slider/slider';
import FeatureFlagManager from 'config/featureFlags/FeatureFlagManager';
import { FeatureFlagName } from 'config/featureFlags';

const _ = require('lodash');
const showdown = require('showdown');

initSurveyJsSlider(Survey);

/**
 * Assessment Creator/Editor manager.  This component reads the raw question data (if the Survey JS version
 * doesn't exist) from the database, and converts the raw question data to custom Survey JS format. The user
 * can then edit, organize, and make any other modifications to the assessment before saving them.
 * Once the Assessment is saved as a Survey JS format, it'll be available for users to take.
 */
export class AssessmentCreator extends Component<AssessmentEditorProps, AssessmentEditorState> {
  surveyCreator: SurveyCreator;
  renderContainerId = 'assessmentEditorContainer';
  context!: React.ContextType<typeof NotyfContext>;
  menuPopupModel: Survey.PopupModel;

  constructor(props: AssessmentEditorProps) {
    super(props);

    if (props.surveyJSFormDefinition) {
      this.state = {
        surveyJSFormDefinition: props.surveyJSFormDefinition,
      };
    }

    const creatorOptions = {
      showJSONEditorTab: true, // Remove after development
      showLogicTab: true,
      questionTypes: [
        SurveyJSQuestionTypes.TEXT,
        SurveyJSQuestionTypes.CHECKBOX,
        SurveyJSQuestionTypes.DROPDOWN,
        SurveyJSQuestionTypes.RADIO_GROUP,
        SurveyJSQuestionTypes.MATRIX_SINGLE,
        SurveyJSQuestionTypes.EXPRESSION,
        SurveyJSQuestionTypes.HTML,
        SurveyJSQuestionTypes.PANEL,
        SurveyJSQuestionTypes.RATING,
        SurveyJSQuestionTypes.DYNAMIC_PANEL,
        ...(FeatureFlagManager.get<boolean>(FeatureFlagName.isCommentQuestionEnabled) ? [SurveyJSQuestionTypes.COMMENT] : []),
      ],

      showTranslationTab: true,
      showSidebar: true,
      showElementEditorAsPropertyGrid: true,
    };

    this.surveyCreator = new SurveyCreator(creatorOptions);

    // Set license key. In development this will just be an empty string, but it shouldn't
    // have an impact on functionality.
    if (process.env.REACT_APP_SURVEYJS_LICENSE_KEY) {
      Survey.setLicenseKey(process.env.REACT_APP_SURVEYJS_LICENSE_KEY);
    }

    const self = this;
    this.menuPopupModel = new Survey.PopupModel(
      'sv-list',
      {
        model: new Survey.ListModel(
          [
            { id: 'alt-option-raw-reset', title: 'Reset to Default' },
            { id: 'alt-option-revert', title: 'Revert' },
          ],
          (item: any) => {
            this.menuPopupModel.toggleVisibility();
            let successMessage = '';
            switch (item.id) {
              case 'alt-option-raw-reset':
                self.loadAssessmentEditor(self.props.rawAssessmentJson);
                successMessage = 'Assessment successfully reset back to default.';
                break;
              case 'alt-option-revert':
                self.loadAssessmentEditor(this.props.assessmentDefinitionJSON);
                successMessage = 'Assessment restored to the last saved version.';
                break;
              default:
                console.log('unknown option');
            }
            if (successMessage) {
              this.context.success(successMessage);
            }
          },
          true
        ),
      },
      'bottom',
      'center'
    );
  }

  /**
   * Override the beforeunload event to ensure the <Prompt> for 'save before close' dialog is triggered on browser back/refresh.
   *
   * @param event beforeunload event
   */
  handlePageClosePrompt = (event: any): any => {
    event.preventDefault();
    event.returnValue = ''; // eslint-disable-line no-param-reassign
  };

  componentDidMount(): void {
    window.addEventListener('beforeunload', this.handlePageClosePrompt);

    this.SetupSurveyJSEditorTheme();
    this.AddCustomPropertiesToEditorJSON();
    this.CustomizeEditorProperties();
    // JBC SurveyCreator.removeAdorners(['choices-label', 'choices-draggable', 'select-choices']);

    this.loadAssessmentEditor(this.props.assessmentDefinitionJSON || this.props.rawAssessmentJson);
    this.loadAssessmentSettings();
  }

  componentWillUnmount(): void {
    window.removeEventListener('beforeunload', this.handlePageClosePrompt);
  }

  isAssessmentDirty = (): boolean => {
    return this.surveyCreator.state === 'modified';
  };

  SetupSurveyJSEditorTheme = (): void => {
    Survey.StylesManager.applyTheme('default');

    // JBC
    // const mainColor = '#6177a3';
    // const defaultThemeColorsEditor = Survey.StylesManager.ThemeColors.default;
    // defaultThemeColorsEditor['$primary-color'] = mainColor;
    // defaultThemeColorsEditor['$secondary-color'] = mainColor;
    // defaultThemeColorsEditor['$primary-hover-color'] = mainColor;
    // defaultThemeColorsEditor['$primary-text-color'] = 'black';
    // defaultThemeColorsEditor['$selection-border-color'] = mainColor;
    // Survey.StylesManager.applyTheme();

    // const defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors.default;
    // defaultThemeColorsSurvey['$main-color'] = mainColor;
    // defaultThemeColorsSurvey['$main-hover-color'] = mainColor;
    // defaultThemeColorsSurvey['$text-color'] = mainColor;
    // defaultThemeColorsSurvey['$header-color'] = mainColor;
    // defaultThemeColorsSurvey['$header-background-color'] = mainColor;
    // defaultThemeColorsSurvey['$body-container-background-color'] = mainColor;
    // Survey.StylesManager.applyTheme();
  };

  addCustomQuestionStyle(options: any): void {
    const questionType = options.question.getType();

    switch (questionType) {
      case SurveyJSQuestionTypes.RATING: {
        if (options.question.getPropertyValue('useEmoticon')) {
          EmoticonController.updateEmoticonRatingStyle(options);
        }

        break;
      }
      case SurveyJSQuestionTypes.COMMENT: {
        CommentController.updateQuestionStyle(options);
        break;
      }
      default:
        break;
    }
  }

  /**
   * Async callback function to retrieve any specified Extreme Response Message to show the user.
   * This returns a dummy data just to show some text. Real text would show in the Taker.
   *
   * @param this *hack* to type 'this' to any. 'this' is for the async call back to the surveyJS expression.
   * @returns dummy data. Async call returns true.
   */
  showExtremeMessage(this: any): boolean {
    this.returnResult(true);
    return false;
  }

  /**
   * Async callback function to retrieve a flag whether to check if the current user is a patient or not.
   * This returns a dummy data to hook into the Survey Creator. The real value will be usable in the taker.
   *
   * @param this *hack* to type 'this' to any. 'this' is for the async call back to the surveyJS expression.
   * @returns dummy data. Async call returnResult with the actual value.
   */
  isNonPatientUser(this: any): boolean {
    this.returnResult(false); // eslint-disable-line react/no-this-in-sfc
    return false; // this is ignored.
  }

  AddCustomPropertiesToEditorJSON = (): void => {
    Survey.settings.useLocalTimeZone = true;
    const additionalProperties = AdditionalSurveyAttrHandler.getInstance().getAdditionalProperties();
    for (let index = 0; index < additionalProperties.length; index += 1) {
      Survey.Serializer.addProperty(additionalProperties[index].propertyName, additionalProperties[index].propertyInfo);
    }

    Survey.Serializer.getProperty('comment', 'maxLength').defaultValue = 1000;

    // Register dummy functions just to show something for the editor.
    Survey.FunctionFactory.Instance.register('showExtremeMessage', this.showExtremeMessage, true);
    Survey.FunctionFactory.Instance.register('isNotPatient', this.isNonPatientUser, true);

    const self = this;
    this.surveyCreator.toolbarItems.push(
      new Survey.Action({
        id: 'action-dropdown',
        title: '...',
        // iconName: '\f2bb',
        visible: true,
        component: 'sv-action-bar-item-dropdown',
        popupModel: self.menuPopupModel,
        action: () => {
          self.menuPopupModel.toggleVisibility();
        },
      })
    );

    // add custom close button
    this.surveyCreator.toolbarItems.push(
      new Survey.Action({
        id: 'close-editor',
        visible: true,
        title: 'Close',
        action() {
          self.props.closeEditorEvent(self.isAssessmentDirty());
        },
      })
    );

    this.surveyCreator.onPreviewSurveyCreated.add((_survey: any, options: any) => {
      options.survey.onAfterRenderQuestion.add((_survey: any, options: any) => {
        this.addCustomQuestionStyle(options);
      });
    });
  };

  CustomizeEditorProperties = (): void => {
    // JBC
    // Survey.Serializer.findProperty('question', 'name').readOnly = true;
    // Survey.Serializer.findProperty('question', 'variableID').readOnly = true;
    // Survey.Serializer.findProperty('question', 'valueName').readOnly = true;
    // Survey.Serializer.findProperty('question', 'readOnly').visible = false;
    // Survey.Serializer.findProperty('question', 'correctAnswer').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'choices').readOnly = true;
    // Survey.Serializer.findProperty('radiogroup', 'choicesFromQuestion').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'hasOther').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'hasComment').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'hasNone').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'showClearButton').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'choicesByUrl').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'enableIf').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'defaultValueExpression').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'hideIfChoicesEmpty').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'choicesVisibleIf').visible = false;
    // Survey.Serializer.findProperty('radiogroup', 'choicesEnableIf').visible = false;
    // Survey.Serializer.findProperty('dropdown', 'optionsCaption').visible = false;
    // Survey.Serializer.findProperty('dropdown', 'choices').visible = false;
    // Survey.Serializer.findProperty('dropdown', 'choicesMin').visible = false;
    // Survey.Serializer.findProperty('dropdown', 'choicesMax').visible = false;
    // Survey.Serializer.findProperty('dropdown', 'choicesStep').visible = false;
    // Survey.Serializer.findProperty('dropdown', 'showOptionsCaption').visible = false;
    // Survey.Serializer.findProperty('matrix', 'columns').visible = false;
    // Survey.Serializer.findProperty('matrix', 'rows').visible = false;
    // Survey.Serializer.findProperty('matrix', 'cells').visible = false;
    // Survey.Serializer.findProperty('matrix', 'columnsVisibleIf').visible = false;
    // Survey.Serializer.findProperty('matrix', 'rowsVisibleIf').visible = false;
    // Survey.Serializer.findProperty('text', 'inputType').choicesValue = [
    //     'date', 'datetime', 'datetime-local', 'email', 'number', 'tel', 'text', 'time', 'url',
    // ];
  };

  /**
   * Save the current Survey with a API call to the MHO database.
   *
   * @param saveNo save operation number.
   * @param callback optional paramater to notify when the save operation is complete.
   */
  private saveCurrentSurvey = (saveNo: number, callback: Function): void => {
    this.state.surveyJSFormDefinition.AssessmentEditorJSON = this.surveyCreator.text;

    if (!this.state.surveyJSFormDefinition.AssessmentEditorID) {
      this.state.surveyJSFormDefinition.CreatedBy = this.props.editingUserEmail;
    }

    this.state.surveyJSFormDefinition.UpdatedBy = this.props.editingUserEmail;
    this.state.surveyJSFormDefinition.StatusID = 1; // Set to dropdown value

    const data = [this.state.surveyJSFormDefinition];

    AssessmentDefinitionProxy.saveAssessmentJSONDefinition(data)
      .then((assessmentEditorId: number) => {
        if (Number.isInteger(assessmentEditorId)) {
          this.state.surveyJSFormDefinition.AssessmentEditorID = assessmentEditorId;
          this.context.success('Save Successful!');
        }
      })
      .catch(() => {
        this.context.error('An unknown error occurred');
      });
  };

  loadAssessmentEditor = (assessmentJSON: any): boolean => {
    // eslint-disable-next-line no-alert
    const updateQuestions =
      Utilities.isEmpty(this.surveyCreator.JSON.pages) || _.isEqual(this.surveyCreator.JSON, assessmentJSON) || window.confirm('This action will override the current assessment. Continue?');

    if (updateQuestions) {
      this.surveyCreator.JSON = assessmentJSON;
    }
    return updateQuestions;
  };

  loadAssessmentSettings = (): void => {
    this.surveyCreator.saveSurveyFunc = this.saveCurrentSurvey;

    // this.surveyCreator.showToolbox = 'left';
    // this.surveyCreator.showPropertyGrid = 'left';
    // this.surveyCreator.leftContainerActiveItem('toolbox');
    // this.surveyCreator.clearInvisibleValues = none; // This may go on the survey object and not editor

    // JBC
    // this.surveyCreator.tabs().push({
    //     name: 'emergent-messaging',
    //     title: 'Emergent Messaging',
    //     template: 'custom-tab-emergent-messaging',
    //     action: () => {
    //         this.surveyCreator.makeNewViewActive('emergent-messaging');
    //     },
    //     data: {},
    // });

    // this.surveyCreator.tabs().push({
    //     name: 'additional-settings',
    //     title: 'Additional Settings',
    //     template: 'custom-tab-additional-settings',
    //     action: () => {
    //         this.surveyCreator.makeNewViewActive('additional-settings');
    //     },
    //     data: {},
    // });

    const converter = new showdown.Converter();

    function doMarkdown(survey: any, options: any): void {
      let str = converter.makeHtml(options.text);
      if (str.indexOf('<p>') === 0) {
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
      }
      options.html = str;
    }

    this.surveyCreator.survey.onTextMarkdown.add(doMarkdown);

    this.surveyCreator.onDesignerSurveyCreated.add((editor: any, options: any) => {
      options.survey.onTextMarkdown.add(doMarkdown);
    });

    this.surveyCreator.onTestSurveyCreated.add((editor: any, options: any) => {
      options.survey.onTextMarkdown.add(doMarkdown);
    });

    // Temporarily disable the code below -- until more testing and settings can be better locked down to suite the client's need.
    this.surveyCreator.render(this.renderContainerId);
    return;

    // this.surveyCreator.toolbox.changeCategories([
    //     { name: SurveyJSQuestionTypes.HTML, category: 'General' },
    //     { name: SurveyJSQuestionTypes.PANEL, category: 'General' },
    //     { name: SurveyJSQuestionTypes.TEXT, category: 'Types' },
    //     { name: SurveyJSQuestionTypes.CHECKBOX, category: 'Types' },
    //     { name: SurveyJSQuestionTypes.RADIO_GROUP, category: 'Types' },
    //     { name: SurveyJSQuestionTypes.DROPDOWN, category: 'Types' },
    //     { name: SurveyJSQuestionTypes.MATRIX_SINGLE, category: 'Types' },
    // ]);

    this.surveyCreator.onQuestionAdded.add((sender: any, options: any) => {
      // Left empty for future use
    });

    // this.surveyCreator.onCanShowProperty.add((_sender: any, options: any) => {
    //     const { obj } = options;

    //     if (!obj || !obj.page) return;

    //     if (HiddenQuestions.getInstance().includesQuestionID(obj.description)) {
    //         options.canShow = false;
    //     }
    // });

    this.surveyCreator.onElementAllowOperations.add((_sender: any, options: any) => {
      const { obj } = options;

      if (!obj || !obj.page) return;

      if (HiddenQuestions.getInstance().includesQuestionID(obj.description)) {
        options.allowDelete = false;
        options.allowEdit = false;
        options.allowCopy = false;
        options.allowAddToToolbox = false;
        options.allowDragging = false;
        options.allowChangeType = false;
        options.allowChangeRequired = false;
        options.allowShowHideTitle = false;
        options.allowShowEditor = false;
      } else if (obj.getType() === SurveyJSQuestionTypes.PANEL) {
        options.allowDelete = obj.questions.length === 0;
        options.allowShowHideTitle = false;
        options.allowEdit = true;
        options.allowChangeRequired = true;
        options.allowCopy = false;
        options.allowAddToToolbox = false;
        options.allowChangeType = false;
      } else if (obj.getType() === SurveyJSQuestionTypes.HTML) {
        options.allowDelete = true;
        options.allowEdit = true;
        options.allowCopy = true;
        options.allowShowHideTitle = true;
        options.allowAddToToolbox = false;
        options.allowChangeType = false;
        options.allowChangeRequired = false;
      } else {
        options.allowChangeType = true;
        options.allowChangeRequired = true;
        options.allowEdit = true;
        options.allowShowHideTitle = true;
        options.allowDragging = true;
        options.allowDelete = false;
        options.allowCopy = false;
        options.allowAddToToolbox = false;
      }
    });

    this.surveyCreator.render(this.renderContainerId);
  };

  render(): JSX.Element {
    return (
      <>
        <script type="text/html" id="custom-tab-emergent-messaging">
          {'<div id="test">Emergent Messaging</div>'}
        </script>
        <script type="text/html" id="custom-tab-additional-settings">
          {'<div id="test">Survey State:</div>'}
        </script>
        <div id="survey-creator-container">
          <div id={this.renderContainerId} className="creator-element" />
        </div>
        <Prompt when={this.isAssessmentDirty()} message="Are you sure you want to close the editor? Any unsaved changes will be lost." />
        <RatingEmoticons />
      </>
    );
  }
}
