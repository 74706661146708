import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AttachmentsProxy from '../../../api/attachments/AttachmentsProxy';

type AttachmentDownloadProp = {
  attachmentId: string
}

/**
 * Retrieves the attachment file from the API/DB and inserts it into an IFrame to display.
 *
 * @returns iframe component with the file data loaded.
 */
export const AttachmentDownload = (): JSX.Element => {
  const { attachmentId } = useParams<AttachmentDownloadProp>();
  const [fileData, setFileData] = useState<any>();
  /**
   * Retrieve the attachment file from the api/db then convert into a base64 to load.
   */
  useEffect(() => {
    AttachmentsProxy.download(attachmentId)
      .then((response) => {
        if (response.data) {
          const reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onloadend = () => {
            const base64String = reader.result as string;
            setFileData(base64String);
          };
        }
      }).catch((error) => {
        console.log(error);
      });
  }, [attachmentId]);

  return (
    <iframe title={attachmentId} src={fileData} className="pdf-frame" />
  );
};
