import { Configuration, LogLevel } from '@azure/msal-browser';
import { AnonymousRoutes } from 'constants/routes';

export const SharedPolicies = {
  authorityDomain: 'mhob2c.b2clogin.com',
};

export const MsalConfig: Configuration = {
  auth: {
    clientId: 'cb4071d0-c11d-40a2-8d9a-20f78a52e3fb',
    knownAuthorities: [SharedPolicies.authorityDomain],
    redirectUri: AnonymousRoutes.LOGIN,
    postLogoutRedirectUri: AnonymousRoutes.LOGIN,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message); // Uncomment for debugging
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.log(message);
        }
      },
    },
  },
};
