import './styles.scss'
import { useHistory, useParams } from 'react-router-dom'
import { useContext, useState } from 'react'
import PageLayout from 'global_elements/Layouts/PageLayout'
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants'
import FlexContainer from 'global_elements/Layouts/FlexContainer'
import { DisplayVariant, AlignVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants'
import PrimaryHeader from 'global_elements/Text/PrimaryHeader'
import { FontColors, FontSizes } from 'global_elements/Text/variants'
import Button from 'global_elements/Button'
import { ButtonVariant } from 'global_elements/Button/variants'
import InlineText from 'global_elements/Text/InlineText'
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput'
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants'
import Paragraph from 'global_elements/Text/Paragraph'
import GroupTherapyProxy from 'api/groupTherapy/GroupTherapyProxy'
import { UserContext } from 'context/user'
import { UriParams } from 'api/groupTherapy/types'

const MAX_ERROR_COUNT = 3
const PIN_LENGTH = 6
const PIN_ERROR = 'Invalid PIN, please try again.'
const PIN_ERROR_MAX = 'Invalid PIN, please try again. If you cannot remember your PIN, close browser and log back into Reflections. This will return you to your Dashboard.'

const GroupTherapyExitSessionPage = (): JSX.Element => {
  const { groupId } = useParams<UriParams>()
  const history = useHistory()
  const [exitPin, setExitPin] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { SetActiveGroupTherapySessionId } = useContext(UserContext)
  // eslint-disable-next-line
  const [_failedAttempts, setFailedAttempts] = useState<number>(0)

  const goBack = (): void => {
    history.goBack()
  }

  const inputValid = (): boolean => (exitPin.length === PIN_LENGTH)

  const pinChange = (e: any): void => {
    setExitPin(e.target.value.replace(/[^0-9]/g, '').substring(0, PIN_LENGTH))
  }

  const submit = async (): Promise<void> => {
    await GroupTherapyProxy.getGroupTherapySession('all', groupId).then((result) => {
      if (result[0].groupTherapyPIN.toString() === exitPin) {
        setErrorMessage('')
        SetActiveGroupTherapySessionId(null)
        history.push('/')
      } else {
        setFailedAttempts((prevAttempts) => {
          const newAttempts = prevAttempts + 1
          if (newAttempts < MAX_ERROR_COUNT) {
            setErrorMessage(PIN_ERROR)
          } else {
            setErrorMessage(PIN_ERROR_MAX)
          }

          return newAttempts
        })
      }
    })
  }

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Exit Group Therapy Session Page"
      hideHeader
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="group-therapy-exit-session-container"
      >
        <PrimaryHeader text="Exit Group Therapy Session" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
        {errorMessage !== '' && (
          <Paragraph
            text={errorMessage}
            fontColor={FontColors.HIGH_PRIORITY}
            fontSize={FontSizes.REGULAR}
            extraClasses="error-message"
          />
        )}
        <form onSubmit={submit}>
          <LabledTextInput
            label="Session PIN (6 digits)"
            instructions="Enter the PIN set at the beginning of this session."
            name="exit-pin"
            value={exitPin}
            type="password"
            onChange={(e) => pinChange(e)}
            variant={TextInputVariant.PRIMARY}
            maxTextLength={PIN_LENGTH}
            testid="exit-pin-input"
            autoComplete="new-password"
            autoFocus
            inputMode="numeric"
          />
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            justify={JustifyVariant.SPACE_BETWEEN}
            align={AlignVariant.START}
            extraClasses="button-container"
          >
            <Button
              variant={ButtonVariant.SECONDARY}
              onClick={goBack}
              testid="back-button"
            >
              <InlineText
                text="Back to Session"
                fontColor={FontColors.BACKGROUND}
                fontSize={FontSizes.REGULAR}
                bold
              />
            </Button>
            <Button
              variant={ButtonVariant.PRIMARY}
              onClick={submit}
              disabled={!inputValid()}
              testid="submit-button"
              submit
            >
              <InlineText
                text="Exit Session"
                fontColor={FontColors.BACKGROUND}
                fontSize={FontSizes.REGULAR}
                bold
              />
            </Button>
          </FlexContainer>
        </form>
      </FlexContainer>
    </PageLayout>
  )
}

export default GroupTherapyExitSessionPage
