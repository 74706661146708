import { PublicClientApplication } from '@azure/msal-browser';
import { getMsalConfig, isTestEnvironment } from 'config/msal/configMgmt';

const msalInstance = isTestEnvironment() ? null : new PublicClientApplication(getMsalConfig());

const acquireAccessToken = async () : Promise<string> => {
  // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  if (!isTestEnvironment() && msalInstance) {
    const activeAccount = msalInstance.getActiveAccount();

    if (activeAccount) {
      const accounts = msalInstance.getAllAccounts();

      if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
      }
      const request = {
        scopes: ['https://mhob2c.onmicrosoft.com/api/tasks.read'],
        account: activeAccount || accounts[0],
        authority: 'https://mhob2c.b2clogin.com/mhob2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      };

      const authResult = await msalInstance.acquireTokenSilent(request);

      return authResult.accessToken;
    }
  }

  return '';
};

export default acquireAccessToken;
