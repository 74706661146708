import { HttpStatus } from 'constants/httpStatus';
import HttpStatusConverter from '../../converters/httpStatusConverter';

/**
 * @deprecated use v2 instead
 */
class ApiResponse<T> {
    status: HttpStatus;
    message: string;
    data: T|null;
    success: boolean;

    constructor(httpStatus: number | null, message: string, data: T|null = null) {
      this.status = HttpStatusConverter.intToHttpStatus(httpStatus);
      this.message = message;
      this.data = data;

      this.success = (
        this.status === HttpStatus.OK
          || this.status === HttpStatus.CREATED
      );
    }
}

export default ApiResponse;
